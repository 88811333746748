import { connect } from 'react-redux';
import Component from './component';
var mapStateToProps = function (state) {
    return {
        token: state.playerAuthorization.token,
        isBank: state.player.company.isBank,
        playerId: state.player.id,
        companyId: state.player.company.id,
        taskSettings: state.game.taskSettings
    };
};
export default connect(mapStateToProps)(Component);
