var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import PlayersListGrouped from 'components/shared/transaction/new/playersList/playersListGrouped/component';
import PlayersListUngrouped from 'components/shared/transaction/new/playersList/playersListUngrouped/component';
import { filterByKeyword } from 'helpers/utilities';
import { NEW_TRANSACTION_INITIAL_VIEW } from 'components/player/money/transactions/types';
import clsx from 'clsx';
import { setT } from 'helpers/translationUtilities';
var BULK_SELECT_COMPANIES = 'BULK_SELECT_COMPANIES';
var BULK_SELECT_PLAYERS = 'BULK_SELECT_PLAYERS';
PlayersList.propTypes = {
    companies: PropTypes.array,
    players: PropTypes.array,
    updateMainPayload: PropTypes.func.isRequired,
    currentAccountId: PropTypes.string,
    gameRules: PropTypes.object.isRequired,
    isFacilitatorBulkView: PropTypes.bool,
    selectedPlayers: PropTypes.array
};
export default function PlayersList(_a) {
    var _b = _a.companies, companies = _b === void 0 ? [] : _b, _c = _a.players, players = _c === void 0 ? [] : _c, updateMainPayload = _a.updateMainPayload, _d = _a.currentAccountId, currentAccountId = _d === void 0 ? '' : _d, gameRules = _a.gameRules, _e = _a.isFacilitatorBulkView, isFacilitatorBulkView = _e === void 0 ? false : _e, _f = _a.selectedPlayers, selectedPlayers = _f === void 0 ? [] : _f;
    var playersAndCompanies = __spreadArray(__spreadArray([], players, true), companies, true);
    if (!isFacilitatorBulkView) {
        players = setDisableProperty(players);
        companies = setDisableProperty(companies);
    }
    var _g = useState(__spreadArray([], selectedPlayers, true)), selected = _g[0], setSelected = _g[1];
    var _h = useState(''), keyword = _h[0], setKeyword = _h[1];
    var groups = createCompanyPlayerGroups(companies, players);
    if (keyword) {
        playersAndCompanies = filterByKeyword(playersAndCompanies, keyword);
    }
    function accountDisablingConditions(gameRules, isListAccountCompany, isCurrentAccountCompany) {
        return [
            !gameRules.companyToCompany && isCurrentAccountCompany && isListAccountCompany,
            !gameRules.companyToPlayer && isCurrentAccountCompany && !isListAccountCompany,
            !gameRules.playerToCompany && !isCurrentAccountCompany && isListAccountCompany,
            !gameRules.playerToPlayer && !isCurrentAccountCompany && !isListAccountCompany,
            isCurrentAccountCompany.id === currentAccountId
        ];
    }
    function setDisableProperty(accounts) {
        var currentAccount = playersAndCompanies.find(function (account) { return currentAccountId === account.id; });
        var isCurrentAccountCompany = currentAccount && currentAccount.isCompany;
        return accounts.map(function (account) {
            account.disabled = accountDisablingConditions(gameRules, account.isCompany, isCurrentAccountCompany).some(function (condition) { return condition === true; });
            return account;
        });
    }
    function handleSearchChange(_a) {
        var value = _a.target.value;
        setKeyword(value);
    }
    function onGroupItemSelect(id) {
        if (!isFacilitatorBulkView) {
            setSelected(__spreadArray(__spreadArray([], selectedPlayers, true), [id], false));
            updateMainPayload({
                to: [id]
            }, NEW_TRANSACTION_INITIAL_VIEW);
        }
        var index = selected.findIndex(function (selectedId) { return selectedId === id; });
        var newSelectedArray = __spreadArray([], selected, true);
        index !== -1 ? newSelectedArray.splice(index, 1) : newSelectedArray.push(id);
        setSelected(newSelectedArray);
    }
    function facilitatorBulkSelect(selectionType) {
        areAllEntitiesInAGroupSelected(selectionType === BULK_SELECT_COMPANIES ? companies : players, selected) ?
            setSelected(removeGroupFromSelected(selectionType)) :
            setSelected(setGroupToSelected(selectionType));
    }
    function removeGroupFromSelected(selectionType) {
        var group = selectionType === BULK_SELECT_COMPANIES ? companies : players;
        var entitiesToRemove = __spreadArray([], group, true).filter(function (item) { return selectionType === BULK_SELECT_COMPANIES ? item.isCompany : !item.isCompany; }).map(function (item) { return item.id; });
        return __spreadArray([], selected, true).filter(function (id) {
            var toBeRemoved = entitiesToRemove.some(function (entityId) { return id === entityId; });
            return !toBeRemoved;
        });
    }
    function setGroupToSelected(selectionType) {
        var group = selectionType === BULK_SELECT_COMPANIES ? companies : players;
        var entitiesToAdd = __spreadArray([], group, true).filter(function (item) {
            return !selected.some((function (selectedID) { return selectedID === item.id; }));
        }).map(function (item) { return item.id; });
        return __spreadArray(__spreadArray([], selected, true), entitiesToAdd, true);
    }
    function areAllEntitiesInAGroupSelected(group, selected) {
        var everyoneInGroupSelected = true;
        for (var i = 0; i < group.length; i++) {
            var isPersonPresent = false;
            for (var j = 0; j < selected.length; j++) {
                if (group[i].id === selected[j]) {
                    isPersonPresent = true;
                    break;
                }
            }
            if (!isPersonPresent) {
                everyoneInGroupSelected = false;
            }
        }
        return everyoneInGroupSelected;
    }
    function onFacilitatorProceedClick() {
        if (!selected.length) {
            return;
        }
        updateMainPayload({
            to: __spreadArray([], selected, true)
        }, NEW_TRANSACTION_INITIAL_VIEW);
    }
    return (React.createElement("div", { className: "user-select-list" },
        React.createElement("div", { className: "user-select-list__header" },
            React.createElement("input", { type: "text", onChange: handleSearchChange, value: keyword }),
            isFacilitatorBulkView ?
                React.createElement(Fragment, null,
                    React.createElement("button", { className: clsx(['main-button main-button--lg main-button--hollow', areAllEntitiesInAGroupSelected(players, selected) && 'main-button--hollow-active']), onClick: function () { return facilitatorBulkSelect(BULK_SELECT_PLAYERS); } }, setT('select_all_players')),
                    React.createElement("button", { className: clsx(['main-button main-button--lg main-button--hollow', areAllEntitiesInAGroupSelected(companies, selected) && 'main-button--hollow-active']), onClick: function () { return facilitatorBulkSelect(BULK_SELECT_COMPANIES); } }, setT('select_all_companies')),
                    React.createElement("button", { className: clsx(['main-button main-button--lg', !selected.length && 'main-button--disabled']), onClick: function () { return onFacilitatorProceedClick(); } }, setT('proceed'))) : null),
        React.createElement("div", { className: "user-select-list__list" }, !keyword ? (groups.map(function (group) { return (React.createElement(PlayersListGrouped, { key: group.id, group: group, selected: selected, onSelect: onGroupItemSelect })); })) : (React.createElement(PlayersListUngrouped, { players: playersAndCompanies, selected: selected, onSelect: onGroupItemSelect })))));
}
function createCompanyPlayerGroups(companies, players) {
    return companies.map(function (company) {
        company.relatedPlayers = players.filter(function (player) { return player.companyID === company.id; });
        return company;
    });
}
