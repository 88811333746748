import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import SetOutsideClickEvents from 'helpers/outsideClickHandler';
import clsx from 'clsx';
GenericDropdown.propTypes = {
    options: PropTypes.array,
    opensInFullscreen: PropTypes.bool,
    component: PropTypes.func,
    onButtonClick: PropTypes.func.isRequired,
    onOptionClick: PropTypes.func.isRequired,
    selectedLabel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onOutsideClick: PropTypes.func.isRequired
};
export default function GenericDropdown(_a) {
    var _b = _a.options, options = _b === void 0 ? [] : _b, _c = _a.opensInFullscreen, opensInFullscreen = _c === void 0 ? false : _c, _d = _a.component, component = _d === void 0 ? null : _d, onButtonClick = _a.onButtonClick, onOptionClick = _a.onOptionClick, selectedLabel = _a.selectedLabel, isOpen = _a.isOpen, onOutsideClick = _a.onOutsideClick;
    var dropdownRef = useRef(null);
    SetOutsideClickEvents(onOutsideClick, dropdownRef);
    return (React.createElement("div", { className: "dropdown", ref: dropdownRef },
        React.createElement("button", { className: "dropdown__button", onClick: onButtonClick, type: "button" },
            React.createElement("span", { className: "dropdown__icon" }),
            React.createElement("span", null, selectedLabel)),
        React.createElement(CSSTransition, { in: isOpen, timeout: 300, classNames: "anim-fade", unmountOnExit: true },
            React.createElement("div", { className: clsx(['dropdown__area', opensInFullscreen && 'dropdown__area--fullscreen']) }, options.length ? getGenericDropdownMenu(options, onOptionClick) : component))));
}
function getGenericDropdownMenu(options, onOptionClick) {
    return (React.createElement("div", { className: "dropdown__menu" }, options.map(function (option) { return (React.createElement("div", { key: option.id, className: "dropdown__menu-item", onClick: function (e) { return onOptionClick(option, e); } }, option.label)); })));
}
