var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Modal from 'components/player/shared/modal/component';
import React, { useEffect, useRef, useState } from 'react';
import GenericDropdown from 'components/shared/dropdown/component';
import { playerRequestAddPlayer } from '../../../../../socketConnection/socketMessageRequests/playerRequests';
import { setT } from 'helpers/translationUtilities';
import CustomToast from 'components/shared/toast/component';
import { usePrevious } from 'helpers/utilityHooks';
import { useTranslation } from 'react-i18next';
export default function NewPlayerModal(_a) {
    var _this = this;
    var _b = _a.players, players = _b === void 0 ? [] : _b, _c = _a.companies, companies = _c === void 0 ? [] : _c, _d = _a.websocketMessage, websocketMessage = _d === void 0 ? function () { } : _d, _e = _a.modalAction, modalAction = _e === void 0 ? function () { } : _e, _f = _a.modalOpen, modalOpen = _f === void 0 ? false : _f;
    var t = useTranslation().t;
    var inputRef = useRef(null);
    var _g = useState(false), companySelectOpen = _g[0], setCompanySelectOpen = _g[1];
    var _h = useState(''), selectedCompanyId = _h[0], setSelectedCompanyId = _h[1];
    var _j = useState(false), waitForToast = _j[0], setWaitForToast = _j[1];
    var _k = useState(''), toastMessage = _k[0], setToastMessage = _k[1];
    var prevPlayers = usePrevious(players) || [];
    var selectedCompany = companies.find(function (company) { return selectedCompanyId === company.id; });
    useEffect(function () {
        if (waitForToast && players.length > prevPlayers.length) {
            CustomToast().success({
                position: 'TOP_CENTER',
                time: 3000,
                message: toastMessage
            });
        }
    }, [players, toastMessage]);
    var dropdownOptions = companies.map(function (company) {
        return {
            id: company.id,
            value: company.id,
            label: company.name
        };
    });
    dropdownOptions.unshift({ id: '', value: '', label: setT('select_company') });
    var onFormSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var username, companyName, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    username = e.target.elements.username.value;
                    companyName = selectedCompanyId ? companies.find(function (company) { return company.id === selectedCompanyId; }).name : setT('unset_company');
                    if (!username) return [3 /*break*/, 2];
                    setToastMessage("".concat(t('player'), " ").concat(username, " ").concat(t('added_to_company'), " ").concat(companyName));
                    setWaitForToast(true);
                    inputRef.current.value = '';
                    _a = websocketMessage;
                    return [4 /*yield*/, playerRequestAddPlayer(username, selectedCompanyId || '')];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Modal, { expanded: modalOpen, onClose: function () { return modalAction(false); }, modalInnerClasses: "overflow-visible" },
        React.createElement("form", { className: "text-align-center", onSubmit: onFormSubmit },
            React.createElement("div", { className: "main-title" }, setT('add_player')),
            React.createElement("div", { className: "mar-bottom-20" },
                React.createElement("input", { ref: inputRef, className: "main-input w-100", type: "text", name: "username", style: { maxWidth: '400px' } })),
            React.createElement("div", { className: "mar-bottom-20" },
                React.createElement(GenericDropdown, { opensInFullscreen: true, selectedLabel: selectedCompany ? selectedCompany.name : dropdownOptions[0].label, onButtonClick: function () { return setCompanySelectOpen(!companySelectOpen); }, onOutsideClick: function () { return setCompanySelectOpen(false); }, isOpen: companySelectOpen, onOptionClick: function (option) {
                        setSelectedCompanyId(option.id);
                        setCompanySelectOpen(false);
                    }, options: dropdownOptions })),
            React.createElement("div", { className: "d-flex flex-gap-10 mar-bottom-10 justify-content-center" },
                React.createElement("button", { className: "main-button main-button--lg main-button--secondary", type: "button", onClick: function () { return modalAction(false); } }, setT('close')),
                React.createElement("button", { className: "main-button main-button--lg main-button--green", type: "submit" }, setT('add_player'))))));
}
