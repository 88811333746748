var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { get, post } from 'axios';
import { ADMIN_CREATE_NEW_USER_API_FAIL, ADMIN_PURGE_FORM_ERRORS, ADMIN_PURGE_SINGLE_TEMPLATE, ADMIN_RECEIVE_SINGLE_GAME_TASKS, ADMIN_RECEIVE_SINGLE_TEMPLATE, ADMIN_RECEIVE_SINGLE_TEMPLATE_API_FAIL, ADMIN_RECEIVE_TEMPLATES, ADMIN_RECEIVE_TEMPLATES_API_FAIL, GAME_PURGE_ALL_DATA, GAME_RECEIVE_SINGLE_GAME_FROM_API, MANAGER_SINGLE_GAME_API_FAILED, PLAYER_PURGE_ALL_DATA, PLAYER_PURGE_COMPANY, WEBSOCKET_DISCONNECTED } from 'components/constants';
import { userAuthLogoutRequestAction } from '../userAuthorization.actions';
import ApiGameService from 'services/ApiService/ApiGameService/ApiGameService';
import { disconnect } from 'library/socket-connection';
import ApiService from 'services/ApiService/ApiService';
export var adminGetTemplateListingAction = function (authToken) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, get("".concat(window.globals.API_URL, "/templates"), {
                            withCredentials: true,
                            headers: {
                                'User-Auth-Token': authToken,
                            },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    dispatch({
                        payload: data,
                        type: ADMIN_RECEIVE_TEMPLATES
                    });
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    dispatch({
                        type: ADMIN_RECEIVE_TEMPLATES_API_FAIL
                    });
                    if (err_1.response && err_1.response.status === 403) {
                        dispatch(userAuthLogoutRequestAction());
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var adminGetTemplateAction = function (id, authToken) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, get("".concat(window.globals.API_URL, "/templates/").concat(id), {
                            withCredentials: true,
                            headers: {
                                'User-Auth-Token': authToken,
                            },
                        })];
                case 1:
                    data = (_a.sent()).data;
                    dispatch({
                        payload: data,
                        type: ADMIN_RECEIVE_SINGLE_TEMPLATE
                    });
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    dispatch({
                        type: ADMIN_RECEIVE_SINGLE_TEMPLATE_API_FAIL
                    });
                    if (err_2.response && err_2.response.status === 403) {
                        dispatch(userAuthLogoutRequestAction());
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var adminResetTemplateStateAction = function () {
    return function (dispatch) {
        dispatch({
            type: ADMIN_PURGE_SINGLE_TEMPLATE
        });
    };
};
export var adminCreateNewUserAction = function (payload) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, post("".concat(window.globals.API_URL, "/admin/users"), payload, {
                            withCredentials: true
                        })];
                case 1:
                    data = (_a.sent()).data;
                    dispatch({
                        payload: data,
                        type: ADMIN_PURGE_FORM_ERRORS
                    });
                    return [3 /*break*/, 3];
                case 2:
                    err_3 = _a.sent();
                    if (err_3.response && err_3.response.status === 400) {
                        dispatch({
                            payload: err_3.response.data.length
                                ? err_3.response.data[0].message
                                : null,
                            type: ADMIN_CREATE_NEW_USER_API_FAIL
                        });
                    }
                    if (err_3.response && err_3.response.status === 403) {
                        dispatch(userAuthLogoutRequestAction());
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var adminGetGameTasksAction = function (gameID) {
    var address = "".concat(window.globals.API_URL, "/games/").concat(gameID, "/taskset");
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, err_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, get(address, { withCredentials: true })];
                case 1:
                    data = (_a.sent()).data;
                    dispatch({
                        payload: data,
                        type: ADMIN_RECEIVE_SINGLE_GAME_TASKS
                    });
                    return [2 /*return*/, data];
                case 2:
                    err_4 = _a.sent();
                    if (err_4.response && err_4.response.status === 403) {
                        dispatch(userAuthLogoutRequestAction());
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var adminSelectGameAction = function (id) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, response, err_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, ApiGameService.requestGame(id)];
                case 1:
                    data = (_a.sent()).data;
                    dispatch({ type: GAME_PURGE_ALL_DATA });
                    dispatch({ type: PLAYER_PURGE_ALL_DATA });
                    dispatch({ type: PLAYER_PURGE_COMPANY });
                    dispatch({
                        type: GAME_RECEIVE_SINGLE_GAME_FROM_API,
                        payload: data
                    });
                    dispatch({ type: WEBSOCKET_DISCONNECTED });
                    disconnect();
                    return [4 /*yield*/, ApiService.getWebsocketAddress(data.token)];
                case 2:
                    response = _a.sent();
                    if (response && response.host) {
                        window.globals.WS_URL = response.host;
                    }
                    dispatch({
                        type: 'ROUTE',
                        to: "/facilitatorjoin/facilitator/".concat(data.token)
                    });
                    return [3 /*break*/, 4];
                case 3:
                    err_5 = _a.sent();
                    dispatch({
                        type: MANAGER_SINGLE_GAME_API_FAILED
                    });
                    if (err_5.response && err_5.response.status === 403) {
                        dispatch(userAuthLogoutRequestAction());
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
