var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { findFromCells } from 'helpers/utilities';
import clsx from 'clsx';
export default function MobBankingTableConfig(_a) {
    var tableConfig = _a.tableConfig;
    var t = useTranslation().t;
    var mainConfig = {
        options: {
            childrenTransparent: true,
        },
        rows: [],
        keyValues: [
            { label: '', name: 'info' },
        ],
    };
    mainConfig.rows = tableConfig.map(function (company) {
        return {
            key: company.id,
            component: mainRowComponent(company),
            childrenVisible: company.isRowOpen,
            childRows: getChildRows(company.rows)
        };
    });
    return mainConfig;
    function mainRowComponent(company) {
        var groupHeaderCells = company.groupHeaderCells;
        return rowHTML(findFromCells(groupHeaderCells, 'name'), findFromCells(groupHeaderCells, 'button'), findFromCells(groupHeaderCells, 'amount'), true, company.isRowOpen);
    }
    function rowHTML(name, button, amount, isExtendable, isOpen) {
        var chevronBaseStyles = {
            marginRight: '10px',
            marginLeft: '15px'
        };
        return (React.createElement("div", { className: "f-grid-row" },
            React.createElement("div", { className: "f-grid" },
                React.createElement("div", { className: "f-grid-col-6" },
                    React.createElement("div", { className: "bold text-transform-uppercase mar-bottom-5 turncate-text" }, name)),
                React.createElement("div", { className: "f-grid-col-6 text-align-right" },
                    React.createElement("div", { className: "d-flex flex-justify-content-flex-end mar-bottom-10" },
                        React.createElement("div", null,
                            t('amount'),
                            ": ",
                            React.createElement("span", { className: "bold" }, amount)),
                        React.createElement("div", { className: clsx(['chevron chevron--sm', !isExtendable && 'opacity-0']), style: isOpen || !isExtendable ? __assign(__assign({}, chevronBaseStyles), { transform: 'rotate(-90deg)' }) : __assign({}, chevronBaseStyles) })),
                    React.createElement("div", null, button)))));
    }
    function getChildRows(rows, companyId) {
        return rows.map(function (row) {
            return {
                key: "".concat(row.name, "_").concat(companyId),
                info: {
                    component: rowHTML(row.name, row.button.component, row.amount)
                }
            };
        });
    }
}
