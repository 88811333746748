var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setT } from 'helpers/translationUtilities';
import { STAGE_PLAY, STAGE_THEORY } from 'components/views/admin/templates/single/structure/constants';
import { minsToSecs, secToMins } from 'helpers/timeUtilities';
import TinyMCE from 'components/shared/TinyMCE/component';
StageForm.propTypes = {
    stage: PropTypes.object.isRequired,
    stages: PropTypes.array.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateStage: PropTypes.func.isRequired,
    templateId: PropTypes.string,
    createStage: PropTypes.func.isRequired
};
export default function StageForm(_a) {
    var stage = _a.stage, stages = _a.stages, onModalClose = _a.onModalClose, updateStage = _a.updateStage, templateId = _a.templateId, createStage = _a.createStage;
    var _b = useState(getDescription(stage)), editorText = _b[0], setEditorText = _b[1];
    var onFormSubmit = function (e) {
        var target = e.target;
        e.preventDefault();
        stage ? stageUpdateHandler(target) : stageCreationHandler(target);
        onModalClose();
    };
    function stageCreationHandler(target) {
        var payload = {
            game: templateId,
            title: target.title.value,
            description: editorText,
            ordinal: Number(target.ordinal.value),
            discussion_break: target.type.value === STAGE_THEORY,
            duration: minsToSecs(Number(target.duration.value))
        };
        createStage(payload);
    }
    function stageUpdateHandler(target) {
        var payload = {
            game: templateId,
            title: __assign(__assign({}, stage.title[0]), { value: target.title.value }),
            description: __assign(__assign({}, stage.description[0]), { value: editorText }),
            ordinal: Number(target.ordinal.value),
            discussion_break: target.type.value === STAGE_THEORY,
            duration: minsToSecs(Number(target.duration.value)),
        };
        updateStage(stage._id, payload);
    }
    var title = stage ? stage.title[0].value : '';
    var duration = stage ? stage.duration : 600;
    return (React.createElement("form", { onSubmit: onFormSubmit, className: "text-align-left admin-form" },
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null,
                setT('stage_name'),
                ":"),
            React.createElement("input", { type: "text", name: "title", defaultValue: title })),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null, "Ordinal:"),
            React.createElement("input", { type: "number", name: "ordinal", defaultValue: stage ? stage.ordinal : stages.length + 1 })),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null, "Duration:"),
            React.createElement("input", { type: "number", name: "duration", defaultValue: secToMins(duration) })),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null,
                setT('stage_type'),
                ":"),
            React.createElement("select", { name: "type", defaultValue: stage && stage.discussion_break ? STAGE_THEORY : STAGE_PLAY },
                React.createElement("option", { value: STAGE_PLAY }, setT('play')),
                React.createElement("option", { value: STAGE_THEORY }, setT('theory_break')))),
        React.createElement("div", { className: "mar-top-5" },
            React.createElement(TinyMCE, { value: editorText || null, onChange: function (value) { return setEditorText(value); } })),
        React.createElement("div", { className: "mar-top-20 text-align-center flex-justify-content-center d-flex flex-gap-10" },
            React.createElement("button", { className: "main-button main-button--error", onClick: function () { return onModalClose(); } }, "close"),
            React.createElement("button", { className: "main-button", type: "submit" }, "Save"))));
}
var getDescription = function (stage) {
    if (!stage)
        return "";
    var description = stage.description;
    if (Array.isArray(description) && description.length) {
        return description[0].value;
    }
    return description;
};
