import { WEBSOCKET_MESSAGE } from 'components/constants';
import { connect } from 'react-redux';
import Component from './component';
var mapStateToProps = function (state) {
    return {
        players: state.general.players,
        companies: state.general.companies,
        token: state.playerAuthorization.token,
        stages: state.game.stages,
        scoreListingItems: state.reports.scoreListing.items,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketMessage: function (msg) {
            return dispatch({
                type: WEBSOCKET_MESSAGE,
                payload: msg
            });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
