import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import UserNavigationMenu from 'components/manager/shared/userNavigationMenu/container';
HeaderDropdown.propTypes = {
    open: PropTypes.bool,
    logoutRequest: PropTypes.func.isRequired,
    userSession: PropTypes.object,
    setOpen: PropTypes.func.isRequired,
    profileRef: PropTypes.object
};
export default function HeaderDropdown(_a) {
    var _b = _a.open, open = _b === void 0 ? false : _b, logoutRequest = _a.logoutRequest, _c = _a.userSession, userSession = _c === void 0 ? {} : _c, setOpen = _a.setOpen, profileRef = _a.profileRef;
    useEffect(function () {
        document.addEventListener('mousedown', outsideClickHandler);
        return function () {
            document.removeEventListener('mousedown', outsideClickHandler);
        };
    }, []);
    function outsideClickHandler(_a) {
        var target = _a.target;
        if (profileRef.current && !profileRef.current.contains(target)) {
            setOpen(false);
        }
    }
    return (React.createElement(CSSTransition, { in: open, timeout: 500, classNames: "anim-fade", unmountOnExit: true },
        React.createElement("div", { className: "admin-actions-menu" },
            React.createElement(UserNavigationMenu, { userRank: userSession.rank, logoutRequest: logoutRequest }))));
}
