import { connect } from 'react-redux';
import Component from './component';
import { locSetLanguage } from '../../../../redux/actions/localization.actions';
import { LANG_SERVICE_DEFAULT } from 'services/LanguageService/languageService.types';
var mapStateToProps = function (state) {
    return {
        currentLang: state.localization.language || LANG_SERVICE_DEFAULT,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        setLanguage: function (lang, i18n) { return dispatch(locSetLanguage(lang, i18n)); },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
