import { connect } from 'react-redux';
import Component from './component';
var mapStateToProps = function (state) {
    return {
        companies: state.general.companies,
        players: state.general.players,
        gameRules: state.game.settings
    };
};
export default connect(mapStateToProps)(Component);
