import PropTypes from 'prop-types';
import Sidebar from 'components/shared/sidebar/container';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
import NewTransaction from 'components/shared/transaction/new/container';
import React from 'react';
ViewNewBankTransaction.propTypes = {
    match: PropTypes.object.isRequired
};
export default function ViewNewBankTransaction(_a) {
    var match = _a.match;
    return (React.createElement("div", null,
        React.createElement(Sidebar, { type: SIDEBAR_MODE_PLAYER }),
        React.createElement("div", { className: "topheader text-align-right" },
            React.createElement(NewTransaction, { type: match.params.type, accountId: match.params.accountId }))));
}
