var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import clsx from 'clsx';
import { pingMessageRequest } from '../../socketConnection/socketMessageRequests/pingRequest';
import { history } from '../../store';
import { WrapperRouter } from 'components/routers/WrapperRouter';
import LoadView from 'components/global-wrapper/loadView/component';
import { hasGameEnded } from 'helpers/gameUtilities';
import ApiGameService from '../../services/ApiService/ApiGameService/ApiGameService';
import ApiService from '../../services/ApiService/ApiService';
import { gameJoinAction } from '../../redux/actions/game.actions';
import { analytics } from 'library/analytics';
var globalDisconnectionInterval = null;
var connectionAttemptInterval = null;
var stageUpdateTimeout = null;
var connectionAttempts = 0;
var MAX_CONNECTION_ATTEMPTS = 3;
GlobalWrapper.propTypes = {
    websocketConnection: PropTypes.func.isRequired,
    websocketDisconnect: PropTypes.func.isRequired,
    websocketMessage: PropTypes.func.isRequired,
    children: PropTypes.any,
    token: PropTypes.string,
    showStageInfoBar: PropTypes.bool,
    autoOpenGameOverlay: PropTypes.func.isRequired,
    currentUserRank: PropTypes.string,
    stages: PropTypes.array,
    refreshTokenRequest: PropTypes.func.isRequired,
    authToken: PropTypes.string,
    wsConnectionEstablished: PropTypes.bool.isRequired,
    wsConnectionAttemptMade: PropTypes.bool.isRequired,
    setWsConnectionAttemptMade: PropTypes.func.isRequired,
    falseLogin: PropTypes.bool,
    redirectToToken: PropTypes.func.isRequired,
    game: PropTypes.object.isRequired
};
export default function GlobalWrapper(_a) {
    var _b = _a.token, token = _b === void 0 ? '' : _b, websocketConnection = _a.websocketConnection, websocketDisconnect = _a.websocketDisconnect, websocketMessage = _a.websocketMessage, _c = _a.showStageInfoBar, showStageInfoBar = _c === void 0 ? false : _c, _d = _a.stages, stages = _d === void 0 ? [] : _d, refreshTokenRequest = _a.refreshTokenRequest, _e = _a.authToken, authToken = _e === void 0 ? '' : _e, wsConnectionEstablished = _a.wsConnectionEstablished, redirectToToken = _a.redirectToToken, game = _a.game;
    var _f = useState(null), notificationModal = _f[0], setNotificationModal = _f[1];
    useStagesHandling(stages, game, authToken);
    function attemptGameConnection() {
        websocketConnection(token);
    }
    useEffect(function () {
        function initializeConnection() {
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            analytics.event("Initialize User Connection");
                            if (!window.globals.WS_URL && !token) {
                                redirectToToken();
                                return [2 /*return*/];
                            }
                            if (!(!window.globals.WS_URL && token)) return [3 /*break*/, 2];
                            return [4 /*yield*/, ApiService.getWebsocketAddress(token)];
                        case 1:
                            response = _a.sent();
                            if (response && response.host) {
                                window.globals.WS_URL = response.host;
                                attemptGameConnection();
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        initializeConnection();
        return function () {
            websocketDisconnect();
            pingInterval();
            clearInterval(globalDisconnectionInterval);
            clearInterval(connectionAttemptInterval);
        };
    }, []);
    useEffect(function () {
        pingInterval();
        if (connectionAttemptInterval && wsConnectionEstablished) {
            clearInterval(connectionAttemptInterval);
        }
        if (!wsConnectionEstablished) {
            connectionReattempting(wsConnectionEstablished, attemptGameConnection, setNotificationModal);
        }
        else {
            gameJoinAction(token);
        }
        if (!token) {
            clearInterval(globalDisconnectionInterval);
        }
    }, [token, wsConnectionEstablished]);
    function pingInterval() {
        setDisconnectingInterval(token, websocketMessage, authToken, refreshTokenRequest);
    }
    function renderMainContent() {
        if (wsConnectionEstablished && stages.length) {
            return (React.createElement(WrapperRouter, { history: history }));
        }
        return (React.createElement(LoadView, { displayModal: notificationModal }));
    }
    return (React.createElement("div", { className: clsx(['app-wrapper', showStageInfoBar && 'app-wrapper--bar-open']) },
        renderMainContent(),
        React.createElement(ToastContainer, null)));
}
function setDisconnectingInterval(token, websocketMessage, authToken, refreshTokenRequest) {
    return __awaiter(this, void 0, void 0, function () {
        var _this = this;
        return __generator(this, function (_a) {
            if (globalDisconnectionInterval) {
                clearInterval(globalDisconnectionInterval);
            }
            globalDisconnectionInterval = setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = websocketMessage;
                            return [4 /*yield*/, pingMessageRequest(token)];
                        case 1:
                            _a.apply(void 0, [_b.sent()]);
                            if (authToken) {
                                refreshTokenRequest(authToken);
                            }
                            return [2 /*return*/];
                    }
                });
            }); }, 30000);
            return [2 /*return*/];
        });
    });
}
function connectionReattempting(wsConnectionEstablished, attemptGameConnection, setNotificationModal) {
    if (connectionAttemptInterval) {
        clearInterval(connectionAttemptInterval);
    }
    connectionAttemptInterval = setInterval(function () {
        if (wsConnectionEstablished || connectionAttempts > MAX_CONNECTION_ATTEMPTS) {
            setNotificationModal(true);
            clearInterval(connectionAttemptInterval);
            if (connectionAttempts > MAX_CONNECTION_ATTEMPTS) {
                analytics.event("User Reconnection Attempts Max Value Reached");
            }
            return;
        }
        analytics.event("User Reconnection Attempt", "Count", connectionAttempts);
        attemptGameConnection();
        connectionAttempts++;
    }, 2000);
}
function useStagesHandling(stages, game, authToken) {
    useEffect(function () {
        if (stageUpdateTimeout) {
            clearTimeout(stageUpdateTimeout);
        }
        stageUpdateTimeout = setTimeout(function () {
            if (hasGameEnded(stages) && authToken) {
                ApiGameService.updateApiGameStateToEnded(game);
            }
        }, 3000);
    }, [stages]);
}
