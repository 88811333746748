import { connect } from 'react-redux';
import Component from './component';
import { adminRemoveCompanyAction, adminUpdateCompanyAction } from '../../../../../../redux/actions/admin/admin.company.actions';
var mapStateToProps = function (state) {
    return {
        template: state.admin.singleTemplate
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        updateCompany: function (id, payload) { return dispatch(adminUpdateCompanyAction(id, payload)); },
        removeCompany: function (id, companyId) { return dispatch(adminRemoveCompanyAction(id, companyId)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
