import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { convertUnix, minsToSecs, secToMins } from 'helpers/timeUtilities';
import { setT } from 'helpers/translationUtilities';
CollapseTableContent.propTypes = {
    stageDescription: PropTypes.any,
    times: PropTypes.object.isRequired,
    inputMode: PropTypes.bool,
    stageUpdateRequest: PropTypes.func.isRequired,
    stageId: PropTypes.string.isRequired,
    hasStageEnded: PropTypes.bool.isRequired
};
export default function CollapseTableContent(_a) {
    var _b = _a.stageDescription, stageDescription = _b === void 0 ? '' : _b, times = _a.times, stageUpdateRequest = _a.stageUpdateRequest, stageId = _a.stageId, hasStageEnded = _a.hasStageEnded;
    var _c = useState(times.formatted.plannedMins), plannedTime = _c[0], setTimePlanned = _c[1];
    var _d = useState(false), inputMode = _d[0], setInputMode = _d[1];
    function onTimeChangeSubmit(e) {
        e.preventDefault();
        stageUpdateRequest(stageId, { duration: minsToSecs(plannedTime) });
        setInputMode(false);
    }
    return (React.createElement("div", { className: "collapse-custom-content" },
        React.createElement("div", { className: "d-block d-flex-md flex-wrap flex-justify-content-between flex-direction-row-reverse-md" },
            React.createElement("div", { className: "flex-shrink-1" },
                React.createElement("form", { className: "d-flex flex-align-items-center flex-wrap flex-nowrap-md mar-bottom-15 mar-bottom-0-md", onSubmit: function (e) { return onTimeChangeSubmit(e); } },
                    React.createElement("div", { className: "collapse-custom-content__times" },
                        React.createElement("div", { className: "flex-align-self-center" },
                            setT('planned_time'),
                            ":"),
                        React.createElement("div", null,
                            !inputMode ?
                                secToMins(times.planned) :
                                React.createElement("input", { style: { display: "inline-block", width: '65px', padding: '10px' }, className: "main-input main-input--auto-height text-align-center bold", name: "planned_time", value: plannedTime, onChange: function (e) { return setTimePlanned(Number(e.target.value)); } }),
                            " ",
                            setT('minutes_short')),
                        React.createElement("div", null,
                            setT('spent_time'),
                            ":"),
                        React.createElement("div", null,
                            secToMins(times.spent),
                            " ",
                            setT('minutes_short')),
                        React.createElement("div", null,
                            setT('estimated_end'),
                            ":"),
                        React.createElement("div", null, convertUnix(times.estimated_end))),
                    !hasStageEnded ? setButton(inputMode, setInputMode) : null)),
            React.createElement("div", { className: "flex-grow-1 mar-bottom-10 mar-bottom-0-md" },
                React.createElement("div", { className: "custom-content-area", dangerouslySetInnerHTML: { __html: stageDescription } })))));
}
function setButton(inputMode, setInputMode) {
    if (inputMode) {
        return (React.createElement("button", { type: "submit", className: "main-button main-button--lg" }, setT('save')));
    }
    return (React.createElement("button", { type: "button", className: "main-button main-button--lg", onClick: function (e) { return onOpenButtonClick(e, setInputMode); } }, setT('change')));
}
function onOpenButtonClick(event, setInputMode) {
    event.preventDefault();
    setInputMode(true);
}
