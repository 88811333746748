import React from 'react';
import { REQUEST_GAME_STATE_CHANGE_NEXT } from 'components/shared/gameStageControls/constants';
import { USER_RANK_ADMIN, USER_RANK_FACILITATOR } from 'components/permissions/types';
import AuthPermissions from 'components/permissions/container';
import { hasGameEnded } from 'helpers/gameUtilities';
import { setT } from 'helpers/translationUtilities';
export default function lockedGameOverlay(manipulateGameStage, stages) {
    var gameFinished = hasGameEnded(stages);
    return (React.createElement("div", { className: "locked-overlay" },
        React.createElement("div", { className: "locked-overlay__inner d-flex flex-align-center" },
            React.createElement("div", { className: "flex-grow-1 text-center" },
                React.createElement("div", { className: "locked-overlay__label bold text-transform-uppercase", style: { marginBottom: '5px' } },
                    gameFinished ? setT('game_has_ended') : setT('game_is_locked'),
                    "!"),
                React.createElement("div", { className: "locked-overlay__label locked-overlay__label--sm" }, setT('transactions_are_disabled'))),
            React.createElement("div", { className: "flex-align-self-stretch" },
                React.createElement(AuthPermissions, { allowedRanks: [USER_RANK_ADMIN, USER_RANK_FACILITATOR] }, !gameFinished ?
                    React.createElement("button", { className: "full-height control-button control-button__green", onClick: function () { return manipulateGameStage(REQUEST_GAME_STATE_CHANGE_NEXT); } },
                        React.createElement("span", { className: "icon icon--game_start icon--game_start--lg" })) : null)))));
}
