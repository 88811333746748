import { connect } from 'react-redux';
import Component from './component';
import { filterOutCompaniesWithNoPlayers } from 'helpers/companyUtilities';
var mapStateToProps = function (state) {
    var _a = state.general, players = _a.players, companies = _a.companies;
    return {
        companies: filterOutCompaniesWithNoPlayers(players, companies),
        players: players
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        joinGameWithToken: function (gameToken) { return dispatch({ type: 'ROUTE', to: "/join/".concat(gameToken) }); },
        redirectToFacilitator: function () { return dispatch({ type: 'ROUTE', to: "/facilitator" }); },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
