import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { history } from '../../../../store';
ViewURLAuthentication.propTypes = {
    purgeAndReconnect: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    userAuthorization: PropTypes.string,
    ownToken: PropTypes.bool
};
export default function ViewURLAuthentication(_a) {
    var purgeAndReconnect = _a.purgeAndReconnect, _b = _a.match, match = _b === void 0 ? {} : _b, _c = _a.userAuthorization, userAuthorization = _c === void 0 ? '' : _c, _d = _a.ownToken, ownToken = _d === void 0 ? false : _d;
    var _e = match === null || match === void 0 ? void 0 : match.params, gameToken = _e.gameToken, pathing = _e.pathing;
    useEffect(function () {
        var redirect = "/onboarding/".concat(gameToken);
        if ((!pathing && userAuthorization) || (!userAuthorization && !pathing && ownToken)) {
            redirect = "/dashboard";
        }
        else if (pathing && userAuthorization) {
            redirect = "/facilitator";
        }
        purgeAndReconnect(gameToken).then(function () {
            history.push(redirect);
        });
    }, [gameToken, purgeAndReconnect, pathing, userAuthorization, ownToken]);
    return null;
}
