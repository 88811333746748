import { connect } from 'react-redux';
import Component from './component';
import { gameGetTemplatesAction, gameCreateGameAction } from '../../../../redux/actions/game.actions.js';
import { WEBSOCKET_MESSAGE } from 'components/constants';
var mapStateToProps = function (state) {
    return {
        authToken: state.userAuthorization.token
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketMessage: function (msg) { return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg }); },
        getGameTemplates: function (authToken) { return dispatch(gameGetTemplatesAction(authToken)); },
        createGame: function (id, name, datetime, token) { return dispatch(gameCreateGameAction(id, name, datetime, token)); },
        redirectToFacilitatorGames: function () { return dispatch({ type: 'ROUTE', to: '/facilitator/games' }); },
        redirectToGames: function () { return dispatch({ type: 'ROUTE', to: '/games' }); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
