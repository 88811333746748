import React from 'react';
import { useTranslation } from 'react-i18next';
import { hasGameEnded } from 'helpers/gameUtilities';
import { getActiveStage } from 'helpers/stageUtilities';
import { getAllCompanyTotals, getCompanyScoresInASingleStage, setScoresToCompanies } from 'components/manager/facilitator/reports/utilities';
export default function CompanyFinancesTableConfig(players, companies, scores, stages) {
    var t = useTranslation().t;
    var companiesWithScoresAdded = setScoresToCompanies(companies, stages, scores);
    var rows = stages.map(function (stage) {
        return {
            id: stage.id,
            mainRow: createMainRow(stage, companiesWithScoresAdded),
            children: createChildRows(stage, companiesWithScoresAdded)
        };
    });
    rows.unshift(getAllTotalsRow(companiesWithScoresAdded, stages, t));
    return {
        headers: getHeaders(companies),
        rows: rows
    };
    function createChildRows(stage, companiesWithScoresAdded) {
        return [
            getRowCompanyValues(companiesWithScoresAdded, stage, t('income'), 'income'),
            getRowCompanyValues(companiesWithScoresAdded, stage, t('expenses'), 'expense', true),
            getRowCompanyValues(companiesWithScoresAdded, stage, t('balance'), 'balance'),
        ];
    }
}
function getAllTotalsRow(companies, stages, t) {
    var mainRow = {
        label: { utility: { id: "total_row" }, value: t('total') },
        chevron: { utility: { id: "total_chevron" }, value: '' },
    };
    var childRows = [
        getChildTotalRow('income', t('income'), companies),
        getChildTotalRow('expense', t('expenses'), companies, true),
        getChildTotalRow('balance', t('balance'), companies, false, stages)
    ];
    companies.map(function (company) {
        var allTotals = getAllCompanyTotals(company);
        mainRow[company.name] = {
            value: setDataValue(allTotals.income - allTotals.expense),
            utility: {
                id: "".concat(company.id, "_total_row")
            },
        };
    });
    return {
        id: 'total_row',
        mainRow: mainRow,
        children: childRows
    };
}
function getChildTotalRow(key, label, companies, setNegative, stages) {
    var row = {
        label: { utility: { id: "".concat(key, "_total_label") }, value: label },
        chevron: { utility: { id: "".concat(key, "_total_chevron") }, value: '' }
    };
    companies.map(function (company) {
        var allTotals = getAllCompanyTotals(company);
        var value = setDataValue(setNegative ? (-1 * allTotals[key]) : allTotals[key]);
        if (key === 'balance') {
            var gameEnded = hasGameEnded(stages);
            var relevantStageId_1;
            if (gameEnded) {
                relevantStageId_1 = stages[stages.length - 1].id;
            }
            else {
                relevantStageId_1 = getActiveStage(stages) ? getActiveStage(stages).id : null;
            }
            if (relevantStageId_1) {
                value = setDataValue(company.scores.find(function (score) { return score.stageId === relevantStageId_1; }).balance);
            }
        }
        row[company.name] = {
            value: value,
            utility: {
                id: "".concat(company.id, "_").concat(label, "_total_").concat(key)
            },
        };
    });
    return row;
}
function getHeaders(companies) {
    var headers = [
        { key: 'label', label: '' }
    ];
    companies.map(function (company) {
        headers.push({
            key: company.name,
            label: company.name,
        });
    });
    headers.push({ key: 'chevron', label: '' });
    return headers;
}
function createMainRow(stage, companiesWithScoresAdded) {
    var groupData = {
        label: { utility: { id: stage.stageName }, value: stage.stageName },
        chevron: { utility: { id: 'chevron' }, value: '' },
    };
    companiesWithScoresAdded.map(function (company) {
        var currentStageScores = company.scores.find(function (score) { return score.stageId === stage.id; });
        groupData[company.name] = {
            value: setDataValue(currentStageScores.income - currentStageScores.expense),
            utility: {
                id: "".concat(company.id, "_").concat(stage.id)
            },
        };
    });
    return groupData;
}
function getRowCompanyValues(companiesWithScoresAdded, stage, label, key, setNegative) {
    var groupData = {
        label: { utility: { id: "".concat(label, "_").concat(stage.id, "_").concat(key) }, value: label },
        chevron: { utility: { id: "".concat(label, "_").concat(stage.id, "_chevron") }, value: '' }
    };
    companiesWithScoresAdded.map(function (company) {
        var currentStageScores = getCompanyScoresInASingleStage(company.scores, stage.id);
        groupData[company.name] = {
            value: setDataValue(setNegative ? (-1 * currentStageScores[key]) : currentStageScores[key]),
            utility: {
                id: "".concat(company.id, "_").concat(label, "_").concat(stage.id, "_").concat(currentStageScores[key])
            },
        };
    });
    return groupData;
}
function setDataValue(value) {
    if (!Number.isInteger(value)) {
        return (React.createElement("span", null, value));
    }
    return value < 0 ? React.createElement("span", { className: "balance-table__negative" }, value) : React.createElement("span", { className: "balance-table__positive" }, value);
}
