var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CollapsedTable from 'components/shared/tables/collapsedTable/component';
import TransactionTableConfig from 'components/configs/collapseTable/transactionTable/TransactionTableConfig';
import { useAutoOpenedGameStage } from 'helpers/stageUtilities';
import MobCollapseTable from 'components/shared/tables/mobCollapseTable/component';
import MobTransactionTableConfig from 'components/configs/collapseTable/transactionTable/MobTransactionTableConfig';
Transactions.propTypes = {
    player: PropTypes.object.isRequired,
    company: PropTypes.object,
    game: PropTypes.object,
    playersAndCompanies: PropTypes.array,
    presetTransactions: PropTypes.array,
    type: PropTypes.string,
    currentlyActiveState: PropTypes.object
};
export default function Transactions(_a) {
    var player = _a.player, _b = _a.company, company = _b === void 0 ? {
        transactions: []
    } : _b, _c = _a.game, game = _c === void 0 ? null : _c, _d = _a.playersAndCompanies, playersAndCompanies = _d === void 0 ? [] : _d, _e = _a.presetTransactions, presetTransactions = _e === void 0 ? [] : _e, _f = _a.type, type = _f === void 0 ? '' : _f, _g = _a.currentlyActiveState, currentlyActiveState = _g === void 0 ? {} : _g;
    var _h = useState([]), openRowIds = _h[0], setOpenRowIds = _h[1];
    var transactions = type === 'company' ? company.transactions : presetTransactions;
    useAutoOpenedGameStage(currentlyActiveState, setOpenRowIds);
    function onExtensionClick(id) {
        var newIdsArray = __spreadArray([], openRowIds, true);
        var newIdsArrayIndex = newIdsArray.findIndex(function (presentId) { return presentId === id; });
        if (newIdsArrayIndex !== -1) {
            newIdsArray.splice(newIdsArrayIndex, 1);
        }
        else {
            newIdsArray.push(id);
        }
        setOpenRowIds(newIdsArray);
    }
    var tablesProperties = {
        transactions: transactions,
        game: game,
        playersAndCompanies: playersAndCompanies,
        openRows: openRowIds,
        onItemClick: function (id) { return onExtensionClick(id); },
        currentAccount: type === 'company' ? player.company.id : player.id
    };
    var tableConfig = TransactionTableConfig(__assign({}, tablesProperties));
    var mobileTableConfig = MobTransactionTableConfig(tableConfig);
    return (React.createElement("div", { className: "transactions" },
        React.createElement("div", { className: "d-none d-block-md" },
            React.createElement(CollapsedTable, { tableWrapperClasses: 'collapse-table--last-icons collapse-table--side-paddings', groups: tableConfig })),
        React.createElement("div", { className: "d-none-md" },
            React.createElement(MobCollapseTable, { onExtensionClick: onExtensionClick, config: mobileTableConfig }))));
}
