import React, { useState } from 'react';
import AuthWrapper from '../../../auth/component';
import PropTypes from 'prop-types';
import AuthLogo from '../../../auth/shared/logo/component';
import { setT } from 'helpers/translationUtilities';
import { useTranslation } from 'react-i18next';
ViewLogin.propTypes = {
    redirectToToken: PropTypes.func.isRequired,
    loginRequest: PropTypes.func.isRequired,
    falseLogin: PropTypes.bool,
};
export default function ViewLogin(_a) {
    var redirectToToken = _a.redirectToToken, loginRequest = _a.loginRequest, falseLogin = _a.falseLogin;
    var _b = useState(null), name = _b[0], setName = _b[1];
    var _c = useState(null), password = _c[0], setPassword = _c[1];
    var _d = useState(false), loginRequested = _d[0], setLoginRequested = _d[1];
    function onFieldChange(_a) {
        var target = _a.target;
        if (target.name === 'password') {
            setPassword(target.value);
        }
        else {
            setName(target.value);
        }
    }
    function onSubmit() {
        setLoginRequested(true);
        loginRequest(name, password);
    }
    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            onSubmit();
        }
    }
    var t = useTranslation().t;
    return (React.createElement(AuthWrapper, null,
        React.createElement("div", { className: "token-authentication token-authentication--login clear" },
            React.createElement("div", { className: "token-authentication__wrapper" },
                React.createElement("div", { className: "token-authentication__inner" },
                    React.createElement(AuthLogo, null),
                    falseLogin && loginRequested ? (React.createElement("div", { className: "general-error" }, setT('wrong_username_or_pass'))) : (''),
                    React.createElement("div", { className: "token-authentication__text token-authentication__text--small" }, setT('username')),
                    React.createElement("div", { className: "token-authentication__input" },
                        React.createElement("input", { className: "basic-input", placeholder: t('enter_username'), type: "text", name: "name", onChange: onFieldChange, onKeyPress: handleKeyPress })),
                    React.createElement("div", { className: "token-authentication__text token-authentication__text--small" }, setT('password')),
                    React.createElement("div", { className: "token-authentication__input" },
                        React.createElement("input", { className: "basic-input", placeholder: t('enter_password'), type: "password", name: "password", onChange: onFieldChange, onKeyPress: handleKeyPress })),
                    React.createElement("div", { className: "token-authentication__input" },
                        React.createElement("div", { className: "main-button main-button--fullwidth text-align-center", onClick: onSubmit }, setT('play')))),
                React.createElement("div", { className: "token-authentication__bottom" },
                    React.createElement("div", { className: "token-authentication__footer" },
                        setT('have_gamecode'),
                        React.createElement("span", { onClick: redirectToToken }, t('login'))))),
            React.createElement("div", { className: "token-authentication__sideimage" }))));
}
