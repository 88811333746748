var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as PropTypes from 'prop-types';
import Chart from "react-apexcharts";
import React, { useState } from 'react';
import ReportsChartsConfig from 'components/configs/charts/ReportsCharts';
import { setScoresToCompanies } from 'components/manager/facilitator/reports/utilities';
import GenericDropdown from 'components/shared/dropdown/component';
import { setT } from 'helpers/translationUtilities';
import ReportsModal from 'components/views/facilitator/reports/reportsModal';
export var GRAPH_SETTING_COMPANIES = 'companies';
export var GRAPH_SETTING_COMPANY_MONTHLY = 'company_monthly';
export var GRAPH_WIDTH = 800;
ReportsChart.propTypes = {
    stages: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
    scores: PropTypes.array.isRequired
};
export default function ReportsChart(_a) {
    var stages = _a.stages, companies = _a.companies, scores = _a.scores;
    var _b = useState(null), chosenStageId = _b[0], setChosenStageId = _b[1];
    var _c = useState(companies[0].id), chosenCompanyId = _c[0], setChosenCompanyId = _c[1];
    var _d = useState(GRAPH_SETTING_COMPANIES), graphSetting = _d[0], setGraphSetting = _d[1];
    var _e = useState(false), reportZoomModalOpen = _e[0], setReportZoomModalOpen = _e[1];
    var _f = useState({
        dropdownOne: false,
        dropdownTwo: false,
    }), openDropdowns = _f[0], setOpenDropdowns = _f[1];
    var chartConfig = ReportsChartsConfig({ companies: setScoresToCompanies(companies, stages, scores), stages: stages, graphSetting: graphSetting, chosenStageId: chosenStageId, chosenCompanyId: chosenCompanyId });
    function updateStateDropdown(newValue) {
        setOpenDropdowns(__assign(__assign({}, openDropdowns), newValue));
    }
    function onDropdownOneClick(value) {
        if (graphSetting === GRAPH_SETTING_COMPANIES) {
            setChosenStageId(value.id);
        }
        else {
            setChosenCompanyId(value.id);
        }
        updateStateDropdown({ dropdownOne: false });
    }
    function onDropdownTwoClick(value) {
        if (value.id !== GRAPH_SETTING_COMPANIES) {
            setChosenStageId(null);
        }
        else {
            setChosenCompanyId(companies[0].id);
        }
        setGraphSetting(value.id);
        updateStateDropdown({ dropdownTwo: false });
    }
    var zoomIcon = require('assets/img/icons/icon_zoom.svg?fill=#ffffff');
    return (React.createElement("div", { className: "chart-wrapper" },
        React.createElement("div", { className: "chart-wrapper__container", style: { width: GRAPH_WIDTH } },
            React.createElement(Chart, { options: chartConfig.options, series: chartConfig.series, width: GRAPH_WIDTH, height: 420 })),
        React.createElement("div", { className: "chart-wrapper__footer" },
            React.createElement(GenericDropdown, { options: graphSetting === GRAPH_SETTING_COMPANIES ? getStagesSelection(stages) : getCompanySelection(companies), selectedLabel: graphSetting === GRAPH_SETTING_COMPANIES ? getDropdownOneLabel(chosenStageId, stages, 'stageName', setT('total')) : getDropdownOneLabel(chosenCompanyId, companies, 'name', 'undefined'), isOpen: openDropdowns.dropdownOne, onButtonClick: function () { return updateStateDropdown({ dropdownOne: !openDropdowns.dropdownOne }); }, onOptionClick: function (value) { return onDropdownOneClick(value); }, onOutsideClick: function () { return updateStateDropdown({ dropdownOne: false }); } }),
            React.createElement(GenericDropdown, { options: getGraphSettingsSelection(), selectedLabel: getGraphSettingsSelection().find(function (setting) { return setting.id === graphSetting; }).label, isOpen: openDropdowns.dropdownTwo, onButtonClick: function () { return updateStateDropdown({ dropdownTwo: !openDropdowns.dropdownTwo }); }, onOptionClick: function (value) { return onDropdownTwoClick(value); }, onOutsideClick: function () { return updateStateDropdown({ dropdownTwo: false }); } }),
            React.createElement("button", { className: "button main-button chart-wrapper__zoom-button", onClick: function () { return setReportZoomModalOpen(true); } },
                React.createElement("span", { className: "icon icon--eye", style: { 'backgroundImage': "url(".concat(zoomIcon, ")") } }))),
        React.createElement(ReportsModal, { chartConfig: chartConfig, open: reportZoomModalOpen, onClose: function () { return setReportZoomModalOpen(false); } })));
}
function getDropdownOneLabel(chosenId, array, propertyKey, alternativeLabel) {
    var foundEntity = array.find(function (item) { return item.id === chosenId; });
    return foundEntity ? foundEntity[propertyKey] : alternativeLabel;
}
function getCompanySelection(companies) {
    return companies.map(function (company) {
        return {
            id: company.id,
            value: company.id,
            label: company.name,
        };
    });
}
function getGraphSettingsSelection() {
    return [
        {
            id: GRAPH_SETTING_COMPANIES,
            value: GRAPH_SETTING_COMPANIES,
            label: setT('company_comparison')
        },
        {
            id: GRAPH_SETTING_COMPANY_MONTHLY,
            value: GRAPH_SETTING_COMPANY_MONTHLY,
            label: setT('monthly_progress')
        }
    ];
}
function getStagesSelection(stages) {
    var mapped = stages.map(function (stage) {
        return {
            id: stage.id,
            value: stage.id,
            label: stage.stageName
        };
    });
    return __spreadArray([{ id: null, value: null, label: setT('total') }], mapped, true);
}
