var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import clsx from 'clsx';
import React from 'react';
import Avatar from 'components/shared/avatar/component';
import Checkbox from 'components/shared/checkbox/component';
import { getPlayersTokenFromCompany } from 'helpers/tokenUtilities';
import { setT } from 'helpers/translationUtilities';
import { getEntityBalance } from 'helpers/utilities';
function getTableColumns() {
    return [
        { label: '', name: 'avatar' },
        { label: setT('name'), name: 'name' },
        { label: setT('game_code'), name: 'token' },
        { label: '', name: 'players_count' },
        { label: setT('balance'), name: 'balance' },
        { label: '', name: 'button' },
        { label: '', name: 'chevron' },
    ];
}
export default function ParticipantsTableConfig(_a) {
    var _b = _a.selectedPlayers, selectedPlayers = _b === void 0 ? [] : _b, _c = _a.movePlayersState, movePlayersState = _c === void 0 ? false : _c, openRows = _a.openRows, companies = _a.companies, players = _a.players, balances = _a.balances, onGroupItemClick = _a.onGroupItemClick, onSinglePlayerClick = _a.onSinglePlayerClick, redirect = _a.redirect;
    function setActivePlayer(e, token) {
        e.stopPropagation();
        redirect(token);
    }
    function getTableMainHeader(movePlayersState) {
        var groupHeaderCells = [
            { id: 'avatar', title: '' },
            {
                id: 'name',
                title: setT('name'),
                properties: { className: 'text-align-left' }
            },
            {
                id: 'code',
                title: setT('game_code'),
                properties: { className: 'text-align-left' }
            },
            {
                id: 'count',
                title: setT('players_count'),
                properties: { className: 'text-align-left' }
            },
            {
                id: 'amount',
                title: setT('balance'),
                properties: { className: 'text-align-left' }
            },
            {
                id: 'utility',
                title: '',
                properties: { colSpan: 2 }
            },
        ];
        return {
            id: 'utility_row',
            addedClasses: clsx(['collapse-table__group-title--main']),
            groupHeaderCells: movePlayersState ? __spreadArray([{ id: 'checkbox', title: '' }], groupHeaderCells, true) : groupHeaderCells
        };
    }
    var mainRows = [getTableMainHeader(movePlayersState)];
    mainRows.push(placeEmptyCompany({
        openRows: openRows,
        onGroupItemClick: onGroupItemClick,
        createPlayerRows: createPlayerRows,
        players: players,
        balances: balances,
        setActivePlayer: setActivePlayer,
        onSinglePlayerClick: onSinglePlayerClick,
        selectedPlayers: selectedPlayers,
        movePlayersState: movePlayersState
    }));
    var _loop_1 = function (i) {
        var company = companies[i];
        var companyPlayers = players.filter(function (player) { return player.companyID === company.id; });
        var isCurrentRowOpen = openRows.findIndex(function (rowId) { return rowId === company.id; }) !== -1;
        mainRows.push({
            id: company.id,
            isRowOpen: isCurrentRowOpen,
            balance: getEntityBalance(balances, company),
            playersCount: companyPlayers.length,
            addedClasses: clsx(['cursor-pointer text-align-left', isCurrentRowOpen && 'extended']),
            innerAreaClasses: clsx([isCurrentRowOpen && 'extended']),
            tableColumns: movePlayersState ? __spreadArray([{ label: '', name: 'checkbox' }], getTableColumns(), true) : getTableColumns(),
            properties: {
                onClick: function () { return movePlayersState && selectedPlayers.length ? onSinglePlayerClick(company.id) : onGroupItemClick(company.id); },
            },
            groupHeaderCells: getGroupHeaderCells(company, balances, setActivePlayer, companyPlayers, movePlayersState),
            rows: createPlayerRows({
                companyPlayers: companyPlayers,
                balances: balances,
                setActivePlayer: setActivePlayer,
                onSinglePlayerClick: onSinglePlayerClick,
                selectedPlayers: selectedPlayers
            }),
        });
    };
    for (var i = 0; i < companies.length; i++) {
        _loop_1(i);
    }
    return mainRows;
}
function placeEmptyCompany(_a) {
    var openRows = _a.openRows, onGroupItemClick = _a.onGroupItemClick, createPlayerRows = _a.createPlayerRows, players = _a.players, balances = _a.balances, setActivePlayer = _a.setActivePlayer, onSinglePlayerClick = _a.onSinglePlayerClick, selectedPlayers = _a.selectedPlayers, movePlayersState = _a.movePlayersState;
    var emptyCompanyId = 1;
    var isRowOpen = openRows.findIndex(function (rowId) { return rowId === emptyCompanyId; }) !== -1;
    var noCompanyPlayers = players.filter(function (player) { return !player.companyID; });
    return {
        id: emptyCompanyId,
        isRowOpen: isRowOpen,
        addedClasses: clsx(['cursor-pointer text-align-left', isRowOpen && 'extended']),
        innerAreaClasses: clsx([isRowOpen && 'extended']),
        tableColumns: movePlayersState ? __spreadArray([{ label: '', name: 'checkbox' }], getTableColumns(), true) : getTableColumns(),
        properties: {
            onClick: function () { return movePlayersState && selectedPlayers.length ? onSinglePlayerClick(emptyCompanyId) : onGroupItemClick(emptyCompanyId); },
        },
        groupHeaderCells: getGroupHeaderCells({}, balances, setActivePlayer, noCompanyPlayers, movePlayersState),
        rows: createPlayerRows({
            companyPlayers: players.filter(function (player) { return !player.companyID; }),
            balances: balances,
            setActivePlayer: setActivePlayer,
            onSinglePlayerClick: onSinglePlayerClick,
            selectedPlayers: selectedPlayers
        }),
    };
}
function createPlayerRows(_a) {
    var companyPlayers = _a.companyPlayers, balances = _a.balances, setActivePlayer = _a.setActivePlayer, onSinglePlayerClick = _a.onSinglePlayerClick, selectedPlayers = _a.selectedPlayers;
    return companyPlayers.map(function (player) {
        return {
            key: player.id,
            checkbox: {
                component: React.createElement(Checkbox, { selected: selectedPlayers.some(function (selectedPlayer) { return selectedPlayer === player.id; }) })
            },
            avatar: {
                component: React.createElement(Avatar, { src: player.thumbnail })
            },
            name: player.name,
            token: player.token,
            balance: getEntityBalance(balances, player),
            button: {
                component: player.companyID ? React.createElement("button", { className: "main-button", onClick: function (e) { setActivePlayer(e, player.token); } }, setT('view_account')) : null
            },
            customProperties: {
                className: 'bg-transparent',
                onClick: function () { return onSinglePlayerClick(player.id); },
                cellProperties: {
                    button: {
                        className: 'text-align-right'
                    }
                }
            }
        };
    });
}
function getGroupHeaderCells(company, balances, setActivePlayer, players, movePlayersState) {
    if (players === void 0) { players = []; }
    var cellsArray = [
        {
            id: "".concat(company.id, "_avatar"),
            name: 'avatar',
            component: React.createElement(Avatar, { src: company.thumbnail })
        },
        {
            id: "".concat(company.id, "_title"),
            name: 'title',
            title: company.name || setT('unassigned'),
        },
        {
            id: "".concat(company.id, "_token"),
            name: 'token',
            title: company.token,
        },
        {
            id: "".concat(company.id, "_players"),
            name: 'players',
            title: players.length,
        },
        {
            id: "".concat(company.id, "_amount"),
            name: 'amount',
            title: getEntityBalance(balances, company),
        },
        {
            id: "".concat(company.id, "_button"),
            properties: { className: 'text-align-right' },
            name: 'button',
            component: company.id ? React.createElement("button", { className: "main-button", onClick: function (e) { return setActivePlayer(e, getPlayersTokenFromCompany(company.id, players)); } }, setT('view_account')) : null
        },
        {
            id: "".concat(company.id, "_chevron"),
            properties: { className: 'text-align-right' },
            component: React.createElement("span", { className: "chevron" })
        }
    ];
    if (movePlayersState) {
        cellsArray.unshift({
            id: "".concat(company.id, "_checkbox"),
            title: ''
        });
    }
    return cellsArray;
}
