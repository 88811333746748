import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
Tabs.propTypes = {
    config: PropTypes.object
};
export default function Tabs(_a) {
    var config = _a.config;
    var tabs = config.tabs, tabsClasses = config.tabsClasses;
    return (React.createElement("div", { className: clsx(['tabs', tabsClasses]) }, tabs.map(function (tab) { return (React.createElement("div", { key: tab.id, className: clsx(['tabs__item', tabsClasses, tab.active && 'tabs__item--active']), onClick: function (e) { return tab.onClickEvent(tab, e); } }, tab.label)); })));
}
