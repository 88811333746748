var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FacilitatorTemplateSingle from './single/component.jsx';
var FacilitatorTemplateList = /** @class */ (function (_super) {
    __extends(FacilitatorTemplateList, _super);
    function FacilitatorTemplateList() {
        var _this = _super.call(this) || this;
        _this.state = {
            newGameView: false
        };
        return _this;
    }
    FacilitatorTemplateList.prototype.shouldComponentUpdate = function () {
        return true;
    };
    FacilitatorTemplateList.prototype.setDashboardView = function () {
        this.setState({
            newGameView: !this.state.newGameView
        });
    };
    FacilitatorTemplateList.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "cardview" }, this.props.gameTemplates.map(function (x, index) {
            return (React.createElement(FacilitatorTemplateSingle, { key: index, onTemplateClick: _this.props.onTemplateClick, id: x._id, name: x.name, description: x.description, selectedTemplateID: _this.props.selectedTemplateID, isClickable: true }));
        })));
    };
    FacilitatorTemplateList.propTypes = {
        companies: PropTypes.array,
        player: PropTypes.object,
        onTemplateClick: PropTypes.func,
        gameTemplates: PropTypes.array,
        selectedTemplateID: PropTypes.string
    };
    FacilitatorTemplateList.defaultProps = {
        companies: [],
        player: {},
        onTemplateClick: null,
        gameTemplates: [],
        selectedTemplateID: ''
    };
    return FacilitatorTemplateList;
}(Component));
export default FacilitatorTemplateList;
