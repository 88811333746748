import { connect } from 'react-redux';
import { purgeAndReconnectAction } from '../../../../redux/actions/shared.actions';
import Component from './component';
var mapStateToProps = function (state) {
    return {
        ownToken: state.player.ownToken,
        failed: state.playerAuthorization.fail,
        userAuthorization: state.userAuthorization.userSession.id
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        purgeAndReconnect: function (gameToken) { return dispatch(purgeAndReconnectAction(gameToken)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
