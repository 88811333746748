import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { REQUEST_GAME_STATE_CHANGE_NEXT, REQUEST_GAME_STATE_CHANGE_PLAY_TRIGGER, REQUEST_GAME_STATE_CHANGE_STOP } from 'components/shared/gameStageControls/constants';
import clsx from 'clsx';
import { isGameLocked } from 'helpers/gameUtilities';
import { setT } from 'helpers/translationUtilities';
GameControls.propTypes = {
    manipulateGameStage: PropTypes.func.isRequired,
    stages: PropTypes.array,
    activeStage: PropTypes.object
};
export default function GameControls(_a) {
    var manipulateGameStage = _a.manipulateGameStage, stages = _a.stages, activeStage = _a.activeStage;
    var isGameInLockdown = isGameLocked(stages);
    var isActiveStageLast = stages.findIndex(function (stage) { return stage.id === activeStage.id; }) + 1 === stages.length;
    var lockBtn = function (classes, iconClasses) {
        return (React.createElement("button", { className: clsx(['control__button control-button control-button__orange', classes]), onClick: function () { return manipulateGameStage(REQUEST_GAME_STATE_CHANGE_PLAY_TRIGGER); } },
            React.createElement("span", { className: clsx(['icon', iconClasses, isGameInLockdown ? 'icon--game_unlock' : 'icon--game_lock']) }),
            React.createElement("span", { className: "control-button__label" }, setT('lock'))));
    };
    var endBtn = function (classes, iconClasses) {
        return (React.createElement("button", { className: clsx(['control__button control-button control-button__red', classes]), onClick: function () { return manipulateGameStage(REQUEST_GAME_STATE_CHANGE_STOP); } },
            React.createElement("span", { className: clsx(['icon icon--game_end', iconClasses]) }),
            React.createElement("span", { className: "control-button__label" }, setT('end'))));
    };
    var nextBtn = function (classes, iconClasses) {
        return (React.createElement("button", { className: clsx(['control-button control-button__green', classes, isActiveStageLast && 'control-button--disabled']), onClick: function () { return !isActiveStageLast ? manipulateGameStage(REQUEST_GAME_STATE_CHANGE_NEXT) : null; } },
            React.createElement("span", { className: clsx(['icon icon--game_start', iconClasses]) }),
            React.createElement("span", { className: "control-button__label" }, setT('next'))));
    };
    function desktopControls() {
        return (React.createElement("div", { className: "controls d-none d-flex-md" },
            React.createElement("div", { className: "control__column d-flex flex-direction-column" },
                lockBtn('flex-grow-1 full-height'),
                endBtn('flex-grow-1 full-height')),
            React.createElement("div", { className: "control__column" }, nextBtn('full-height control-button__main', 'icon--game_start-lg'))));
    }
    function mobileControls() {
        return (React.createElement("div", { className: "controls d-none-md flex-gap-10" },
            lockBtn(),
            endBtn(),
            nextBtn()));
    }
    return (React.createElement(Fragment, null,
        desktopControls(),
        mobileControls()));
}
