import moment from 'moment';
export var GetTime = function (timestamp1, timestamp2, allowNegative, pausedSeconds) {
    var dif = timestamp1 - timestamp2 + pausedSeconds;
    if (!allowNegative) {
        dif = dif < 0 ? dif * -1 : 0;
    }
    return dif;
};
export var secToMins = function (seconds) {
    return Number(Number(seconds / 60).toFixed(0));
};
export var minsToSecs = function (seconds) {
    return Number(Number(seconds * 60).toFixed(0));
};
export var convertUnix = function (timestamp) {
    return moment.unix(timestamp).format('HH:mm');
};
export var secToHrAndMin = function (number, format) {
    if (format === void 0) { format = 'HH:mm:sss'; }
    if (!number) {
        return '';
    }
    var isNegativeResult = number < 0;
    var secNum = parseInt(isNegativeResult ? number * -1 : number, 10);
    var hours = Math.floor(secNum / 3600);
    var minutes = Math.floor((secNum - (hours * 3600)) / 60);
    var seconds = secNum - (hours * 3600) - (minutes * 60);
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    var formattedTime = format;
    formattedTime = formattedTime.replace('HH', hours.toString()).replace('mm', minutes.toString()).replace('ss', seconds.toString());
    return isNegativeResult ? "-".concat(formattedTime) : formattedTime;
};
export var formatTimes = function (time, addSeconds) {
    var seconds = Math.floor(time % 60);
    var minutes = Math.floor((time % 3600) / 60);
    minutes = minutes < 0 ? minutes * -1 : 0;
    var hours = Math.floor(time / 3600);
    if (minutes.toString().length === 1) {
        minutes = '0' + minutes;
    }
    if (hours.toString().length === 1) {
        hours = '0' + hours;
    }
    return hours + 'h' + minutes + 'mins' + (addSeconds ? seconds + 's' : '');
};
export default GetTime;
