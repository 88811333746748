var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Avatar from 'components/shared/avatar/component';
import React from 'react';
import clsx from 'clsx';
import { isTaskPersonal } from 'components/player/task/utilities/utilities';
import { TASK_STATUS_DONE, TASK_STATUS_PROGRESS, TASK_STATUS_SKIPPED } from 'components/player/task/constants';
import { setT } from 'helpers/translationUtilities';
import { mergeStageToPreviousStage } from 'helpers/stageUtilities';
function getTableColumns() {
    return [
        { label: '', name: 'avatar' },
        { label: setT('name'), name: 'taskTaker' },
        { label: setT('task'), name: 'description' },
        { label: setT('status'), name: 'status' }
    ];
}
export default function TaskTableConfig(_a) {
    var tasks = _a.tasks, stages = _a.stages, account = _a.account, onClick = _a.onClick, openRows = _a.openRows, onSingleRowClick = _a.onSingleRowClick;
    var mappedStageArray = [];
    var rows = createGameRows(tasks, account, onSingleRowClick);
    var _loop_1 = function (i) {
        var stage = stages[i];
        var nextStage = stages.find(function (singleStage) { return singleStage.ordinal === stage.ordinal + 1; });
        var prevStage = stages.find(function (singleStage) { return singleStage.ordinal === stage.ordinal - 1; });
        var nextStageRows = [];
        var isCurrentRowOpen = openRows.findIndex(function (rowId) { return rowId === stage.id; }) !== -1;
        if (nextStage && nextStage.discussionBreak) {
            nextStageRows = rows.filter(function (value) { return value.stage === nextStage.id; });
        }
        if (mergeStageToPreviousStage(stage, prevStage)) {
            return "continue";
        }
        mappedStageArray.push({
            id: stage.id,
            stageName: stage.stageName,
            isRowOpen: isCurrentRowOpen,
            addedClasses: clsx(['cursor-pointer', isCurrentRowOpen && 'extended']),
            innerAreaClasses: clsx([isCurrentRowOpen && 'extended']),
            tableColumns: getTableColumns(),
            properties: {
                onClick: function () { return onClick(stage.id); }
            },
            groupHeaderCells: [
                {
                    id: "".concat(stage.id, "_stage_name"),
                    title: stage.stageName,
                    properties: { colSpan: 5, className: 'text-align-left' }
                },
                {
                    id: "".concat(stage.id, "_chevron"),
                    properties: { className: 'text-align-right' },
                    component: React.createElement("span", { className: "chevron" })
                }
            ],
            rows: __spreadArray(__spreadArray([], rows.filter(function (value) { return value.stage === stage.id; }), true), nextStageRows, true)
        });
    };
    for (var i = 0; i < stages.length; i++) {
        _loop_1(i);
    }
    return mappedStageArray.filter(function (stage) { return stage.rows.length; });
}
function sortRows(a, b) {
    if (b.status !== TASK_STATUS_PROGRESS) {
        return -1;
    }
}
function createGameRows(tasks, account, onSingleRowClick) {
    return tasks.sort(sortRows).map(function (task) {
        var relevantAccount = getRelevantAccountInfo(isTaskPersonal(task, account), account);
        return {
            key: task.id,
            avatar: {
                component: React.createElement(Avatar, { src: relevantAccount.thumbnail })
            },
            taskTaker: relevantAccount.name,
            description: task.description,
            stage: task.stage,
            status: setStatusIndicator(task.status),
            customProperties: {
                className: 'cursor-pointer',
                onClick: function () { return onSingleRowClick(task.id); },
                cellProperties: {
                    description: { colSpan: 3 }
                }
            }
        };
    });
}
function getRelevantAccountInfo(isPersonal, account) {
    return {
        thumbnail: getAccountProperty(isPersonal, account, 'thumbnail'),
        name: getAccountProperty(isPersonal, account, 'name')
    };
}
function getAccountProperty(isPersonal, account, property) {
    var theProperty = account && account[property];
    if (!isPersonal) {
        theProperty =
            account.company && account.company[property]
                ? account.company[property]
                : theProperty;
    }
    return theProperty;
}
function setStatusIndicator(status) {
    var _a;
    var statusVariations = (_a = {},
        _a[TASK_STATUS_DONE] = {
            class: 'done',
            title: setT('done')
        },
        _a[TASK_STATUS_PROGRESS] = {
            class: 'progress',
            title: setT('in_progress')
        },
        _a[TASK_STATUS_SKIPPED] = {
            class: 'skip',
            title: setT('skipped')
        },
        _a);
    var currentStatus = statusVariations[status];
    return (React.createElement("span", { className: clsx('task-indicator', currentStatus.class) }, currentStatus.title));
}
