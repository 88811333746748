import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { setT } from 'helpers/translationUtilities';
RecipientSelection.propTypes = {
    changeStep: PropTypes.func,
    receivers: PropTypes.array,
    companiesAndPlayers: PropTypes.array
};
export default function RecipientSelection(_a) {
    var _b = _a.changeStep, changeStep = _b === void 0 ? null : _b, _c = _a.receivers, receivers = _c === void 0 ? [] : _c, _d = _a.companiesAndPlayers, companiesAndPlayers = _d === void 0 ? [] : _d;
    return (React.createElement(Fragment, null,
        React.createElement("div", null,
            receivers.length
                ? hasBeenSelectedMessage(receivers, companiesAndPlayers)
                : null,
            changeStep ? (React.createElement("button", { className: "main-button main-button--lg", onClick: function () { return changeStep(); } }, receivers.length ? setT('change') : setT('select'))) : null)));
}
function hasBeenSelectedMessage(receivers, companiesAndPlayers) {
    var messageString = '';
    if (receivers.length > 1) {
        messageString = 'Multiple subjects selected';
    }
    else {
        var foundPlayer = companiesAndPlayers.find(function (entity) { return receivers[0] === entity.id; });
        if (foundPlayer) {
            messageString = "".concat(foundPlayer.name, " selected");
        }
    }
    return React.createElement("div", { className: "payment__message" }, messageString);
}
