export var filterOutCompaniesWithNoPlayers = function (players, companies) {
    return companies.filter(function (company) {
        var anyPlayerIsInThisCompany = players.some(function (player) { return player.companyID === company.id; });
        if (anyPlayerIsInThisCompany) {
            return company;
        }
    });
};
export var getPlayersOfACompany = function (players, companyId) {
    return players.filter(function (player) {
        return player.companyID === companyId;
    });
};
