import { connect } from 'react-redux';
import Component from './component';
import { adminCreateTaskAction, adminGetTaskAction, adminUpdateTaskAction } from '../../../../../../../redux/actions/admin/admin.task.actions';
var mapStateToProps = function (state) {
    return {
        template: state.admin.singleTemplate,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        getTask: function (gameId, taskId) { return dispatch(adminGetTaskAction(gameId, taskId)); },
        createTask: function (gameId, payload) { return dispatch(adminCreateTaskAction(gameId, payload)); },
        updateTask: function (gameId, taskId, payload) { return dispatch(adminUpdateTaskAction(gameId, taskId, payload)); },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
