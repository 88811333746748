import clsx from 'clsx';
import Avatar from 'components/shared/avatar/component';
import React from 'react';
import PropTypes from 'prop-types';
PlayersListItem.propTypes = {
    player: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    currentAccountId: PropTypes.object
};
export default function PlayersListItem(_a) {
    var player = _a.player, isSelected = _a.isSelected, _b = _a.onSelect, onSelect = _b === void 0 ? false : _b;
    var disabled = player.disabled;
    return (React.createElement("div", { key: player.id, className: clsx('user-group__item', isSelected && 'selected', disabled && 'disabled'), onClick: function () {
            onItemClick(onSelect, player.id, disabled);
        } },
        React.createElement("div", { className: "user-group__avatar" },
            React.createElement(Avatar, { src: player.thumbnail })),
        React.createElement("div", { className: "user-group__info" }, player.name)));
}
function onItemClick(onSelect, id, disabled) {
    if (disabled) {
        return;
    }
    onSelect(id);
}
