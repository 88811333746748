import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ViewOnboarding from 'components/views/user/onboarding/companies/container';
import ViewWrapper from 'components/views/user/root/container';
import ViewOnboardingPlayersNew from 'components/views/user/onboarding/players/container';
export var WrapperRouter = function (_a) {
    var history = _a.history;
    return (React.createElement(Router, { history: history },
        React.createElement(Switch, null,
            React.createElement(Route, { path: "/onboarding/players/:companyId", component: ViewOnboardingPlayersNew }),
            React.createElement(Route, { path: "/onboarding/players", component: ViewOnboardingPlayersNew }),
            React.createElement(Route, { path: "/onboarding/:tokenId", component: ViewOnboarding }),
            React.createElement(Route, { path: "/onboarding", component: ViewOnboarding }),
            React.createElement(Route, { path: "/", component: ViewWrapper }))));
};
WrapperRouter.propTypes = {
    history: PropTypes.object.isRequired
};
