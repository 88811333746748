var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ALLOW_GAME_INIT, GENERAL_RECEIVE_COMPANIES, GENERAL_RECEIVE_GAMES, GENERAL_UPDATE_GAME_STATUS, GENERAL_RECEIVE_GAMETEMPLATES, GENERAL_RECEIVE_NOTIFICATION, GENERAL_RECEIVE_PLAYERS, SERVER_ACTION_RECEIVED, } from 'components/constants';
var initialState = {
    players: [],
    companies: [],
    gameTemplates: [],
    games: [],
    allowGameInit: false,
    notification: {
        type: ''
    }
};
export default function (state, _a) {
    if (state === void 0) { state = initialState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        default:
            return state;
        case GENERAL_RECEIVE_GAMETEMPLATES: {
            return __assign(__assign({}, state), { gameTemplates: payload });
        }
        case GENERAL_RECEIVE_GAMES: {
            return __assign(__assign({}, state), { games: payload });
        }
        case GENERAL_RECEIVE_NOTIFICATION: {
            return __assign(__assign({}, state), { notification: payload });
        }
        case SERVER_ACTION_RECEIVED: {
            return state;
        }
        case GENERAL_UPDATE_GAME_STATUS:
            break;
        case ALLOW_GAME_INIT: {
            return __assign(__assign({}, state), { allowGameInit: payload });
        }
        case GENERAL_RECEIVE_PLAYERS: {
            return __assign(__assign({}, state), { players: payload.map(function (player) {
                    player.isCompany = false;
                    return player;
                }) });
        }
        case GENERAL_RECEIVE_COMPANIES: {
            return __assign(__assign({}, state), { companies: payload.map(function (company) {
                    company.isCompany = true;
                    return company;
                }) });
        }
    }
}
;
