import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TemplateEntityEditingModal from 'components/views/admin/templates/single/templateEntityEditingModal/component';
import { STAGE_PLAY, STAGE_THEORY } from 'components/views/admin/templates/single/structure/constants';
import { sortStagesByOrdinal } from 'helpers/stageUtilities';
import { secToMins } from 'helpers/timeUtilities';
import StageForm from 'components/views/admin/templates/single/structure/stageForm/container';
import TemplateConfirmationModal from 'components/views/admin/templates/templateConfirmationModal/component';
TemplateStructure.propTypes = {
    template: PropTypes.object,
    removeStage: PropTypes.func.isRequired,
    updateStage: PropTypes.func.isRequired
};
export default function TemplateStructure(_a) {
    var _b = _a.template, template = _b === void 0 ? {} : _b, removeStage = _a.removeStage, updateStage = _a.updateStage;
    var _c = useState([]), stages = _c[0], setStages = _c[1];
    var _d = useState(false), stageEditModalOpen = _d[0], setStageEditModalOpen = _d[1];
    var _e = useState(false), confirmModalOpen = _e[0], setConfirmModalOpen = _e[1];
    var _f = useState(null), openId = _f[0], setOpenId = _f[1];
    useEffect(function () {
        setStages(sortStagesByOrdinal(template.stages));
        return function () {
            setStages([]);
        };
    }, []);
    useEffect(function () {
        setStages(sortStagesByOrdinal(template.stages));
    }, [template]);
    function onConfirmModalClose() {
        setConfirmModalOpen(false);
        setOpenId(null);
    }
    function onStageModifyClick(id) {
        setStageEditModalOpen(true);
        setOpenId(id);
    }
    function onStageDeleteClick(id) {
        setConfirmModalOpen(true);
        setOpenId(id);
    }
    function onStageConfirmDelete() {
        setConfirmModalOpen(false);
        removeStage(openId);
    }
    function onModalClose() {
        setStageEditModalOpen(false);
        setOpenId(null);
    }
    function addStage() {
        setStageEditModalOpen(true);
    }
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("ul", { className: "list-styles-clear" }, stages.map(function (stage) { return (React.createElement("li", { className: "mar-bottom-5 mar-top-5", key: stage._id },
                React.createElement("span", { style: { display: "inline-block", width: 300 } },
                    stage.ordinal,
                    ".)\u00A0",
                    stage.title[0].value,
                    ",\u00A0",
                    secToMins(stage.duration),
                    "\u00A0min, (",
                    stage.discussion_break ? STAGE_THEORY : STAGE_PLAY,
                    ")"),
                React.createElement("button", { className: "main-button mar-left-10 mar-right-10", onClick: function () { return onStageModifyClick(stage._id); } }, "modify"),
                React.createElement("button", { className: "main-button main-button--error", onClick: function () { return onStageDeleteClick(stage._id); } }, "delete"))); })),
            React.createElement(TemplateEntityEditingModal, { stageEditModalOpen: stageEditModalOpen, onModalClose: onModalClose },
                React.createElement(StageForm, { stages: stages, stage: template.stages.find(function (stage) { return openId === stage._id; }), onModalClose: onModalClose })),
            React.createElement(TemplateConfirmationModal, { modalOpen: confirmModalOpen, onModalClose: function () { return onConfirmModalClose(); } },
                React.createElement("div", null,
                    React.createElement("h2", null,
                        "Oled kindel et soovid kustutada m\u00E4ngu etappi ",
                        getStageTitle(openId, template.stages),
                        "?"),
                    React.createElement("div", { className: "d-flex mar-top-20 justify-content-center flex-gap-10" },
                        React.createElement("button", { className: "main-button", onClick: function () { return onConfirmModalClose(); } }, "Sulge"),
                        React.createElement("button", { className: "main-button main-button--error", onClick: function () { return onStageConfirmDelete(); } }, "Kinnita")))),
            React.createElement("button", { className: "main-button", onClick: function () { return addStage(); } }, "Lisa m\u00E4ngu etapp"))));
}
var getStageTitle = function (id, stages) {
    var stage = stages.find(function (stage) { return stage._id === id; });
    if ((stage === null || stage === void 0 ? void 0 : stage.title) && stage.title[0]) {
        return stage.title[0].value;
    }
    return '';
};
