import { compose, createStore, applyMiddleware } from 'redux';
import { getState, saveState } from 'library/local-storage';
import { createBrowserHistory } from 'history';
import reduxThunk from 'redux-thunk';
import { routerMiddleware } from './middleware/redirection';
import { socketMiddleware } from './middleware/socket';
import rootReducer from './redux/root.reducer';
import { activeStageTimerMiddleware } from './middleware/activeStageTimer';
var history = createBrowserHistory();
var preloadedState = getState() ? getState() : {};
var composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
var store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(reduxThunk, routerMiddleware, socketMiddleware, activeStageTimerMiddleware)));
store.subscribe(function () {
    saveState(store.getState());
});
export { store, history };
