import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Header from 'components/shared/header/container';
import PlayerAllocating from 'components/shared/playerAllocating/container';
ViewOnboardingPlayersNew.propTypes = {
    match: PropTypes.object,
};
export default function ViewOnboardingPlayersNew(_a) {
    var _b = _a.match, match = _b === void 0 ? {
        params: null
    } : _b;
    return (React.createElement("div", { className: clsx(['wrapper wrapper--no-sidebar']) },
        React.createElement("div", { className: "container-fluid" },
            React.createElement(Header, null),
            React.createElement("div", { className: clsx(['playerdashboard']) },
                React.createElement(PlayerAllocating, { companyId: (match.params && match.params.companyId) || null })))));
}
