import { connect } from 'react-redux';
import Component from './component';
import { filterOutCompaniesWithNoPlayers } from 'helpers/companyUtilities';
import { userAuthLogoutRequestAction } from '../../../redux/actions/userAuthorization.actions';
import { gameJoinAction } from '../../../redux/actions/game.actions';
var mapStateToProps = function (state) {
    var _a = state.general, players = _a.players, companies = _a.companies;
    return {
        company: state.player.company,
        companiesWithPlayers: filterOutCompaniesWithNoPlayers(players, companies),
        playerRank: state.userAuthorization.userSession.rank,
        players: players
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        facilitatorRedirect: function (gameToken) { return dispatch({ type: 'ROUTE', to: "/join/".concat(gameToken) }); },
        regularPlayerRedirect: function (gameToken) { return dispatch(gameJoinAction(gameToken)); },
        redirectToFacilitator: function () { return dispatch({ type: 'ROUTE', to: "/facilitator" }); },
        logoutRequest: function () { return dispatch(userAuthLogoutRequestAction()); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
