import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { setT } from 'helpers/translationUtilities';
export default function MobTransactionTable(tableConfig) {
    var t = useTranslation().t;
    var mainConfig = {
        rows: [],
        keyValues: [
            { label: "".concat(t('payee'), "/").concat(t('receiver')), name: 'receiver' },
            { label: t('remark'), name: 'remark' },
            { label: t('amount'), name: 'amount' },
        ],
    };
    mainConfig.rows = tableConfig.map(function (stage) {
        return {
            key: stage.id,
            component: mainRowComponent(stage),
            childrenVisible: stage.isRowOpen,
            childRows: getChildRows(stage.rows)
        };
    });
    return mainConfig;
}
function getChildRows(stageRows) {
    return stageRows.map(function (row) {
        return {
            key: row.key,
            receiver: row.receiver,
            remark: row.remark,
            amount: React.createElement("span", { className: clsx(['transaction__amount', setTransactionTypeClass(row)]) }, row.expenses ? -1 * row.transactionAmount : row.transactionAmount)
        };
    });
}
function mainRowComponent(stage) {
    var stageTotals = stage.stageTotals;
    return (React.createElement("div", null,
        React.createElement("div", { className: "bold text-transform-uppercase mar-bottom-5" }, stage.stageName),
        React.createElement("div", { className: "d-flex" },
            React.createElement("div", null,
                React.createElement("div", { className: "d-inline-block" },
                    React.createElement("span", { className: "mar-right-5" },
                        setT('total_income'),
                        ":"),
                    React.createElement("span", { className: "transaction__amount transaction__amount--positive" }, stageTotals.income)),
                React.createElement("span", { className: "d-inline-block mar-right-5 mar-left-5" }, "|"),
                React.createElement("div", { className: "d-inline-block" },
                    React.createElement("span", { className: "mar-right-5" },
                        setT('total_expense'),
                        ":"),
                    React.createElement("span", { className: "transaction__amount transaction__amount--negative" }, stageTotals.expenses))))));
}
function setTransactionTypeClass(row) {
    if (row.expenses) {
        return 'transaction__amount--negative';
    }
    return 'transaction__amount--positive';
}
