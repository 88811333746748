export var WEBSOCKET_CONNECT = 'WEBSOCKET_CONNECT';
export var WEBSOCKET_CONNECTED = 'WEBSOCKET_CONNECTED';
export var WEBSOCKET_TIMED_OUT = 'WEBSOCKET_TIMED_OUT';
export var WEBSOCKET_RECEIVED_MESSAGE = 'WEBSOCKET_RECEIVED_MESSAGE';
export var WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';
export var WEBSOCKET_DISCONNECTED = 'WEBSOCKET_DISCONNECTED';
export var WEBSOCKET_MESSAGE = 'WEBSOCKET_MESSAGE';
export var PLAYER_RECEIVE_INFORMATION = 'PLAYER_INFORMATION';
export var PLAYER_RECEIVE_COMPANY_INFORMATION = 'COMPANY_INFORMATION';
export var PLAYER_UPDATE_COMPANY_INFORMATION = 'PLAYER_COMPANY_UPDATE';
export var GENERAL_RECEIVE_PLAYERS = 'PLAYERS_RECEIVED';
export var PLAYER_PURGE_ALL_DATA = 'PURGE_PLAYERINFO';
export var PLAYER_PURGE_COMPANY = 'PURGE_ACTIVE_COMPANY';
export var GAME_PURGE_ALL_DATA = 'PURGE_GAME_INFO';
export var PLAYER_PURGE_ALL_PLAYER_INTERNAL_INFO = 'PURGE_ACTIONS_DATA';
export var GENERAL_RECEIVE_COMPANIES = 'COMPANIES_RECEIVED';
export var API_GAME_STATUS_ACTIVE = 'ACTIVE';
export var API_GAME_STATUS_ENDED = 'ENDED';
export var GENERAL_RECEIVE_NOTIFICATION = 'NOTIFICATION_RECEIVED';
export var SERVER_ACTION_RECEIVED = 'SERVER_ACTION_RECEIVED';
export var ALLOW_GAME_INIT = 'ALLOW_GAME_INIT';
export var PLAYER_RECEIVE_TRANSACTION = 'TRANSACTION_RECEIVED';
export var PLAYER_RECEIVE_TRANSACTION_LIST = 'TRANSACTIONLIST_RECIEVED';
export var PLAYER_RECEIVE_BANK_BALANCES = 'BANKBALANCES_RECIEVED';
export var GAME_RECEIVE_INFORMATION = 'GAME_INFORMATION';
export var PLAYER_RECEIVE_TASK = 'TASK_RECEIVED';
export var PLAYER_RECEIVE_TICKET_LIST = 'TICKET_LISTING_RECIEVED';
export var PLAYER_RECIEVE_COMPANY_ID = 'TOKEN_RECIEVED';
export var PLAYER_AUTHORIZATION_FAIL_TOKEN = 'TOKEN_FAILED';
export var LOGIN_API_REQUEST = 'LOGIN_API_REQUEST';
export var LOGIN_API_SUCCESS = 'LOGIN_API_SUCCESS';
export var USER_AUTHORIZATION_RECEIVE_USER_DATA = 'USER_SESSION';
export var PLAYER_AUTHORIZATION_RECEIVE_TOKEN = 'SESSION_SAVE';
export var USER_AUTHORIZATION_API_FAIL = 'LOGIN_API_FAIL';
export var USER_AUTHORIZATION_PURGE_USER_DATA = 'LOGIN_PURGE';
export var GAMESTAGE_NEXT = 'GAMESTAGE_NEXT';
export var GAME_STOP = 'GAME_STOP';
export var GAMESTAGE_PAUSE = 'GAMESTAGE_PAUSE';
export var GAMESTAGE_ACTIVATE = 'GAMESTAGE_ACTIVATE';
export var GAME_SET_CURRENT_STAGE_PAUSE = 'GAMESTAGE_CHANGE';
export var GENERAL_UPDATE_GAME_STATUS = 'GAMESTATUS_CHANGE';
export var PLAYER_RECEIVE_OWN_TOKEN = 'USERS_OWN_TOKEN';
export var PLAYER_RECEIVE_ONBOARDING_TYPE = 'CURRENT_ONBOARDING_TYPE';
export var RESET_INFO = 'RESET_INFO';
export var GENERAL_RECEIVE_GAMETEMPLATES = 'GAMETEMPLATES_RECIEVED';
export var GENERAL_RECEIVE_GAMES = 'GAMES_RECIEVED';
export var UI_GAMESLIST_REQUEST = 'GAMESLIST_REQUEST';
export var UI_GAMESLIST_REQUEST_ENDED = 'GAMESLIST_REQUEST_ENDED';
export var UI_GAMES_MAX_PAGES_RECIEVED = 'GAMES_MAX_PAGES_RECIEVED';
export var GAME_UPDATE_STAGES = 'GAME_UPDATED_STAGES';
export var ADMIN_RECEIVE_UPDATED_TEMPLATE_COMPANY = 'ADMIN_RECEIVE_UPDATED_TEMPLATE_COMPANY';
export var ADMIN_RECEIVE_NEW_TEMPLATE_COMPANY = 'ADMIN_RECEIVE_NEW_TEMPLATE_COMPANY';
export var ADMIN_DELETE_TEMPLATE_COMPANY = 'ADMIN_DELETE_TEMPLATE_COMPANY';
export var ADMIN_RECEIVE_UPDATED_TEMPLATE = 'ADMIN_RECEIVE_UPDATED_TEMPLATE';
export var ADMIN_RECEIVE_DUPLICATE_TEMPLATE = 'ADMIN_RECEIVE_DUPLICATE_TEMPLATE';
export var ADMIN_RECEIVE_DELETE_TEMPLATE = 'ADMIN_RECEIVE_DELETE_TEMPLATE';
export var ADMIN_RECEIVE_TASK = 'ADMIN_RECEIVE_TASK';
export var ADMIN_UPDATE_TASK = 'ADMIN_UPDATE_TASK';
export var ADMIN_DELETE_TASK = 'ADMIN_DELETE_TASK';
export var ADMIN_RECEIVE_TEMPLATES = 'MANAGER_TEMPLATES_API_SUCCESS';
export var ADMIN_RECEIVE_TEMPLATES_API_FAIL = 'MANAGER_TEMPLATES_API_FAILED';
export var ADMIN_RECEIVE_SINGLE_TEMPLATE = 'MANAGER_SINGLE_TEMPLATE_API_SUCCESS';
export var ADMIN_RECEIVE_SINGLE_TEMPLATE_API_FAIL = 'MANAGER_SINGLE_TEMPLATE_API_FAILED';
export var ADMIN_PURGE_SINGLE_TEMPLATE = 'MANAGER_SINGLE_TEMPLATE_RESET';
export var ADMIN_RECEIVE_TEMPLATE_UPDATED_STAGE = 'MANAGER_RECEIVE_TEMPLATE_UPDATED_STAGE';
export var ADMIN_DELETE_TEMPLATE_STAGE = 'MANAGER_ADMIN_DELETE_TEMPLATE_STAGE';
export var ADMIN_RECEIVE_NEW_STAGE = 'MANAGER_RECEIVE_NEW_STAGE';
export var GAME_RECEIVE_SINGLE_GAME_FROM_API = 'MANAGER_SINGLE_GAME_API_SUCCESS';
export var MANAGER_SINGLE_GAME_API_FAILED = 'MANAGER_SINGLE_GAME_API_FAILED';
export var GAME_RECEIVE_STAGES_FROM_API = 'MANAGER_SINGLE_GAME_STAGES_API_SUCCESS';
export var MANAGER_TEMPLATES_STAGE_UPDATE_API_FAILED = 'MANAGER_TEMPLATES_STAGE_UPDATE_API_FAILED';
export var ADMIN_RECEIVE_SINGLE_GAME_TASKS = 'MANAGER_SINGLE_GAME_TASKSET_API_SUCCESS';
export var ADMIN_PURGE_FORM_ERRORS = 'MANAGER_CREATE_NEW_USER_SUCCESS';
export var ADMIN_CREATE_NEW_USER_API_FAIL = 'MANAGER_CREATE_NEW_USER_FAILED';
export var PLAYER_AUTHORIZATION_PURGE_TOKEN = 'PURGE_PLAYER_TOKEN';
export var PURGE_ALL_APP_DATA = 'PURGE_ALL_GAME_DATA';
export var SIDEBAR_MODE_PLAYER = 'SIDEBAR_MODE_PLAYER';
export var SIDEBAR_MODE_FACILITATOR = 'SIDEBAR_MODE_FACILITATOR';
export var SIDEBAR_MODE_ADMIN = 'SIDEBAR_MODE_ADMIN';
export var UI_TOGGLE_STAGE_INFO_BAR = 'TOGGLE_STAGE_INFO_BAR';
export var UI_OPEN_STAGE_INFO_BAR = 'OPEN_STAGE_INFO_BAR';
export var UI_SET_MOB_PLAYER_SELECTION = 'UI_SET_MOB_PLAYER_SELECTION';
export var UI_WS_CONNECTION_ATTEMPT_MADE = 'WS_CONNECTION_ATTEMPT_MADE';
export var REPORTS_RECEIVE_SCORES = 'REPORTS_GET_ALL_SCORES';
