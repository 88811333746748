var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
MainTable.propTypes = {
    config: PropTypes.object
};
export default function MainTable(_a) {
    var config = _a.config;
    var tableColumns = config.tableColumns, rows = config.rows;
    return (React.createElement("div", { className: "primary-table" },
        React.createElement("table", { className: "text-align-left" },
            getHead(tableColumns),
            React.createElement("tbody", null, getTableRows(rows)))));
}
function getHead(tableColumns) {
    return (React.createElement("thead", null,
        React.createElement("tr", null, tableColumns.map(function (headCellValue) { return (React.createElement("th", { className: clsx([headCellValue.classes]), key: "".concat(headCellValue.label, "_").concat(headCellValue.name) }, headCellValue.label)); }))));
}
function getTableRows(rows) {
    return rows.map(function (row) { return (React.createElement("tr", { key: row.utilityProps.id }, getTableCells(row))); });
}
function getTableCells(row) {
    var cells = [];
    var rowUtils = row.utilityProps;
    for (var prop in row) {
        if (prop !== 'utilityProps') {
            var cellUtils = row[prop].className;
            cells.push(React.createElement("td", __assign({ key: row[prop].id }, row.utilityProps, { className: clsx([rowUtils.className, cellUtils]) }), row[prop].value));
        }
    }
    return cells;
}
