import SocketService from '../SocketService';
export var subscriptionAction = function (type) {
    return SocketService.createEncodedMessage({
        kind: 'subscriptionAction',
        subscriptionAction: {
            type: type
        }
    });
};
export var FACILITATOR_SUBSCRIBE = 1;
export var FACILITATOR_UNSUBSCRIBE = 2;
