var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LOGIN_API_REQUEST, LOGIN_API_SUCCESS, USER_AUTHORIZATION_RECEIVE_USER_DATA, USER_AUTHORIZATION_API_FAIL, PURGE_ALL_APP_DATA, WEBSOCKET_DISCONNECT } from 'components/constants';
import { post } from 'axios';
export var userAuthLoginRequestAction = function (username, password) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch({ type: WEBSOCKET_DISCONNECT });
                    dispatch({ type: PURGE_ALL_APP_DATA });
                    dispatch({ type: LOGIN_API_REQUEST });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, post("".concat(window.globals.API_URL, "/session"), { username: username, password: password }, { withCredentials: true })];
                case 2:
                    data = (_a.sent()).data;
                    dispatch({ payload: data, type: USER_AUTHORIZATION_RECEIVE_USER_DATA });
                    dispatch({ type: LOGIN_API_SUCCESS });
                    dispatch({ to: '/games', type: 'ROUTE' });
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    dispatch({
                        payload: err_1.response ? err_1.response.data.message : 'Unknown error!',
                        type: USER_AUTHORIZATION_API_FAIL
                    });
                    if (err_1.response && err_1.response.status === 403) {
                        dispatch(userAuthLogoutRequestAction());
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var userAuthTokenLoginRequestAction = function (token) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch({ type: WEBSOCKET_DISCONNECT });
                    dispatch({ type: PURGE_ALL_APP_DATA });
                    dispatch({ type: LOGIN_API_REQUEST });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, post("".concat(window.globals.API_URL, "/session/token"), { token: token }, { withCredentials: true })];
                case 2:
                    data = (_a.sent()).data;
                    dispatch({ payload: data, type: USER_AUTHORIZATION_RECEIVE_USER_DATA });
                    dispatch({ type: LOGIN_API_SUCCESS });
                    return [2 /*return*/, data];
                case 3:
                    err_2 = _a.sent();
                    dispatch({
                        payload: err_2.response ? err_2.response.data.message : 'Unknown error!',
                        type: USER_AUTHORIZATION_API_FAIL
                    });
                    if (err_2.response && err_2.response.status === 403) {
                        dispatch(userAuthLogoutRequestAction());
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var userAuthRefreshTokenRequestAction = function (token) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var data, err_3;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, post("".concat(window.globals.API_URL, "/session/refresh"), {}, {
                            withCredentials: true,
                            headers: {
                                'User-Auth-Token': token,
                            },
                        })];
                case 1:
                    data = (_c.sent()).data;
                    dispatch({ payload: data, type: USER_AUTHORIZATION_RECEIVE_USER_DATA });
                    return [3 /*break*/, 3];
                case 2:
                    err_3 = _c.sent();
                    console.error('UserAuthRefresh Error:', (_b = (_a = err_3 === null || err_3 === void 0 ? void 0 : err_3.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message);
                    dispatch(userAuthLogoutRequestAction());
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
};
export var userAuthLogoutRequestAction = function () {
    return function (dispatch) {
        dispatch({
            type: WEBSOCKET_DISCONNECT
        });
        dispatch({
            type: PURGE_ALL_APP_DATA
        });
        dispatch({
            type: 'ROUTE',
            to: '/login'
        });
    };
};
