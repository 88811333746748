var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { checkIfTransactionPositive, getSingleStageTotals } from 'components/player/shared/helpers';
import moment from 'moment';
import TableLabel from 'components/player/shared/TableLabel/component';
import Numeral from 'numeral';
import Avatar from 'components/shared/avatar/component';
import React from 'react';
export var getNextStage = function (_a) {
    var previousStage = _a.previousStage, stages = _a.stages, tableValues = _a.tableValues, playersAndCompanies = _a.playersAndCompanies, currentAccount = _a.currentAccount, transactions = _a.transactions;
    var newStageValues = {
        transactionTotals: 0,
        rows: []
    };
    var nextStage = stages.find(function (singleStage) { return singleStage.ordinal === previousStage.ordinal + 1; }); // Finding next stage by ordinal is most reliable
    if (nextStage && nextStage.discussionBreak) {
        newStageValues.transactionTotals = getSingleStageTotals(transactions.filter(function (value) { return value.stage === nextStage.id; }), playersAndCompanies, currentAccount);
        newStageValues.rows = tableValues.filter(function (value) { return value.stage === nextStage.id; });
    }
    return newStageValues;
};
export var singleStageTransaction = function (transactions, stage) {
    return transactions.filter(function (value) { return value.stage === stage.id; });
};
export var getRelevantAccountFromTransaction = function (transaction, playersAndCompanies, currentAccount) {
    return currentAccount === transaction.to
        ? getAccount(playersAndCompanies, transaction.from)
        : getAccount(playersAndCompanies, transaction.to);
};
export var getAccount = function (accounts, transactionField) {
    return accounts.find(function (account) {
        return account.id === transactionField;
    });
};
export var addNextStageTotalsToCurrentStage = function (nxtStageTotals, currentStageTotals) {
    var expenses = nxtStageTotals.expenses, income = nxtStageTotals.income;
    currentStageTotals.expenses += expenses;
    currentStageTotals.income += income;
};
export var remapTransactions = function (transactions, playersAndCompanies, currentAccount) {
    return transactions.map(function (transaction) {
        var unix = moment.unix(transaction.datetime);
        var relevantAccount = {
            thumbnail: '',
            name: ''
        };
        relevantAccount = getRelevantAccountFromTransaction(transaction, playersAndCompanies, currentAccount);
        var isTransactionPositive = checkIfTransactionPositive(transaction, currentAccount);
        var incomeAndExpenses = {
            transactionAmount: transaction.amount,
            income: isTransactionPositive
                ? {
                    component: (React.createElement(TableLabel, { addedClasses: 'transaction__amount--positive', label: "".concat(Numeral(transaction.amount).format('0,0')) }))
                }
                : null,
            expenses: !isTransactionPositive
                ? {
                    component: (React.createElement(TableLabel, { addedClasses: 'transaction__amount--negative', label: "-".concat(Numeral(transaction.amount).format('0,0')) }))
                }
                : null
        };
        return __assign(__assign({ key: "".concat(currentAccount, "_").concat(transaction.datetime, "_").concat(transaction.to, "_").concat(transaction.from), avatar: {
                component: (React.createElement(Avatar, { src: relevantAccount ? relevantAccount.thumbnail : '' }))
            }, receiver: relevantAccount ? relevantAccount.name : '', time: unix.fromNow(), date: unix.format('DD.MM.YYYY'), remark: transaction.message }, incomeAndExpenses), { stage: transaction.stage });
    });
};
