import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Timeline from 'components/shared/timeline/container';
import GameControls from 'components/manager/shared/gameControls/gameControls';
import lockedGameOverlay from 'components/shared/lockedGameoverlay/component';
import { USER_RANK_ADMIN, USER_RANK_FACILITATOR } from 'components/permissions/types';
import AuthPermissions from 'components/permissions/container';
import { isCurrentStageInOvertime } from 'helpers/stageUtilities';
import { hasGameEnded, hasGameStarted, isGameLocked } from 'helpers/gameUtilities';
import { REQUEST_GAME_STATE_CHANGE_STOP } from 'components/shared/gameStageControls/constants';
import GameStageConfirmation from 'components/shared/gameStageControls/gameStageConfirmation/component';
GameStageControls.propTypes = {
    stages: PropTypes.array.isRequired,
    activeStage: PropTypes.object,
    manipulateGameStage: PropTypes.func.isRequired,
    currentUserRank: PropTypes.string,
};
export default function GameStageControls(_a) {
    var stages = _a.stages, _b = _a.activeStage, activeStage = _b === void 0 ? undefined : _b, manipulateGameStage = _a.manipulateGameStage, _c = _a.currentUserRank, currentUserRank = _c === void 0 ? '' : _c;
    var _d = useState(null), showConfirmationModal = _d[0], setShowConfirmationModal = _d[1];
    function getTimelineAndControls() {
        return (React.createElement(Fragment, null,
            React.createElement(GameStageConfirmation, { onAcceptClick: function () { return manipulateGameStage(REQUEST_GAME_STATE_CHANGE_STOP); }, onCancelClick: function () { return setShowConfirmationModal(false); }, onModalClose: function () { return setShowConfirmationModal(false); }, show: showConfirmationModal }),
            React.createElement("div", { className: "flex-grow-1 w-100 w-auto-md" },
                React.createElement(Timeline, { stages: stages, activeStage: activeStage })),
            React.createElement(AuthPermissions, { allowedRanks: [USER_RANK_ADMIN, USER_RANK_FACILITATOR] },
                React.createElement(GameControls, { stages: stages, activeStage: activeStage, manipulateGameStage: manipulateGameRequest }))));
    }
    function manipulateGameRequest(requestType) {
        if (requestType === REQUEST_GAME_STATE_CHANGE_STOP) {
            setShowConfirmationModal(true);
            return;
        }
        manipulateGameStage(requestType);
    }
    function displayLockedGameOverlay() {
        return lockedGameOverlay(manipulateGameStage, stages, currentUserRank);
    }
    function showLockedGame() {
        return !hasGameStarted(stages) || hasGameEnded(stages) || (isGameLocked(stages) && currentUserRank !== USER_RANK_ADMIN && currentUserRank !== USER_RANK_FACILITATOR);
    }
    return (showLockedGame() ? displayLockedGameOverlay() :
        React.createElement("div", { className: clsx(['staging-controls', activeStage && isCurrentStageInOvertime(activeStage) && 'staging-controls--warning']) },
            React.createElement("div", { className: "d-flex flex-align-items-center flex-gap-15 flex-direction-column-reverse flex-direction-unset-md" }, getTimelineAndControls())));
}
