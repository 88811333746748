import { connect } from 'react-redux';
import Component from './component';
import { userAuthLoginRequestAction } from '../../../../redux/actions/userAuthorization.actions';
var mapStateToProps = function (state) {
    return {
        falseLogin: state.userAuthorization.userSession.userSession
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        loginRequest: function (username, password) { return dispatch(userAuthLoginRequestAction(username, password)); },
        redirectToToken: function () { return dispatch({ type: 'ROUTE', to: '/token' }); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
