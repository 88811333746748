var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoboxColumn from './column/component';
import clsx from 'clsx';
var Infobox = /** @class */ (function (_super) {
    __extends(Infobox, _super);
    function Infobox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Infobox.prototype.shouldComponentUpdate = function () {
        return true;
    };
    Infobox.prototype.setGridColumnLength = function () {
        var gridFullLength = 12;
        var columns = this.props.columns;
        return gridFullLength / columns.length >= 1
            ? Math.floor(gridFullLength / columns.length)
            : 1;
    };
    Infobox.prototype.setInfoboxHeader = function () {
        var _a = this.props, title = _a.title, subtitle = _a.subtitle, icon = _a.icon, headerRender = _a.headerRender;
        if (title || subtitle) {
            return (React.createElement("div", { className: clsx('infobox__header', icon && 'infobox__header--with-icon') },
                icon ? (React.createElement("div", { className: "infobox__icon icon--img icon--".concat(icon) })) : (''),
                React.createElement("div", { className: "infobox__titles" },
                    React.createElement("div", { className: "infobox__heading" }, title),
                    React.createElement("div", { className: "infobox__subheading" }, subtitle)),
                headerRender ? (React.createElement("div", { className: "infobox__dynamicheader" }, headerRender())) : null));
        }
        return null;
    };
    Infobox.prototype.render = function () {
        var props = this.props;
        var className = clsx('infobox__column', props.includeGrid && "f-grid-col-".concat(this.setGridColumnLength()));
        return (React.createElement("div", { className: "infobox" },
            React.createElement("div", { className: clsx('infobox__wrapper', props.className) },
                this.setInfoboxHeader(),
                props.columns.length > 0 ? (React.createElement("div", { className: "f-grid" }, props.columns.map(function (column, index) { return (React.createElement(InfoboxColumn, { key: index, className: clsx(className, column.className), label: column.label, value: column.value, icon: column.icon })); }))) : null,
                props.children)));
    };
    Infobox.propTypes = {
        columns: PropTypes.array,
        className: PropTypes.string,
        icon: PropTypes.string,
        includeGrid: PropTypes.bool,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        children: PropTypes.any,
        headerRender: PropTypes.func
    };
    Infobox.defaultProps = {
        columns: [],
        className: '',
        icon: '',
        includeGrid: true,
        title: '',
        subtitle: '',
        children: null,
        headerRender: null
    };
    return Infobox;
}(Component));
export default Infobox;
