var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { WEBSOCKET_CONNECTED, WEBSOCKET_RECEIVED_MESSAGE, WEBSOCKET_TIMED_OUT, WEBSOCKET_DISCONNECTED, UI_TOGGLE_STAGE_INFO_BAR, UI_OPEN_STAGE_INFO_BAR, UI_WS_CONNECTION_ATTEMPT_MADE, UI_GAMESLIST_REQUEST, UI_GAMESLIST_REQUEST_ENDED, UI_GAMES_MAX_PAGES_RECIEVED, UI_SET_MOB_PLAYER_SELECTION } from 'components/constants';
var initialState = {
    showStageInfoBar: false,
    wsConnectionEstablished: false,
    wsConnectionAttemptMade: false,
    gamesListRequested: false,
    gamesPaginationMaxPages: 1,
    showMobilePlayerSelection: false
};
export default function (state, _a) {
    if (state === void 0) { state = initialState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        default:
            return state;
        case UI_SET_MOB_PLAYER_SELECTION: {
            return __assign(__assign({}, state), { showMobilePlayerSelection: payload });
        }
        case UI_TOGGLE_STAGE_INFO_BAR: {
            return __assign(__assign({}, state), { showStageInfoBar: !state.showStageInfoBar });
        }
        case UI_OPEN_STAGE_INFO_BAR: {
            return __assign(__assign({}, state), { showStageInfoBar: true });
        }
        case WEBSOCKET_CONNECTED: {
            return __assign(__assign({}, state), { wsConnectionEstablished: true, wsConnectionTimedOut: false });
        }
        case WEBSOCKET_RECEIVED_MESSAGE: {
            return __assign(__assign({}, state), { wsConnectionEstablished: true, wsConnectionTimedOut: false });
        }
        case WEBSOCKET_TIMED_OUT: {
            return __assign(__assign({}, state), { wsConnectionEstablished: false, wsConnectionTimedOut: true });
        }
        case WEBSOCKET_DISCONNECTED: {
            return __assign(__assign({}, state), { wsConnectionEstablished: false, wsConnectionTimedOut: true });
        }
        case UI_WS_CONNECTION_ATTEMPT_MADE: {
            return __assign(__assign({}, state), { wsConnectionAttemptMade: payload });
        }
        case UI_GAMESLIST_REQUEST: {
            return __assign(__assign({}, state), { gamesListRequested: true });
        }
        case UI_GAMESLIST_REQUEST_ENDED: {
            return __assign(__assign({}, state), { gamesListRequested: false });
        }
        case UI_GAMES_MAX_PAGES_RECIEVED: {
            return __assign(__assign({}, state), { gamesPaginationMaxPages: payload });
        }
    }
}
;
