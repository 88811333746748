var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PLAYER_AUTHORIZATION_PURGE_TOKEN, PLAYER_AUTHORIZATION_RECEIVE_TOKEN, PLAYER_AUTHORIZATION_FAIL_TOKEN } from 'components/constants';
var initialState = {
    token: null,
    falseLogin: false,
};
export default (function (state, _a) {
    if (state === void 0) { state = initialState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        default:
            return state;
        case PLAYER_AUTHORIZATION_PURGE_TOKEN:
            return __assign(__assign({}, state), { token: null });
        case PLAYER_AUTHORIZATION_RECEIVE_TOKEN:
            return __assign(__assign({}, state), { token: payload, falseLogin: false });
        case PLAYER_AUTHORIZATION_FAIL_TOKEN:
            return __assign(__assign({}, state), { falseLogin: true });
    }
});
