import 'babel-polyfill';
import ReactGA from 'react-ga';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { globals } from '../config/globals';
import 'assets/style/style.scss';
import { purgeLocalStorage } from 'helpers/utilities';
import { I18nextProvider } from 'react-i18next';
import LanguageServiceConfig from './services/LanguageService/LanguageService';
import App from 'components/App';
import * as Sentry from '@sentry/react';
ReactGA.initialize(process.env.UA_TRACKING_ID);
window.globals = globals;
purgeLocalStorage();
var isDev = process.env.NODE_ENV === 'development';
Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Sentry.Replay()],
    sampleRate: isDev ? 1 : 0.1,
    tracesSampleRate: isDev ? 1 : 0.1,
    // Session Replay
    replaysSessionSampleRate: isDev ? 0 : 0.1,
    replaysOnErrorSampleRate: isDev ? 0 : 1.0
});
render(React.createElement(I18nextProvider, { i18n: LanguageServiceConfig },
    React.createElement(Suspense, { fallback: "loading" },
        React.createElement(Provider, { store: store },
            React.createElement(App, null)))), document.getElementById('app'));
