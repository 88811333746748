var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { taskTypes } from './task';
import { transactionTypes } from './transaction';
import { gameTypes } from './game';
import { dataListingTypes } from './dataListing';
import { authMessageTypes } from './authMessage';
import { ReportsMessageTypes } from './reports';
export var socketMessageTypes = __assign(__assign(__assign(__assign(__assign(__assign({}, taskTypes), transactionTypes), gameTypes), dataListingTypes), authMessageTypes), ReportsMessageTypes);
