var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import GameStageControls from 'components/shared/gameStageControls/GameStageControls';
import { activeStageUpdateRequest } from '../../../socketConnection/socketMessageRequests/stageRequests';
import Modal from 'components/player/shared/modal/component';
import AuthPermissions from 'components/permissions/component';
import { USER_RANK_ADMIN, USER_RANK_FACILITATOR } from 'components/permissions/types';
import { getActiveStage } from 'helpers/stageUtilities';
import { secToHrAndMin } from 'helpers/timeUtilities';
import HeaderDesktop from 'components/shared/header/headerDesktop/component';
import HeaderMobile from 'components/shared/header/headerMobile/component';
import { setT } from 'helpers/translationUtilities';
var internalTimer;
Header.propTypes = {
    adminName: PropTypes.string,
    player: PropTypes.object,
    playerThumbnail: PropTypes.string,
    companyThumbnail: PropTypes.string,
    game: PropTypes.object,
    stages: PropTypes.array,
    websocketMessage: PropTypes.func.isRequired,
    showStageInfoBar: PropTypes.bool,
    currentUserRank: PropTypes.string
};
export default function Header(_a) {
    var _b = _a.adminName, adminName = _b === void 0 ? '' : _b, _c = _a.player, player = _c === void 0 ? null : _c, _d = _a.playerThumbnail, playerThumbnail = _d === void 0 ? '' : _d, _e = _a.companyThumbnail, companyThumbnail = _e === void 0 ? '' : _e, _f = _a.game, game = _f === void 0 ? null : _f, _g = _a.stages, stages = _g === void 0 ? [] : _g, websocketMessage = _a.websocketMessage, _h = _a.showStageInfoBar, showStageInfoBar = _h === void 0 ? false : _h, _j = _a.currentUserRank, currentUserRank = _j === void 0 ? '' : _j;
    var _k = useState(false), headerOpen = _k[0], setHeaderOpen = _k[1];
    var _l = useState(false), showOvertimeModal = _l[0], setShowOvertimeModal = _l[1];
    var _m = useState(null), timeLeft = _m[0], setTimeLeft = _m[1];
    var activeStage = getActiveStage(stages);
    useEffect(function () {
        return function () {
            if (internalTimer) {
                clearTimeout(internalTimer);
            }
        };
    }, []);
    if (!internalTimer && activeStage && remainingTime(activeStage) <= 60) {
        setShowOvertimeModal(true);
        setInternalOvertimeTimer(activeStage, setTimeLeft);
    }
    var elementRef = useRef(null);
    var menuPassedVariables = {
        elementRef: elementRef,
        adminName: adminName,
        player: player,
        playerThumbnail: playerThumbnail,
        companyThumbnail: companyThumbnail,
        game: game,
        headerOpen: headerOpen,
        setHeaderOpen: setHeaderOpen,
        currentUserRank: currentUserRank
    };
    return (React.createElement("div", { className: "header" },
        React.createElement("div", { className: "d-none d-block-md" },
            React.createElement(HeaderDesktop, __assign({}, menuPassedVariables))),
        React.createElement("div", { className: "d-none-md" },
            React.createElement(HeaderMobile, __assign({}, menuPassedVariables))),
        showStageInfoBar ?
            React.createElement("div", { className: "header__game-state" },
                React.createElement(GameStageControls, { stages: stages, activeStage: activeStage, manipulateGameStage: function (action) { return manipulateGameStage(action, websocketMessage); }, currentUserRank: currentUserRank })) : null,
        React.createElement(AuthPermissions, { currentUserRank: currentUserRank, allowedRanks: [USER_RANK_ADMIN, USER_RANK_FACILITATOR] },
            React.createElement(Modal, { expanded: showOvertimeModal, modalInnerClasses: 'modal__inner--content-width modal__inner--error' },
                React.createElement("div", null,
                    React.createElement("div", { className: "d-flex flex-align-middle" },
                        React.createElement("div", { className: "text-transform-uppercase bold text-center", style: { marginRight: '20px', fontSize: '20px' } },
                            setT('time_remaining_until_end_of_stage'),
                            React.createElement("div", { className: "red-color-primary", style: { marginTop: '8px' } }, secToHrAndMin(timeLeft, 'mm:ss'))),
                        React.createElement("button", { className: "main-button main-button--lg", onClick: function () { return setShowOvertimeModal(false); } }, setT('ok'))))))));
}
function setInternalOvertimeTimer(activeStage, setTimeLeft) {
    internalTimer = setInterval(function () {
        var remainingSeconds = remainingTime(activeStage);
        setTimeLeft(remainingSeconds);
    }, 1000);
}
function remainingTime(stage) {
    return (stage.startedAt + stage.duration) - moment().unix();
}
function manipulateGameStage(action, websocketMessage) {
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = websocketMessage;
                    return [4 /*yield*/, activeStageUpdateRequest(action)];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [2 /*return*/];
            }
        });
    });
}
