var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import clsx from 'clsx';
import Numeral from 'numeral';
import TableLabel from 'components/player/shared/TableLabel/component';
import { getSingleStageTotals } from 'components/player/shared/helpers';
import { addNextStageTotalsToCurrentStage, getNextStage, remapTransactions, singleStageTransaction } from 'components/configs/collapseTable/transactionTable/shared';
import { setT } from 'helpers/translationUtilities';
import { mergeStageToPreviousStage } from 'helpers/stageUtilities';
function getTableColumns() {
    return [
        { label: '', name: 'avatar' },
        { label: React.createElement("div", null,
                setT('payee'),
                "/",
                setT('receiver')), name: 'receiver' },
        { label: setT('time'), name: 'time' },
        { label: setT('remark'), name: 'remark' },
        { label: setT('income'), name: 'income' },
        { label: setT('expenses'), name: 'expenses' },
        { label: '', name: '' }
    ];
}
export default function TransactionTableConfig(_a) {
    var transactions = _a.transactions, game = _a.game, playersAndCompanies = _a.playersAndCompanies, onItemClick = _a.onItemClick, openRows = _a.openRows, currentAccount = _a.currentAccount;
    var tableValues = remapTransactions(transactions.sort(function (a, b) { return b.datetime - a.datetime; }), playersAndCompanies, currentAccount);
    var mappedStageArray = [];
    var _loop_1 = function (i) {
        var stage = game.stages[i];
        var nextStage = getNextStage({
            previousStage: stage,
            stages: game.stages,
            tableValues: tableValues,
            playersAndCompanies: playersAndCompanies,
            currentAccount: currentAccount,
            transactions: transactions
        });
        var prevStage = game.stages.find(function (singleStage) { return singleStage.ordinal === stage.ordinal - 1; });
        var isCurrentRowOpen = openRows.findIndex(function (rowId) { return rowId === stage.id; }) !== -1;
        var currentStageTransactionsTotals = getSingleStageTotals(singleStageTransaction(transactions, stage), playersAndCompanies, currentAccount);
        if (nextStage.rows.length) {
            addNextStageTotalsToCurrentStage(nextStage.transactionTotals, currentStageTransactionsTotals);
        }
        if (mergeStageToPreviousStage(stage, prevStage)) {
            return "continue";
        }
        mappedStageArray.push({
            id: stage.id,
            stageName: stage.stageName,
            isRowOpen: isCurrentRowOpen,
            stageTotals: currentStageTransactionsTotals,
            addedClasses: clsx(['cursor-pointer', isCurrentRowOpen && 'extended']),
            innerAreaClasses: clsx([isCurrentRowOpen && 'extended']),
            tableColumns: getTableColumns(),
            properties: {
                onClick: function () { return onItemClick(stage.id); }
            },
            groupHeaderCells: getSingleStageHeaderCellsInfo(stage, currentStageTransactionsTotals),
            rows: __spreadArray(__spreadArray([], tableValues.filter(function (value) { return value.stage === stage.id; }), true), nextStage.rows, true)
        });
    };
    for (var i = 0; i < game.stages.length; i++) {
        _loop_1(i);
    }
    return mappedStageArray.filter(function (stage) { return stage.rows.length; });
}
function getSingleStageHeaderCellsInfo(stage, totalAmounts) {
    return [
        {
            id: "".concat(stage.id, "_stage_name"),
            title: stage.stageName,
            properties: { colSpan: 4, className: 'text-align-left' }
        },
        {
            id: "".concat(stage.id, "_stage_income"),
            properties: { className: 'text-align-left' },
            component: (React.createElement(TableLabel, { addedClasses: 'transaction__amount--positive', label: "".concat(Numeral(totalAmounts.income).format('0,0')) }))
        },
        {
            id: "".concat(stage.id, "_stage_expenses"),
            properties: { className: 'text-align-left' },
            component: (React.createElement(TableLabel, { addedClasses: 'transaction__amount--negative', label: "-".concat(Numeral(totalAmounts.expenses).format('0,0')) }))
        },
        {
            id: "".concat(stage.id, "_chevron"),
            component: React.createElement("span", { className: "chevron" })
        }
    ];
}
