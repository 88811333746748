import SocketService from '../SocketService';
export var balanceRequest = function (id, type) {
    return SocketService.createEncodedMessage({
        kind: 'bankAction',
        bankAction: {
            id: id,
            type: type
        }
    });
};
