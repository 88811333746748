// 4 entering as company, 3 from game
export var authMessageTypes = {
    authMessage: [
        {
            type: 'SESSION_SAVE',
            payload: function (payload) { return payload.authMessage.message; },
            conditional: function (payload) { return payload.authMessage.type === 1; }
        },
        {
            type: 'USERS_OWN_TOKEN',
            payload: function () {
                return { ownToken: true };
            },
            conditional: function (payload) { return payload.authMessage.type === 1; }
        },
        {
            type: 'TOKEN_FAILED',
            payload: function () {
                return { ownToken: false };
            },
            conditional: function (payload) { return payload.authMessage.type === 2; }
        },
        {
            type: 'USERS_OWN_TOKEN',
            payload: function () {
                return { ownToken: false };
            },
            conditional: function (payload) { return payload.authMessage.type === 3; }
        },
        {
            type: 'CURRENT_ONBOARDING_TYPE',
            payload: function () {
                return { onBoardingType: 'game' };
            },
            conditional: function (payload) { return payload.authMessage.type === 3; }
        },
        {
            type: 'USERS_OWN_TOKEN',
            payload: function () {
                return { ownToken: false };
            },
            conditional: function (payload) { return payload.authMessage.type === 4; }
        },
        {
            type: 'CURRENT_ONBOARDING_TYPE',
            payload: function () {
                return { onBoardingType: 'company' };
            },
            conditional: function (payload) { return payload.authMessage.type === 4; }
        },
        {
            type: 'TOKEN_RECIEVED',
            payload: function (payload) { return payload.authMessage.message; },
            conditional: function (payload) { return payload.authMessage.type === 4; }
        }
    ]
};
