var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { connect } from 'react-redux';
import Component from './component';
import { isUser } from 'helpers/utilities';
var mapStateToProps = function (state) {
    return {
        playerIsUser: isUser(state.userAuthorization.userSession.rank),
        company: {
            id: state.player.company.id,
            totalBalance: state.player.company.totalBalance,
            thumbnail: state.player.company.thumbnail,
            name: state.player.company.name
        },
        currentUserRank: state.userAuthorization.userSession.rank,
        companiesAndPlayers: __spreadArray(__spreadArray([], state.general.companies, true), state.general.players, true),
        player: {
            id: state.player.id,
            name: state.player.name,
            totalBalance: state.player.totalBalance,
            thumbnail: state.player.thumbnail
        },
        stages: state.game.stages
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirect: function (url) { return dispatch({ to: url, type: 'ROUTE' }); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
