import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GenericDropdown from 'components/shared/dropdown/component';
import { setT } from 'helpers/translationUtilities';
Pagination.propTypes = {
    itemsPerPage: PropTypes.object,
    pages: PropTypes.object
};
export default function Pagination(_a) {
    var _b = _a.itemsPerPage, dropdownOptions = _b.dropdownOptions, currentPerPage = _b.currentPerPage, setItemsPerPage = _b.setItemsPerPage, _c = _a.pages, currentPage = _c.currentPage, maxPages = _c.maxPages, onPrevPage = _c.onPrevPage, onNextPage = _c.onNextPage;
    var _d = useState(false), rowsDropdownOpen = _d[0], setRowsDropdownOpen = _d[1];
    return (React.createElement("div", { className: "pagination" },
        React.createElement("div", { className: "pagination__dropdown" },
            React.createElement("div", { className: "pagination__dropdown-label" },
                setT('rows_on_page'),
                ":"),
            React.createElement(GenericDropdown, { options: dropdownOptions, selectedLabel: currentPerPage, onButtonClick: function () { return setRowsDropdownOpen(!rowsDropdownOpen); }, onOptionClick: function (option) {
                    setItemsPerPage(option.value);
                    setRowsDropdownOpen(false);
                }, isOpen: rowsDropdownOpen, onOutsideClick: function () { return setRowsDropdownOpen(false); } })),
        React.createElement("div", { className: "pagination__pages" },
            React.createElement("div", { className: "pagination__arrow", onClick: onPrevPage },
                React.createElement("div", { className: "pagination__icon" })),
            React.createElement("div", { className: "pagination__pages-label" },
                setT('page'), "".concat(currentPage, "/").concat(maxPages)),
            React.createElement("div", { className: "pagination__arrow", onClick: onNextPage },
                React.createElement("div", { className: "pagination__icon" })))));
}
