var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { Fragment } from 'react';
import { findFromCells, objectHasProperty } from 'helpers/utilities';
import { setT } from 'helpers/translationUtilities';
export default function MobParticipantsTableConfig(tableConfig, movePlayersState, onGroupItemClick) {
    if (movePlayersState === void 0) { movePlayersState = false; }
    var mainConfig = {
        rows: [],
        keyValues: [],
    };
    mainConfig.rows = __spreadArray([], tableConfig, true).filter(function (companyInfoRow) { return companyInfoRow.id !== 'utility_row'; })
        .map(function (companyInfoRow) {
        return {
            key: companyInfoRow.id,
            component: mainRowComponent(parseOriginalConfigCellInfo(companyInfoRow.groupHeaderCells, onGroupItemClick)),
            childComponent: React.createElement(Fragment, { key: companyInfoRow.id }, getChildComponent(companyInfoRow.rows, mainRowComponent, movePlayersState)),
            childrenVisible: companyInfoRow.isRowOpen,
        };
    });
    return mainConfig;
    function getChildComponent(children, mainRowComponent, movePlayersState) {
        return (React.createElement(Fragment, null, children.map(function (child, j) { return (React.createElement("div", { className: "mob-collapse-table__child-wrapper", key: "".concat(child.id, "_").concat(child.name) }, mainRowComponent({
            title: child.name,
            code: child.token,
            balance: child.balance,
            avatar: child.avatar.component,
            button: child.button.component,
            checkbox: movePlayersState ? child.checkbox.component : null,
            onClick: child.customProperties.onClick
        }))); })));
    }
    function mainRowComponent(values) {
        return (React.createElement("div", { className: "mar-left-10 mar-right-10", onClick: values.onClick },
            React.createElement("div", { className: "d-flex w-100 flex-justify-content-between flex-align-items-center" },
                React.createElement("div", { className: "d-flex" },
                    values.avatar,
                    React.createElement("div", { className: "mar-left-10" },
                        React.createElement("div", { className: "bold" }, values.title || setT('unassigned')),
                        values.code ? React.createElement("div", null,
                            setT('code'),
                            ": ",
                            values.code) : React.createElement("div", null))),
                values.balance ? React.createElement("div", null,
                    setT('balance'),
                    ": ",
                    React.createElement("b", null, values.balance)) : React.createElement("div", null)),
            React.createElement("div", { className: "d-flex w-100 flex-justify-content-between flex-align-items-center" },
                values.checkbox ? values.checkbox : null,
                objectHasProperty(values, 'players') ? React.createElement("div", null,
                    setT('players'),
                    ": ",
                    React.createElement("b", null, values.players)) : React.createElement("div", null),
                React.createElement("div", null, values.button))));
    }
}
function parseOriginalConfigCellInfo(cellsInfo, onGroupItemClick) {
    return {
        title: findFromCells(cellsInfo, 'title'),
        code: findFromCells(cellsInfo, 'token'),
        balance: findFromCells(cellsInfo, 'amount'),
        avatar: findFromCells(cellsInfo, 'avatar'),
        players: findFromCells(cellsInfo, 'players'),
        button: findFromCells(cellsInfo, 'button'),
        onClick: onGroupItemClick
    };
}
