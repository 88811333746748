import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { reformatTinyMCEiframe } from 'helpers/utilities';
var FacilitatorTemplateInfo = function (props) {
    var thumbnail = {
        backgroundImage: 'url(' + props.thumbnail + ')'
    };
    useEffect(function () {
        reformatTinyMCEiframe("iframe");
    }, []);
    return (React.createElement("div", { className: clsx([
            'cardview__item cardview__item--boxed',
            props.isClickable && ' cardview__item--clickable',
            props.selectedTemplateID === props.id && 'cardview__item--selected'
        ]), style: { height: "auto" }, onClick: function () { return props.onTemplateClick(props.id); } },
        React.createElement("div", { className: "cardview__inner" },
            React.createElement("div", { className: "cardview__avatar" },
                React.createElement("div", { className: "cardview__inneravatar", style: thumbnail })),
            React.createElement("div", { className: "cardview__content" },
                React.createElement("div", { className: "cardview__topcontent" }),
                React.createElement("div", { className: "cardview__title" }, props.name),
                React.createElement("div", { className: "cardview__desc", dangerouslySetInnerHTML: { __html: props.description } })))));
};
FacilitatorTemplateInfo.propTypes = {
    id: PropTypes.any,
    name: PropTypes.string,
    description: PropTypes.string,
    thumbnail: PropTypes.string,
    onTemplateClick: PropTypes.func,
    selectedTemplateID: PropTypes.string,
    isClickable: PropTypes.bool
};
FacilitatorTemplateInfo.defaultProps = {
    id: '',
    name: '',
    description: '',
    thumbnail: '',
    onTemplateClick: null,
    selectedTemplateID: '',
    isClickable: false
};
export default FacilitatorTemplateInfo;
