import React from 'react';
import PropTypes from "prop-types";
import { Editor } from '@tinymce/tinymce-react';
TinyMCE.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
export default function TinyMCE(props) {
    var value = props.value, onChange = props.onChange;
    var handleEditorChange = function (content, editor) {
        if (content !== value) {
            if (typeof onChange === "function") {
                onChange(content);
            }
        }
    };
    return (React.createElement(Editor, { apiKey: process.env.TINYMCE_API_TOKEN, init: {
            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker',
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
        }, value: value, onEditorChange: handleEditorChange }));
}
