import { USER_TYPE_USER } from '../types/user-types';
export var reformatTinyMCEiframe = function (selector) {
    var iframes = document.querySelectorAll(selector);
    Array.prototype.forEach.call(iframes, function (iframe) {
        if (iframe.parentNode.classList.contains('iframe-container')) {
            return;
        }
        var wrapper = document.createElement('div');
        wrapper.className = 'iframe-container';
        iframe.parentNode.insertBefore(wrapper, iframe);
        wrapper.appendChild(iframe);
    });
};
export var filterByKeyword = function (theArray, word) {
    return theArray.filter(function (item) {
        var regex = new RegExp(word, 'ig');
        return regex.test(item.name);
    });
};
export var isUser = function (permission) {
    return permission === USER_TYPE_USER;
};
export var objectHasProperty = function (obj, property) {
    return Object.prototype.hasOwnProperty.call(obj, property);
};
export var getEntityBalance = function (balances, entity) {
    if (!balances || !balances.length || !entity) {
        return 0;
    }
    var foundBalance = balances.find(function (balance) {
        return entity.id === balance.id;
    });
    return foundBalance ? foundBalance.balance : 0;
};
export var purgeLocalStorage = function () {
    var storeV = localStorage.getItem('store_v');
    if (!storeV || window.globals.CURRENT_STORE_VERSION !== storeV) {
        localStorage.removeItem('store');
        localStorage.setItem('store_v', window.globals.CURRENT_STORE_VERSION);
    }
};
export var findFromCells = function (cells, property) {
    var foundItem = cells.find(function (cell) { return cell.name === property; });
    if (!foundItem) {
        return null;
    }
    return foundItem.component ? foundItem.component : foundItem.title;
};
