import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/shared/avatar/component';
import { setT } from 'helpers/translationUtilities';
var playersbarSingle = function (props) {
    return (React.createElement("div", { className: "playerlisting__single playerlisting__single--fullwidth playerlisting__single--nocheck", onClick: props.onPlayerClick },
        React.createElement("div", { className: "playerlisting__set" },
            React.createElement("div", { className: "playerlisting__avatar" },
                React.createElement(Avatar, { src: props.player.thumbnail }))),
        React.createElement("div", { className: "playerlisting__inner" },
            React.createElement("div", { className: "playerlisting__name" },
                props.player.name,
                props.visibleCode ? (React.createElement("div", { className: "playerlisting__code" },
                    setT('game_code'),
                    ": ",
                    props.player.token)) : null))));
};
playersbarSingle.propTypes = {
    player: PropTypes.object,
    onPlayerClick: PropTypes.func,
    visibleCode: PropTypes.bool
};
playersbarSingle.defaultProps = {
    player: {},
    onPlayerClick: null,
    visibleCode: false
};
export default playersbarSingle;
