import { disconnect, establishConnection, sendMessage } from 'library/socket-connection';
import { socketConnectionTimeout } from '../socketConnection/SocketService';
import { WEBSOCKET_CONNECT, WEBSOCKET_CONNECTED, WEBSOCKET_DISCONNECT, WEBSOCKET_MESSAGE } from 'components/constants';
import { gameJoinAction } from '../redux/actions/game.actions';
export var socketMiddleware = function (_a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return function (next) { return function (action) {
        switch (action.type) {
            default:
                break;
            case WEBSOCKET_CONNECT:
                establishConnection(dispatch, action.payload);
                break;
            case WEBSOCKET_CONNECTED:
                {
                    var playerAuthorization = getState().playerAuthorization;
                    if (playerAuthorization.token) {
                        dispatch(gameJoinAction(playerAuthorization.token));
                    }
                }
                break;
            case WEBSOCKET_MESSAGE:
                sendMessage(action.payload);
                break;
            case WEBSOCKET_DISCONNECT:
                if (socketConnectionTimeout) {
                    clearTimeout(socketConnectionTimeout);
                }
                disconnect();
                window.globals.WS_URL = null;
                break;
        }
        next(action);
    }; };
};
