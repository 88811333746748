var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { GRAPH_SETTING_COMPANIES, GRAPH_SETTING_COMPANY_MONTHLY } from 'components/manager/facilitator/reports/reportsChart/component';
import { getAllCompanyTotals, getCompanyScoresInASingleStage } from 'components/manager/facilitator/reports/utilities';
import { styleVariables } from 'components/styleConstants';
import { useTranslation } from 'react-i18next';
export default function ReportsChartsConfig(_a) {
    var companies = _a.companies, stages = _a.stages, graphSetting = _a.graphSetting, chosenStageId = _a.chosenStageId, chosenCompanyId = _a.chosenCompanyId;
    var t = useTranslation().t;
    var companyNames = companies.map(function (company) { return company.name; });
    var stagesNames = stages.map(function (stage) { return stage.stageName; });
    var chosenCompany = companies.find(function (company) { return company.id === chosenCompanyId; });
    function graphSettingDefinedVariables(graphSetting) {
        var settings = {};
        var _a = styleVariables.colors, primaryColor = _a.primaryColor, greenColorPrimary = _a.greenColorPrimary, redColorPrimary = _a.redColorPrimary, timelineActiveColor = _a.timelineActiveColor;
        if (graphSetting === GRAPH_SETTING_COMPANIES) {
            var stageIncomes = setCompaniesComparisonData(chosenStageId, companies, 'income');
            var stageExpenses = setCompaniesComparisonData(chosenStageId, companies, 'expense');
            settings = {
                xaxis: {
                    categories: companyNames,
                },
                colors: [greenColorPrimary, redColorPrimary, primaryColor],
                stroke: {
                    curve: ['smooth', 'smooth', 'straight'],
                    dashArray: 4,
                    width: [0, 0, 3]
                },
                series: [
                    {
                        name: t('income'),
                        type: 'column',
                        data: stageIncomes
                    },
                    {
                        name: t('expenses'),
                        type: 'column',
                        data: stageExpenses
                    },
                    {
                        name: "".concat(t('profit'), "/").concat(t('loss')),
                        type: 'line',
                        data: getStageProfitLosses(stageIncomes, stageExpenses)
                    },
                ],
            };
        }
        if (graphSetting === GRAPH_SETTING_COMPANY_MONTHLY) {
            settings = {
                xaxis: {
                    categories: stagesNames,
                },
                colors: [greenColorPrimary, redColorPrimary, timelineActiveColor, primaryColor],
                stroke: {
                    curve: ['smooth', 'smooth', 'straight', 'straight'],
                    dashArray: [0, 0, 0, 4],
                    width: [0, 0, 3, 3]
                },
                series: [
                    {
                        name: t('income'),
                        type: 'column',
                        data: setSingleCompanyMonthComparisonData(stages, chosenCompany, 'income')
                    },
                    {
                        name: t('expenses'),
                        type: 'column',
                        data: setSingleCompanyMonthComparisonData(stages, chosenCompany, 'expense')
                    },
                    {
                        name: t('balance'),
                        type: 'line',
                        data: setSingleCompanyMonthComparisonData(stages, chosenCompany, 'balance')
                    },
                    {
                        name: t('start_balance'),
                        type: 'line',
                        data: setSingleCompanyMonthComparisonData(stages, chosenCompany, 'startingBalance')
                    },
                ]
            };
        }
        return settings;
    }
    var settings = graphSettingDefinedVariables(graphSetting);
    return {
        options: {
            grid: {
                padding: {
                    bottom: -20,
                }
            },
            legend: {
                itemMargin: {
                    vertical: 20
                },
            },
            colors: settings.colors,
            stroke: settings.stroke,
            chart: {
                id: 'ReportsChart',
                align: 'center',
                background: '#fff',
                toolbar: {
                    show: false,
                    tools: {
                        download: false,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: '',
                    }
                }
            },
            xaxis: {
                categories: settings.xaxis.categories,
                labels: {
                    rotateAlways: true,
                    minHeight: 100,
                    maxHeight: 180
                }
            },
        },
        series: __spreadArray([], settings.series, true)
    };
}
function getStageProfitLosses(incomes, expenses) {
    var profitsLosses = [];
    for (var i = 0; i < incomes.length; i++) {
        profitsLosses.push((incomes[i] - expenses[i]));
    }
    return profitsLosses;
}
function setSingleCompanyMonthComparisonData(stages, company, key) {
    return stages.map(function (stage) {
        var scores = company.scores;
        return scores.find(function (score) { return score.stageId === stage.id; })[key];
    });
}
function setCompaniesComparisonData(chosenStageId, companies, key) {
    return companies.map(function (company) {
        var companyMoneyValues = chosenStageId ? getCompanyScoresInASingleStage(company.scores, chosenStageId) : getAllCompanyTotals(company);
        return companyMoneyValues[key];
    });
}
