var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';
var Avatar = /** @class */ (function (_super) {
    __extends(Avatar, _super);
    function Avatar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Avatar.prototype.shouldComponentUpdate = function () {
        return true;
    };
    Avatar.prototype.render = function () {
        var _a = this.props, position = _a.position, addedClasses = _a.addedClasses;
        var avatarUrl = {
            backgroundImage: 'url(' + this.props.src + ')'
        };
        return (React.createElement("div", { className: clsx('avatar', position, addedClasses) },
            React.createElement("div", { className: "avatar__inner", style: avatarUrl })));
    };
    Avatar.defaultProps = {
        src: '',
        position: '',
        addedClasses: ''
    };
    Avatar.propTypes = {
        src: PropTypes.string,
        position: PropTypes.string,
        addedClasses: PropTypes.string
    };
    return Avatar;
}(Component));
export default Avatar;
