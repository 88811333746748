var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PLAYER_RECEIVE_INFORMATION, PLAYER_RECEIVE_COMPANY_INFORMATION, PLAYER_RECEIVE_TRANSACTION, PLAYER_RECEIVE_TRANSACTION_LIST, PLAYER_RECEIVE_BANK_BALANCES, PLAYER_RECEIVE_TASK, PLAYER_RECEIVE_TICKET_LIST, PLAYER_RECIEVE_COMPANY_ID, PLAYER_RECEIVE_OWN_TOKEN, PLAYER_RECEIVE_ONBOARDING_TYPE, PLAYER_PURGE_ALL_DATA, PLAYER_PURGE_ALL_PLAYER_INTERNAL_INFO, PLAYER_PURGE_COMPANY, PLAYER_UPDATE_COMPANY_INFORMATION } from 'components/constants';
var initialState = {
    id: null,
    name: null,
    recievedCompanyID: null,
    incomeBalance: 0,
    expenseBalance: 0,
    totalBalance: 0,
    transactions: [],
    tasks: {
        items: [],
        last: null
    },
    lastTask: null,
    thumbnail: '',
    ownToken: false,
    onBoardingType: '',
    allowGameInit: false,
    company: {
        incomeBalance: 0,
        expenseBalance: 0,
        totalBalance: 0,
        transactions: [],
        id: '',
        name: '',
        description: '',
        thumbnail: '',
        isBank: false
    },
    bank: {
        transactions: [],
        incomeBalance: 0,
        expenseBalance: 0,
        totalBalance: 0,
        balances: []
    }
};
var task = {
    id: '',
    to: null,
    status: 0,
    name: '',
    description: '',
    skippable: false,
    stage: '',
    datetime: 0
};
var getTransactionsObject = function (payload) {
    return {
        transactions: payload.transactions,
        expenseBalance: payload.expenseBalance,
        incomeBalance: payload.incomeBalance,
        totalBalance: payload.totalBalance
    };
};
var getUpdatedTaskList = function (tasks, receivedTask) {
    var index = tasks.findIndex(function (task) { return task.id === receivedTask.id; });
    if (index === -1) {
        tasks.push(receivedTask);
    }
    else {
        if (receivedTask.status === 0) {
            tasks[index] = receivedTask;
        }
        else {
            tasks[index].status = receivedTask.status;
        }
    }
};
export default (function (state, _a) {
    if (state === void 0) { state = initialState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        default:
            return state;
        case PLAYER_PURGE_ALL_DATA: {
            return initialState;
        }
        case PLAYER_PURGE_ALL_PLAYER_INTERNAL_INFO: {
            return __assign(__assign({}, state), { tasks: initialState.tasks, company: __assign(__assign({}, state.company), { transactions: [] }), bank: initialState.bank, allowGameInit: initialState.allowGameInit });
        }
        case PLAYER_PURGE_COMPANY: {
            return __assign(__assign({}, state), { company: initialState.company });
        }
        case PLAYER_RECEIVE_INFORMATION: {
            return __assign(__assign({}, state), { name: payload.playerName, id: payload.playerId, thumbnail: payload.thumbnail, incomeBalance: payload.incomeBalance, expenseBalance: payload.expenseBalance, totalBalance: payload.totalBalance });
        }
        case PLAYER_RECEIVE_COMPANY_INFORMATION: {
            return __assign(__assign({}, state), { company: __assign(__assign({}, state.company), { name: payload.name, description: payload.description, id: payload.id, isBank: payload.isBank, thumbnail: payload.thumbnail, incomeBalance: payload.incomeBalance, expenseBalance: payload.expenseBalance, totalBalance: payload.totalBalance }) });
        }
        case PLAYER_UPDATE_COMPANY_INFORMATION: {
            return __assign(__assign({}, state), { company: __assign({}, payload) });
        }
        case PLAYER_RECEIVE_ONBOARDING_TYPE: {
            return __assign(__assign({}, state), { onBoardingType: payload.onBoardingType });
        }
        case PLAYER_RECEIVE_OWN_TOKEN: {
            return __assign(__assign({}, state), { ownToken: payload.ownToken });
        }
        case PLAYER_RECEIVE_TRANSACTION: {
            var transactions = __spreadArray([], state.transactions, true);
            var companyTransactions = __spreadArray([], (state.company.transactions || []), true);
            var bankTransactions = __spreadArray([], (state.bank.transactions || []), true);
            if (payload.kind === 'PLAYER') {
                transactions.push(payload);
            }
            else if (payload.kind === 'COMPANY') {
                companyTransactions.push(payload);
            }
            else if (payload.kind === 'BANK') {
                bankTransactions.push(payload);
            }
            var newState = __assign(__assign({}, state), { transactions: transactions, bank: __assign(__assign({}, state.bank), { transactions: bankTransactions }), company: __assign(__assign({}, state.company), { transactions: companyTransactions }) });
            if (payload.kind === 'PLAYER') {
                newState.expenseBalance = payload.expenseBalance;
                newState.incomeBalance = payload.incomeBalance;
                newState.totalBalance = payload.totalBalance;
            }
            else if (payload.kind === 'COMPANY') {
                newState.company.expenseBalance = payload.expenseBalance;
                newState.company.incomeBalance = payload.incomeBalance;
                newState.company.totalBalance = payload.totalBalance;
            }
            else if (payload.kind === 'BANK') {
                newState.bank.expenseBalance = payload.expenseBalance;
                newState.bank.incomeBalance = payload.incomeBalance;
                newState.bank.totalBalance = payload.totalBalance;
            }
            return newState;
        }
        case PLAYER_RECEIVE_BANK_BALANCES: {
            return __assign(__assign({}, state), { bank: __assign(__assign({}, state.bank), { balances: payload }) });
        }
        case PLAYER_RECIEVE_COMPANY_ID: {
            return __assign(__assign({}, state), { recievedCompanyID: payload });
        }
        case PLAYER_RECEIVE_TRANSACTION_LIST: {
            var transactionsObject = getTransactionsObject(payload);
            if (payload.kind === 'PLAYER') {
                return __assign(__assign({}, state), transactionsObject);
            }
            if (payload.kind === 'BANK') {
                return __assign(__assign({}, state), { bank: __assign({}, transactionsObject) });
            }
            return __assign(__assign({}, state), { company: __assign(__assign({}, state.company), transactionsObject) });
        }
        case PLAYER_RECEIVE_TICKET_LIST: {
            var items = __spreadArray([], state.tasks.items, true);
            for (var _i = 0, _b = payload.items; _i < _b.length; _i++) {
                var ticket = _b[_i];
                var receivedTask = __assign(__assign({}, task), ticket);
                getUpdatedTaskList(items, receivedTask);
            }
            return __assign(__assign({}, state), { tasks: {
                    items: items
                } });
        }
        case PLAYER_RECEIVE_TASK: {
            var items = __spreadArray([], state.tasks.items, true);
            var receivedTask = __assign(__assign({}, task), payload);
            getUpdatedTaskList(items, receivedTask);
            return __assign(__assign({}, state), { tasks: {
                    items: items,
                    last: receivedTask
                } });
        }
    }
});
