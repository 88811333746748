import SocketService from '../SocketService';
export var onboardingRequestNewPlayer = function (token) {
    return SocketService.createEncodedMessage({
        kind: 'gameJoin',
        gameJoin: {
            token: token
        }
    });
};
export var onboardingRequestCompanyJoin = function (companyID, players, inGame) {
    return SocketService.createEncodedMessage({
        kind: 'companyJoin',
        companyJoin: {
            companyID: companyID,
            players: players,
            inGame: inGame
        }
    });
};
