export var TABLE_FILTER_ALL = 0;
export var TABLE_FILTER_PERSONAL = 1;
export var TABLE_FILTER_COMPANY = 2;
export var TASK_REQUEST_SKIP = 1;
export var TASK_REQUEST_DONE = 3;
export var TASK_STATUS_SKIPPED = 1;
export var TASK_STATUS_PROGRESS = 2;
export var TASK_STATUS_DONE = 3;
export var TASK_NEW_MODAL_NAME = 'newTaskModal';
export var TASK_UPDATE_MODAL_NAME = 'updateModal';
export var TASK_TYPE_PLAYER = 1;
export var TASK_TYPE_COMPANY = 2;
export var TASK_STRING_TYPE_COMPANY = 'company';
export var TASK_STRING_TYPE_PLAYER = 'player';
export var TASK_REQUIRE_PLAYER = 'TASK_REQUIRE_PLAYER';
export var TASK_REQUIRE_COMPANY = 'TASK_REQUIRE_COMPANY';
export var TASK_REQUIRE_NEW = 'TASK_REQUIRE_NEW';
