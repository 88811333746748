import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import clsx from 'clsx';
import moment from 'moment';
import { isCurrentStageInOvertime } from 'helpers/stageUtilities';
import { convertUnix } from 'helpers/timeUtilities';
import { setT } from 'helpers/translationUtilities';
Timeline.propTypes = {
    stages: PropTypes.array.isRequired,
    activeStage: PropTypes.object
};
// TODO: remove everything related to temporary stages
export default function Timeline(_a) {
    var _b = _a.stages, stages = _b === void 0 ? [] : _b, _c = _a.activeStage, activeStage = _c === void 0 ? { startedAt: 0, duration: 0 } : _c;
    var combinedGameTime = stages.reduce(function (accumulator, stage) {
        if (stage.endedAt) {
            return accumulator + stage.spentTime;
        }
        return accumulator + stage.duration;
    }, 0);
    var firstStage = stages.length ? stages[0] : { startedAt: 0 };
    var estimates = {
        currentStage: {
            beginning: activeStage.startedAt,
            end: activeStage.startedAt + activeStage.duration,
            isCurrentStageInOvertime: isCurrentStageInOvertime(activeStage)
        },
        game: {
            beginning: firstStage ? firstStage.startedAt : 0,
            end: firstStage ? firstStage.startedAt + combinedGameTime : 0
        }
    };
    return (React.createElement("div", { className: "timeline" },
        TimelineHeaderHTML(activeStage),
        React.createElement("div", { className: "timeline__wrap" },
            React.createElement("div", { className: "timeline__bar" },
                React.createElement("div", { className: "timeline__active-bar", style: { width: "".concat(getCurrentGamePercentage({ stages: stages, combinedGameTime: combinedGameTime, activeStage: activeStage, isCurrentStageInOvertime: estimates.currentStage.isCurrentStageInOvertime }), "%") } }),
                React.createElement("div", { className: "timeline__final-point" },
                    React.createElement("div", { className: "timeline__label" }, convertUnix(estimates.game.beginning))),
                stages.map(function (stage, index) { return (React.createElement("div", { className: "timeline__stage", key: stage.id, style: { width: "".concat(getStageStartingPositionPercentage(combinedGameTime, stage.endedAt ? stage.spentTime : stage.duration), "%") } }, handleIndicators({
                    estimate: estimates.currentStage,
                    activeStage: activeStage,
                    stage: stage,
                    position: indicatorPositionInBar(stages, index)
                }))); }),
                React.createElement("div", { className: "timeline__final-point last" },
                    React.createElement("div", { className: "timeline__label" }, convertUnix(estimates.game.end)))))));
}
function indicatorPositionInBar(stages, index) {
    if (stages.length - 1 === index) {
        return 'last';
    }
    else if (index === 0) {
        return 'first';
    }
    return null;
}
function handleIndicators(_a) {
    var estimate = _a.estimate, activeStage = _a.activeStage, stage = _a.stage, position = _a.position;
    var isActiveStage = activeStage.id === stage.id;
    var classes = clsx(['timeline__indicator', isActiveStage && 'active']);
    var indicatorProps = {
        startLabel: '',
        endLabel: '',
    };
    if (isActiveStage) {
        indicatorProps.startLabel = convertUnix(estimate.beginning);
        indicatorProps.endLabel = convertUnix(estimate.end);
    }
    return (React.createElement(Fragment, null,
        position !== 'first' ? React.createElement("div", { className: clsx([classes, 'first']) },
            React.createElement("span", { className: "timeline__label" }, indicatorProps.startLabel)) : null,
        position !== 'last' ? React.createElement("div", { className: classes },
            React.createElement("span", { className: "timeline__label" }, indicatorProps.endLabel)) : null));
}
function getCurrentGamePercentage(_a) {
    var stages = _a.stages, combinedGameTime = _a.combinedGameTime, activeStage = _a.activeStage, isCurrentStageInOvertime = _a.isCurrentStageInOvertime;
    if (isCurrentStageInOvertime) {
        return getFixedOvertimePercentage(stages, activeStage, combinedGameTime);
    }
    var totalTimeSpentInGame = stages.reduce(function (total, stage) {
        var startedAt = stage.startedAt, endedAt = stage.endedAt;
        if (startedAt) {
            if (endedAt) {
                total += endedAt - startedAt;
            }
            else {
                total += moment().unix() - startedAt;
            }
        }
        return total;
    }, 0);
    var recievedPercentage = (totalTimeSpentInGame / combinedGameTime) * 100;
    return recievedPercentage <= 100 ? recievedPercentage : 0; // cant go above 100, just to avoid absurdity
}
function getFixedOvertimePercentage(stages, activeStage, combinedGameTime) {
    var currentStageIndex = stages.findIndex(function (stage) { return stage.id === activeStage.id; });
    if (currentStageIndex !== -1) {
        var totalFromStagesSofar = 0;
        for (var i = 0; i < currentStageIndex + 1; i++) {
            totalFromStagesSofar += stages[i].endedAt ? stages[i].spentTime : stages[i].duration;
        }
        return (totalFromStagesSofar / combinedGameTime) * 100;
    }
}
function TimelineHeaderHTML(stage) {
    return (React.createElement("div", { className: "timeline__header" },
        React.createElement("div", { className: "timeline__label" }, setT('start')),
        React.createElement("div", { className: "timeline__title" },
            setT('current_stage'),
            ": ",
            React.createElement("span", { className: "timeline__label" }, stage.stageName)),
        React.createElement("div", { className: "timeline__label" }, setT('end'))));
}
function getStageStartingPositionPercentage(combinedGameTime, duration) {
    return (duration / combinedGameTime * 100);
}
