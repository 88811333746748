var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { connect } from 'react-redux';
import Component from './component';
import { getCurrentlyActiveState, sortGameStagesByStartDate } from 'helpers/stageUtilities';
var mapStateToProps = function (state) {
    var stages = state.game.stages;
    return {
        player: state.player,
        playersAndCompanies: __spreadArray(__spreadArray([], state.general.players, true), state.general.companies, true),
        presetTransactions: state.player.transactions,
        game: state.game,
        company: {
            transactions: state.player.company.transactions
        },
        stages: sortGameStagesByStartDate(stages, 'DESC'),
        currentlyActiveState: getCurrentlyActiveState(stages)
    };
};
export default connect(mapStateToProps, null)(Component);
