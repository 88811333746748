import React from 'react';
import Modal from 'components/player/shared/modal/component';
import Chart from "react-apexcharts";
import * as PropTypes from 'prop-types';
ReportsModal.propTypes = {
    chartConfig: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired
};
export default function ReportsModal(_a) {
    var _b = _a.open, open = _b === void 0 ? false : _b, chartConfig = _a.chartConfig, onClose = _a.onClose;
    var chartWidth = window.innerWidth - 220;
    var chartHeight = window.innerHeight < (chartWidth / 2) ? window.innerHeight - 200 : chartWidth / 2;
    var closeIcon = require('assets/img/icons/icon_x.svg?fill=#ffffff');
    return (React.createElement(Modal, { fullScreen: true, mobileFullScreen: true, expanded: open, innerModalNonFixedWidth: true, onClose: onClose, modalInnerClasses: "d-flex flex-justify-content-center", externalElements: React.createElement("div", { className: "d-flex flex-justify-content-flex-end mar-right-20 mar-top-20" },
            React.createElement("span", { className: "icon icon--x close-button", style: { 'backgroundImage': "url(".concat(closeIcon, ")") } })) },
        React.createElement(Chart, { options: chartConfig.options, series: chartConfig.series, width: chartWidth, height: chartHeight })));
}
