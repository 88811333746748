import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PlayersListItem from 'components/shared/transaction/new/playersList/playersListItem/component';
PlayersListUngrouped.propTypes = {
    players: PropTypes.array.isRequired,
    selected: PropTypes.array,
    onSelect: PropTypes.func,
    currentAccountId: PropTypes.string
};
export default function PlayersListUngrouped(_a) {
    var players = _a.players, _b = _a.selected, selected = _b === void 0 ? [] : _b, _c = _a.onSelect, onSelect = _c === void 0 ? function () { } : _c;
    return (React.createElement(Fragment, null, players.map(function (player) { return (React.createElement("div", { className: "user-group", key: player.id },
        React.createElement(PlayersListItem, { player: player, isSelected: !!selected.find(function (item) { return item === player.id; }), onSelect: onSelect }))); })));
}
