import { TASK_STATUS_PROGRESS, TASK_STRING_TYPE_COMPANY, TASK_STRING_TYPE_PLAYER } from 'components/player/task/constants';
export var unfinishedTasksLimits = function (maxCounts, tasksPool, currentPlayer) {
    var playerTasks = tasksPool.filter(function (task) { return currentPlayer.id === task.to; });
    var companyTasks = tasksPool.filter(function (task) { return currentPlayer.id !== task.to; });
    return {
        company: unFinishedTasksLimitReached(maxCounts.company, companyTasks),
        player: unFinishedTasksLimitReached(maxCounts.player, playerTasks),
    };
};
export var unFinishedTasksLimitReached = function (limit, tasks) {
    var unfinishedTasksCounter = tasks.reduce(function (accumulator, task) {
        if (task.status === TASK_STATUS_PROGRESS) {
            return accumulator + 1;
        }
        return accumulator;
    }, 0);
    return unfinishedTasksCounter >= limit;
};
export var determineTaskFor = function (taskID, playerID) {
    return taskID === playerID ? TASK_STRING_TYPE_PLAYER : TASK_STRING_TYPE_COMPANY;
};
