var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import Sidebar from 'components/shared/sidebar/container';
import { SIDEBAR_MODE_ADMIN } from 'components/constants';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { setT } from 'helpers/translationUtilities';
import { generatePathWithParams } from 'components/routers/router';
import { ADMIN_PATHS } from 'components/routers/constants';
import { history } from '../../../../../../../store';
import TinyMCE from 'components/shared/TinyMCE/component';
ViewSingleTemplateTaskEdit.propTypes = {
    template: PropTypes.object,
    task: PropTypes.object,
    getTask: PropTypes.func.isRequired,
    createTask: PropTypes.func.isRequired,
    updateTask: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};
var populateTaskStateFields = function (task) {
    return {
        name: task.reference.name[0].value,
        description: task.reference.description[0].content[0].value,
        skippable: task.skippable,
        reusable: task.reusable,
        stages: task.stages,
        companyIds: task.address.companyIds,
        target: task.address.target,
        order: task.order
    };
};
export default function ViewSingleTemplateTaskEdit(_a) {
    var _this = this;
    var _b, _c;
    var template = _a.template, getTask = _a.getTask, createTask = _a.createTask, updateTask = _a.updateTask, match = _a.match;
    var params = match.params;
    var id = params.id, taskId = params.taskId;
    var stages = (_b = template === null || template === void 0 ? void 0 : template.stages) !== null && _b !== void 0 ? _b : [];
    var companies = (_c = template === null || template === void 0 ? void 0 : template.companies) !== null && _c !== void 0 ? _c : [];
    var _d = useState(null), task = _d[0], setTask = _d[1];
    var _e = useState({
        name: '',
        description: '',
        skippable: false,
        reusable: false,
        stages: [],
        companyIds: [],
        target: 'Player',
        order: null
    }), fields = _e[0], setFields = _e[1];
    var _f = useState(true), hideCompanies = _f[0], setHideCompanies = _f[1];
    var _g = useState(true), hideStages = _g[0], setHideStages = _g[1];
    var _h = useState(false), showOrder = _h[0], setShowOrder = _h[1];
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                var task_1, _a, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 4, , 5]);
                            if (!(taskId !== "new")) return [3 /*break*/, 2];
                            return [4 /*yield*/, getTask(id, taskId).then(function (response) { return (response); })];
                        case 1:
                            _a = _b.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            _a = null;
                            _b.label = 3;
                        case 3:
                            task_1 = _a;
                            setTask(task_1);
                            return [3 /*break*/, 5];
                        case 4:
                            e_1 = _b.sent();
                            console.error(e_1);
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
    }, [taskId]);
    useEffect(function () {
        if (task) {
            setHideCompanies(task.address.companyIds.length === 0);
            setHideStages(task.stages.length === 0);
            setShowOrder(task.order !== null);
            setFields(function (prevFields) { return (__assign(__assign({}, prevFields), populateTaskStateFields(task))); });
        }
    }, [task]);
    var onTaskFormSubmit = function (event) { return __awaiter(_this, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!(taskId === "new")) return [3 /*break*/, 3];
                    return [4 /*yield*/, createTask(id, fields)];
                case 2:
                    data = _a.sent();
                    if (data && data._id !== "new") {
                        history.push(generatePathWithParams(ADMIN_PATHS.TEMPLATE_TASK, { id: id, taskId: data._id }));
                    }
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, updateTask(id, taskId, fields)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onFieldChange = function (name, value) {
        setFields(function (prevFields) {
            var _a;
            return (__assign(__assign({}, prevFields), (_a = {}, _a[name] = value, _a)));
        });
    };
    var onSelectFieldChange = function (event) {
        var _a = event.target, name = _a.name, value = _a.value;
        setFields(function (prevFields) {
            var _a;
            return (__assign(__assign({}, prevFields), (_a = {}, _a[name] = __spreadArray(__spreadArray([], prevFields[name], true), [value], false), _a)));
        });
        event.target.selectedIndex = 0;
    };
    var onClickRemoveFieldItem = function (name, value) {
        setFields(function (prevFields) {
            var _a;
            return (__assign(__assign({}, prevFields), (_a = {}, _a[name] = prevFields[name].filter(function (item) { return item !== value; }), _a)));
        });
    };
    var onHideCompaniesChange = function (checked) {
        setHideCompanies(checked);
        if (checked) {
            setFields(function (prevFields) { return (__assign(__assign({}, prevFields), { companyIds: [] })); });
        }
    };
    var onHideStagesChange = function (checked) {
        setHideStages(checked);
        if (checked) {
            setFields(function (prevFields) { return (__assign(__assign({}, prevFields), { stages: [] })); });
        }
    };
    var onHideOrderChange = function (checked) {
        setShowOrder(checked);
        if (!checked) {
            setFields(function (prevFields) { return (__assign(__assign({}, prevFields), { order: null })); });
        }
    };
    /** @type {React.CSSProperties} */
    var FormFieldStyles = {
        display: 'flex',
        flexDirection: 'row',
        gap: '2em',
        justifyContent: 'left',
        alignItems: 'flex-start',
    };
    /** @type {React.CSSProperties} */
    var FormFieldLabelStyles = {
        maxWidth: '15em',
        width: '100%',
        textAlign: 'right',
    };
    return (React.createElement("div", null,
        React.createElement(Sidebar, { type: SIDEBAR_MODE_ADMIN }),
        React.createElement("div", { style: { margin: "5em 0 5em 5em" } },
            React.createElement("div", { style: { maxWidth: "75em", margin: "auto", padding: "0 1em" } },
                React.createElement("form", { onSubmit: onTaskFormSubmit },
                    React.createElement("div", { style: { display: 'flex', flexDirection: 'column', gap: '1em' } },
                        React.createElement("label", { style: FormFieldStyles },
                            React.createElement("span", { style: FormFieldLabelStyles }, "Task Name:"),
                            React.createElement("input", { name: "name", type: "text", value: fields.name, onChange: function (event) { return onFieldChange(event.target.name, event.target.value); } })),
                        React.createElement("label", { style: FormFieldStyles },
                            React.createElement("span", { style: FormFieldLabelStyles }, "Task type:"),
                            React.createElement("select", { name: "target", onChange: function (event) { return onFieldChange(event.target.name, event.target.value); }, defaultValue: fields.target }, ["Player", "Company"].map(function (item, index) { return (React.createElement("option", { key: index, value: item }, item)); }))),
                        React.createElement("label", { style: FormFieldStyles },
                            React.createElement("span", { style: FormFieldLabelStyles }, "Player can skip this task:"),
                            React.createElement("input", { type: "checkbox", name: "skippable", checked: fields.skippable, onChange: function (event) { return onFieldChange(event.target.name, event.target.checked); } })),
                        React.createElement("div", { style: FormFieldStyles },
                            React.createElement("span", { style: FormFieldLabelStyles }, "Task is available on all stages:"),
                            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', gap: '1em', alignItems: "flex-start" } },
                                React.createElement("input", { type: "checkbox", checked: hideStages, onChange: function (event) { return onHideStagesChange(event.target.checked); } }),
                                !hideStages ? (React.createElement("div", { style: { display: 'flex', flexDirection: 'column', gap: '1em' } },
                                    React.createElement("div", null,
                                        React.createElement("select", { name: "stages", onChange: function (event) { return onSelectFieldChange(event); } },
                                            React.createElement("option", null, "- Select stages -"),
                                            stages.filter(function (item) { return (!fields.stages.includes(item._id)); }).map(function (item, index) { return (React.createElement("option", { key: index, value: item._id }, item.title[0].value)); }))),
                                    React.createElement("div", null, stages.filter(function (item) { return (fields.stages.includes(item._id)); }).map(function (item, index) { return (React.createElement("div", { key: index, style: {
                                            position: 'relative',
                                            display: 'inline-block',
                                            margin: '.5em',
                                            padding: '.25em 2em .25em .5em',
                                            border: '1px solid black',
                                            borderRadius: '.5em'
                                        } },
                                        React.createElement("span", null, item.title[0].value),
                                        React.createElement("span", { className: "icon icon--close style--red", style: {
                                                position: 'absolute',
                                                top: '50%',
                                                right: '.5em',
                                                transform: 'translateY(-50%)'
                                            }, onClick: function () { return onClickRemoveFieldItem('stages', item._id); } }))); })))) : null)),
                        React.createElement("div", { style: FormFieldStyles },
                            React.createElement("span", { style: FormFieldLabelStyles }, "Task appears at specific order:"),
                            React.createElement("input", { type: "checkbox", checked: showOrder, onChange: function (event) { return onHideOrderChange(event.target.checked); } })),
                        showOrder ? (React.createElement("div", { style: FormFieldStyles },
                            React.createElement("span", { style: FormFieldLabelStyles }, "Enter order number:"),
                            React.createElement("input", { type: "number", name: "order", value: fields.order ? fields.order : "", onChange: function (event) { return onFieldChange(event.target.name, Number(event.target.value)); } }))) : null,
                        React.createElement("div", { style: FormFieldStyles },
                            React.createElement("span", { style: FormFieldLabelStyles }, "Task appears to all players:"),
                            React.createElement("input", { type: "checkbox", checked: hideCompanies, onChange: function (event) { return onHideCompaniesChange(event.target.checked); } })),
                        !hideCompanies ? (React.createElement("div", { style: FormFieldStyles },
                            React.createElement("span", { style: FormFieldLabelStyles }, "Companies:"),
                            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', gap: '1em' } },
                                React.createElement("div", null,
                                    React.createElement("select", { name: "companyIds", onChange: function (event) { return onSelectFieldChange(event); } },
                                        React.createElement("option", null, "- Select company -"),
                                        companies.filter(function (item) { return (!fields.companyIds.includes(item._id)); }).map(function (item, index) { return (React.createElement("option", { key: index, value: item._id }, item.name[0].value)); }))),
                                React.createElement("div", null, companies.filter(function (item) { return (fields.companyIds.includes(item._id)); }).map(function (item, index) { return (React.createElement("div", { key: index, style: {
                                        position: "relative",
                                        display: 'inline-block',
                                        margin: '.5em',
                                        padding: '.25em 2em .25em .5em',
                                        border: '1px solid black',
                                        borderRadius: '.5em'
                                    } },
                                    React.createElement("span", null, item.name[0].value),
                                    React.createElement("span", { className: "icon icon--close style--red", style: {
                                            position: 'absolute',
                                            top: "50%",
                                            right: ".5em",
                                            transform: "translateY(-50%)",
                                        }, onClick: function () { return onClickRemoveFieldItem("companyIds", item._id); } }))); }))))) : null,
                        React.createElement("div", { style: FormFieldStyles },
                            React.createElement("span", { style: FormFieldLabelStyles }, "Task Description:"),
                            React.createElement(TinyMCE, { value: fields.description ? fields.description : null, onChange: function (value) { return onFieldChange('description', value); } })),
                        React.createElement("div", { style: FormFieldStyles },
                            React.createElement("div", { style: FormFieldLabelStyles },
                                React.createElement(Link, { to: "/admin/templates/".concat(params.id) }, setT('Back'))),
                            React.createElement("button", null, taskId === "new" ? "create" : "Update"))))))));
}
