import { connect } from 'react-redux';
import Component from './component';
import { userAuthTokenLoginRequestAction } from '../../../../redux/actions/userAuthorization.actions';
import { purgeAndReconnectAction } from '../../../../redux/actions/shared.actions';
var mapStateToProps = function (state) {
    return {
        userAuthorization: state.userAuthorization.userSession.id
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        tokenLoginRequest: function (token) { return dispatch(userAuthTokenLoginRequestAction(token)); },
        purgeAndReconnect: function (gameToken) { return dispatch(purgeAndReconnectAction(gameToken)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
