import { useEffect } from 'react';
// ButtonRef is used in-case the trigger button is outside elementRef.
// If this isn't present, and the trigger is outside, clicking on the trigger wont close the item.
export default function SetOutsideClickEvents(action, elementRef, buttonRef) {
    useEffect(function () {
        document.addEventListener('mousedown', function (e) { return handleOutSideClick(e, action, elementRef, buttonRef); });
        return function () {
            document.removeEventListener('mousedown', function (e) { return handleOutSideClick(e, action, elementRef, buttonRef); });
        };
    }, []);
}
function handleOutSideClick(_a, action, elementRef, buttonRef) {
    var target = _a.target;
    if (elementRef.current && !elementRef.current.contains(target)) {
        if (buttonRef && !buttonRef.current.contains(target)) {
            action();
        }
        if (!buttonRef) {
            action();
        }
    }
}
