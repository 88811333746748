import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import { setT } from 'helpers/translationUtilities';
BalanceBox.propTypes = {
    balance: PropTypes.number
};
export default function BalanceBox(_a) {
    var _b = _a.balance, balance = _b === void 0 ? 0 : _b;
    return (React.createElement("div", { className: "balance-box" },
        React.createElement("div", { className: "balance-box__bordered-area" },
            React.createElement("div", { className: "balance-box__label" }, setT('total_balance')),
            React.createElement("div", { className: "balance-box__value" }, Numeral(balance).format('00')))));
}
