import { connect } from 'react-redux';
import Component from './component';
import { getPlayersWithNoCompany } from 'helpers/playerUtilities';
import { getPlayersOfACompany } from 'helpers/companyUtilities';
import { WEBSOCKET_MESSAGE } from 'components/constants';
import { gameJoinAction } from '../../../redux/actions/game.actions';
var mapStateToProps = function (state, props) {
    var _a, _b;
    var companyId = props.companyId;
    var _c = state.general, players = _c.players, companies = _c.companies;
    var relevantCompanyId = companyId || ((_a = state.player.company) === null || _a === void 0 ? void 0 : _a.id);
    return {
        playerId: (_b = state.player) === null || _b === void 0 ? void 0 : _b.id,
        unAllocatedPlayers: getPlayersWithNoCompany(players),
        companyPlayers: relevantCompanyId ? getPlayersOfACompany(players, relevantCompanyId) : [],
        players: players,
        company: companies.find(function (company) { return relevantCompanyId === company.id; }),
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketMessage: function (msg) { return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg }); },
        redirectToPlayerDashboard: function () { return dispatch({ type: 'ROUTE', to: "/dashboard" }); },
        joinGameWithToken: function (token) { return dispatch(gameJoinAction(token)); },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
