import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Player from './player/component';
import { CSSTransition } from 'react-transition-group';
PlayersSidebar.propTypes = {
    players: PropTypes.array,
    player: PropTypes.object,
    gameJoin: PropTypes.func.isRequired,
    isMobilePlayerSelectionOpen: PropTypes.bool.isRequired
};
export default function PlayersSidebar(_a) {
    var _b = _a.players, players = _b === void 0 ? [] : _b, _c = _a.player, player = _c === void 0 ? {} : _c, gameJoin = _a.gameJoin, isMobilePlayerSelectionOpen = _a.isMobilePlayerSelectionOpen;
    function onPlayerClick(id, token) {
        if (player.id !== id) {
            gameJoin(token);
        }
    }
    function getWrapper() {
        var companyID = player.company.id;
        var thisCompPlayers = players.filter(function (player) {
            return player.companyID === companyID;
        });
        return (React.createElement("div", { className: "sidebar-players__wrapper" },
            React.createElement("div", { className: "sidebar-players__list" }, thisCompPlayers.map(function (compPlayer, index) { return (React.createElement(Player, { isActive: player.id === compPlayer.id, key: index, name: compPlayer.name, onClick: function () { return onPlayerClick(compPlayer.id, compPlayer.token); }, thumbnail: compPlayer.thumbnail })); }))));
    }
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: "sidebar-players d-none d-block-md" }, getWrapper()),
        React.createElement(CSSTransition, { in: isMobilePlayerSelectionOpen, timeout: 100, classNames: "anim-righttoleft", unmountOnExit: true },
            React.createElement("div", { className: "sidebar-players" }, getWrapper()))));
}
