var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { connect } from 'react-redux';
import { WEBSOCKET_MESSAGE } from 'components/constants';
import Component from './component';
import { getCurrentlyActiveState, sortGameStagesByStartDate } from 'helpers/stageUtilities';
var mapStateToProps = function (state) {
    var stages = state.game.stages;
    return {
        company: state.player.company,
        bank: state.player.bank,
        game: state.game,
        playersAndCompanies: __spreadArray(__spreadArray([], state.general.companies, true), state.general.players, true),
        companies: state.general.companies,
        isBank: state.player.company.isBank,
        players: state.general.players,
        transactions: state.player.bank.transactions,
        stages: sortGameStagesByStartDate(stages, 'DESC'),
        currentlyActiveState: getCurrentlyActiveState(stages)
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirect: function () { return dispatch({ type: 'ROUTE', to: '/banking/' }); },
        redirectToDashboard: function () { return dispatch({ type: 'ROUTE', to: '/dashboard/' }); },
        redirectToTransaction: function (id) { return dispatch({ type: 'ROUTE', to: "/banking/".concat(id, "/transaction") }); },
        websocketMessage: function (msg) { return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg }); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
