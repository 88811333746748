import React from 'react';
import { setT } from 'helpers/translationUtilities';
export default function MobTaskTableConfig(tableConfig, onSingleRowClick) {
    var mainConfig = {
        rows: [],
        keyValues: [
            { label: '', name: 'avatar' },
            { label: setT('notes'), name: 'info' },
            { label: setT('status'), name: 'status' },
        ],
    };
    mainConfig.rows = tableConfig.map(function (stage) {
        return {
            key: stage.id,
            component: mainRowComponent(stage),
            childrenVisible: stage.isRowOpen,
            childRows: getChildRows(stage.rows, onSingleRowClick)
        };
    });
    return mainConfig;
}
function mainRowComponent(stage) {
    return (React.createElement("span", { className: "bold text-transform-uppercase" }, stage.stageName));
}
function getChildRows(stageRows, onSingleRowClick) {
    return stageRows.map(function (row) {
        return {
            key: row.key,
            onRowClick: function () { return onSingleRowClick(row.key); },
            info: {
                component: (React.createElement("div", null,
                    React.createElement("div", { className: "bold" }, row.taskTaker),
                    React.createElement("div", { className: "mob-collapse-table__turncated-content" }, row.description)))
            },
            avatar: row.avatar,
            status: row.status
        };
    });
}
