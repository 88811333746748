var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TinyMCE from 'components/shared/TinyMCE/component';
CompanyForm.propTypes = {
    company: PropTypes.object.isRequired,
    onModalClose: PropTypes.func.isRequired,
    updateCompany: PropTypes.func.isRequired,
    templateId: PropTypes.string,
    createCompany: PropTypes.func.isRequired
};
export default function CompanyForm(_a) {
    var company = _a.company, onModalClose = _a.onModalClose, updateCompany = _a.updateCompany, templateId = _a.templateId, createCompany = _a.createCompany;
    var title = company ? company.name[0].value : '';
    var isCompanyBank = company && company.isBank || false;
    var isCompanyVisible = company ? company.visibility : true;
    var _b = useState(getDescription(company)), editorText = _b[0], setEditorText = _b[1];
    var _c = useState(isCompanyBank), isBank = _c[0], setIsBank = _c[1];
    var _d = useState(isCompanyVisible), isVisible = _d[0], setIsVisible = _d[1];
    var onFormSubmit = function (e) {
        var target = e.target;
        e.preventDefault();
        company ? stageUpdateHandler(target) : stageCreationHandler(target);
        onModalClose();
    };
    function stageCreationHandler(target) {
        var payload = {
            template_id: templateId,
            balance: Number(target.balance.value),
            name: target.name.value,
            description: editorText,
            isBank: isBank,
            visibility: isVisible
        };
        createCompany(payload);
    }
    function stageUpdateHandler(target) {
        var payload = {
            template_id: templateId,
            balance: Number(target.balance.value),
            name: __assign(__assign({}, company.name[0]), { value: target.name.value }),
            description: __assign(__assign({}, company.description[0]), { value: editorText }),
            isBank: isBank,
            visibility: isVisible
        };
        updateCompany(company._id, payload);
    }
    return (React.createElement("form", { onSubmit: onFormSubmit, className: "text-align-left admin-form" },
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null, "Firma nimi:"),
            React.createElement("input", { type: "text", name: "name", defaultValue: title })),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null, "Algusraha:"),
            React.createElement("input", { type: "number", name: "balance", defaultValue: company ? company.balance : 1000 })),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null, "Bank?:"),
            React.createElement("input", { type: "checkbox", name: "bank", defaultChecked: isBank, onChange: function () { return setIsBank(!isBank); } })),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null, "N\u00E4htav?:"),
            React.createElement("input", { type: "checkbox", name: "visibility", defaultChecked: isVisible, onChange: function () { return setIsVisible(!isVisible); } })),
        React.createElement("div", { className: "mar-top-5" },
            React.createElement(TinyMCE, { value: editorText || null, onChange: function (value) { return setEditorText(value); } })),
        React.createElement("div", { className: "mar-top-20 text-align-center flex-justify-content-center d-flex flex-gap-10" },
            React.createElement("button", { className: "main-button main-button--error", onClick: function () { return onModalClose(); } }, "close"),
            React.createElement("button", { className: "main-button", type: "submit" }, "Save"))));
}
var getDescription = function (stage) {
    if (!stage)
        return "";
    var description = stage.description;
    if (Array.isArray(description) && description.length) {
        return description[0].value;
    }
    return description;
};
