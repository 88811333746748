import { RECEIVED_TRANSACTION_TYPE_DEPOSIT, RECEIVED_TRANSACTION_TYPE_WITHDRAW } from 'components/player/money/transactions/types';
export var checkIfTransactionPositive = function (transaction, receiverID) {
    var receiverIsPlayer = transaction.to === receiverID;
    return setTransactionEvent(receiverIsPlayer, transaction.type);
};
var setTransactionEvent = function (receiverIsPlayer, type) {
    switch (type) {
        case RECEIVED_TRANSACTION_TYPE_DEPOSIT:
            return true;
        case RECEIVED_TRANSACTION_TYPE_WITHDRAW:
            return false;
        default:
            return receiverIsPlayer;
    }
};
export function getSingleStageTotals(stageTransactionsList, playersAndCompanies, currentAccount) {
    var totals = {
        income: 0,
        expenses: 0
    };
    if (!playersAndCompanies.length) {
        return totals;
    }
    stageTransactionsList.reduce(function (accumulator, transaction) {
        var isTransactionPositive = checkIfTransactionPositive(transaction, currentAccount);
        if (isTransactionPositive) {
            totals.income += transaction.amount;
        }
        else {
            totals.expenses += transaction.amount;
        }
        return totals;
    }, totals);
    return totals;
}
