import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PlayersListItem from 'components/shared/transaction/new/playersList/playersListItem/component';
PlayersListGrouped.propTypes = {
    group: PropTypes.object,
    selected: PropTypes.array,
    onSelect: PropTypes.func,
    currentAccountId: PropTypes.string
};
export default function PlayersListGrouped(_a) {
    var _b = _a.group, group = _b === void 0 ? {
        id: '',
        name: '',
        relatedPlayers: []
    } : _b, _c = _a.selected, selected = _c === void 0 ? [] : _c, _d = _a.onSelect, onSelect = _d === void 0 ? function () { } : _d;
    var relatedPlayers = group.relatedPlayers;
    return (React.createElement("div", { className: "user-group" },
        React.createElement("div", { className: clsx('user-group__header', !relatedPlayers.length && 'user-group__header--no-players') },
            React.createElement(PlayersListItem, { key: group.id, player: group, isSelected: !!selected.find(function (item) { return item === group.id; }), onSelect: onSelect })),
        relatedPlayers.length ? (React.createElement("div", { className: "user-group__listing" }, relatedPlayers.map(function (player) { return (React.createElement(PlayersListItem, { key: player.id, player: player, isSelected: !!selected.find(function (item) { return item === player.id; }), onSelect: onSelect })); }))) : null));
}
