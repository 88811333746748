import { connect } from 'react-redux';
import Component from './component';
import { PURGE_ALL_APP_DATA } from 'components/constants';
var mapDispatchToProps = function (dispatch) {
    return {
        logoutFromApp: function () {
            dispatch({ type: PURGE_ALL_APP_DATA });
            dispatch({ to: '/token', type: 'ROUTE' });
        },
    };
};
export default connect(null, mapDispatchToProps)(Component);
