import { setT } from 'helpers/translationUtilities';
import clsx from 'clsx';
import Avatar from 'components/shared/avatar/component';
import React from 'react';
import { getEntityBalance } from 'helpers/utilities';
function getTableColumns() {
    return [
        { label: '', name: 'avatar' },
        { label: setT('name'), name: 'name' },
        { label: setT('amount'), name: 'amount' },
        { label: '', name: 'button' },
        { label: '', name: 'chevron' },
    ];
}
function getGroupHeaderCells(company, balances, onViewAccountClick) {
    return [
        {
            id: "".concat(company.id, "_avatar"),
            properties: { className: 'text-align-left' },
            name: 'avatar',
            component: React.createElement(Avatar, { src: company.thumbnail })
        },
        {
            id: "".concat(company.id, "_title"),
            properties: { className: 'text-align-left' },
            name: 'name',
            title: company.name,
        },
        {
            id: "".concat(company.id, "_amount"),
            properties: { className: 'text-align-left' },
            name: 'amount',
            title: getEntityBalance(balances, company),
        },
        {
            id: "".concat(company.id, "_button"),
            properties: { className: 'text-align-right' },
            name: 'button',
            component: company.id ? React.createElement("button", { className: "main-button whitespace-nowrap", onClick: function () { return onViewAccountClick(company.id); } }, setT('view_account')) : null
        },
        {
            id: "".concat(company.id, "_chevron"),
            properties: { className: 'text-align-right' },
            name: 'chevron',
            component: React.createElement("div", { className: "chevron" })
        },
    ];
}
function createPlayerRows(_a) {
    var companyPlayers = _a.companyPlayers, balances = _a.balances, onViewAccountClick = _a.onViewAccountClick;
    return companyPlayers.map(function (player) {
        return {
            key: player.id,
            avatar: {
                component: React.createElement(Avatar, { src: player.thumbnail })
            },
            name: player.name,
            amount: getEntityBalance(balances, player),
            button: {
                component: player.companyID ? React.createElement("button", { className: "main-button whitespace-nowrap", onClick: function () { onViewAccountClick(player.id); } }, setT('view_account')) : null
            },
            customProperties: {
                className: 'bg-transparent',
                cellProperties: {
                    button: {
                        className: 'text-align-right'
                    }
                }
            }
        };
    });
}
export default function BankingTableConfig(_a) {
    var companies = _a.companies, players = _a.players, balances = _a.balances, onItemClick = _a.onItemClick, openRows = _a.openRows, onViewAccountClick = _a.onViewAccountClick;
    return companies.map(function (company) {
        var isCurrentRowOpen = openRows.findIndex(function (rowId) { return rowId === company.id; }) !== -1;
        return {
            id: company.id,
            isRowOpen: isCurrentRowOpen,
            addedClasses: clsx(['cursor-pointer stage-line', isCurrentRowOpen && 'extended']),
            innerAreaClasses: clsx([isCurrentRowOpen && 'extended']),
            tableColumns: getTableColumns(),
            groupHeaderCells: getGroupHeaderCells(company, balances, onViewAccountClick),
            rows: createPlayerRows({
                companyPlayers: players.filter(function (player) { return player.companyID === company.id; }),
                balances: balances,
                onViewAccountClick: onViewAccountClick
            }),
            properties: {
                onClick: function () { return onItemClick(company.id); }
            }
        };
    });
}
