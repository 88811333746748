import { Router, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import { AuthRoute } from 'components/routers/AuthRoute';
import ViewHome from 'components/views/user/home/container';
import ViewDashboard from 'components/views/user/dashboard/container';
import ViewMoney from 'components/views/user/money/container';
import ViewTask from 'components/views/user/task/container';
import ViewNewTask from 'components/views/user/task/new/container';
import ViewBanking from 'components/views/user/banking/container';
import ViewBankingTransactions from 'components/views/user/banking/list/container';
import ViewFacilitatorDashboard from 'components/views/facilitator/dashboard/container';
import ViewFacilitatorParticipants from 'components/views/facilitator/participants/container';
import ViewFacilitatorGames from 'components/views/facilitator/games/component';
import ViewPlayerNewTransaction from 'components/views/user/money/new/component';
import ViewNewBankTransaction from 'components/views/user/banking/transactions/new/component';
import ViewBulkTransaction from 'components/views/facilitator/bulkTransaction/component';
import ViewReports from 'components/views/facilitator/reports/container';
import { USER_RANK_ADMIN, USER_RANK_FACILITATOR } from 'components/permissions/types';
export var UserRouter = function (_a) {
    var history = _a.history, rank = _a.rank;
    var isManagerRank = [USER_RANK_ADMIN, USER_RANK_FACILITATOR].includes(rank);
    return (React.createElement(Router, { history: history },
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: "/", component: ViewHome }),
            React.createElement(Route, { exact: true, path: "/money/:type/:accountId", component: ViewMoney }),
            React.createElement(Route, { exact: true, path: "/money/:type/:accountId/pay", component: ViewPlayerNewTransaction }),
            React.createElement(Route, { exact: true, path: "/task/new", component: ViewNewTask }),
            React.createElement(Route, { exact: true, path: "/task", component: ViewTask }),
            React.createElement(Route, { exact: true, path: "/banking", component: ViewBanking }),
            React.createElement(Route, { exact: true, path: "/banking/:accountId", component: ViewBankingTransactions }),
            React.createElement(Route, { exact: true, path: "/banking/:accountId/transaction", component: ViewNewBankTransaction }),
            React.createElement(Route, { exact: true, path: "/dashboard", component: ViewDashboard }),
            React.createElement(Route, { path: "/reports", component: ViewReports }),
            React.createElement(AuthRoute, { isAuthenticated: isManagerRank, exact: true, path: "/facilitator", component: ViewFacilitatorDashboard }),
            ",",
            React.createElement(AuthRoute, { isAuthenticated: isManagerRank, exact: true, path: "/facilitator/participants", component: ViewFacilitatorParticipants }),
            ",",
            React.createElement(AuthRoute, { isAuthenticated: isManagerRank, exact: true, path: "/facilitator/games", component: ViewFacilitatorGames }),
            ",",
            React.createElement(AuthRoute, { isAuthenticated: isManagerRank, path: "/facilitator/actions/:facilitatorActionType", component: ViewBulkTransaction }),
            ",")));
};
UserRouter.propTypes = {
    history: PropTypes.object.isRequired,
    rank: PropTypes.string.isRequired,
};
