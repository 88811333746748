import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import { isCurrentStageInOvertime } from 'helpers/stageUtilities';
import { secToHrAndMin } from 'helpers/timeUtilities';
import { FilterColor, Solver } from '../../../../helpers/filterColor.ts';
import hexRgb from 'hex-rgb';
Timer.propTypes = {
    activeStage: PropTypes.object,
    stages: PropTypes.array,
    toggleStageBar: PropTypes.func.isRequired
};
export default function Timer(_a) {
    var _b = _a.activeStage, activeStage = _b === void 0 ? null : _b, _c = _a.stages, stages = _c === void 0 ? [] : _c, toggleStageBar = _a.toggleStageBar;
    var currentGameStatuses = setGameStatuses(activeStage, stages);
    var timeLeft = activeStage ? getTimeLeft(activeStage) : getTimeLeft(stages[0]);
    var whiteColor = hexRgb('#ffffff'); // white
    var whiteSolver = new Solver(new FilterColor(whiteColor.red, whiteColor.green, whiteColor.blue));
    var whiteFilter = whiteSolver.solve().filter;
    return (React.createElement("div", { className: clsx(['timer', currentGameStatuses.isLocked && 'timer--locked']), onClick: function () { return toggleStageBar(); } },
        React.createElement("div", { className: "timer__label" },
            React.createElement("div", { className: "timer__time-left" }, timeLeft),
            React.createElement("div", { className: "timer__stage-display" },
                React.createElement("strong", null, activeStage ? activeStage.stageName : stages[0] && stages[0].stageName))),
        React.createElement("div", { className: "timer__icon d-none d-block-md" },
            React.createElement("div", { className: "timer__notification" },
                React.createElement("span", { className: clsx(['icon', currentGameStatuses.isLocked ? 'icon--game_lock icon--fill-white' : 'icon--play']), style: { filter: currentGameStatuses.isLocked ? whiteFilter : '' } })))));
}
function setGameStatuses(activeStage, stages) {
    var last = stages[stages.length - 1];
    return {
        isLocked: activeStage ? (!!activeStage.pausedAt || !!activeStage.endedAt) : true,
        notStarted: stages[0] && !stages[0].startedAt,
        finished: last && last.endedAt
    };
}
function getTimeLeft(stage) {
    if (!stage) {
        return null;
    }
    var isInOverTime = isCurrentStageInOvertime(stage);
    var diff = !isInOverTime ? stage.duration - stage.spentTime : stage.spentTime - stage.duration;
    return "".concat(isInOverTime ? '-' : '').concat(secToHrAndMin(diff, 'HH:mm'));
}
