import React from 'react';
import PropTypes from 'prop-types';
var OnboardingCompany = function (props) {
    var thumbnail = { backgroundImage: 'url(' + props.logo + ')' };
    return (React.createElement("div", { className: "company__single", onClick: function () { return props.onCompanyClick(props.id); } },
        React.createElement("div", { className: "company__inner" },
            React.createElement("div", { className: "company__image" },
                React.createElement("div", { className: "company__midimage" },
                    React.createElement("div", { className: "company__innerimage", style: thumbnail }))),
            React.createElement("div", { className: "company__text" }, props.name))));
};
OnboardingCompany.propTypes = {
    logo: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    onCompanyClick: PropTypes.func
};
OnboardingCompany.defaultProps = {
    logo: '',
    name: '',
    id: '',
    onCompanyClick: null
};
export default OnboardingCompany;
