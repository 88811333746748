import { connect } from 'react-redux';
import Component from './component';
import { adminGetTemplateAction, adminResetTemplateStateAction } from '../../../../../redux/actions/admin/admin.actions';
var mapStateToProps = function (state) {
    return {
        template: state.admin.singleTemplate,
        authToken: state.userAuthorization.token,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        getTemplate: function (id, authToken) { return dispatch(adminGetTemplateAction(id, authToken)); },
        resetTemplateState: function () { return dispatch(adminResetTemplateStateAction()); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
