var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { socketDecode, socketEncode } from 'library/socket-buffer';
import { PLAYER_RECEIVE_BANK_BALANCES, GENERAL_RECEIVE_NOTIFICATION, SERVER_ACTION_RECEIVED, WEBSOCKET_CONNECTED, WEBSOCKET_RECEIVED_MESSAGE, WEBSOCKET_TIMED_OUT, WEBSOCKET_DISCONNECTED } from 'components/constants';
import autobind from 'autobind-decorator';
import { socketMessageTypes } from './socketActions';
import { objectHasProperty } from 'helpers/utilities';
import { analytics } from 'library/analytics';
export var socketConnectionTimeout = null;
var SocketService = /** @class */ (function () {
    function SocketService(dispatch) {
        this.dispatch = dispatch;
    }
    SocketService.bootstrap = function (dispatch) {
        return new SocketService(dispatch);
    };
    SocketService.createEncodedMessage = function (payloadObject) {
        return socketEncode('Message', payloadObject);
    };
    SocketService.prototype.onOpen = function (payload) {
        analytics.event("User Connected");
        this.dispatch({ type: WEBSOCKET_CONNECTED, payload: payload });
    };
    SocketService.prototype.onClose = function () {
        var _this = this;
        console.warn('Server closed');
        this.dispatch({ type: WEBSOCKET_DISCONNECTED });
        if (!this.automaticReconnect) {
            analytics.event("User Connection Closed");
            return;
        }
        setTimeout(function () {
            analytics.event("Automatic User Reconnection Attempt On Closed Socket Connection");
            _this.socket = null;
            _this.connect();
        }, 2000);
    };
    SocketService.prototype.onError = function (error) {
        analytics.event("User Connection Error");
        console.error(error);
    };
    SocketService.prototype.dispatchMessage = function (receivedStatus, payload) {
        if (!objectHasProperty(receivedStatus, 'conditional') ||
            receivedStatus.conditional(payload)) {
            this.dispatch({
                type: receivedStatus.type,
                payload: receivedStatus.payload(payload)
            });
        }
    };
    SocketService.prototype.onMessage = function (_a) {
        var data = _a.data;
        return __awaiter(this, void 0, void 0, function () {
            var uintArray, payload_1, receivedStatus, type, err_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // Reset timeout.
                        if (socketConnectionTimeout)
                            clearTimeout(socketConnectionTimeout);
                        socketConnectionTimeout = setTimeout(function () {
                            _this.dispatch({
                                type: WEBSOCKET_TIMED_OUT
                            });
                        }, 3200);
                        this.dispatch({ type: WEBSOCKET_RECEIVED_MESSAGE });
                        uintArray = new Uint8Array(data);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, socketDecode('Message', uintArray)];
                    case 2:
                        payload_1 = _b.sent();
                        receivedStatus = socketMessageTypes[payload_1.kind];
                        if (process.env.NODE_ENV === 'development' && payload_1.kind !== 'uptimeMessage') {
                            console.info("Message received with type ".concat(payload_1.kind), payload_1);
                        }
                        if (objectHasProperty(socketMessageTypes, payload_1.kind)) {
                            if (!Array.isArray(receivedStatus)) {
                                this.dispatchMessage(receivedStatus, payload_1);
                            }
                            else {
                                receivedStatus.map(function (status) {
                                    _this.dispatchMessage(status, payload_1);
                                });
                            }
                        }
                        switch (payload_1.kind) {
                            default:
                                break;
                            case 'balanceListing':
                                this.dispatch({
                                    type: PLAYER_RECEIVE_BANK_BALANCES,
                                    payload: payload_1.balanceListing.balanceInformation
                                });
                                break;
                            case 'notification':
                                this.dispatch({
                                    type: GENERAL_RECEIVE_NOTIFICATION,
                                    payload: payload_1.notification
                                });
                                break;
                            case 'uptimeMessage':
                                // console.info('Ping');
                                break;
                            case 'deviceAction':
                                {
                                    type = payload_1.deviceAction.type;
                                    if (type === 'FORCE_DISABLE_AUTO_RECONNECT') {
                                        this.automaticReconnect = false;
                                        this.dispatch({
                                            type: GENERAL_RECEIVE_NOTIFICATION,
                                            payload: {
                                                type: 'You were kicked by Bizplay'
                                            }
                                        });
                                    }
                                    this.dispatch({
                                        type: SERVER_ACTION_RECEIVED,
                                        payload: payload_1.deviceAction
                                    });
                                }
                                break;
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        console.error('Socket Message Error: ', err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SocketService.prototype.connect = function () {
        var serverUrl = SINGLE_INSTANCE_MODE
            ? WS_URL
            : 'wss://' + window.globals.WS_URL;
        if ((!this.socket && serverUrl) || (this.socket && this.socket.readyState === 3 && serverUrl)) {
            this.disconnected = false;
            // eslint-disable-next-line no-undef
            this.socket = new WebSocket(serverUrl);
            this.socket.binaryType = 'arraybuffer';
            this.socket.keepAlive = true;
            this.socket.onerror = this.onError;
            this.socket.onmessage = this.onMessage;
            this.socket.onopen = this.onOpen;
            this.socket.onclose = this.onClose;
        }
    };
    SocketService.prototype.disconnect = function () {
        if (!this.disconnected) {
            analytics.event("User Disconnected");
            this.disconnected = true;
            this.socket.close();
        }
    };
    __decorate([
        autobind
    ], SocketService.prototype, "onOpen", null);
    __decorate([
        autobind
    ], SocketService.prototype, "onClose", null);
    __decorate([
        autobind
    ], SocketService.prototype, "onError", null);
    __decorate([
        autobind
    ], SocketService.prototype, "onMessage", null);
    return SocketService;
}());
export default SocketService;
