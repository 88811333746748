import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Tabs from 'components/shared/tabs/component';
import Loader from 'components/shared/loader/component';
import MainTable from 'components/shared/tables/mainTable/component';
import GamesTableConfig from 'components/configs/mainTable/gamesTable/GamesTableConfig';
import Pagination from 'components/shared/pagination/component';
import { Link } from 'react-router-dom';
import { setT } from 'helpers/translationUtilities';
import { USER_TYPE_ADMIN } from '../../../../types/user-types';
var GAME_STATE_ACTIVE = 'ACTIVE';
var GAME_STATE_ENDED = 'ENDED';
GamesListing.propTypes = {
    userAuthorization: PropTypes.object,
    games: PropTypes.array.isRequired,
    getGames: PropTypes.func.isRequired,
    selectGame: PropTypes.func.isRequired,
    gamesPaginationMaxPages: PropTypes.number,
    gamesListRequested: PropTypes.bool,
    logoutFromApp: PropTypes.func.isRequired,
    isFacilitatorOrigin: PropTypes.bool
};
export default function GamesListing(_a) {
    var _b = _a.userAuthorization, userAuthorization = _b === void 0 ? {} : _b, games = _a.games, getGames = _a.getGames, selectGame = _a.selectGame, _c = _a.gamesPaginationMaxPages, gamesPaginationMaxPages = _c === void 0 ? 1 : _c, gamesListRequested = _a.gamesListRequested, logoutFromApp = _a.logoutFromApp, _d = _a.isFacilitatorOrigin, isFacilitatorOrigin = _d === void 0 ? false : _d;
    var token = userAuthorization.token, _e = userAuthorization.userSession, rank = _e.rank, name = _e.name;
    var _f = useState(GAME_STATE_ACTIVE), activeTab = _f[0], setActiveTab = _f[1];
    var TabsConfig = {
        tabs: [
            {
                id: GAME_STATE_ACTIVE,
                label: setT('active'),
                onClickEvent: function () { return setActiveTab(GAME_STATE_ACTIVE); },
                active: activeTab === GAME_STATE_ACTIVE
            },
            {
                id: GAME_STATE_ENDED,
                label: setT('ended'),
                onClickEvent: function () { return setActiveTab(GAME_STATE_ENDED); },
                active: activeTab === GAME_STATE_ENDED
            },
        ]
    };
    var _g = useState(15), itemsPerPage = _g[0], setItemsPerPage = _g[1];
    var _h = useState(1), currentPage = _h[0], setCurrentPage = _h[1];
    var dropdownOptions = [
        { id: 1, value: 15, label: 15 },
        { id: 2, value: 30, label: 30 },
        { id: 3, value: 45, label: 45 }
    ];
    useEffect(function () {
        if (token) {
            getGames(token, {
                page: currentPage,
                perPage: itemsPerPage,
                status: activeTab
            });
        }
        else {
            logoutFromApp();
        }
        if (currentPage > gamesPaginationMaxPages) {
            setCurrentPage(gamesPaginationMaxPages);
        }
    }, [itemsPerPage, currentPage, token, gamesPaginationMaxPages, activeTab]);
    var isAdmin = [USER_TYPE_ADMIN].includes(rank);
    var isExceptionUser = [
        "Admin",
        "Tallinna Ettevõtlusküla",
        "Tartu Ettevõtlusküla",
    ].includes(name);
    var canAccessGameCreate = isAdmin || isExceptionUser;
    return (React.createElement("div", { className: "games-list loader-container" },
        React.createElement("div", { className: "games-list__header" },
            React.createElement("div", { className: "games-list__button" }, canAccessGameCreate ? (React.createElement(Link, { to: "/new-game".concat(isFacilitatorOrigin ? '?origin=facilitator' : ''), className: "main-button main-button--notice main-button--lg" }, setT('add_new_game'))) : (React.createElement("a", { href: "https://my.bizplay.ee/et/loo-mang/", className: "main-button main-button--notice main-button--lg" }, setT('add_new_game')))),
            React.createElement("div", { className: "games-list__tabs" },
                React.createElement(Tabs, { config: TabsConfig }))),
        gamesListRequested ?
            React.createElement("div", { className: "d-flex flex-justify-content-center games-list__loader" },
                React.createElement(Loader, null))
            :
                React.createElement(Fragment, null,
                    React.createElement(MainTable, { config: GamesTableConfig({ games: games, selectGame: selectGame }) }),
                    React.createElement("div", { className: "games-list__pagination" },
                        React.createElement(Pagination, { itemsPerPage: {
                                dropdownOptions: dropdownOptions,
                                currentPerPage: itemsPerPage,
                                setItemsPerPage: function (value) { return setItemsPerPage(value); },
                            }, pages: {
                                currentPage: currentPage,
                                maxPages: gamesPaginationMaxPages,
                                onPrevPage: function () {
                                    if (currentPage !== 1) {
                                        setCurrentPage(currentPage - 1);
                                    }
                                },
                                onNextPage: function () {
                                    if (currentPage !== gamesPaginationMaxPages) {
                                        setCurrentPage(currentPage + 1);
                                    }
                                },
                            } })))));
}
