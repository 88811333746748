var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import Sidebar from 'components/shared/sidebar/container';
import PropTypes from 'prop-types';
import { SIDEBAR_MODE_ADMIN } from 'components/constants';
import { Trans } from 'react-i18next';
var ViewAdminUsers = /** @class */ (function (_super) {
    __extends(ViewAdminUsers, _super);
    function ViewAdminUsers(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            newUserFormFields: {}
        };
        _this.onSubmit = _this.onSubmit.bind(_this);
        _this.onFieldChange = _this.onFieldChange.bind(_this);
        return _this;
    }
    ViewAdminUsers.prototype.shouldComponentUpdate = function () {
        return true;
    };
    ViewAdminUsers.prototype.onFieldChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        this.setState({
            newUserFormFields: __assign(__assign({}, this.state.newUserFormFields), (_a = {}, _a[name] = value, _a))
        });
    };
    ViewAdminUsers.prototype.onSubmit = function (e) {
        e.preventDefault();
        this.props.createNewUser(this.state.newUserFormFields);
    };
    ViewAdminUsers.prototype.render = function () {
        var labelInlineStyles = {
            width: '150px',
            display: 'inline-block',
            marginBottom: '15px'
        };
        var inputInlineStyles = {
            width: '250px',
            display: 'inline-block',
            height: '30px'
        };
        return (React.createElement("div", null,
            React.createElement(Sidebar, { type: SIDEBAR_MODE_ADMIN }),
            React.createElement("div", { className: "mar-left-80 mar-top-80" },
                React.createElement("div", { style: { maxWidth: "75em", margin: "auto", padding: "0 1em" } },
                    React.createElement("h2", null, "Create new user"),
                    React.createElement("form", { onSubmit: this.onSubmit },
                        React.createElement("div", null,
                            React.createElement("label", { style: labelInlineStyles }, "Username:"),
                            React.createElement("input", { style: inputInlineStyles, onChange: this.onFieldChange, name: "username" })),
                        React.createElement("div", null,
                            React.createElement("label", { style: labelInlineStyles }, "Name:"),
                            React.createElement("input", { style: inputInlineStyles, onChange: this.onFieldChange, name: "name" })),
                        React.createElement("div", null,
                            React.createElement("label", { style: labelInlineStyles }, "Email:"),
                            React.createElement("input", { style: inputInlineStyles, onChange: this.onFieldChange, name: "email" })),
                        React.createElement("div", null,
                            React.createElement("label", { style: labelInlineStyles }, "Password:"),
                            React.createElement("input", { style: inputInlineStyles, onChange: this.onFieldChange, name: "password", type: "password" })),
                        React.createElement("div", null,
                            React.createElement("label", { style: labelInlineStyles }, "Role:"),
                            React.createElement("select", { name: "rank", onChange: this.onFieldChange, style: inputInlineStyles, value: this.state.newUserFormFields.rank },
                                React.createElement("option", { value: "" }),
                                React.createElement("option", { value: "USER" }, "User"),
                                React.createElement("option", { value: "FACILITATOR" },
                                    React.createElement(Trans, { i18nKey: "facilitator" })),
                                React.createElement("option", { value: "ADMIN" }, "Admin"))),
                        React.createElement("input", { className: "main-button", type: "submit", value: "Create user" }))))));
    };
    ViewAdminUsers.propTypes = {
        createNewUser: PropTypes.func
    };
    ViewAdminUsers.defaultProps = {
        createNewUser: null
    };
    return ViewAdminUsers;
}(Component));
export default ViewAdminUsers;
