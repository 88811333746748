import { connect } from 'react-redux';
import Component from './component';
import { WEBSOCKET_MESSAGE } from 'components/constants';
import { getCurrentlyActiveState, sortGameStagesByStartDate } from 'helpers/stageUtilities';
var mapStateToProps = function (state) {
    var stages = state.game.stages;
    return {
        player: state.player,
        presetPlayer: state.player,
        tasks: state.player.tasks.items,
        presetTasks: state.player.tasks.items,
        stages: sortGameStagesByStartDate(stages, 'DESC'),
        game: state.game,
        currentlyActiveState: getCurrentlyActiveState(stages)
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirectToNewTask: function () { return dispatch({ type: 'ROUTE', to: '/task/new' }); },
        websocketMessage: function (msg) {
            return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
