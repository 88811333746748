var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FacilitatorTemplateSingle from '../list/single/component';
import FacilitatorTemplateForm from './form/container';
var FacilitatorTemplateDetails = /** @class */ (function (_super) {
    __extends(FacilitatorTemplateDetails, _super);
    function FacilitatorTemplateDetails() {
        var _this = _super.call(this) || this;
        _this.state = {
            newGameView: false
        };
        return _this;
    }
    FacilitatorTemplateDetails.prototype.shouldComponentUpdate = function () {
        return true;
    };
    FacilitatorTemplateDetails.prototype.setDashboardView = function () {
        this.setState({
            newGameView: ''
        });
    };
    FacilitatorTemplateDetails.prototype.render = function () {
        var _this = this;
        var selectedTemplate = this.props.gameTemplates.find(function (x) {
            return x._id === _this.props.templateID;
        });
        return (React.createElement("div", { className: "customtemplatearea" },
            React.createElement("div", { className: "customtemplatearea__card" },
                React.createElement(FacilitatorTemplateSingle, { id: selectedTemplate._id, name: selectedTemplate.name, description: selectedTemplate.description, selectedTemplateID: selectedTemplate._id, onTemplateClick: function () { } })),
            React.createElement("div", { className: "customtemplatearea__form" },
                React.createElement(FacilitatorTemplateForm, { onTitleChange: this.props.onTitleChange, onDateTimeChange: this.props.onDateTimeChange }))));
    };
    FacilitatorTemplateDetails.propTypes = {
        templateID: PropTypes.string,
        gameTemplates: PropTypes.array,
        onTitleChange: PropTypes.func,
        onDateTimeChange: PropTypes.func
    };
    FacilitatorTemplateDetails.defaultProps = {
        templateID: '',
        gameTemplates: [],
        onTitleChange: null,
        onDateTimeChange: null
    };
    return FacilitatorTemplateDetails;
}(Component));
export default FacilitatorTemplateDetails;
