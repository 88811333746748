var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Sidebar from 'components/shared/sidebar/container';
import Modal from 'components/player/shared/modal/component';
import TaskModalContent from '../../../player/task/modal-content/component';
import PlayersSidebar from 'components/player/shared/players-sidebar/container';
import FilterButtons from 'components/player/task/filter-buttons/component';
import TaskCounters from 'components/player/task/task-counters/task-counters';
import { getTasksCounts } from 'components/player/task/utilities/getTasksCounts';
import { TABLE_FILTER_COMPANY, TABLE_FILTER_PERSONAL, TASK_NEW_MODAL_NAME, TASK_REQUIRE_NEW, TASK_REQUIRE_PLAYER, TASK_STRING_TYPE_COMPANY, TASK_STRING_TYPE_PLAYER, TASK_TYPE_COMPANY, TASK_TYPE_PLAYER, TASK_UPDATE_MODAL_NAME } from 'components/player/task/constants';
import { onNewTaskClick } from 'components/player/task/utilities/onNewTaskClick';
import { taskRequestNew, taskRequestUpdate } from '../../../../socketConnection/socketMessageRequests/taskRequests';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
import TaskTableConfig from 'components/configs/collapseTable/taskTable/TaskTableConfig';
import CollapsedTable from 'components/shared/tables/collapsedTable/component';
import { useAutoOpenedGameStage } from 'helpers/stageUtilities';
import { isGameInShutDownState } from 'helpers/gameUtilities';
import MobCollapseTable from 'components/shared/tables/mobCollapseTable/component';
import MobTaskTableConfig from 'components/configs/collapseTable/taskTable/MobTaskTableConfig';
import { setT } from 'helpers/translationUtilities';
import { determineTaskFor, unfinishedTasksLimits } from 'helpers/taskUtilities';
import { reformatTinyMCEiframe } from 'helpers/utilities';
ViewTask.propTypes = {
    websocketMessage: PropTypes.func.isRequired,
    redirectToNewTask: PropTypes.func.isRequired,
    presetPlayer: PropTypes.object,
    game: PropTypes.object.isRequired,
    presetTasks: PropTypes.array,
    stages: PropTypes.array,
    currentlyActiveState: PropTypes.object
};
export default function ViewTask(_a) {
    var _this = this;
    var _b, _c;
    var websocketMessage = _a.websocketMessage, redirectToNewTask = _a.redirectToNewTask, _d = _a.presetPlayer, presetPlayer = _d === void 0 ? {} : _d, game = _a.game, _e = _a.presetTasks, presetTasks = _e === void 0 ? [] : _e, _f = _a.stages, stages = _f === void 0 ? [] : _f, _g = _a.currentlyActiveState, currentlyActiveState = _g === void 0 ? null : _g;
    var skipTaskSelection = !game.taskSettings.companyCards || !game.taskSettings.playerCards;
    var _h = useState(presetPlayer), currentPlayer = _h[0], setCurrentPlayer = _h[1];
    var _j = useState(0), tableView = _j[0], setTableView = _j[1];
    var _k = useState(null), expanded = _k[0], setExpanded = _k[1];
    var _l = useState(null), selectedTask = _l[0], setSelectedTask = _l[1];
    var _m = useState(presetTasks), tasks = _m[0], setTasks = _m[1];
    var _o = useState(null), newTask = _o[0], setNewTask = _o[1];
    var _p = useState(null), taskFor = _p[0], setTaskFor = _p[1];
    var _q = useState(false), waitingForNewTask = _q[0], setWaitingForNewTask = _q[1];
    var _r = useState([]), openRowIds = _r[0], setOpenRowIds = _r[1];
    useAutoOpenedGameStage(currentlyActiveState, setOpenRowIds);
    var reachedUnfinishedTasksLimit = ((_c = (_b = game === null || game === void 0 ? void 0 : game.taskSettings) === null || _b === void 0 ? void 0 : _b.unusedTicketsCount) === null || _c === void 0 ? void 0 : _c.game) ?
        unfinishedTasksLimits(game.taskSettings.unusedTicketsCount.game, tasks, currentPlayer) :
        { company: false, player: false };
    useEffect(function () {
        if (currentlyActiveState) {
            setOpenRowIds([currentlyActiveState.id]);
        }
    }, []);
    useEffect(function () {
        switch (tableView) {
            case TABLE_FILTER_COMPANY:
                setTasks(presetTasks.filter(function (task) { return task.to !== currentPlayer.id; }));
                break;
            case TABLE_FILTER_PERSONAL:
                setTasks(presetTasks.filter(function (task) { return task.to === currentPlayer.id; }));
                break;
            default:
                setTasks(presetTasks);
        }
    }, [tableView]);
    useEffect(function () {
        // If a player switch happens, take that into account and rerender the entire list
        setTasks(presetTasks);
        if (currentPlayer.id !== presetPlayer.id) {
            setCurrentPlayer(presetPlayer);
        }
        else {
            // Upon receiving new task update the state
            if (presetTasks &&
                presetTasks.length > tasks.length &&
                waitingForNewTask) {
                var foundNewTask = presetTasks.find(function (nextPropsTask) {
                    var foundStateTask = tasks.find(function (stateTask) {
                        return nextPropsTask.id === stateTask.id;
                    });
                    if (!foundStateTask &&
                        (nextPropsTask.to === currentPlayer.id ||
                            nextPropsTask.to === currentPlayer.company.id)) {
                        return nextPropsTask;
                    }
                });
                setNewTask(foundNewTask);
                setTasks(presetTasks);
                if (foundNewTask) {
                    setTaskFor(determineTaskFor(foundNewTask.to, currentPlayer.id));
                    setWaitingForNewTask(false);
                    setExpanded(TASK_NEW_MODAL_NAME);
                }
            }
        }
        reformatTinyMCEiframe(".collapse-table__cell-data iframe");
    }, [presetTasks, presetPlayer]);
    var gameLocked = isGameInShutDownState(stages);
    var tableComponentConfig = {
        tasks: tasks.sort(sortTasksByDateTime),
        stages: stages,
        account: currentPlayer,
        onClick: function (id) { return onGroupHeaderClick(id); },
        openRows: openRowIds,
        onSingleRowClick: function (id) { return onTableRowClick(id); }
    };
    var tableValues = TaskTableConfig(tableComponentConfig);
    var taskCounters = getTasksCounts(presetTasks, tableView, presetPlayer);
    var taskClickTrigger = function () { return __awaiter(_this, void 0, void 0, function () {
        var taskAction, gameLocked, _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    taskAction = onNewTaskClick(game);
                    gameLocked = isGameInShutDownState(stages);
                    if (gameLocked) {
                        return [2 /*return*/, null];
                    }
                    if (!(skipTaskSelection || reachedUnfinishedTasksLimit.company || reachedUnfinishedTasksLimit.player)) return [3 /*break*/, 3];
                    setWaitingForNewTask(true);
                    _a = websocketMessage;
                    return [4 /*yield*/, taskRequestNew({
                            type: getNewAutomaticallyRequestedTaskType(game.taskSettings, reachedUnfinishedTasksLimit)
                        })];
                case 1: return [4 /*yield*/, _a.apply(void 0, [_e.sent()])];
                case 2:
                    _e.sent();
                    return [2 /*return*/];
                case 3:
                    _b = taskAction;
                    switch (_b) {
                        case TASK_REQUIRE_NEW: return [3 /*break*/, 4];
                        case TASK_REQUIRE_PLAYER: return [3 /*break*/, 5];
                    }
                    return [3 /*break*/, 8];
                case 4:
                    redirectToNewTask();
                    return [3 /*break*/, 11];
                case 5:
                    _c = websocketMessage;
                    return [4 /*yield*/, taskRequestNew({ type: TASK_TYPE_PLAYER })];
                case 6: return [4 /*yield*/, _c.apply(void 0, [_e.sent()])];
                case 7:
                    _e.sent();
                    return [3 /*break*/, 11];
                case 8:
                    _d = websocketMessage;
                    return [4 /*yield*/, taskRequestNew({ type: TASK_TYPE_COMPANY })];
                case 9: return [4 /*yield*/, _d.apply(void 0, [_e.sent()])];
                case 10:
                    _e.sent();
                    _e.label = 11;
                case 11: return [2 /*return*/];
            }
        });
    }); };
    var onTableRowClick = function (id) {
        var task = tasks.find(function (task) {
            return task.id === id;
        });
        var taskFor = TASK_STRING_TYPE_PLAYER;
        if (task.to !== presetPlayer.id) {
            taskFor = TASK_STRING_TYPE_COMPANY;
        }
        setSelectedTask(task);
        setTaskFor(taskFor);
        setExpanded(TASK_UPDATE_MODAL_NAME);
    };
    var taskUpdate = function (id, type, status) { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = websocketMessage;
                    return [4 /*yield*/, taskRequestUpdate(id, type, status)];
                case 1: return [4 /*yield*/, _a.apply(void 0, [_b.sent()])];
                case 2:
                    _b.sent();
                    onClose();
                    return [2 /*return*/];
            }
        });
    }); };
    var onClose = function () {
        setExpanded(false);
    };
    var onGroupHeaderClick = function (id) {
        var newIdsArray = __spreadArray([], openRowIds, true);
        var newIdsArrayIndex = newIdsArray.findIndex(function (presentId) { return presentId === id; });
        if (newIdsArrayIndex !== -1) {
            newIdsArray.splice(newIdsArrayIndex, 1);
        }
        else {
            newIdsArray.push(id);
        }
        setOpenRowIds(newIdsArray);
    };
    var newTaskButton = function (addedClasses) { return (React.createElement("button", { className: clsx([
            'main-button', addedClasses,
            ((reachedUnfinishedTasksLimit.company && reachedUnfinishedTasksLimit.player) || gameLocked) && ' main-button--disabled'
        ]), onClick: gameLocked || (reachedUnfinishedTasksLimit.company && reachedUnfinishedTasksLimit.player)
            ? null : function () { return taskClickTrigger(); } }, setT('new_task'))); };
    return (React.createElement("div", { className: "playerdashboard playerdashboard--with-players-sidebar" },
        React.createElement(Sidebar, { type: SIDEBAR_MODE_PLAYER }),
        React.createElement(PlayersSidebar, null),
        React.createElement("div", { className: "" },
            React.createElement("div", { className: "f-grid flex-align-items-center" },
                React.createElement("div", { className: "f-grid-col" },
                    React.createElement(TaskCounters, { counters: taskCounters })),
                newTaskButton('mob-player-action-button'),
                React.createElement("div", { className: "f-grid-col flex-shrink-1 flex-grow-0 d-none d-block-md" }, newTaskButton()))),
        React.createElement("div", null,
            React.createElement(FilterButtons, { setTableView: function (id) { return setTableView(id); }, tableView: tableView })),
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("div", { className: "d-none-md" },
                    React.createElement(MobCollapseTable, { onExtensionClick: onGroupHeaderClick, config: MobTaskTableConfig(tableValues, function (id) { return onTableRowClick(id); }) })),
                React.createElement("div", { className: "d-none d-block-md" },
                    React.createElement(CollapsedTable, { groups: tableValues, tableWrapperClasses: 'collapse-table--last-icons collapse-table--side-paddings' })),
                React.createElement(Modal, { mobileFullScreen: true, expanded: expanded === 'updateModal', onClose: function () { return onClose(); } },
                    React.createElement(TaskModalContent, { icon: taskFor === TASK_STRING_TYPE_PLAYER ? 'task_personal' : 'task_company', action: 'finalize', isPlayerTask: taskFor === TASK_STRING_TYPE_PLAYER, fields: selectedTask, onUpdate: function (id, type, status) { return taskUpdate(id, type, status); }, closeModal: function () { return onClose(); } })),
                React.createElement(Modal, { mobileFullScreen: true, expanded: expanded === 'newTaskModal', onClose: function () { return onClose(); } },
                    React.createElement(TaskModalContent, { icon: taskFor === TASK_STRING_TYPE_PLAYER ? 'task_personal' : 'task_company', action: 'confirm', isPlayerTask: taskFor === TASK_STRING_TYPE_PLAYER, fields: newTask, onUpdate: function (id, type, status) { return taskUpdate(id, type, status); }, closeModal: function () { return onClose(); } }))))));
}
function getNewAutomaticallyRequestedTaskType(taskSettings, reachedUnfinishedTasksLimit) {
    if (!taskSettings.companyCards || reachedUnfinishedTasksLimit.company) {
        return TASK_TYPE_PLAYER;
    }
    return TASK_TYPE_COMPANY;
}
function sortTasksByDateTime(a, b) {
    if (b.updatedAt) {
        if (b.updatedAt > a.updatedAt) {
            return 1;
        }
        return -1;
    }
    else {
        if (a.updatedAt) {
            return -1;
        }
        if (b.startedAt < a.startedAt) {
            return -1;
        }
        return 1;
    }
}
