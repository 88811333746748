import { connect } from 'react-redux';
import Component from './component';
import { adminAddStageAction, adminUpdateStageAction } from '../../../../../../../redux/actions/admin/admin.stage.actions';
var mapStateToProps = function (state) {
    return {
        templateId: state.admin.singleTemplate._id
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        updateStage: function (id, payload) { return dispatch(adminUpdateStageAction(id, payload)); },
        createStage: function (payload) { return dispatch(adminAddStageAction(payload)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
