var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TinyMCE from 'components/shared/TinyMCE/component';
TemplateGeneral.propTypes = {
    template: PropTypes.object,
    updateTemplate: PropTypes.func.isRequired
};
export default function TemplateGeneral(_a) {
    var _b, _c, _d, _e, _f, _g;
    var template = _a.template, updateTemplate = _a.updateTemplate;
    var transactionSettings = template.transactionSettings, taskSettings = template.taskSettings;
    var _h = useState(template.description), editorText = _h[0], setEditorText = _h[1];
    var _j = useState({
        playerToPlayer: transactionSettings === null || transactionSettings === void 0 ? void 0 : transactionSettings.playerToPlayer,
        playerToCompany: transactionSettings === null || transactionSettings === void 0 ? void 0 : transactionSettings.playerToCompany,
        companyToPlayer: transactionSettings === null || transactionSettings === void 0 ? void 0 : transactionSettings.companyToPlayer,
        companyToCompany: transactionSettings === null || transactionSettings === void 0 ? void 0 : transactionSettings.companyToCompany,
        playerTasks: taskSettings === null || taskSettings === void 0 ? void 0 : taskSettings.playerCards,
        companyTasks: taskSettings === null || taskSettings === void 0 ? void 0 : taskSettings.companyCards
    }), formCheckboxValues = _j[0], setFormCheckboxValues = _j[1];
    function onCheckboxClick(property) {
        var _a;
        var newValue = !formCheckboxValues[property];
        setFormCheckboxValues(__assign(__assign({}, formCheckboxValues), (_a = {}, _a[property] = newValue, _a)));
    }
    function onFormSubmit(e) {
        e.preventDefault();
        var target = e.target;
        var payload = {
            name: target.name.value,
            description: editorText,
            transactionSettings: {
                playerToPlayer: formCheckboxValues.playerToPlayer,
                playerToCompany: formCheckboxValues.playerToCompany,
                companyToPlayer: formCheckboxValues.companyToPlayer,
                companyToCompany: formCheckboxValues.companyToCompany
            },
            taskSettings: {
                playerCards: formCheckboxValues.playerTasks,
                companyCards: formCheckboxValues.companyTasks,
                unusedTicketsCount: {
                    game: {
                        player: Number(target.player_tasks_max.value),
                        company: Number(target.company_tasks_max.value)
                    }
                }
            }
        };
        updateTemplate(template._id, payload);
    }
    return (React.createElement("form", { onSubmit: onFormSubmit, className: "text-align-left admin-form" },
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null, "Template name:"),
            React.createElement("input", { type: "text", name: "name", defaultValue: template.name })),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null, "Template description:"),
            React.createElement(TinyMCE, { value: editorText || null, onChange: function (value) { return setEditorText(value); } })),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null, "Max player tasks in progress:"),
            React.createElement("input", { type: "number", name: "player_tasks_max", defaultValue: ((_d = (_c = (_b = template === null || template === void 0 ? void 0 : template.taskSettings) === null || _b === void 0 ? void 0 : _b.unusedTicketsCount) === null || _c === void 0 ? void 0 : _c.game) === null || _d === void 0 ? void 0 : _d.player) || 10 })),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("span", null, "Max company tasks in progress:"),
            React.createElement("input", { type: "number", name: "company_tasks_max", defaultValue: ((_g = (_f = (_e = template === null || template === void 0 ? void 0 : template.taskSettings) === null || _e === void 0 ? void 0 : _e.unusedTicketsCount) === null || _f === void 0 ? void 0 : _f.game) === null || _g === void 0 ? void 0 : _g.company) || 10 })),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("label", null,
                React.createElement("input", { type: "checkbox", name: "player-to-player", defaultChecked: formCheckboxValues.playerToPlayer, onChange: function () { return onCheckboxClick("playerToPlayer"); } }),
                React.createElement("span", null, "Allow Player to Player transactions"))),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("label", null,
                React.createElement("input", { type: "checkbox", name: "player-to-company", defaultChecked: formCheckboxValues.playerToCompany, onChange: function () { return onCheckboxClick("playerToCompany"); } }),
                React.createElement("span", null, "Allow Player to Company transactions"))),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("label", null,
                React.createElement("input", { type: "checkbox", name: "company-to-player", defaultChecked: formCheckboxValues.companyToPlayer, onChange: function () { return onCheckboxClick("companyToPlayer"); } }),
                React.createElement("span", null, "Allow Company to Player transactions"))),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("label", null,
                React.createElement("input", { type: "checkbox", name: "company-to-company", defaultChecked: formCheckboxValues.companyToCompany, onChange: function () { return onCheckboxClick("companyToCompany"); } }),
                React.createElement("span", null, "Allow Company to Company transactions"))),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("label", null,
                React.createElement("input", { type: "checkbox", name: "company-to-company", defaultChecked: formCheckboxValues.playerTasks, onChange: function () { return onCheckboxClick("playerTasks"); } }),
                React.createElement("span", null, "Allow player tasks"))),
        React.createElement("div", { className: "mar-top-5 admin-form__area" },
            React.createElement("label", null,
                React.createElement("input", { type: "checkbox", name: "company-to-company", defaultChecked: formCheckboxValues.companyTasks, onChange: function () { return onCheckboxClick("companyTasks"); } }),
                React.createElement("span", null, "Allow company tasks"))),
        React.createElement("button", { className: "main-button", type: "submit" }, "Kinnita")));
}
