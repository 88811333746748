var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Sidebar from 'components/shared/sidebar/container';
import { movePlayersToCompany } from '../../../../socketConnection/socketMessageRequests/companyRequests';
import { SIDEBAR_MODE_FACILITATOR } from 'components/constants';
import { FACILITATOR_SUBSCRIBE, FACILITATOR_UNSUBSCRIBE, subscriptionAction, } from '../../../../socketConnection/socketMessageRequests/subscribtionRequests';
import CollapsedTable from 'components/shared/tables/collapsedTable/component';
import ParticipantsTableConfig from 'components/configs/collapseTable/participantsTable/ParticipantsTableConfig';
import MobParticipantsTableConfig from 'components/configs/collapseTable/participantsTable/MobParticipantsTableConfig';
import MobCollapseTable from 'components/shared/tables/mobCollapseTable/component';
import { setT } from 'helpers/translationUtilities';
import NewPlayerModal from 'components/views/facilitator/participants/newPlayerModal/container';
var dataSubscriptionInterval;
ViewFacilitatorParticipants.propTypes = {
    websocketMessage: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    allowGameJoin: PropTypes.func.isRequired,
    redirectionGameJoin: PropTypes.func.isRequired,
    players: PropTypes.any,
    companies: PropTypes.any,
    bank: PropTypes.object
};
export default function ViewFacilitatorParticipants(props) {
    var websocketMessage = props.websocketMessage, redirect = props.redirect, allowGameJoin = props.allowGameJoin, redirectionGameJoin = props.redirectionGameJoin, _a = props.players, players = _a === void 0 ? null : _a, _b = props.companies, companies = _b === void 0 ? null : _b, _c = props.bank, bank = _c === void 0 ? {
        balances: []
    } : _c;
    var _d = useState(false), movePlayersState = _d[0], setMovePlayersState = _d[1];
    var _e = useState([]), selectedPlayerIds = _e[0], setSelectedPlayerIds = _e[1];
    var _f = useState([]), openCompaniesIds = _f[0], setOpenCompaniesIds = _f[1];
    var _g = useState(false), newPlayerModalOpen = _g[0], setNewPlayerModalOpen = _g[1];
    function onNewPlayerAdd() {
        allowGameJoin(true);
        setNewPlayerModalOpen(true);
    }
    function onRowClick(companyID) {
        return __awaiter(this, void 0, void 0, function () {
            var isCompany, chosenPlayedIds, selectedIDIndex, playerArray, playerIDs, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!companyID) {
                            return [2 /*return*/];
                        }
                        isCompany = !!companies.find(function (company) {
                            return company.id === companyID;
                        });
                        if (!(!isCompany && movePlayersState)) return [3 /*break*/, 1];
                        chosenPlayedIds = __spreadArray([], selectedPlayerIds, true);
                        selectedIDIndex = chosenPlayedIds.findIndex(function (x) {
                            return x === companyID;
                        });
                        if (selectedIDIndex === -1) {
                            chosenPlayedIds.push(companyID);
                        }
                        else {
                            chosenPlayedIds.splice(selectedIDIndex, 1);
                        }
                        setSelectedPlayerIds(chosenPlayedIds);
                        return [3 /*break*/, 3];
                    case 1:
                        if (!(isCompany && movePlayersState)) return [3 /*break*/, 3];
                        if (!selectedPlayerIds.length) return [3 /*break*/, 3];
                        playerArray = setPlayerTokens();
                        playerIDs = playerArray.map(function (player) {
                            return player.id;
                        });
                        _a = websocketMessage;
                        return [4 /*yield*/, movePlayersToCompany(playerIDs, companyID)];
                    case 2:
                        _a.apply(void 0, [_b.sent()]);
                        setSelectedPlayerIds([]);
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    function setPlayerTokens() {
        var foundPlayers = [];
        var _loop_1 = function (id) {
            var foundPlayer = players.find(function (player) {
                return player.id === id;
            });
            if (foundPlayer) {
                foundPlayers.push({ id: foundPlayer.id, token: foundPlayer.token });
            }
        };
        for (var _i = 0, selectedPlayerIds_1 = selectedPlayerIds; _i < selectedPlayerIds_1.length; _i++) {
            var id = selectedPlayerIds_1[_i];
            _loop_1(id);
        }
        return foundPlayers;
    }
    function switchModes() {
        setMovePlayersState(!movePlayersState);
    }
    useEffect(function () {
        facilitatorSubscribe();
        dataSubscriptionInterval = setInterval(function () { return facilitatorSubscribe(); }, 5000);
        return function () {
            clearInterval(dataSubscriptionInterval);
            facilitatorUnsubscribe();
        };
    }, []);
    function facilitatorSubscribe() {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = websocketMessage;
                        return [4 /*yield*/, subscriptionAction(FACILITATOR_SUBSCRIBE)];
                    case 1:
                        _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    }
    function facilitatorUnsubscribe() {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = websocketMessage;
                        return [4 /*yield*/, subscriptionAction(FACILITATOR_UNSUBSCRIBE)];
                    case 1:
                        _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    }
    function onGroupItemClick(id) {
        var newIdsArray = __spreadArray([], openCompaniesIds, true);
        var newIdsArrayIndex = newIdsArray.findIndex(function (presentId) { return presentId === id; });
        if (newIdsArrayIndex !== -1) {
            newIdsArray.splice(newIdsArrayIndex, 1);
        }
        else {
            newIdsArray.push(id);
        }
        setOpenCompaniesIds(newIdsArray);
    }
    var newTableValues = ParticipantsTableConfig({
        selectedPlayers: selectedPlayerIds,
        movePlayersState: movePlayersState,
        openRows: openCompaniesIds,
        companies: companies,
        players: players,
        balances: bank.balances,
        onGroupItemClick: function (id) { return onGroupItemClick(id); },
        onSinglePlayerClick: function (id) { return onRowClick(id); },
        redirect: redirectionGameJoin,
    });
    return (React.createElement("div", null,
        React.createElement(Sidebar, { type: SIDEBAR_MODE_FACILITATOR }),
        newPlayerModalOpen ?
            React.createElement(NewPlayerModal, { modalAction: setNewPlayerModalOpen, modalOpen: newPlayerModalOpen }) : null,
        React.createElement("div", { className: "buttons-row" },
            React.createElement("button", { className: "main-button main-button--orange main-button--lg", onClick: function () { return redirect('/facilitator/actions/mass-transaction'); } }, setT('mass_transaction')),
            React.createElement("button", { className: "main-button main-button--lg main-button--green", onClick: onNewPlayerAdd }, setT('add_player')),
            React.createElement("button", { className: "main-button main-button--lg", onClick: switchModes }, !movePlayersState
                ? setT('move_players')
                : setT('switch_to_player_selection'))),
        React.createElement("div", { className: "d-none-md" },
            React.createElement(MobCollapseTable, { config: MobParticipantsTableConfig(newTableValues, movePlayersState), onExtensionClick: movePlayersState && selectedPlayerIds.length ? onRowClick : onGroupItemClick })),
        React.createElement("div", { className: "d-none d-block-md" },
            React.createElement(CollapsedTable, { groups: newTableValues, tableWrapperClasses: 'collapse-table--last-icons collapse-table--side-paddings' }))));
}
