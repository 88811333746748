import { combineReducers } from 'redux';
import { PURGE_ALL_APP_DATA } from 'components/constants';
import general from './reducers/general.reducer';
import player from './reducers/player.reducer';
import playerAuthorization from './reducers/playerAuthorization.reducer';
import userAuthorization from './reducers/userAuthorization.reducer';
import admin from './reducers/admin.reducer';
import reports from './reducers/reports.reducer';
import userInterface from './reducers/userInterface.reducer';
import game from './reducers/game.reducer';
import localization from './reducers/localization.reducer';
var appReducer = combineReducers({
    game: game,
    playerAuthorization: playerAuthorization,
    admin: admin,
    player: player,
    general: general,
    userAuthorization: userAuthorization,
    reports: reports,
    userInterface: userInterface,
    localization: localization,
});
var rootReducer = function (state, action) {
    if (action.type === PURGE_ALL_APP_DATA) {
        state = undefined;
    }
    return appReducer(state, action);
};
export default rootReducer;
