var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { toast } from 'react-toastify';
import './style.scss';
export default function CustomToast() {
    var baseOptions = function (_a) {
        var position = _a.position, time = _a.time;
        return {
            position: toast.POSITION[position],
            autoClose: time,
        };
    };
    function error(_a) {
        var message = _a.message, position = _a.position, time = _a.time;
        toast(message, __assign(__assign({}, baseOptions({ position: position, time: time })), { className: 'error-toast' }));
    }
    function success(_a) {
        var message = _a.message, position = _a.position, time = _a.time;
        toast(message, __assign(__assign({}, baseOptions({ position: position, time: time })), { className: 'success-toast' }));
    }
    return {
        error: error,
        success: success
    };
}
