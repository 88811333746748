import SocketService from '../SocketService';
var activeStageUpdateRequest = function (action) {
    if (!action) {
        throw new Error("activeStageUpdateRequest missing action");
    }
    return SocketService.createEncodedMessage({
        kind: 'activeStageUpdate',
        activeStageUpdate: {
            action: action
        }
    });
};
var stageUpdateRequest = function (id, params) {
    if (params === void 0) { params = {
        duration: 0
    }; }
    if (!params.duration) {
        throw new Error("stageUpdateRequest missing duration");
    }
    return SocketService.createEncodedMessage({
        kind: 'stageUpdate',
        stageUpdate: {
            id: id,
            params: params
        }
    });
};
export { activeStageUpdateRequest, stageUpdateRequest };
