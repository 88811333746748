import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import momentLocalizer from 'react-widgets-moment';
import 'newtime-input-moment-extended/dist/input-moment.css';
import GenericModal from 'components/shared/Modal/component';
import Moment from 'moment';
import InputMoment from 'newtime-input-moment-extended';
import { setT } from 'helpers/translationUtilities';
import { LANG_SERVICE_DEFAULT } from 'services/LanguageService/languageService.types';
FacilitatorTemplateForm.propTypes = {
    onTitleChange: PropTypes.func,
    onDateTimeChange: PropTypes.func,
    currentLang: PropTypes.string
};
export default function FacilitatorTemplateForm(_a) {
    var _b = _a.onTitleChange, onTitleChange = _b === void 0 ? null : _b, _c = _a.onDateTimeChange, onDateTimeChange = _c === void 0 ? null : _c, _d = _a.currentLang, currentLang = _d === void 0 ? LANG_SERVICE_DEFAULT : _d;
    var _e = useState(Moment()), currentDate = _e[0], setCurrentDate = _e[1];
    var _f = useState(null), currentDatePickerTab = _f[0], setCurrentDatePickerTab = _f[1];
    var _g = useState(null), expanded = _g[0], setExpanded = _g[1];
    useEffect(function () {
        Moment.locale('en');
        momentLocalizer();
    }, []);
    function onFieldChange(_a) {
        var target = _a.target;
        if (target && target.name === 'title') {
            onTitleChange(target.value);
        }
    }
    function handleTabClick(e, tab) {
        e.preventDefault();
        setCurrentDatePickerTab(tab);
    }
    function handleChange(value) {
        var newCurrentDate = currentDate.format('X');
        setCurrentDate(value);
        setCurrentDatePickerTab(1);
        onDateTimeChange(newCurrentDate);
    }
    function onSave() {
        setExpanded(false);
    }
    function onOutSideClick() {
        setExpanded(false);
    }
    function onDateInputClick(openedTab) {
        setExpanded(true);
        setCurrentDatePickerTab(openedTab);
    }
    return (React.createElement("div", { className: "form-control" },
        React.createElement("div", { className: "form-control__full" },
            React.createElement("div", { className: "form-control__group" },
                React.createElement("div", { className: "form-control__label" }, setT('title')),
                React.createElement("input", { type: "text", name: "title", className: "basic-input", onChange: onFieldChange })),
            React.createElement("div", { className: "form-control__group" },
                React.createElement("div", { className: "grid-row" },
                    React.createElement("div", { className: "grid-lg-6" },
                        React.createElement("div", { className: "form-control__label" }, setT('date')),
                        React.createElement("div", { className: "basic-input-element" },
                            React.createElement("div", { className: "basic-input-element__cover basic-input-element__cover--icon basic-input-element__cover--icon-calendar", onClick: function () { return onDateInputClick(0); } },
                                React.createElement("input", { type: "text", className: "basic-input", value: Moment(currentDate).format('DD-MM-YYYY'), readOnly: true })))),
                    React.createElement("div", { className: "grid-lg-6" },
                        React.createElement("div", { className: "form-control__label" }, setT('select_time')),
                        React.createElement("div", { className: "basic-input-element" },
                            React.createElement("div", { className: "basic-input-element__cover basic-input-element__cover--icon basic-input-element__cover--icon-clock", onClick: function () { return onDateInputClick(1); } },
                                React.createElement("input", { type: "text", className: "basic-input", value: Moment(currentDate).format('HH:mm'), readOnly: true }))))))),
        React.createElement(GenericModal, { expanded: expanded, onOutSideClick: function () { return onOutSideClick(); }, modalClasses: 'modal--datepicker' },
            React.createElement(InputMoment, { moment: currentDate, onChange: handleChange, onSave: onSave, minStep: 1, hourStep: 1, prevMonthIcon: "ion-ios-arrow-left" // default
                , nextMonthIcon: "ion-ios-arrow-right" // default
                , tab: currentDatePickerTab, handleclicktab: function (e, tab) {
                    handleTabClick(e, tab);
                }, hoursLabel: setT('hours_timepicker'), minutesLabel: setT('minutes_timepicker'), dateLabel: setT('date_timepicker'), timeLabel: setT('time_timepicker'), savebuttonlabel: setT('close'), currentLanguage: currentLang || LANG_SERVICE_DEFAULT }))));
}
