import { connect } from 'react-redux';
import Component from './component';
import { WEBSOCKET_MESSAGE } from 'components/constants';
import { getCurrentlyActiveState } from 'helpers/stageUtilities';
var mapStateToProps = function (state) {
    var stages = state.game.stages;
    return {
        stages: stages,
        currentUserRank: state.userAuthorization.userSession.rank,
        currentlyActiveState: getCurrentlyActiveState(stages)
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketMessage: function (msg) {
            return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
