import { connect } from 'react-redux';
import Component from './component';
import { WEBSOCKET_MESSAGE } from 'components/constants';
var mapStateToProps = function (state) {
    return {
        tasks: state.player.tasks.items,
        game: state.game,
        player: state.player
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirectToTasks: function () { return dispatch({ type: 'ROUTE', to: '/task' }); },
        websocketMessage: function (msg) {
            return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
