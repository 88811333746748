import SocketService from '../SocketService';
var taskRequestNew = function (type) {
    return SocketService.createEncodedMessage({
        kind: 'takeTask',
        takeTask: type
    });
};
var taskRequestUpdate = function (id, type, status) {
    return SocketService.createEncodedMessage({
        kind: 'task',
        task: {
            id: id,
            type: type,
            status: status
        }
    });
};
export { taskRequestNew, taskRequestUpdate };
