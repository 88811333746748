import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from 'assets/translations/en/index';
import etTranslations from 'assets/translations/et/index';
import ruTranslations from 'assets/translations/ru/index';
import { LANG_SERVICE_DEFAULT } from 'services/LanguageService/languageService.types';
i18next.use(initReactI18next).init({
    keySeparator: '.',
    resources: {
        en: enTranslations,
        et: etTranslations,
        ru: ruTranslations
    },
    fallbackNS: 'common',
    fallbackLng: {
        default: [LANG_SERVICE_DEFAULT],
    },
    react: {
        useSuspense: true,
    },
    debug: process.env.DEBUG,
    interpolation: false,
});
export default i18next;
