var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Infobox from 'components/player/shared/infobox/component';
import NumericSelection from './numeric-selection/component';
import PaymentAvatar from 'components/player/shared/payment-modules/avatar/component';
import BalanceBox from 'components/player/shared/balanceBox/component';
import { NEW_TRANSACTION_CONFIRMATION_VIEW, NEW_TRANSACTION_RECIPIENT_SELECTION_VIEW, NEW_TRANSACTION_WALLET_KIND_BANK, NEW_TRANSACTION_WALLET_KIND_PLAYER, TRANSACTION_TYPE_DEPOSIT, TRANSACTION_TYPE_REGULAR_TRANSACTION, TRANSACTION_TYPE_WITHDRAW } from 'components/player/money/transactions/types';
import RecipientSelection from 'components/shared/transaction/new/process/recipientSelection/container';
import CustomToast from 'components/shared/toast/component';
import { findPlayerById, getBankPlayerBalanceFromId, isPlayerAdminOrFacilitator } from 'components/player/utilities';
import { isGameInShutDownState } from 'helpers/gameUtilities';
import AuthPermissions from 'components/permissions/component';
import { USER_RANK_ADMIN, USER_RANK_FACILITATOR } from 'components/permissions/types';
import { setT } from 'helpers/translationUtilities';
NewTransactionInitialized.propTypes = {
    companiesAndPlayers: PropTypes.array.isRequired,
    company: PropTypes.object.isRequired,
    currentUserRank: PropTypes.string.isRequired,
    player: PropTypes.object.isRequired,
    kind: PropTypes.number.isRequired,
    stages: PropTypes.array.isRequired,
    setStep: PropTypes.func.isRequired,
    changeStep: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    presetPayload: PropTypes.object.isRequired,
    updateMainPayload: PropTypes.func.isRequired,
    bankVariables: PropTypes.object,
    playerIsUser: PropTypes.bool,
    urlParams: PropTypes.object,
    isFacilitatorBulkView: PropTypes.bool
};
export default function NewTransactionInitialized(_a) {
    var _b = _a.companiesAndPlayers, companiesAndPlayers = _b === void 0 ? [] : _b, _c = _a.company, company = _c === void 0 ? {} : _c, currentUserRank = _a.currentUserRank, _d = _a.player, player = _d === void 0 ? {} : _d, _e = _a.kind, kind = _e === void 0 ? NEW_TRANSACTION_WALLET_KIND_PLAYER : _e, _f = _a.stages, stages = _f === void 0 ? [] : _f, setStep = _a.setStep, changeStep = _a.changeStep, redirect = _a.redirect, _g = _a.presetPayload, presetPayload = _g === void 0 ? {} : _g, updateMainPayload = _a.updateMainPayload, _h = _a.bankVariables, bankVariables = _h === void 0 ? null : _h, _j = _a.playerIsUser, playerIsUser = _j === void 0 ? true : _j, _k = _a.urlParams, urlParams = _k === void 0 ? {
        type: '',
        accountId: ''
    } : _k, _l = _a.isFacilitatorBulkView, isFacilitatorBulkView = _l === void 0 ? false : _l;
    var titleValues = {
        facilitator: {
            single: setT('subject'),
            multiple: setT('subjects')
        },
        player: setT('recipient')
    };
    var withdrawString = isFacilitatorBulkView ? setT('views:mass-transaction.withdraw') : setT('withdraw');
    var currentPlayer = getCurrentPlayer();
    var relevantRecipientTitle = setTitle(playerIsUser, presetPayload.receivers, titleValues);
    var balance = bankVariables
        ? getBankPlayerBalanceFromId(currentPlayer.id, bankVariables.balances)
        : currentPlayer.totalBalance;
    var gameLocked = isGameInShutDownState(stages);
    var submittingLocked = !isPlayerAdminOrFacilitator(currentUserRank) && gameLocked;
    var _m = useState(presetPayload.amount), transactionValue = _m[0], setTransactionValue = _m[1];
    useEffect(function () {
        updateMainPayload({ amount: +transactionValue, from: isFacilitatorBulkView ? '' : currentPlayer.id });
    }, [transactionValue]);
    function processRecipientSelection() {
        return (React.createElement(RecipientSelection, { changeStep: presetPayload.type === TRANSACTION_TYPE_REGULAR_TRANSACTION || isFacilitatorBulkView
                ? function () { return changeStep(NEW_TRANSACTION_RECIPIENT_SELECTION_VIEW); }
                : null, receivers: presetPayload.to }));
    }
    function getCurrentPlayer() {
        if (kind === NEW_TRANSACTION_WALLET_KIND_BANK && bankVariables) {
            return findPlayerById(bankVariables.currentlyViewedPlayer, companiesAndPlayers);
        }
        return kind === NEW_TRANSACTION_WALLET_KIND_PLAYER ? player : company;
    }
    function validate() {
        var messagesPool = [];
        var toastOptions = {
            position: 'TOP_RIGHT',
            time: 3000,
            message: ''
        };
        if (!presetPayload.to.length) {
            messagesPool.push(setT('missing_recipient'));
        }
        if (presetPayload.amount > balance && presetPayload.type !== TRANSACTION_TYPE_DEPOSIT) {
            messagesPool.push(setT('not_enough_funds'));
        }
        if (presetPayload.amount === 0) {
            messagesPool.push(setT('transaction_amount_not_set'));
        }
        if (messagesPool.length) {
            toastOptions.message = messagesPool.join('! ');
            CustomToast().error(toastOptions);
            return;
        }
        setStep(NEW_TRANSACTION_CONFIRMATION_VIEW);
    }
    function displayBalanceBox() {
        if (!isFacilitatorBulkView && presetPayload.type !== TRANSACTION_TYPE_DEPOSIT) {
            return React.createElement(BalanceBox, { balance: balance });
        }
    }
    return (React.createElement("div", { className: "grid-row" },
        React.createElement("div", { className: "grid-sm-12" },
            React.createElement(Infobox, null,
                displayBalanceBox(),
                React.createElement("div", { className: "payment" },
                    React.createElement("div", { className: "payment__inner" },
                        React.createElement("div", { className: "payment__item  payment__item--title" }, !isFacilitatorBulkView ?
                            React.createElement(Fragment, null,
                                React.createElement("div", { className: "payment__title payment__title--error" }, setT('payee')),
                                processPaymentAvatar(currentPlayer, presetPayload.type)) :
                            React.createElement(AuthPermissions, { currentUserRank: currentUserRank, allowedRanks: [USER_RANK_FACILITATOR, USER_RANK_ADMIN] },
                                React.createElement("div", { className: "payment__title payment__title--error" }, setT('action')),
                                React.createElement("div", { className: "payment__bulk-actions" },
                                    React.createElement("button", { className: clsx(['main-button main-button--lg main-button--hollow main-button--custom-action', presetPayload.type === TRANSACTION_TYPE_DEPOSIT && 'main-button--custom-action-active']), onClick: function () { return updateMainPayload({ type: TRANSACTION_TYPE_DEPOSIT, from: '' }); } }, setT('add')),
                                    React.createElement("button", { className: clsx(['main-button main-button--lg main-button--hollow main-button--custom-action', presetPayload.type === TRANSACTION_TYPE_WITHDRAW && 'main-button--custom-action-active']), onClick: function () { return updateMainPayload({ type: TRANSACTION_TYPE_WITHDRAW, from: '' }); } }, withdrawString)))),
                        React.createElement("div", { className: "payment__item payment__item--icon payment__item--no-stretch d-none d-flex-md" },
                            React.createElement("div", { className: "chevron" })),
                        React.createElement("div", { className: "payment__item mar-bottom-20 mar-bottom-0-md" },
                            React.createElement(NumericSelection, { presetValue: transactionValue, onChange: function (value) { return setTransactionValue(parseInt(value)); } })),
                        React.createElement("div", { className: "payment__item payment__item--icon payment__item--no-stretch d-none d-flex-md" },
                            React.createElement("div", { className: "chevron" })),
                        React.createElement("div", { className: "payment__item payment__item--title" },
                            React.createElement("div", { className: "payment__title" }, relevantRecipientTitle),
                            processRecipientSelection(function () { return changeStep(NEW_TRANSACTION_RECIPIENT_SELECTION_VIEW); }, presetPayload)))),
                React.createElement("div", { className: "infobox__footer justify-content-center text-align-center" },
                    React.createElement("button", { className: "main-button main-button--lg main-button--secondary", onClick: function () {
                            return redirect(setRedirectionCancelUrl(bankVariables, urlParams, isFacilitatorBulkView));
                        } }, setT('cancel')),
                    React.createElement("button", { className: clsx('main-button main-button--lg main-button--inline', submittingLocked && 'main-button--disabled'), onClick: submittingLocked ? null : function () { return validate(); } }, setT('proceed')))))));
}
function setRedirectionCancelUrl(bankVariables, urlParams, isFacilitatorBulkView) {
    if (bankVariables && bankVariables.currentlyViewedPlayer) {
        return "/banking/".concat(bankVariables.currentlyViewedPlayer);
    }
    else if (isFacilitatorBulkView) {
        return "/facilitator/participants";
    }
    return "/money/".concat(urlParams.type, "/").concat(urlParams.accountId);
}
function processPaymentAvatar(currentPlayer, transactionType) {
    var playerInfo = {
        thumbnail: currentPlayer ? currentPlayer.thumbnail : '',
        name: currentPlayer ? currentPlayer.name : '',
        payeeString: ''
    };
    switch (transactionType) {
        default:
            break;
        case TRANSACTION_TYPE_WITHDRAW:
            playerInfo = __assign(__assign({}, playerInfo), { name: setT('withdraw'), thumbnail: require('assets/img/icons/icon_money.svg'), addedClasses: 'payee--money-icon' });
            break;
        case TRANSACTION_TYPE_DEPOSIT:
            playerInfo = __assign(__assign({}, playerInfo), { name: setT('deposit'), thumbnail: require('assets/img/icons/icon_money.svg'), addedClasses: 'payee--money-icon' });
            break;
    }
    return React.createElement(PaymentAvatar, __assign({}, playerInfo));
}
function setTitle(playerIsUser, receivers, titleValues) {
    if (!playerIsUser) {
        return receivers && receivers.length > 1
            ? titleValues.facilitator.multiple
            : titleValues.facilitator.single;
    }
    return titleValues.player;
}
