import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/shared/avatar/component';
var Player = function (_a) {
    var isActive = _a.isActive, name = _a.name, onClick = _a.onClick, thumbnail = _a.thumbnail;
    var className = 'sidebar-players__item text-align-center';
    if (isActive) {
        className += ' sidebar-players__item--active';
    }
    return (React.createElement("div", { className: className, onClick: onClick },
        React.createElement("div", { className: "sidebar-players__icon" },
            isActive ? React.createElement("span", { className: "icon icon--play" }) : null,
            React.createElement(Avatar, { src: thumbnail })),
        isActive ? (React.createElement("div", { className: "sidebar-players-item__name" }, name)) : null));
};
Player.propTypes = {
    isActive: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    thumbnail: PropTypes.string.isRequired
};
Player.defaultProps = {
    isActive: false
};
export default Player;
