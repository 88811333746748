import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { history } from '../../../../store';
import ApiGameService from 'services/ApiService/ApiGameService/ApiGameService';
ViewApiAuthentication.propTypes = {
    tokenLoginRequest: PropTypes.func.isRequired,
    purgeAndReconnect: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
};
export default function ViewApiAuthentication(_a) {
    var match = _a.match, tokenLoginRequest = _a.tokenLoginRequest, purgeAndReconnect = _a.purgeAndReconnect;
    var _b = match === null || match === void 0 ? void 0 : match.params, token = _b.token, gameToken = _b.gameToken;
    var queryParams = new URLSearchParams(window.location.search);
    var redirect = queryParams.get('redirect') || "/games";
    useEffect(function () {
        tokenLoginRequest(token).then(function (loginResponse) {
            if (!gameToken) {
                history.push(redirect);
                return;
            }
            /**
             * Allow game join and redirect via token and game link
             *
             * @example http://localhost/login/:user_token/game/:game_token?redirect=/facilitator/games
             */
            ApiGameService.requestGames(token).then(function (response) {
                var hasAccess = loginResponse.message.rank === "ADMIN";
                for (var index in response.data) {
                    if (response.data[index].token === gameToken) {
                        hasAccess = true;
                        break;
                    }
                }
                if (hasAccess) {
                    purgeAndReconnect(gameToken).then(function () {
                        history.push(redirect);
                    }).catch(function (error) {
                        console.error('Error connecting to websocket:', error);
                        history.push("/games");
                    });
                }
                else {
                    history.push("/games");
                }
            }).catch(function (error) {
                console.error('Error fetching games:', error);
                history.push("/games");
            });
        }).catch(function (error) {
            console.error('Error authenticating token login:', error);
            history.push("/login");
        });
    }, [tokenLoginRequest, purgeAndReconnect, token, gameToken, redirect]);
    return null;
}
