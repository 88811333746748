var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ADMIN_RECEIVE_TEMPLATES, ADMIN_RECEIVE_SINGLE_TEMPLATE, ADMIN_PURGE_SINGLE_TEMPLATE, ADMIN_PURGE_FORM_ERRORS, ADMIN_RECEIVE_SINGLE_GAME_TASKS, ADMIN_RECEIVE_TEMPLATE_UPDATED_STAGE, ADMIN_RECEIVE_NEW_STAGE, ADMIN_DELETE_TEMPLATE_STAGE, ADMIN_RECEIVE_NEW_TEMPLATE_COMPANY, ADMIN_DELETE_TEMPLATE_COMPANY, ADMIN_RECEIVE_UPDATED_TEMPLATE_COMPANY, ADMIN_RECEIVE_UPDATED_TEMPLATE, ADMIN_RECEIVE_TASK, ADMIN_UPDATE_TASK, } from 'components/constants';
var initialTaskState = {
    _id: null,
    address: {
        _id: null,
        companyIds: [],
        target: "Company",
    },
    order: null,
    reference: {
        _id: null,
        description: [
            {
                diffLevel: 1,
                content: [
                    {
                        _id: null,
                        language: "et_EE",
                        value: "",
                    }
                ]
            }
        ],
        name: [
            {
                _id: null,
                language: "et_EE",
                value: ""
            }
        ],
        createdAt: null,
        archivedAt: null,
        deletedAt: null,
    },
    reusable: false,
    skippable: false,
};
var initialState = {
    task: initialTaskState,
    tasks: [],
    templates: [],
    singleTemplate: null,
    formErrors: null
};
export default (function (state, _a) {
    if (state === void 0) { state = initialState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        default:
            return state;
        case ADMIN_RECEIVE_SINGLE_GAME_TASKS: {
            return __assign(__assign({}, state), { tasks: payload ? payload.message : [] });
        }
        case ADMIN_RECEIVE_TEMPLATES: {
            return __assign(__assign({}, state), { templates: payload });
        }
        case ADMIN_DELETE_TEMPLATE_COMPANY: {
            return __assign(__assign({}, state), { singleTemplate: __assign(__assign({}, state.singleTemplate), { companies: state.singleTemplate.companies.filter(function (company) { return company._id !== payload; }) }) });
        }
        case ADMIN_RECEIVE_UPDATED_TEMPLATE: {
            return __assign(__assign({}, state), { singleTemplate: payload });
        }
        case ADMIN_RECEIVE_TASK: {
            return __assign(__assign({}, state), { task: payload });
        }
        case ADMIN_UPDATE_TASK: {
            return __assign(__assign({}, state), { task: payload });
        }
        case ADMIN_RECEIVE_NEW_TEMPLATE_COMPANY: {
            return __assign(__assign({}, state), { singleTemplate: __assign(__assign({}, state.singleTemplate), { companies: __spreadArray(__spreadArray([], state.singleTemplate.companies, true), [payload], false) }) });
        }
        case ADMIN_RECEIVE_UPDATED_TEMPLATE_COMPANY: {
            return __assign(__assign({}, state), { singleTemplate: __assign(__assign({}, state.singleTemplate), { companies: updateEntityArray(payload, state.singleTemplate.companies) }) });
        }
        case ADMIN_RECEIVE_NEW_STAGE: {
            return __assign(__assign({}, state), { singleTemplate: __assign(__assign({}, state.singleTemplate), { stages: __spreadArray(__spreadArray([], state.singleTemplate.stages, true), [payload], false) }) });
        }
        case ADMIN_RECEIVE_TEMPLATE_UPDATED_STAGE: {
            return __assign(__assign({}, state), { singleTemplate: __assign(__assign({}, state.singleTemplate), { stages: updateEntityArray(payload, state.singleTemplate.stages) }) });
        }
        case ADMIN_DELETE_TEMPLATE_STAGE: {
            return __assign(__assign({}, state), { singleTemplate: __assign(__assign({}, state.singleTemplate), { stages: state.singleTemplate.stages.filter(function (stage) { return stage._id !== payload; }) }) });
        }
        case ADMIN_RECEIVE_SINGLE_TEMPLATE: {
            return __assign(__assign({}, state), { singleTemplate: payload });
        }
        case ADMIN_PURGE_SINGLE_TEMPLATE: {
            return __assign(__assign({}, state), { singleTemplate: null });
        }
        case ADMIN_PURGE_FORM_ERRORS: {
            return __assign(__assign({}, state), { formErrors: null });
        }
    }
});
var updateEntityArray = function (updatedEntity, entityArrayOld) {
    var updatedEntities = __spreadArray([], entityArrayOld, true);
    var foundStageIndex = updatedEntities.findIndex(function (entity) { return entity._id === updatedEntity._id; });
    updatedEntities[foundStageIndex] = updatedEntity;
    return updatedEntities;
};
