var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import Sidebar from 'components/shared/sidebar/container';
import { balanceRequest } from '../../../../socketConnection/socketMessageRequests/balanceRequests';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
import { setT } from 'helpers/translationUtilities';
import BankingTableConfig from 'components/configs/collapseTable/bankingTable/BankingTableConfig';
import CollapsedTable from 'components/shared/tables/collapsedTable/component';
import MobBankingTableConfig from 'components/configs/collapseTable/bankingTable/MobBankingTableConfig';
import MobCollapseTable from 'components/shared/tables/mobCollapseTable/component';
ViewBanking.propTypes = {
    websocketMessage: PropTypes.func.isRequired,
    players: PropTypes.array,
    companies: PropTypes.array,
    redirect: PropTypes.func.isRequired,
    redirectToDashboard: PropTypes.func.isRequired,
    bank: PropTypes.object,
    stages: PropTypes.array,
    isBank: PropTypes.bool
};
export default function ViewBanking(_a) {
    var websocketMessage = _a.websocketMessage, _b = _a.players, players = _b === void 0 ? [] : _b, _c = _a.companies, companies = _c === void 0 ? [] : _c, redirect = _a.redirect, redirectToDashboard = _a.redirectToDashboard, _d = _a.bank, bank = _d === void 0 ? {
        balances: []
    } : _d, _e = _a.stages, stages = _e === void 0 ? [] : _e, _f = _a.isBank, isBank = _f === void 0 ? false : _f;
    var _g = useState([]), openRowIds = _g[0], setOpenRowIds = _g[1];
    var tableConfig = BankingTableConfig({
        stages: stages,
        companies: companies,
        players: players,
        balances: bank.balances,
        openRows: openRowIds,
        onItemClick: function (id) { return onExtensionClick(id); },
        onViewAccountClick: function (id) { return redirect(id); }
    });
    var mobTableConfig = MobBankingTableConfig({
        tableConfig: tableConfig,
        onGroupItemClick: function (id) { return onExtensionClick(id); }
    });
    function onExtensionClick(id) {
        var newIdsArray = __spreadArray([], openRowIds, true);
        var newIdsArrayIndex = newIdsArray.findIndex(function (presentId) { return presentId === id; });
        if (newIdsArrayIndex !== -1) {
            newIdsArray.splice(newIdsArrayIndex, 1);
        }
        else {
            newIdsArray.push(id);
        }
        setOpenRowIds(newIdsArray);
    }
    useEffect(function () {
        if (!isBank) {
            redirectToDashboard();
        }
        function requestBalances() {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = websocketMessage;
                            return [4 /*yield*/, balanceRequest('', 3)];
                        case 1:
                            _a.apply(void 0, [_b.sent()]);
                            return [2 /*return*/];
                    }
                });
            });
        }
        requestBalances();
    }, []);
    return (React.createElement("div", { className: "playerdashboard" },
        React.createElement(Sidebar, { type: SIDEBAR_MODE_PLAYER }),
        React.createElement("div", { className: "dashboard__header dashboard__header--underlined" },
            React.createElement("div", { className: "dashboard__heading" }, setT('transactions')),
            React.createElement("div", { className: "dashboard__subheading" }, setT('list_of_companies'))),
        React.createElement("div", { className: "d-none-md" },
            React.createElement(MobCollapseTable, { config: mobTableConfig, onExtensionClick: onExtensionClick })),
        React.createElement("div", { className: "d-none d-block-md" },
            React.createElement(CollapsedTable, { groups: tableConfig, tableWrapperClasses: 'collapse-table--last-icons collapse-table--side-paddings' }))));
}
