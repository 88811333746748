import React, { useState } from 'react';
import { Route, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FilterColor, Solver } from '../../../../helpers/filterColor.ts';
import hexRgb from 'hex-rgb';
NavLink.propTypes = {
    children: PropTypes.any,
    onLinkClick: PropTypes.func.isRequired,
    to: PropTypes.string,
    icon: PropTypes.string
};
export default function NavLink(_a) {
    var _b = _a.children, children = _b === void 0 ? null : _b, onLinkClick = _a.onLinkClick, _c = _a.to, to = _c === void 0 ? '/' : _c, _d = _a.icon, icon = _d === void 0 ? '' : _d;
    var isActive = to === window.location.pathname;
    var normalColor = hexRgb('#999999'); // fontColorGrey
    var normalSolver = new Solver(new FilterColor(normalColor.red, normalColor.green, normalColor.blue));
    var normalFilter = normalSolver.solve().filter;
    var activeColor = hexRgb('#03A9F4'); // primaryColor
    var activeSolver = new Solver(new FilterColor(activeColor.red, activeColor.green, activeColor.blue));
    var activeFilter = activeSolver.solve().filter;
    var _e = useState(false), isHovering = _e[0], setIsHovering = _e[1];
    return (React.createElement(Route, null,
        React.createElement(Link, { to: { pathname: to }, className: clsx(['sidebar__item', isActive && 'sidebar__item--active']), onClick: onLinkClick },
            React.createElement("div", { className: "sidebar__link" },
                React.createElement("div", { className: "sidebar__icon sidebar__icon--".concat(icon), style: { filter: isHovering || isActive ? activeFilter : normalFilter }, onMouseEnter: function () { return setIsHovering(true); }, onMouseLeave: function () { return setIsHovering(false); } }),
                React.createElement("div", { className: "sidebar__label" }, children)))));
}
