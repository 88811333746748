import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Timer from 'components/shared/header/timer/container';
import Avatar from 'components/shared/avatar/component';
import MobileSideMenu from 'components/shared/mobileSideMenu/container';
HeaderMobile.propTypes = {
    adminName: PropTypes.string,
    player: PropTypes.object,
    playerThumbnail: PropTypes.string,
    currentUserRank: PropTypes.string,
};
export default function HeaderMobile(_a) {
    var _b = _a.adminName, adminName = _b === void 0 ? '' : _b, _c = _a.player, player = _c === void 0 ? {
        company: {
            name: ''
        }
    } : _c, _d = _a.playerThumbnail, playerThumbnail = _d === void 0 ? '' : _d, currentUserRank = _a.currentUserRank;
    var _e = useState(false), isAdminPanelOpen = _e[0], setIsAdminPanelOpen = _e[1];
    var buttonRef = useRef(null);
    return (React.createElement("div", { className: "container-fluid d-flex flex-justify-content-between" },
        React.createElement("div", { className: "d-flex flex-gap-5 flex-align-items-center" },
            React.createElement(MobileSideMenu, { open: isAdminPanelOpen, setOpen: setIsAdminPanelOpen, buttonRef: buttonRef }),
            React.createElement("div", { className: "header__content" },
                React.createElement("div", { className: "header__companytitle" }, player && player.company ? player.company.name : null))),
        React.createElement("div", { className: "headerutils" },
            React.createElement("div", { className: "headerutils__item" },
                React.createElement(Timer, null)),
            React.createElement("div", { className: "headerutils__item" },
                React.createElement("div", { className: "profile" },
                    React.createElement("div", { className: "profile__inner", onClick: function () { return setIsAdminPanelOpen(!isAdminPanelOpen); }, ref: buttonRef },
                        React.createElement("span", { className: "profile__name" }, adminName || player.name)),
                    React.createElement("div", { className: "profile__image d-none d-block-md" },
                        React.createElement(Avatar, { src: adminName ? '' : playerThumbnail })))))));
}
