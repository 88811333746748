var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import Sidebar from 'components/shared/sidebar/container';
import Infobox from 'components/player/shared/infobox/component';
import Avatar from 'components/shared/avatar/component';
import { balanceRequest } from '../../../../../socketConnection/socketMessageRequests/balanceRequests';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
import { BANK_TRANSACTIONS_SUBSCRIBE, BANK_TRANSACTIONS_UNSUBSCRIBE } from 'components/views/user/banking/list/types';
import CollapsedTable from 'components/shared/tables/collapsedTable/component';
import TransactionTableConfig from 'components/configs/collapseTable/transactionTable/TransactionTableConfig';
import { isGameInShutDownState } from 'helpers/gameUtilities';
import MobTransactionTableConfig from 'components/configs/collapseTable/transactionTable/MobTransactionTableConfig';
import MobCollapseTable from 'components/shared/tables/mobCollapseTable/component';
import { setT } from 'helpers/translationUtilities';
ViewBankingTransactions.propTypes = {
    bank: PropTypes.object,
    companies: PropTypes.array,
    isBank: PropTypes.bool,
    match: PropTypes.object,
    players: PropTypes.array,
    game: PropTypes.object,
    redirect: PropTypes.func,
    redirectToDashboard: PropTypes.func,
    redirectToTransaction: PropTypes.func,
    stages: PropTypes.array,
    currentlyActiveState: PropTypes.object,
    transactions: PropTypes.array,
    websocketMessage: PropTypes.func
};
export default function ViewBankingTransactions(_a) {
    var _b = _a.bank, bank = _b === void 0 ? {} : _b, _c = _a.companies, companies = _c === void 0 ? [] : _c, _d = _a.isBank, isBank = _d === void 0 ? false : _d, _e = _a.match, match = _e === void 0 ? {} : _e, _f = _a.players, players = _f === void 0 ? [] : _f, _g = _a.game, game = _g === void 0 ? {} : _g, _h = _a.redirect, redirect = _h === void 0 ? function () { } : _h, _j = _a.redirectToDashboard, redirectToDashboard = _j === void 0 ? function () { } : _j, _k = _a.redirectToTransaction, redirectToTransaction = _k === void 0 ? function () { } : _k, _l = _a.stages, stages = _l === void 0 ? [] : _l, _m = _a.transactions, transactions = _m === void 0 ? [] : _m, _o = _a.websocketMessage, websocketMessage = _o === void 0 ? function () { } : _o, _p = _a.currentlyActiveState, currentlyActiveState = _p === void 0 ? {} : _p;
    var _q = useState([]), openRowIds = _q[0], setOpenRowIds = _q[1];
    var accountId = match.params.accountId;
    useEffect(function () {
        setOpenRowIds([currentlyActiveState.id]);
        if (!isBank) {
            redirectToDashboard();
        }
        requestBank(accountId);
        return function () {
            requestBank(null);
        };
    }, [setOpenRowIds]);
    function requestBank(id) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = websocketMessage;
                        return [4 /*yield*/, balanceRequest(id || '', id === null
                                ? BANK_TRANSACTIONS_UNSUBSCRIBE
                                : BANK_TRANSACTIONS_SUBSCRIBE)];
                    case 1:
                        _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    }
    function onExtensionClick(id) {
        var openRowIdsClone = __spreadArray([], openRowIds, true);
        var indexInStateArray = openRowIds.findIndex(function (rowId) { return rowId === id; });
        if (indexInStateArray !== -1) {
            openRowIdsClone.splice(indexInStateArray, 1);
        }
        else {
            openRowIdsClone.push(id);
        }
        setOpenRowIds(openRowIdsClone);
    }
    var playersAndCompanies = __spreadArray(__spreadArray([], players, true), companies, true);
    var baseAccount = {
        name: '',
        thumbnail: '',
        token: ''
    };
    var gameLocked = isGameInShutDownState(stages);
    var foundAccount = __assign({ baseAccount: baseAccount }, playersAndCompanies.find(function (item) {
        return item.id === accountId;
    }));
    var foundCompany = __assign({ baseAccount: baseAccount }, companies.find(function (company) {
        return company.id === foundAccount.companyID;
    }));
    var groupedTableValues = TransactionTableConfig({
        transactions: transactions,
        game: game,
        playersAndCompanies: playersAndCompanies,
        openRows: openRowIds,
        onItemClick: function (id) { return onExtensionClick(id); },
        currentAccount: accountId
    });
    var mobileTableConfig = MobTransactionTableConfig(groupedTableValues);
    return (React.createElement("div", null,
        React.createElement(Sidebar, { type: SIDEBAR_MODE_PLAYER }),
        React.createElement("div", { className: "banking-header" },
            React.createElement("div", { className: "banking-header__inner" },
                React.createElement("div", { className: "banking-header__profile" },
                    React.createElement("div", { className: "banking-header-profile__inner" },
                        React.createElement("div", { className: "banking-header-profile__image" },
                            React.createElement(Avatar, { src: foundAccount.thumbnail })),
                        React.createElement("div", { className: "banking-header-profile__name" }, foundAccount.name))),
                React.createElement("div", { className: "banking-header__transaction" },
                    React.createElement("div", { className: "f-grid flex-wrap flex-no-wrap-md" },
                        React.createElement("div", { className: "f-grid-col-12 f-grid-col-md" }, foundCompany.name ?
                            React.createElement("div", { className: "banking-header__company clearfix" },
                                React.createElement("div", { className: "banking-header-company__inner" },
                                    React.createElement("div", { className: "banking-header-company__image" },
                                        React.createElement(Avatar, { src: foundCompany.thumbnail })),
                                    React.createElement("div", { className: "banking-header-company__name" }, foundCompany.name)),
                                React.createElement("div", { className: "banking-header__code" },
                                    setT('game_code'),
                                    ": ",
                                    foundCompany.token)) : null),
                        React.createElement("div", { className: "f-grid-col-12 f-grid-col-md text-align-right d-flex flex-grow-1 flex-justify-content-flex-start flex-justify-content-flex-end-md mar-buttom-20 mar-top-20 mar-buttom-0-md mar-top-0-md flex-gap-10" },
                            React.createElement("button", { className: "main-button main-button--lg main-button--secondary", onClick: redirect }, setT('back')),
                            React.createElement("button", { className: 'main-button main-button--lg' +
                                    (gameLocked ? ' main-button--disabled' : ''), onClick: gameLocked ? null : function () { return redirectToTransaction(accountId); } }, setT('new_transaction')))),
                    React.createElement("div", { className: "banking-header-transaction__inner" },
                        React.createElement("div", { className: "f-grid" },
                            React.createElement("div", { className: "f-grid-col flex-grow-0" },
                                React.createElement(Infobox, { className: 'infobox__wrapper--no-background infobox__wrapper--no-padding', columns: [
                                        {
                                            className: 'infobox__column--primary infobox__column--border-green',
                                            label: setT('total_balance'),
                                            value: Numeral(bank.totalBalance).format('0,0.00')
                                        }
                                    ] })),
                            React.createElement("div", { className: "f-grid-col flex-grow-0" },
                                React.createElement(Infobox, { className: 'infobox__wrapper--no-background infobox__wrapper--no-padding', columns: [
                                        {
                                            className: 'infobox__column--primary infobox__column--border-green',
                                            label: setT('income'),
                                            value: Numeral(bank.incomeBalance).format('0,0.00')
                                        }
                                    ] })),
                            React.createElement("div", { className: "f-grid-col flex-grow-0" },
                                React.createElement(Infobox, { className: 'infobox__wrapper--no-background infobox__wrapper--no-padding', columns: [
                                        {
                                            className: 'infobox__column--primary infobox__column--border-green',
                                            label: setT('expenses'),
                                            value: Numeral(bank.expenseBalance).format('0,0.00')
                                        }
                                    ] }))))))),
        React.createElement("div", { className: "dashboard__header" },
            React.createElement("div", { className: "dashboard__heading" }, setT('transaction_history')),
            React.createElement("div", { className: "dashboard__subheading" }, setT('account_transactions'))),
        React.createElement("div", { className: "transactions" },
            React.createElement("div", { className: "d-none d-block-md" },
                React.createElement(CollapsedTable, { tableWrapperClasses: 'collapse-table--last-icons collapse-table--side-paddings', groups: groupedTableValues })),
            React.createElement("div", { className: "d-none-md" },
                React.createElement(MobCollapseTable, { onExtensionClick: onExtensionClick, config: mobileTableConfig })))));
}
