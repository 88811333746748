import React from 'react';
import PropTypes from 'prop-types';
var InfoboxColumn = function (props) {
    return (React.createElement("div", { className: props.className },
        React.createElement("div", { className: "infoboxes-column__inner" },
            React.createElement("div", { className: "infobox__label" }, props.label),
            React.createElement("div", { className: "infobox__footer" },
                React.createElement("div", { className: "infobox__text" }, props.value),
                props.icon ? (React.createElement("span", { className: 'icon icon--img icon--' + props.icon })) : null))));
};
InfoboxColumn.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    icon: PropTypes.string
};
InfoboxColumn.defaultProps = {
    className: '',
    label: '',
    value: '',
    icon: ''
};
export default InfoboxColumn;
