import React, { useEffect, useState } from 'react';
import Sidebar from 'components/shared/sidebar/container';
import PropTypes from 'prop-types';
import General from './general/container';
import Company from './companies/container';
import Structure from './structure/container';
import ViewSingleTemplateTasks from 'components/views/admin/templates/single/tasks/listing/container';
import { SIDEBAR_MODE_ADMIN } from 'components/constants';
ViewSingleTemplate.propTypes = {
    template: PropTypes.object,
    getTemplate: PropTypes.func.isRequired,
    resetTemplateState: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    authToken: PropTypes.string.isRequired
};
export default function ViewSingleTemplate(_a) {
    var template = _a.template, getTemplate = _a.getTemplate, resetTemplateState = _a.resetTemplateState, match = _a.match, authToken = _a.authToken;
    var params = match.params;
    var _b = useState(1), activeTab = _b[0], setActiveTab = _b[1];
    useEffect(function () {
        resetTemplateState();
        getTemplate(params.id, authToken);
    }, [authToken, getTemplate, params.id]);
    useEffect(function () {
        getActiveTab();
    }, [template]);
    function getActiveTab() {
        if (template) {
            switch (activeTab) {
                default:
                    return null;
                case 1:
                    return React.createElement(General, null);
                case 2:
                    return React.createElement(Structure, null);
                case 3:
                    return React.createElement(Company, null);
                case 4:
                    return React.createElement(ViewSingleTemplateTasks, null);
            }
        }
        return null;
    }
    function updateTab(index) {
        setActiveTab(index);
    }
    return (React.createElement("div", null,
        React.createElement(Sidebar, { type: SIDEBAR_MODE_ADMIN }),
        React.createElement("div", { className: "mar-left-80 mar-top-80" },
            React.createElement("div", { style: { maxWidth: "75em", margin: "auto", padding: "0 1em" } },
                React.createElement("div", { style: { display: "flex", flexDirection: "column", gap: "2em" } },
                    React.createElement("div", { style: { display: "flex", gap: ".5em", flexDirection: "row", alignItems: "center" } },
                        React.createElement("button", { onClick: function () { return updateTab(1); } }, "Edit Template"),
                        React.createElement("button", { onClick: function () { return updateTab(2); } }, "Structure"),
                        React.createElement("button", { onClick: function () { return updateTab(3); } }, "Companies"),
                        React.createElement("button", { onClick: function () { return updateTab(4); } }, "Tasks")),
                    getActiveTab())))));
}
