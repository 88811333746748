import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import UserNavigationMenu from 'components/manager/shared/userNavigationMenu/container';
import SetOutsideClickEvents from 'helpers/outsideClickHandler';
import { getPlayersOfACompany } from 'helpers/companyUtilities';
import Avatar from 'components/shared/avatar/component';
import AuthPermissions from 'components/permissions/component';
import { USER_RANK_ADMIN, USER_RANK_FACILITATOR } from 'components/permissions/types';
import { isPlayerAdminOrFacilitator } from 'components/player/utilities';
MobileSideMenu.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    companiesWithPlayers: PropTypes.array.isRequired,
    playerRank: PropTypes.string.isRequired,
    players: PropTypes.array,
    facilitatorRedirect: PropTypes.func,
    regularPlayerRedirect: PropTypes.func,
    redirectToFacilitator: PropTypes.func,
    logoutRequest: PropTypes.func,
    buttonRef: PropTypes.any
};
export default function MobileSideMenu(_a) {
    var _b = _a.open, open = _b === void 0 ? false : _b, setOpen = _a.setOpen, company = _a.company, companiesWithPlayers = _a.companiesWithPlayers, playerRank = _a.playerRank, players = _a.players, facilitatorRedirect = _a.facilitatorRedirect, regularPlayerRedirect = _a.regularPlayerRedirect, redirectToFacilitator = _a.redirectToFacilitator, logoutRequest = _a.logoutRequest, _c = _a.buttonRef, buttonRef = _c === void 0 ? null : _c;
    var dropdownRef = useRef(null);
    var _d = useState(null), openCompanyInMenu = _d[0], setOpenCompanyInMenu = _d[1];
    SetOutsideClickEvents(function () { return setOpen(false); }, dropdownRef, buttonRef);
    function onItemClick(id) {
        openCompanyInMenu === id ? setOpenCompanyInMenu(null) : setOpenCompanyInMenu(id);
    }
    function onPlayerClick(token, event) {
        event.stopPropagation();
        isPlayerAdminOrFacilitator(playerRank) ? facilitatorRedirect(token) : regularPlayerRedirect(token);
    }
    function getCompanyList() {
        if (!isPlayerAdminOrFacilitator(playerRank)) {
            return (React.createElement("ul", { className: "mobile-sidemenu__player-selection" }, getPlayersOfACompany(players, company === null || company === void 0 ? void 0 : company.id).map(function (player) { return (React.createElement("li", { key: player.id },
                React.createElement("div", { className: "mobile-sidemenu__player", onClick: function (e) { return onPlayerClick(player.token, e); } },
                    React.createElement("div", { className: "mar-right-10" },
                        React.createElement(Avatar, { src: player.thumbnail })),
                    React.createElement("div", null, player.name)))); })));
        }
        return (React.createElement("ul", { className: "mobile-sidemenu__company-selection" },
            companiesWithPlayers.map(function (singleCompany) { return (React.createElement("li", { key: singleCompany.id, onClick: function () { return onItemClick(singleCompany.id); } },
                React.createElement("div", { className: "mobile-sidemenu__company" },
                    React.createElement("div", { className: "mar-right-10" },
                        React.createElement(Avatar, { src: singleCompany.thumbnail })),
                    React.createElement("div", null, singleCompany.name)),
                openCompanyInMenu === singleCompany.id ?
                    React.createElement("ul", { className: "mobile-sidemenu__player-selection" }, getPlayersOfACompany(players, singleCompany === null || singleCompany === void 0 ? void 0 : singleCompany.id).map(function (player) { return (React.createElement("li", { key: player.id },
                        React.createElement("div", { className: "mobile-sidemenu__player", onClick: function (e) { return onPlayerClick(player.token, e); } },
                            React.createElement("div", { className: "mar-right-10" },
                                React.createElement(Avatar, { src: player.thumbnail })),
                            React.createElement("div", null, player.name)))); })) : null)); }),
            React.createElement(AuthPermissions, { currentUserRank: playerRank, allowedRanks: [USER_RANK_FACILITATOR, USER_RANK_ADMIN] },
                React.createElement("li", { className: "text-align-center mar-top-25" },
                    React.createElement("button", { onClick: function () { setOpen(false); redirectToFacilitator(); }, className: "main-button main-button--lg main-button--increased-paddings text-transform-uppercase text-transform-uppercase" }, "Facilitator")))));
    }
    return (React.createElement("div", { className: "mobile-sidemenu", ref: dropdownRef },
        React.createElement(CSSTransition, { in: open, timeout: 200, classNames: "anim-lefttoright", unmountOnExit: true },
            React.createElement("div", { className: "mobile-sidemenu__menu" },
                getCompanyList(),
                React.createElement("div", { className: "mobile-sidemenu__action-selection" },
                    React.createElement(UserNavigationMenu, { userRank: playerRank, logoutRequest: logoutRequest, onItemClick: function () { return setOpen(false); } }))))));
}
