import Sidebar from 'components/shared/sidebar/container';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
import React from 'react';
import NewTransaction from 'components/shared/transaction/new/container';
import PropTypes from 'prop-types';
ViewPlayerNewTransaction.propTypes = {
    match: PropTypes.object.isRequired
};
export default function ViewPlayerNewTransaction(_a) {
    var match = _a.match;
    return (React.createElement("div", { className: "playerdashboard" },
        React.createElement(Sidebar, { type: SIDEBAR_MODE_PLAYER }),
        React.createElement(NewTransaction, { type: match.params.type, accountId: match.params.accountId })));
}
