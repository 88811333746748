import SocketService from '../SocketService';
export var transactionRequestNew = function (data) {
    return SocketService.createEncodedMessage({
        kind: 'transaction',
        transaction: {
            amount: Number(data.amount),
            message: data.message,
            to: data.to,
            from: data.from,
            type: data.type,
            kind: data.kind
        }
    });
};
export var bulkTransactionRequest = function (data) {
    return SocketService.createEncodedMessage({
        kind: 'bulkTransactionAction',
        bulkTransactionAction: {
            amount: Number(data.amount),
            message: data.message,
            to: data.to,
            from: data.from,
            type: data.type,
            kind: data.kind,
        },
    });
};
