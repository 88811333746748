import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TemplateEntityEditingModal from 'components/views/admin/templates/single/templateEntityEditingModal/component';
import CompanyForm from 'components/views/admin/templates/single/companies/companyForm/container';
import TemplateConfirmationModal from 'components/views/admin/templates/templateConfirmationModal/component';
CompanyListing.propTypes = {
    template: PropTypes.object,
    removeCompany: PropTypes.func.isRequired
};
export default function CompanyListing(_a) {
    var _b = _a.template, template = _b === void 0 ? null : _b, removeCompany = _a.removeCompany;
    var _c = useState(false), modalOpen = _c[0], setModalOpen = _c[1];
    var _d = useState(false), confirmModalOpen = _d[0], setConfirmModalOpen = _d[1];
    var _e = useState(null), openId = _e[0], setOpenId = _e[1];
    function onCompanyModify(id) {
        setModalOpen(true);
        setOpenId(id);
    }
    function onCompanyConfirmDelete() {
        removeCompany(openId, template._id);
        setOpenId(null);
        setConfirmModalOpen(false);
    }
    function onCompanyDeleteClick(id) {
        setOpenId(id);
        setConfirmModalOpen(true);
    }
    function onConfirmModalClose() {
        setOpenId(null);
        setConfirmModalOpen(false);
    }
    function onModalClose() {
        setOpenId(null);
        setModalOpen(false);
    }
    return (React.createElement("div", null,
        React.createElement("ul", { className: "list-styles-clear" }, template.companies.map(function (company, index) { return (React.createElement("li", { className: "mar-bottom-5 mar-top-5", key: company._id },
            React.createElement("span", { style: { display: "inline-block", width: 300 } },
                index + 1,
                ".)\u00A0",
                company.name[0].value,
                company.isBank && "(Bank)",
                !company.visibility && "(Nähtamatu)"),
            React.createElement("button", { className: "main-button mar-left-10 mar-right-10", onClick: function () { return onCompanyModify(company._id); } }, "Muuda"),
            React.createElement("button", { className: "main-button main-button--error", onClick: function () { return onCompanyDeleteClick(company._id); } }, "Kustuta"))); })),
        React.createElement("button", { className: "main-button", onClick: function () { return setModalOpen(true); } }, "Add company"),
        React.createElement(TemplateEntityEditingModal, { stageEditModalOpen: modalOpen, onModalClose: function () { return onModalClose(); } },
            React.createElement(CompanyForm, { company: template.companies.find(function (company) { return company._id === openId; }), onModalClose: function () { return onModalClose(); } })),
        React.createElement(TemplateConfirmationModal, { modalOpen: confirmModalOpen, onModalClose: function () { return onConfirmModalClose(); } },
            React.createElement("div", null,
                React.createElement("h2", null,
                    "Oled kindel et soovid kustutada firma ",
                    getCompanyTitle(openId, template.companies),
                    "?"),
                React.createElement("div", { className: "d-flex mar-top-20 justify-content-center flex-gap-10" },
                    React.createElement("button", { className: "main-button", onClick: function () { return onConfirmModalClose(); } }, "Sulge"),
                    React.createElement("button", { className: "main-button main-button--error", onClick: function () { return onCompanyConfirmDelete(); } }, "Kinnita"))))));
}
var getCompanyTitle = function (id, companies) {
    var company = companies.find(function (company) { return company._id === id; });
    if (company && company.name[0]) {
        return company.name[0].value;
    }
    return '';
};
