var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from 'react';
import Avatar from 'components/shared/avatar/component';
import { onboardingRequestCompanyJoin } from '../../../socketConnection/socketMessageRequests/onboardingRequests';
import { setT } from 'helpers/translationUtilities';
import { playerRequestAddPlayer } from '../../../socketConnection/socketMessageRequests/playerRequests';
import { useTranslation } from 'react-i18next';
import CustomToast from 'components/shared/toast/component';
import { usePrevious } from 'helpers/utilityHooks';
import clsx from 'clsx';
export default function PlayerAllocating(_a) {
    var _this = this;
    var _b = _a.unAllocatedPlayers, unAllocatedPlayers = _b === void 0 ? [] : _b, _c = _a.companyPlayers, companyPlayers = _c === void 0 ? [] : _c, _d = _a.websocketMessage, websocketMessage = _d === void 0 ? function () { } : _d, _e = _a.players, players = _e === void 0 ? [] : _e, _f = _a.company, company = _f === void 0 ? null : _f, _g = _a.redirectToPlayerDashboard, redirectToPlayerDashboard = _g === void 0 ? function () { } : _g, _h = _a.joinGameWithToken, joinGameWithToken = _h === void 0 ? function () { } : _h, _j = _a.playerId, playerId = _j === void 0 ? null : _j;
    var inputRef = useRef(null);
    var t = useTranslation().t;
    var _k = useState(false), waitForToast = _k[0], setWaitForToast = _k[1];
    var _l = useState(''), toastMessage = _l[0], setToastMessage = _l[1];
    var prevPlayers = usePrevious(players) || [];
    useEffect(function () {
        if (waitForToast && players.length > prevPlayers.length) {
            CustomToast().success({
                position: 'TOP_CENTER',
                time: 3000,
                message: toastMessage
            });
        }
    }, [players, toastMessage]);
    function onUnallocatedPlayerClick(player) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = websocketMessage;
                        return [4 /*yield*/, onboardingRequestCompanyJoin((company === null || company === void 0 ? void 0 : company.id) || '', [player.id], false)];
                    case 1:
                        _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    }
    var onPlayerAddSubmit = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var username, companyName, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    e.preventDefault();
                    username = e.target.elements.username.value;
                    companyName = company ? company.name : setT('unset_company');
                    if (!username) return [3 /*break*/, 2];
                    setToastMessage("".concat(t('player'), " ").concat(username, " ").concat(t('added_to_company'), " ").concat(companyName));
                    setWaitForToast(true);
                    inputRef.current.value = '';
                    _a = websocketMessage;
                    return [4 /*yield*/, playerRequestAddPlayer(username, (company === null || company === void 0 ? void 0 : company.id) || '')];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    inputRef.current.blur();
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    function onConfirmClick() {
        if (company && companyPlayers.length) {
            playerId ? redirectToPlayerDashboard() : joinGameWithToken(companyPlayers[0].token);
        }
    }
    return (React.createElement("div", { className: "player-allocating" },
        React.createElement("div", { className: "f-grid h-100-md flex-direction-column-reverse flex-direction-row-md" },
            React.createElement("div", { className: "f-grid-col-12 f-grid-col-md-6" },
                React.createElement("div", { className: "player-allocating__boxwrap" },
                    React.createElement("div", { className: "main-title" }, setT('select_players')),
                    React.createElement("div", { className: clsx([
                            'player-allocating__box player-allocating-box--unlocated',
                            !unAllocatedPlayers.length && 'player-allocating__box--unassgined'
                        ]) }, getPlayerList(unAllocatedPlayers, function (player) { return onUnallocatedPlayerClick(player); }, t('no_free_players'))))),
            React.createElement("div", { className: "f-grid-col-12 f-grid-col-md-6" },
                React.createElement("div", { className: "player-allocating__boxwrap" },
                    React.createElement("div", { className: "main-title" }, (company === null || company === void 0 ? void 0 : company.name) || setT('unset_company')),
                    React.createElement("div", { className: clsx([
                            'player-allocating__box player-allocating__box--placed',
                            !companyPlayers.length && 'player-allocating__box--unassgined'
                        ]) }, getPlayerList(companyPlayers, function () { }, t('no_company_players'))),
                    React.createElement("div", null,
                        React.createElement("div", { className: "main-title mar-top-15" }, setT('add_new_player')),
                        React.createElement("form", { className: "d-flex-sm pad-left-5 pad-right-5 pad-left-20-md pad-right-20-md flex-gap-15 text-align-center", onSubmit: onPlayerAddSubmit },
                            React.createElement("input", { className: "main-input flex-grow-1 w-100", type: "text", name: "username", ref: inputRef, placeholder: t('enter_name') }),
                            React.createElement("button", { type: "submit", className: "main-button main-button--lg d-none d-block-md" }, setT('add')))),
                    React.createElement("div", { className: "d-flex flex-justify-content-center mar-top-20 flex-gap-15" },
                        React.createElement("button", { className: "main-button main-button--lg d-none d-inline-block-md", onClick: function () { return onConfirmClick(); } }, setT('ok')),
                        React.createElement("button", { className: "main-button main-button--lg mob-player-action-button mob-player-action-button--to-bottom", onClick: function () { return onConfirmClick(); } }, setT('ok'))))))));
}
function getPlayerList(players, onPlayerClick, noPlayersMsg) {
    if (!players.length) {
        return (React.createElement("div", { className: "player-allocating__label" }, noPlayersMsg));
    }
    return (React.createElement("ul", { className: "list-styles-clear pad-10 pad-20-md" }, players.map(function (player) { return (React.createElement("li", { className: "d-flex flex-align-items-center cursor-pointer mar-top-10 mar-bottom-10 mar-top-20-md mar-bottom-20-md", key: player.id, onClick: function () { return onPlayerClick(player); } },
        React.createElement("div", { className: "mar-right-15" },
            React.createElement(Avatar, { src: player.thumbnail })),
        React.createElement("span", null, player.name))); })));
}
