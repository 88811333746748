import { connect } from 'react-redux';
import Component from './component';
import { adminRemoveStageAction, adminUpdateStageAction } from '../../../../../../redux/actions/admin/admin.stage.actions';
var mapStateToProps = function (state) {
    return {
        template: state.admin.singleTemplate
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        updateStage: function (id, payload) { return dispatch(adminUpdateStageAction(id, payload)); },
        removeStage: function (id) { return dispatch(adminRemoveStageAction(id)); },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
