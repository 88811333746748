import { Router, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import WrapperItems from 'components/WrappedItems';
import ViewTokenAuthentication from 'components/views/public/token/container';
import ViewLogin from 'components/views/public/login/container';
import ViewGames from 'components/views/admin/games/component';
import ViewFacilitatorNewGame from 'components/views/facilitator/newGame/container';
import ViewSingleTemplate from 'components/views/admin/templates/single/container';
import ViewTemplateListing from 'components/views/admin/templates/listing/container';
import ViewAdminUsers from 'components/views/admin/users/container';
import ViewAdminDashboard from 'components/views/admin/dashboard/component';
import ViewSingleTemplateTaskEdit from 'components/views/admin/templates/single/tasks/single/container';
import ViewApiAuthentication from 'components/views/public/api/container';
import ViewURLAuthentication from 'components/views/public/url/container';
import { AuthRoute } from 'components/routers/AuthRoute';
import { USER_RANK_ADMIN, USER_RANK_FACILITATOR } from 'components/permissions/types';
import { ADMIN_PATHS } from 'components/routers/constants';
export var generatePathWithParams = function (templatePath, params) {
    if (params === void 0) { params = {}; }
    var path = templatePath;
    Object.keys(params).forEach(function (key) {
        path = path.replace(":".concat(key), params[key]);
    });
    return path;
};
var router = function (_a) {
    var history = _a.history, rank = _a.rank;
    var isManagerRank = [USER_RANK_ADMIN, USER_RANK_FACILITATOR].includes(rank);
    return (React.createElement(Router, { history: history },
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: "/new-game", component: ViewFacilitatorNewGame }),
            React.createElement(Route, { path: "/token", component: ViewTokenAuthentication }),
            React.createElement(Route, { exact: true, path: "/api/:token", component: ViewApiAuthentication }),
            React.createElement(Route, { exact: true, path: "/login/:token/game/:gameToken", component: ViewApiAuthentication }),
            React.createElement(Route, { exact: true, path: "/login/:token", component: ViewApiAuthentication }),
            React.createElement(Route, { exact: true, path: "/login", component: ViewLogin }),
            React.createElement(Route, { exact: true, path: "/join/:gameToken", component: ViewURLAuthentication }),
            React.createElement(Route, { exact: true, path: "/facilitatorjoin/:pathing/:gameToken", component: ViewURLAuthentication }),
            React.createElement(AuthRoute, { isAuthenticated: isManagerRank, path: "/games", component: ViewGames }),
            React.createElement(AuthRoute, { isAuthenticated: isManagerRank, exact: true, path: ADMIN_PATHS.TEMPLATE_TASK, component: ViewSingleTemplateTaskEdit }),
            React.createElement(AuthRoute, { isAuthenticated: isManagerRank, exact: true, path: "/admin/templates/:id", component: ViewSingleTemplate }),
            React.createElement(AuthRoute, { isAuthenticated: isManagerRank, exact: true, path: "/admin/templates", component: ViewTemplateListing }),
            React.createElement(AuthRoute, { isAuthenticated: isManagerRank, exact: true, path: "/admin/users", component: ViewAdminUsers }),
            React.createElement(AuthRoute, { isAuthenticated: isManagerRank, exact: true, path: "/admin", component: ViewAdminDashboard }),
            React.createElement(Route, { component: WrapperItems }))));
};
router.propTypes = {
    history: PropTypes.object.isRequired,
    rank: PropTypes.string.isRequired,
};
export default router;
