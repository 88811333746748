var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import AuthWrapper from '../../../auth/component';
import PropTypes from 'prop-types';
import AuthLogo from '../../../auth/shared/logo/component';
import ApiService from '../../../../services/ApiService/ApiService';
import { setT } from 'helpers/translationUtilities';
import { useTranslation } from 'react-i18next';
ViewTokenAuthentication.propTypes = {
    redirectToJoin: PropTypes.func.isRequired,
    redirectToLogin: PropTypes.func.isRequired,
    falseLogin: PropTypes.bool.isRequired,
};
export default function ViewTokenAuthentication(_a) {
    var redirectToJoin = _a.redirectToJoin, redirectToLogin = _a.redirectToLogin, falseLogin = _a.falseLogin;
    var _b = useState(null), token = _b[0], setToken = _b[1];
    var t = useTranslation().t;
    function formSubmitHandler(e) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        return [4 /*yield*/, ApiService.getWebsocketAddress(token)];
                    case 1:
                        response = _a.sent();
                        if (response && response.host) {
                            window.globals.WS_URL = response.host;
                            redirectToJoin(token);
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    return (React.createElement(AuthWrapper, null,
        React.createElement("form", { onSubmit: formSubmitHandler, className: "token-authentication clear" },
            React.createElement("div", { className: "token-authentication__inner" },
                React.createElement(AuthLogo, null),
                falseLogin ? (React.createElement("div", { className: "general-error" }, setT('wrong_token'))) : (''),
                React.createElement("div", { className: "token-authentication__text" }, setT('submit_gamecode_to_enter')),
                React.createElement("div", { className: "token-authentication__input" },
                    React.createElement("input", { className: "basic-input", placeholder: t('game_code'), type: "text", name: "token", onChange: function (e) { return setToken(e.target.value); } })),
                React.createElement("div", { className: "token-authentication__input" },
                    React.createElement("button", { className: "main-button main-button--fullwidth text-align-center" }, setT('play'))),
                React.createElement("div", { className: "token-authentication__bottom" },
                    React.createElement("div", { className: "token-authentication__footer" },
                        setT('have_an_account'),
                        React.createElement("span", { onClick: redirectToLogin }, setT('login'))))))));
}
