import { connect } from 'react-redux';
import Component from './component';
import { websocketConnectAction, websocketDisconnectAction } from '../../redux/actions/shared.actions';
import { UI_OPEN_STAGE_INFO_BAR, WEBSOCKET_MESSAGE, UI_WS_CONNECTION_ATTEMPT_MADE } from 'components/constants';
import { userAuthRefreshTokenRequestAction } from '../../redux/actions/userAuthorization.actions';
import { gameJoinAction } from '../../redux/actions/game.actions';
var mapStateToProps = function (state) {
    return {
        token: state.playerAuthorization.token,
        showStageInfoBar: state.userInterface.showStageInfoBar,
        stages: state.game.stages,
        currentUserRank: state.userAuthorization.userSession.rank,
        authToken: state.userAuthorization.token,
        wsConnectionEstablished: state.userInterface.wsConnectionEstablished,
        wsConnectionAttemptMade: state.userInterface.wsConnectionAttemptMade,
        falseLogin: state.playerAuthorization.falseLogin,
        game: state.game
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketConnection: function (token) { return dispatch(websocketConnectAction(token)); },
        websocketDisconnect: function () { return dispatch(websocketDisconnectAction()); },
        websocketMessage: function (msg) { return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg }); },
        gameJoin: function (token) { return dispatch(gameJoinAction(token)); },
        autoOpenGameOverlay: function () { return dispatch({ type: UI_OPEN_STAGE_INFO_BAR }); },
        refreshTokenRequest: function (authToken) { return dispatch(userAuthRefreshTokenRequestAction(authToken)); },
        setWsConnectionAttemptMade: function (payload) { return dispatch({ type: UI_WS_CONNECTION_ATTEMPT_MADE, payload: payload }); },
        redirectToToken: function () { return dispatch({ type: 'ROUTE', to: '/token' }); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
