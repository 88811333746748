import { PLAYER_RECEIVE_TASK, PLAYER_RECEIVE_TICKET_LIST } from 'components/constants';
export var taskTypes = {
    taskListing: {
        type: PLAYER_RECEIVE_TICKET_LIST,
        payload: function (payload) { return payload.taskListing; }
    },
    task: {
        type: PLAYER_RECEIVE_TASK,
        payload: function (payload) { return payload.task; }
    }
};
