import { connect } from 'react-redux';
import { WEBSOCKET_MESSAGE, PLAYER_UPDATE_COMPANY_INFORMATION } from 'components/constants';
import Component from './component';
var mapStateToProps = function (state) {
    return {
        companies: state.general.companies,
        players: state.general.players,
        player: state.player,
        token: state.playerAuthorization.token,
        company: state.player.company
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketMessage: function (msg) {
            return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg });
        },
        updateActiveCompany: function (payload) {
            return dispatch({ type: PLAYER_UPDATE_COMPANY_INFORMATION, payload: payload });
        },
        redirectToOnboardingPlayers: function (id) {
            return dispatch({ type: 'ROUTE', to: "/onboarding/players/".concat(id) });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
