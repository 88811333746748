import { GENERAL_RECEIVE_COMPANIES, GENERAL_RECEIVE_PLAYERS } from 'components/constants';
export var dataListingTypes = {
    dataListing: [
        {
            type: GENERAL_RECEIVE_PLAYERS,
            payload: function (payload) { return payload.dataListing.players; }
        },
        {
            type: GENERAL_RECEIVE_COMPANIES,
            payload: function (payload) { return payload.dataListing.companies; }
        }
    ]
};
