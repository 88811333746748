import React, { Fragment } from 'react';
import Infobox from 'components/player/shared/infobox/component';
import PropTypes from 'prop-types';
import { setT } from 'helpers/translationUtilities';
TaskCounters.propTypes = {
    counters: PropTypes.object.isRequired
};
export default function TaskCounters(_a) {
    var counters = _a.counters;
    var tasksAssigned = counters.tasksAssigned, tasksCompleted = counters.tasksCompleted, tasksSkipped = counters.tasksSkipped, tasksInProgress = counters.tasksInProgress;
    return (React.createElement(Fragment, null,
        desktopTaskCounters({ tasksAssigned: tasksAssigned, tasksInProgress: tasksInProgress, tasksCompleted: tasksCompleted, tasksSkipped: tasksSkipped }),
        mobileTaskCounters({ tasksAssigned: tasksAssigned, tasksInProgress: tasksInProgress, tasksCompleted: tasksCompleted, tasksSkipped: tasksSkipped })));
}
function mobileTaskCounters(_a) {
    var tasksAssigned = _a.tasksAssigned, tasksInProgress = _a.tasksInProgress, tasksCompleted = _a.tasksCompleted, tasksSkipped = _a.tasksSkipped;
    return (React.createElement("div", { className: "mob-transactions-details d-none-md" },
        React.createElement("div", { className: "mob-transactions-details__item" },
            React.createElement("div", { className: "mob-transactions-details__label underline-colored-label underline-colored-label--timeline-color" }, setT('assigned')),
            React.createElement("div", { className: "mob-transactions-details__values" }, tasksAssigned)),
        React.createElement("div", { className: "mob-transactions-details__item" },
            React.createElement("div", { className: "mob-transactions-details__label underline-colored-label underline-colored-label--primary-color" }, setT('in_progress')),
            React.createElement("div", { className: "mob-transactions-details__values" }, tasksInProgress)),
        React.createElement("div", { className: "mob-transactions-details__item" },
            React.createElement("div", { className: "mob-transactions-details__label underline-colored-label underline-colored-label--green" }, setT('done')),
            React.createElement("div", { className: "mob-transactions-details__values" }, tasksCompleted)),
        React.createElement("div", { className: "mob-transactions-details__item" },
            React.createElement("div", { className: "mob-transactions-details__label underline-colored-label underline-colored-label--red" }, setT('skipped')),
            React.createElement("div", { className: "mob-transactions-details__values" }, tasksSkipped))));
}
function desktopTaskCounters(_a) {
    var tasksAssigned = _a.tasksAssigned, tasksInProgress = _a.tasksInProgress, tasksCompleted = _a.tasksCompleted, tasksSkipped = _a.tasksSkipped;
    return (React.createElement("div", { className: "d-none d-block-md" },
        React.createElement("div", { className: "f-grid" },
            React.createElement("div", { className: "f-grid-col" },
                React.createElement(Infobox, { className: 'infobox__wrapper--sm', columns: [
                        {
                            className: 'infobox__column--primary',
                            icon: 'task_colored',
                            label: setT('tasks_assigned'),
                            value: tasksAssigned
                        }
                    ] })),
            React.createElement("div", { className: "f-grid-col" },
                React.createElement(Infobox, { className: 'infobox__wrapper--sm', columns: [
                        {
                            className: 'infobox__column--primary',
                            icon: 'task_colored',
                            label: setT('tasks_in_progress'),
                            value: tasksInProgress
                        }
                    ] })),
            React.createElement("div", { className: "f-grid-col" },
                React.createElement(Infobox, { className: 'infobox__wrapper--sm', columns: [
                        {
                            className: 'infobox__column--primary',
                            icon: 'task_colored',
                            label: setT('completed'),
                            value: tasksCompleted
                        }
                    ] })),
            React.createElement("div", { className: "f-grid-col" },
                React.createElement(Infobox, { className: 'infobox__wrapper--sm', columns: [
                        {
                            className: 'infobox__column--primary',
                            icon: 'skip',
                            label: setT('skipped'),
                            value: tasksSkipped
                        }
                    ] })))));
}
