import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/shared/avatar/component';
import moment from 'moment';
import Timer from 'components/shared/header/timer/container';
import HeaderDropdown from 'components/shared/header/dropdown/container';
HeaderDesktop.propTypes = {
    elementRef: PropTypes.object.isRequired,
    adminName: PropTypes.string,
    player: PropTypes.object.isRequired,
    playerThumbnail: PropTypes.string,
    companyThumbnail: PropTypes.string,
    game: PropTypes.object.isRequired,
    headerOpen: PropTypes.bool.isRequired,
    setHeaderOpen: PropTypes.func.isRequired
};
export default function HeaderDesktop(_a) {
    var elementRef = _a.elementRef, _b = _a.adminName, adminName = _b === void 0 ? '' : _b, player = _a.player, _c = _a.playerThumbnail, playerThumbnail = _c === void 0 ? '' : _c, _d = _a.companyThumbnail, companyThumbnail = _d === void 0 ? '' : _d, game = _a.game, headerOpen = _a.headerOpen, setHeaderOpen = _a.setHeaderOpen;
    return (React.createElement("div", { className: "container-fluid" },
        companyThumbnail ? (React.createElement("div", { className: "header__side" },
            React.createElement(Avatar, { position: "avatar--absolute", src: companyThumbnail }))) : null,
        React.createElement("div", { className: "header__content" },
            React.createElement("div", null,
                React.createElement("div", { className: "header__companytitle" }, player && player.company ? player.company.name : null)),
            React.createElement("div", { className: "header__center-wrapper text-center" },
                React.createElement("div", { className: "header__gameinfo" },
                    React.createElement("strong", null,
                        game.name,
                        " ",
                        moment.unix(game.startAt).format('DD.MM.YYYY'))),
                React.createElement("div", null, game.token)),
            React.createElement("div", { className: "headerutils" },
                React.createElement("div", { className: "headerutils__item" },
                    React.createElement(Timer, null)),
                React.createElement("div", { className: "headerutils__item" },
                    React.createElement("div", { className: "profile", ref: elementRef, onClick: function () { setHeaderOpen(!headerOpen); } },
                        React.createElement("div", { className: "profile__inner" },
                            React.createElement("span", { className: "profile__name" }, adminName || player.name),
                            React.createElement("span", { className: "profile__chevron" })),
                        React.createElement("div", { className: "profile__image" },
                            React.createElement(Avatar, { src: adminName ? '' : playerThumbnail })),
                        React.createElement(HeaderDropdown, { open: headerOpen, setOpen: setHeaderOpen, profileRef: elementRef })))))));
}
