import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import Sidebar from 'components/shared/sidebar/container';
import PlayersSidebar from 'components/player/shared/players-sidebar/container';
import Infobox from 'components/player/shared/infobox/component';
import Transactions from '../../../player/money/transactions/list/container';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
import clsx from 'clsx';
import { isGameInShutDownState } from 'helpers/gameUtilities';
import { setT } from 'helpers/translationUtilities';
ViewMoney.propTypes = {
    company: PropTypes.object,
    player: PropTypes.object,
    redirectToPayment: PropTypes.func,
    redirectToMoney: PropTypes.func,
    stages: PropTypes.array,
    match: PropTypes.object.isRequired
};
export default function ViewMoney(_a) {
    var _b = _a.company, company = _b === void 0 ? {
        incomeBalance: 0,
        expenseBalance: 0,
        totalBalance: 0,
        transactions: []
    } : _b, _c = _a.player, player = _c === void 0 ? null : _c, _d = _a.redirectToPayment, redirectToPayment = _d === void 0 ? function () { } : _d, _e = _a.redirectToMoney, redirectToMoney = _e === void 0 ? function () { } : _e, _f = _a.stages, stages = _f === void 0 ? [] : _f, match = _a.match;
    var _g = match.params, type = _g.type, accountId = _g.accountId;
    var gameLocked = isGameInShutDownState(stages);
    var income = Numeral(player.incomeBalance).format('0,0.00');
    var expense = Numeral(player.expenseBalance).format('0,0.00');
    var total = Numeral(player.totalBalance).format('0,0.00');
    var transactionType = setT('player');
    if (type === 'company') {
        income = Numeral(company.incomeBalance).format('0,0.00');
        expense = Numeral(company.expenseBalance).format('0,0.00');
        total = Numeral(company.totalBalance).format('0,0.00');
        transactionType = setT('business');
    }
    useEffect(function () {
        if (accountId !== player.id && accountId !== player.company.id) {
            var relevantAccount = type === 'player' ? player.id : player.company.id;
            redirectToMoney(type, relevantAccount);
        }
    }, [player]);
    return (React.createElement("div", { className: "playerdashboard playerdashboard--with-players-sidebar" },
        React.createElement(Sidebar, { type: SIDEBAR_MODE_PLAYER }),
        React.createElement(PlayersSidebar, null),
        desktopTransactionDetails({ income: income, expense: expense, total: total, gameLocked: gameLocked, redirectToPayment: redirectToPayment, type: type, accountId: accountId }),
        mobileTransactionDetails({ income: income, expense: expense, total: total }),
        React.createElement("button", { className: clsx(['main-button mob-player-action-button', gameLocked && 'main-button--disabled']), onClick: gameLocked ? null : function () { return redirectToPayment(type, accountId); } }, setT('new_transaction')),
        React.createElement("div", { className: "infobox__header" },
            React.createElement("div", { className: "infobox__heading" }, setT('transaction_history')),
            React.createElement("div", { className: "infobox__subheading" },
                transactionType,
                " ",
                setT('transactions'))),
        React.createElement(Transactions, { type: type })));
}
function mobileTransactionDetails(_a) {
    var income = _a.income, expense = _a.expense, total = _a.total;
    return (React.createElement("div", { className: "mob-transactions-details d-none-md" },
        React.createElement("div", { className: "mob-transactions-details__item" },
            React.createElement("div", { className: "mob-transactions-details__label underline-colored-label underline-colored-label--timeline-color" }, setT('total_balance')),
            React.createElement("div", { className: "mob-transactions-details__values" }, total)),
        React.createElement("div", { className: "mob-transactions-details__item" },
            React.createElement("div", { className: "mob-transactions-details__label underline-colored-label underline-colored-label--green" }, setT('income')),
            React.createElement("div", { className: "mob-transactions-details__values" }, income)),
        React.createElement("div", { className: "mob-transactions-details__item" },
            React.createElement("div", { className: "mob-transactions-details__label underline-colored-label underline-colored-label--red" }, setT('expenses')),
            React.createElement("div", { className: "mob-transactions-details__values" }, expense))));
}
function desktopTransactionDetails(_a) {
    var income = _a.income, expense = _a.expense, total = _a.total, gameLocked = _a.gameLocked, redirectToPayment = _a.redirectToPayment, type = _a.type, accountId = _a.accountId;
    return (React.createElement("div", { className: "d-none d-block-md" },
        React.createElement("div", { className: "f-grid flex-align-items-center flex-justify-content-center" },
            React.createElement("div", { className: "f-grid-col f-grid" },
                React.createElement("div", { className: "f-grid-col-4" },
                    React.createElement(Infobox, { className: 'infobox__wrapper--sm h-100', columns: [
                            {
                                className: 'infobox__column--primary infobox__column--border-green',
                                icon: 'task_colored',
                                label: setT('total_balance'),
                                value: "".concat(total)
                            }
                        ] })),
                React.createElement("div", { className: "f-grid-col-4" },
                    React.createElement(Infobox, { className: 'infobox__wrapper--sm h-100', columns: [
                            {
                                className: 'infobox__column--primary infobox__column--border-green',
                                label: setT('income'),
                                value: "".concat(income)
                            }
                        ] })),
                React.createElement("div", { className: "f-grid-col-4" },
                    React.createElement(Infobox, { className: 'infobox__wrapper--sm h-100', columns: [
                            {
                                className: 'infobox__column--primary infobox__column--border-green',
                                label: setT('expenses'),
                                value: "".concat(expense)
                            }
                        ] }))),
            React.createElement("div", { className: "f-grid-col flex-grow-0 flex-shrink-1" },
                React.createElement("button", { className: clsx([
                        'main-button main-button--lg-fullwidth',
                        gameLocked && 'main-button--disabled'
                    ]), onClick: gameLocked ? null : function () { return redirectToPayment(type, accountId); } }, setT('new_transaction'))))));
}
