import { connect } from 'react-redux';
import Component from './component';
import { gameJoinAction } from '../../../../redux/actions/game.actions';
var mapStateToProps = function (state) {
    return {
        user: {
            username: state.userAuthorization ? state.userAuthorization.userSession.userName : null,
            id: state.userAuthorization ? state.userAuthorization.userSession.id : null
        },
        company: {
            id: state.player.company.id,
            name: state.player.company.name,
            totalBalance: state.player.company.totalBalance,
            transactions: state.player.company.transactions
        }
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirect: function (url) {
            return dispatch({
                type: 'ROUTE',
                to: url
            });
        },
        gameJoin: function (token) { return dispatch(gameJoinAction(token)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
