var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import Sidebar from 'components/shared/sidebar/container';
import { SIDEBAR_MODE_FACILITATOR } from 'components/constants';
import GamesListing from 'components/manager/facilitator/gamesListing/container';
var ViewFacilitatorGames = /** @class */ (function (_super) {
    __extends(ViewFacilitatorGames, _super);
    function ViewFacilitatorGames() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ViewFacilitatorGames.prototype.shouldComponentUpdate = function () {
        return true;
    };
    ViewFacilitatorGames.prototype.render = function () {
        return (React.createElement("div", null,
            React.createElement(Sidebar, { type: SIDEBAR_MODE_FACILITATOR }),
            React.createElement(GamesListing, { isFacilitatorOrigin: true })));
    };
    ViewFacilitatorGames.propTypes = {
        games: PropTypes.array
    };
    ViewFacilitatorGames.defaultProps = {
        games: []
    };
    return ViewFacilitatorGames;
}(Component));
export default ViewFacilitatorGames;
