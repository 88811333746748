var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import PropTypes from 'prop-types';
var Auth = /** @class */ (function (_super) {
    __extends(Auth, _super);
    function Auth() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Auth.prototype.shouldComponentUpdate = function () {
        return true;
    };
    Auth.prototype.render = function () {
        return (React.createElement("div", { className: "wrapper wrapper--alternate wrapper--main-bg" },
            React.createElement("div", { className: "container-fluid" }, this.props.children)));
    };
    Auth.propTypes = {
        children: PropTypes.object
    };
    Auth.defaultProps = {
        children: null
    };
    return Auth;
}(Component));
export default Auth;
