import { connect } from 'react-redux';
import Component from './component';
import { userAuthLogoutRequestAction } from '../../../../redux/actions/userAuthorization.actions';
var mapStateToProps = function (state) {
    return {
        userSession: state.userAuthorization.userSession
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirect: function () { return dispatch({ type: 'ROUTE', to: '/token' }); },
        logoutRequest: function () { return dispatch(userAuthLogoutRequestAction()); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
