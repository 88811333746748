import { connect } from 'react-redux';
import Component from './component';
import { PURGE_ALL_APP_DATA } from 'components/constants';
import { getGamesAction } from '../../../../redux/actions/shared.actions';
import { adminSelectGameAction } from '../../../../redux/actions/admin/admin.actions';
var mapStateToProps = function (state) {
    return {
        userAuthorization: state.userAuthorization,
        games: state.general.games,
        gamesPaginationMaxPages: state.userInterface.gamesPaginationMaxPages,
        gamesListRequested: state.userInterface.gamesListRequested
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        getGames: function (authToken, params) { return dispatch(getGamesAction(authToken, params)); },
        selectGame: function (id) { return dispatch(adminSelectGameAction(id)); },
        logoutFromApp: function () {
            dispatch({ type: PURGE_ALL_APP_DATA });
            dispatch({ to: '/login', type: 'ROUTE' });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
