import React from 'react';
import Modal from 'components/player/shared/modal/component';
import PropTypes from 'prop-types';
import { setT } from 'helpers/translationUtilities';
GameStageConfirmation.propTypes = {
    onAcceptClick: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
    show: PropTypes.bool
};
export default function GameStageConfirmation(_a) {
    var onAcceptClick = _a.onAcceptClick, onCancelClick = _a.onCancelClick, onModalClose = _a.onModalClose, _b = _a.show, show = _b === void 0 ? false : _b;
    return (React.createElement(Modal, { modalClasses: 'modal--error', modalInnerClasses: 'modal__inner--sm', expanded: show, onClose: function () { return onModalClose(); } },
        React.createElement("div", { className: "text-align-center color-font-dark mar-bottom-20", style: { fontSize: '20px' } },
            React.createElement("b", null, setT('are_you_sure'))),
        React.createElement("div", { className: "d-flex flex-justify-content-center flex-gap-15" },
            React.createElement("button", { className: "main-button main-button--lg main-button--error", onClick: function () { return onCancelClick(); } }, setT("no")),
            React.createElement("button", { className: "main-button  main-button--lg", onClick: function () { return onAcceptClick(); } }, setT("yes")))));
}
