import { connect } from 'react-redux';
import Component from './component';
var mapStateToProps = function (state) {
    return {
        falseLogin: state.playerAuthorization.falseLogin,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirectToJoin: function (gameToken) {
            return dispatch({
                type: 'ROUTE',
                to: "/join/".concat(gameToken)
            });
        },
        redirectToLogin: function () {
            return dispatch({
                type: 'ROUTE',
                to: '/login'
            });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
