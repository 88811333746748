var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { USER_AUTHORIZATION_RECEIVE_USER_DATA, USER_AUTHORIZATION_PURGE_USER_DATA, USER_AUTHORIZATION_API_FAIL } from 'components/constants';
var initialState = {
    token: '',
    expires: 0,
    userSession: {
        id: null,
        name: '',
        userName: '',
        token: null,
        rank: 'USER',
        falseLogin: false
    }
};
var getUserSession = function (payload) {
    return {
        id: payload.API._id,
        name: payload.name,
        userName: payload.username,
        permissions: payload.permissions,
        token: payload.token,
        rank: payload.rank,
        falseLogin: false
    };
};
export default (function (state, _a) {
    if (state === void 0) { state = initialState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        default:
            return state;
        case USER_AUTHORIZATION_RECEIVE_USER_DATA: {
            return __assign(__assign({}, state), { token: payload.token, userSession: getUserSession(payload.message), expires: payload.expires });
        }
        case USER_AUTHORIZATION_PURGE_USER_DATA:
            return __assign(__assign({}, state), initialState);
        case USER_AUTHORIZATION_API_FAIL:
            return __assign(__assign({}, state), initialState);
    }
});
