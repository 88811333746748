var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { Fragment, useState } from 'react';
import * as PropTypes from 'prop-types';
import CompanyFinancesTableConfig from 'components/configs/companyFinancesTable/CompanyFinancesTable';
import clsx from 'clsx';
import { setT } from 'helpers/translationUtilities';
CompanyFinancesTable.propTypes = {
    players: PropTypes.array.isRequired,
    companies: PropTypes.array.isRequired,
    scores: PropTypes.array.isRequired,
    stages: PropTypes.array.isRequired
};
export default function CompanyFinancesTable(_a) {
    var players = _a.players, companies = _a.companies, scores = _a.scores, stages = _a.stages;
    var _b = useState([]), openRows = _b[0], setOpenRows = _b[1];
    var tableData = CompanyFinancesTableConfig(players, companies, scores, stages);
    return (React.createElement(Fragment, null,
        React.createElement("h2", { className: "text-align-center" }, setT('company_finances')),
        React.createElement("div", { className: "balance-table" },
            React.createElement("table", null,
                getTableHead(tableData.headers),
                getTableRows(tableData.headers, tableData.rows)))));
    function getTableRows(headers, rows) {
        return rows.map(function (row, i) { return (React.createElement(Fragment, { key: row.id },
            React.createElement("tbody", { className: "balance-table__row", onClick: function () { return onGroupSelect(row.id, openRows, setOpenRows); } },
                React.createElement("tr", null, getRowCells(headers, row.mainRow))),
            areChildRowsOpen(openRows, row.id) ?
                React.createElement("tbody", { className: "balance-table__extended-row" }, getTableChildRows(headers, row.children)) : null)); });
    }
}
function onGroupSelect(id, openRows, setOpenRows) {
    var openRowsCopy = __spreadArray([], openRows, true);
    var indexOfIdInArray = openRowsCopy.findIndex(function (groupID) { return groupID === id; });
    indexOfIdInArray !== -1 ? openRowsCopy.splice(indexOfIdInArray, 1) : openRowsCopy.push(id);
    setOpenRows(openRowsCopy);
}
function getRowCells(headers, row) {
    return headers.map(function (header) {
        return (React.createElement("td", { key: row[header.key].utility.id },
            React.createElement("div", { className: clsx(['balance-table__cell', header.key === 'chevron' && 'balance-table__cell--empty']) }, row[header.key].value)));
    });
}
function areChildRowsOpen(openRows, id) {
    return openRows.some(function (groupID) { return groupID === id; });
}
function getTableHead(data) {
    return (React.createElement("thead", null,
        React.createElement("tr", null, data.map(function (item) { return (React.createElement("th", { key: item.key }, item.label)); }))));
}
function getTableChildRows(headers, rows) {
    return (rows.map(function (row, i) { return (React.createElement("tr", { key: i }, getRowCells(headers, row))); }));
}
