var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var STORE_ITEM_NAME = 'store';
export var getState = function () {
    try {
        var serializedState = window.localStorage.getItem(STORE_ITEM_NAME);
        if (!serializedState) {
            return null;
        }
        return JSON.parse(serializedState);
    }
    catch (e) {
        return null;
    }
};
export var saveState = function (item) {
    if (typeof item === 'object') {
        var object = __assign({}, item);
        delete object.userInterface;
        window.localStorage.setItem(STORE_ITEM_NAME, JSON.stringify(object));
    }
};
export var removeState = function () {
    window.localStorage.removeItem(STORE_ITEM_NAME);
};
