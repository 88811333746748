import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/player/shared/modal/component';
import AuthLogo from 'components/auth/shared/logo/component';
import { setT } from 'helpers/translationUtilities';
DisconnectNotificationModal.propTypes = {
    logoutFromApp: PropTypes.func.isRequired
};
export default function DisconnectNotificationModal(_a) {
    var logoutFromApp = _a.logoutFromApp;
    return (React.createElement(Modal, { expanded: true, lightboxClasses: 'bg-transparent', modalInnerClasses: 'text-align-center', lightboxUnclosable: true },
        React.createElement("div", null,
            React.createElement(AuthLogo, null),
            React.createElement("h2", { className: "color-primary" }, setT('connection_lost'))),
        React.createElement("div", { className: "d-flex flex-align-center flex-justify-content-center", style: { gap: '12px' } },
            React.createElement("button", { className: "main-button main-button--lg", onClick: function () { return logoutFromApp(); } }, setT('to_login')),
            React.createElement("button", { className: "main-button main-button--lg", onClick: function () { return window.location.reload(); } }, setT('reconnect')))));
}
