var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
export var AuthRoute = function (_a) {
    var Component = _a.component, isAuthenticated = _a.isAuthenticated, _b = _a.redirectTo, redirectTo = _b === void 0 ? "/dashboard" : _b, rest = __rest(_a, ["component", "isAuthenticated", "redirectTo"]);
    if (!isAuthenticated) {
        return React.createElement(Redirect, { to: redirectTo });
    }
    return React.createElement(Route, __assign({}, rest, { render: function (props) { return React.createElement(Component, __assign({}, props)); } }));
};
AuthRoute.propTypes = {
    component: PropTypes.elementType.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    redirectTo: PropTypes.string,
};
