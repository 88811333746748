var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { UI_GAMESLIST_REQUEST_ENDED, GENERAL_RECEIVE_GAMES, PLAYER_AUTHORIZATION_RECEIVE_TOKEN, PLAYER_PURGE_ALL_DATA, UI_GAMES_MAX_PAGES_RECIEVED, UI_GAMESLIST_REQUEST, WEBSOCKET_CONNECT, WEBSOCKET_DISCONNECT, PLAYER_AUTHORIZATION_FAIL_TOKEN, } from 'components/constants';
import ApiGameService from '../../services/ApiService/ApiGameService/ApiGameService';
import { userAuthLogoutRequestAction } from './userAuthorization.actions';
import { gameJoinAction } from './game.actions';
export var websocketConnectAction = function (token) {
    return function (dispatch) {
        dispatch({
            type: WEBSOCKET_CONNECT,
            payload: token
        });
    };
};
export var websocketDisconnectAction = function (token) {
    return function (dispatch) {
        dispatch({
            type: WEBSOCKET_DISCONNECT,
            payload: token
        });
    };
};
export var purgeAndReconnectAction = function (gameToken) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch({ type: PLAYER_PURGE_ALL_DATA });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, dispatch(gameJoinAction(gameToken))];
                case 2:
                    _a.sent();
                    dispatch({
                        type: PLAYER_AUTHORIZATION_RECEIVE_TOKEN,
                        payload: gameToken,
                    });
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    dispatch({
                        type: PLAYER_AUTHORIZATION_FAIL_TOKEN,
                    });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var getGamesAction = function (authToken, params) {
    return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, data, headers, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    dispatch({
                        type: UI_GAMESLIST_REQUEST
                    });
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, ApiGameService.requestGames(authToken, params)];
                case 2:
                    _a = _b.sent(), data = _a.data, headers = _a.headers;
                    dispatch({ type: UI_GAMESLIST_REQUEST_ENDED });
                    dispatch({
                        payload: data,
                        type: GENERAL_RECEIVE_GAMES
                    });
                    dispatch({
                        payload: Number(headers['total-pages']),
                        type: UI_GAMES_MAX_PAGES_RECIEVED
                    });
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _b.sent();
                    dispatch({ type: UI_GAMESLIST_REQUEST_ENDED });
                    if (err_1.response && err_1.response.status === 403) {
                        dispatch(userAuthLogoutRequestAction());
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
