var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import clsx from 'clsx';
import React from 'react';
import CollapseTableContent from 'components/manager/facilitator/dashboard/collapseTableContent/container';
import { hasStageEnded, isCurrentStageInOvertime, sortStagesByOrdinal } from 'helpers/stageUtilities';
import { convertUnix, secToMins } from 'helpers/timeUtilities';
import { useTranslation } from 'react-i18next';
function getTableColumns() {
    return [
        { label: '', name: 'stage_name' },
        { label: '', name: 'general_info' }
    ];
}
;
export default function StageTableConfig(_a) {
    var stages = _a.stages, onGroupClick = _a.onGroupClick, openRows = _a.openRows, _b = _a.activeStage, activeStage = _b === void 0 ? {} : _b;
    var t = useTranslation().t;
    var orderedStages = __spreadArray([], sortStagesByOrdinal(stages), true);
    var nextStageFromActive = orderedStages.find(function (stage) { return stage.ordinal === activeStage.ordinal + 1; });
    var mappedStageArray = orderedStages.reduce(function (accumulator, stage) {
        var isCurrentRowOpen = openRows.find(function (row) { return row === stage.id; });
        var isActiveStage = activeStage.id === stage.id;
        var isNextStageFromActive = nextStageFromActive ? nextStageFromActive.id === stage.id : false;
        var times = getStageRelevantTimes(stage, orderedStages, activeStage.id);
        accumulator.push({
            id: stage.id,
            isRowOpen: isCurrentRowOpen,
            addedClasses: clsx(['cursor-pointer stage-line', isCurrentRowOpen && 'extended']),
            innerAreaClasses: clsx([isCurrentRowOpen && 'extended']),
            tableColumns: getTableColumns(),
            properties: {
                onClick: function () { return onGroupClick(stage.id); }
            },
            groupHeaderCells: [
                {
                    id: "".concat(stage.id, "_stage_name"),
                    properties: { className: 'text-align-left' },
                    component: getGroupHeaderNameHTML(stage.stageName, isNextStageFromActive, isActiveStage)
                },
                {
                    id: "".concat(stage.id, "_chevron"),
                    properties: { className: 'text-align-right' },
                    component: getGroupHeaderInfo(times, stage)
                }
            ],
            rows: [setStageRow(stage, times)]
        });
        return accumulator;
    }, []);
    return mappedStageArray;
    function getGroupHeaderNameHTML(name, isNextStageFromActive, isActiveStage) {
        var label = '';
        if (isNextStageFromActive) {
            label = "".concat(t('next_stage'), ":");
        }
        else if (isActiveStage) {
            label = "".concat(t('current_stage'), ":");
        }
        return (React.createElement("span", null,
            label,
            " ",
            React.createElement("b", null, name)));
    }
    function getGroupHeaderInfo(times, stage) {
        var style = { marginLeft: '15px' };
        return (React.createElement("div", { className: "d-flex flex-justify-content-flex-end" },
            React.createElement("span", { style: style },
                secToMins(stage.endedAt ? stage.spentTime : stage.duration),
                " min"),
            React.createElement("span", { style: style },
                groupEndedString(stage),
                " ",
                convertUnix(times.estimated_end)),
            React.createElement("span", { style: style, className: "chevron d-none d-block-md" })));
    }
    function groupEndedString(stage) {
        if (!stage.endedAt || (stage.endedAt && stage.spentTime === 0)) {
            return "".concat(t('estimated_end'), ":");
        }
        return "".concat(t('ended_at'), ":");
    }
}
function setStageRow(stage, times) {
    return {
        key: "".concat(stage.id),
        stage_name: {
            component: React.createElement(CollapseTableContent, { stageDescription: stage.description, times: times, stageId: stage.id, hasStageEnded: hasStageEnded(stage) })
        },
        customProperties: {
            className: 'bg-transparent',
            cellProperties: {
                stage_name: { colSpan: 2 }
            }
        }
    };
}
function getStageRelevantTimes(stage, stages, activeStageId) {
    var times = {
        planned: stage.duration,
        spent: stage.spentTime,
        estimated_end: estimateEndTime(stage, stages, activeStageId),
        formatted: {
            plannedMins: secToMins(stage.duration)
        }
    };
    return times;
}
function estimateStartTime(stage, stages, activeStageId) {
    var currentStateIndex = stages.findIndex(function (singleStage) { return stage.id === singleStage.id; });
    var activeStageIndex = stages.findIndex(function (singleStage) { return activeStageId === singleStage.id; });
    var savedLoopTime = 0; // In loop for active stage this represents presumed endedAt. For not started stages startedAt
    // Loop through stages, starting from active and keep adding durations to it til it hits the desired stage index.
    for (var i = activeStageIndex !== -1 ? activeStageIndex : 0; i < currentStateIndex; i++) {
        var loopStage = stages[i];
        if (loopStage.startedAt) {
            if (i === activeStageIndex && isCurrentStageInOvertime(stages[activeStageIndex])) {
                savedLoopTime = loopStage.startedAt + loopStage.spentTime;
            }
            else {
                savedLoopTime = loopStage.startedAt + loopStage.duration;
            }
        }
        else {
            savedLoopTime += loopStage.duration;
        }
    }
    return savedLoopTime;
}
function estimateEndTime(stage, stages, activeStageId) {
    return (stage.startedAt ? stage.startedAt : estimateStartTime(stage, stages, activeStageId)) + (stage.endedAt || isCurrentStageInOvertime(stage) ? stage.spentTime : stage.duration);
}
