var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OnboardingCompany from '../../../../player/onboarding/companies/company/component';
import Header from 'components/shared/header/container';
import autobind from 'autobind-decorator';
import { onboardingRequestCompanyJoin } from '../../../../../socketConnection/socketMessageRequests/onboardingRequests';
import { setT } from 'helpers/translationUtilities';
import { history } from '../../../../../store';
var ViewOnboarding = /** @class */ (function (_super) {
    __extends(ViewOnboarding, _super);
    function ViewOnboarding() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ViewOnboarding.prototype.getCompany = function (id) {
        var companies = this.props.companies;
        if (id) {
            return companies.find(function (company) { return company.id === id; });
        }
    };
    ViewOnboarding.prototype.componentDidUpdate = function () {
        var _a = this.props, players = _a.players, companies = _a.companies, token = _a.token;
        if (players.length && companies.length && token) {
            var foundPlayer = players.find(function (item) { return (item.token === token); });
            if (foundPlayer) {
                history.push("/dashboard");
                return;
            }
            var foundCompany = companies.find(function (item) { return (item.token === token); });
            if (foundCompany) {
                history.push("/onboarding/players/".concat(foundCompany.id));
            }
        }
    };
    ViewOnboarding.prototype.shouldComponentUpdate = function () {
        return true;
    };
    ViewOnboarding.prototype.onCompanyClick = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var player, company, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        player = this.props.player;
                        company = this.getCompany(id);
                        if (!company) return [3 /*break*/, 3];
                        if (!player.ownToken) return [3 /*break*/, 2];
                        _b = (_a = this.props).websocketMessage;
                        return [4 /*yield*/, onboardingRequestCompanyJoin(id, [this.props.player.id], player.ownToken)];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        _c.label = 2;
                    case 2:
                        this.props.updateActiveCompany(company);
                        _c.label = 3;
                    case 3:
                        this.props.redirectToOnboardingPlayers(id);
                        return [2 /*return*/];
                }
            });
        });
    };
    ViewOnboarding.prototype.render = function () {
        var _this = this;
        var _a = this.props, companies = _a.companies, player = _a.player, token = _a.token;
        return (React.createElement("div", { className: "wrapper wrapper--no-sidebar wrapper--no-padding-top" },
            React.createElement(Header, null),
            React.createElement("div", { className: "wrapper__inner" },
                React.createElement("div", { className: "wrapper__header wrapper__header--overflow" },
                    React.createElement("div", { className: "container" },
                        React.createElement("div", { className: "topheader" },
                            player && player.name ? (React.createElement("div", { className: "topheader__primary" },
                                setT('welcome_back'),
                                ", ",
                                player.name,
                                "!")) : (React.createElement("div", { className: "topheader__primary" },
                                setT('welcome'),
                                "!")),
                            React.createElement("div", { className: "topheader__secondary" },
                                setT('game_code'),
                                ": ",
                                token)))),
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "wrapingbox" },
                        React.createElement("div", { className: "infobox__header" },
                            React.createElement("div", { className: "infobox__heading" }, setT('company')),
                            React.createElement("div", { className: "infobox__subheading" }, setT('choose_a_company_from_list'))),
                        React.createElement("div", { className: "wrapingbox__listing clear" }, companies.map(function (company) { return (React.createElement(OnboardingCompany, { key: company.id, id: company.id, name: company.name, logo: company.thumbnail, onCompanyClick: _this.onCompanyClick })); })))))));
    };
    ViewOnboarding.propTypes = {
        players: PropTypes.array,
        player: PropTypes.object,
        token: PropTypes.string,
        companies: PropTypes.array,
        updateActiveCompany: PropTypes.func.isRequired,
        redirectToOnboardingPlayers: PropTypes.func.isRequired,
        company: PropTypes.object,
        match: PropTypes.object,
        websocketMessage: PropTypes.func.isRequired,
    };
    ViewOnboarding.defaultProps = {
        players: [],
        player: null,
        token: '',
        companies: [],
        company: {},
        match: {
            params: {
                tokenId: ''
            }
        }
    };
    __decorate([
        autobind
    ], ViewOnboarding.prototype, "onCompanyClick", null);
    return ViewOnboarding;
}(Component));
export default ViewOnboarding;
