import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DisconnectNotificationModal from 'components/global-wrapper/disconnectNotificationModal/container';
var modalInterval = null;
LoadView.propTypes = {
    displayModal: PropTypes.bool
};
export default function LoadView(_a) {
    var _b = _a.displayModal, displayModal = _b === void 0 ? false : _b;
    var _c = useState(false), forcedModalDisplay = _c[0], setForcedModalDisplay = _c[1];
    useEffect(function () {
        modalInterval = setInterval(function () {
            setForcedModalDisplay(true);
        }, 6000);
        return function () {
            clearInterval(modalInterval);
        };
    }, []);
    return (React.createElement("div", { className: "overlay overlay--primary-color" }, displayModal || forcedModalDisplay ? React.createElement(DisconnectNotificationModal, null) : React.createElement("div", { className: "loader loader--white loader--overlay" })));
}
