import { connect } from 'react-redux';
import Component from './component';
import { adminGetTemplateListingAction } from '../../../../../redux/actions/admin/admin.actions';
import { adminDeleteTemplateAction, adminDuplicateTemplateAction, } from '../../../../../redux/actions/admin/admin.template.actions';
var mapStateToProps = function (state) {
    return {
        templates: state.admin.templates,
        authToken: state.userAuthorization.token,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        getTemplateListing: function (token) { return dispatch(adminGetTemplateListingAction(token)); },
        duplicateTemplate: function (id) { return dispatch(adminDuplicateTemplateAction(id)); },
        deleteTemplate: function (id) { return dispatch(adminDeleteTemplateAction(id)); },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
