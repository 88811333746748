import Modal from 'components/player/shared/modal/component';
import React from 'react';
import PropTypes from 'prop-types';
TemplateConfirmationModal.propTypes = {
    id: PropTypes.string,
    modalOpen: PropTypes.bool,
    stages: PropTypes.array,
    onModalClose: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired
};
export default function TemplateConfirmationModal(_a) {
    var _b = _a.modalOpen, modalOpen = _b === void 0 ? false : _b, onModalClose = _a.onModalClose, children = _a.children;
    return (React.createElement(Modal, { expanded: modalOpen, modalInnerClasses: 'modal__inner--lg text-align-center', onClose: function () { return onModalClose(); }, lightboxUnclosable: true, lightboxClasses: 'lightbox--closed-top' }, modalOpen && children));
}
