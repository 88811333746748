var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'components/shared/sidebar/container';
import Infobox from 'components/player/shared/infobox/component';
import Modal from 'components/player/shared/modal/component';
import TaskModalContent from '../../../../player/task/modal-content/component';
import { taskRequestNew, taskRequestUpdate } from '../../../../../socketConnection/socketMessageRequests/taskRequests';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
import { TASK_TYPE_COMPANY, TASK_TYPE_PLAYER } from 'components/player/task/constants';
import clsx from 'clsx';
import { setT } from 'helpers/translationUtilities';
var ViewNewTask = /** @class */ (function (_super) {
    __extends(ViewNewTask, _super);
    function ViewNewTask(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            tasks: props.tasks,
            expanded: null,
            player: props.player
        };
        _this.onClose = _this.onClose.bind(_this);
        return _this;
    }
    ViewNewTask.prototype.shouldComponentUpdate = function () {
        return true;
    };
    ViewNewTask.getDerivedStateFromProps = function (nextProps, state) {
        var stateObject = {};
        var player = state.player;
        if (player.id !== nextProps.player.id ||
            player.company.id !== nextProps.player.company.id) {
            stateObject = {
                tasks: nextProps.tasks,
                player: nextProps.player
            };
            return stateObject;
        }
        else {
            if (nextProps.tasks && nextProps.tasks.length > state.tasks.length) {
                var foundNewTask = nextProps.tasks.find(function (nextPropsTask) {
                    var foundStateTask = state.tasks.find(function (stateTask) {
                        return nextPropsTask.id === stateTask.id;
                    });
                    if ((!foundStateTask && nextPropsTask.to === player.id) ||
                        (!foundStateTask && nextPropsTask.to === player.company.id)) {
                        return nextPropsTask;
                    }
                });
                var taskType = 'Player';
                if (foundNewTask && foundNewTask.to !== player.id) {
                    taskType = 'Company';
                }
                stateObject = {
                    tasks: nextProps.tasks,
                    activeTaskID: foundNewTask ? foundNewTask.id : null,
                    taskType: taskType
                };
                if (foundNewTask) {
                    stateObject.expanded = 'taskModal';
                }
                return stateObject;
            }
        }
        return null;
    };
    ViewNewTask.prototype.expand = function (item) {
        this.setState({
            expanded: item
        });
    };
    ViewNewTask.prototype.onClose = function () {
        this.setState({
            expanded: false
        });
    };
    ViewNewTask.prototype.requestNewTask = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.props).websocketMessage;
                        return [4 /*yield*/, taskRequestNew({ type: type })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this.setState({ stateUpdate: '1' });
                        return [2 /*return*/];
                }
            });
        });
    };
    ViewNewTask.prototype.taskUpdate = function (id, type, status) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.props).websocketMessage;
                        return [4 /*yield*/, taskRequestUpdate(id, type, status)];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        this.onClose();
                        this.props.redirectToTasks();
                        return [2 /*return*/];
                }
            });
        });
    };
    ViewNewTask.prototype.render = function () {
        var _this = this;
        var taskSettings = this.props.game.taskSettings;
        var options = [
            {
                name: setT('personal_task'),
                description: setT('new_personal_task_text'),
                disabled: !taskSettings.playerCards,
                type: TASK_TYPE_PLAYER
            },
            {
                name: setT('business_task'),
                description: setT('new_business_task_text'),
                disabled: !taskSettings.companyCards,
                type: TASK_TYPE_COMPANY
            }
        ];
        var task = this.state.tasks.find(function (task) {
            return task.id === _this.state.activeTaskID;
        });
        return (React.createElement("div", { className: "playerdashboard playerdashboard--with-players-sidebar" },
            React.createElement(Sidebar, { type: SIDEBAR_MODE_PLAYER }),
            React.createElement(Infobox, { title: setT('choose_your_task') },
                React.createElement("div", { className: "task-group" },
                    React.createElement("div", { className: "task-group__list f-grid flex-wrap flex-no-wrap-md" }, options.map(function (option, index) { return (React.createElement("div", { key: index, className: clsx(['task-group__item f-grid-col-12 f-grid-col-md-6', option.disabled && 'task-group__item--disabled']), onClick: option.disabled ? null : function () { return _this.requestNewTask(option.type); } },
                        React.createElement("div", { className: "task-group-item__inner" },
                            React.createElement("div", { className: 'task-group-item__icon icon icon--img icon--task_' +
                                    (option.type === 1 ? 'personal' : 'company') }),
                            React.createElement("div", { className: "task-group-item__details text-align-center" },
                                React.createElement("div", { className: "task-group-item__name" }, option.name),
                                React.createElement("div", { className: "task-group-item__description" }, option.description)),
                            React.createElement("div", { className: "icon icon--check icon--fill-grey" })))); })))),
            React.createElement(Modal, { mobileFullScreen: true, expanded: this.state.expanded === 'taskModal', onClose: this.onClose },
                React.createElement(TaskModalContent, { action: 'confirm', redirectToTasks: this.props.redirectToTasks, icon: this.state.taskType === 'Player'
                        ? 'task_personal'
                        : 'task_company', isPlayerTask: this.state.taskFor === 'Player', fields: task, onUpdate: function (ID, type, status) { return _this.taskUpdate(ID, type, status); } }))));
    };
    ViewNewTask.propTypes = {
        websocketMessage: PropTypes.func.isRequired,
        redirectToTasks: PropTypes.func.isRequired,
        tasks: PropTypes.array,
        game: PropTypes.object.isRequired,
        player: PropTypes.object.isRequired
    };
    ViewNewTask.defaultProps = {
        tasks: []
    };
    return ViewNewTask;
}(Component));
export default ViewNewTask;
