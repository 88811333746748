import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { history } from '../store';
import Router from 'components/routers/router';
import { connect } from 'react-redux';
import { locSetLanguage } from '../redux/actions/localization.actions';
import { LANG_SERVICE_DEFAULT } from 'services/LanguageService/languageService.types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
App.propTypes = {
    currentLang: PropTypes.string,
    setLanguage: PropTypes.func.isRequired,
    rank: PropTypes.string.isRequired,
};
function App(_a) {
    var _b = _a.currentLang, currentLang = _b === void 0 ? LANG_SERVICE_DEFAULT : _b, setLanguage = _a.setLanguage, rank = _a.rank;
    var i18n = useTranslation().i18n;
    useEffect(function () {
        setLanguage(currentLang || LANG_SERVICE_DEFAULT, i18n);
        moment.locale(currentLang || LANG_SERVICE_DEFAULT);
    }, []);
    return (React.createElement(Fragment, null,
        React.createElement(Router, { history: history, rank: rank })));
}
var mapStateToProps = function (state) {
    return {
        currentLang: state.localization.language,
        rank: state.userAuthorization.userSession.rank,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        setLanguage: function (lang, i18n) { return dispatch(locSetLanguage(lang, i18n)); },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
