var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import Sidebar from 'components/shared/sidebar/container';
import Infobox from 'components/player/shared/infobox/component';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
import { bulkTransactionRequest, transactionRequestNew } from '../../../../../socketConnection/socketMessageRequests/transactionRequests';
import { NEW_TRANSACTION_INITIAL_VIEW } from 'components/player/money/transactions/types';
import { setT } from 'helpers/translationUtilities';
import { useTranslation } from 'react-i18next';
TransactionFinalize.propTypes = {
    urlParams: PropTypes.object,
    payload: PropTypes.object.isRequired,
    redirect: PropTypes.func.isRequired,
    updateMainPayload: PropTypes.func.isRequired,
    setStep: PropTypes.func,
    websocketMessage: PropTypes.func.isRequired,
    playersAndCompanies: PropTypes.array,
    bankVariables: PropTypes.object,
    isFacilitatorBulkView: PropTypes.bool
};
export default function TransactionFinalize(_a) {
    var _b = _a.urlParams, urlParams = _b === void 0 ? {
        type: '',
        accountId: ''
    } : _b, payload = _a.payload, redirect = _a.redirect, updateMainPayload = _a.updateMainPayload, _c = _a.setStep, setStep = _c === void 0 ? function () { } : _c, websocketMessage = _a.websocketMessage, _d = _a.playersAndCompanies, playersAndCompanies = _d === void 0 ? [] : _d, bankVariables = _a.bankVariables, _e = _a.isFacilitatorBulkView, isFacilitatorBulkView = _e === void 0 ? false : _e;
    var _f = useState(''), message = _f[0], setMessage = _f[1];
    var t = useTranslation().t;
    useEffect(function () {
        if (!message) {
            setMessage(payload.message);
        }
    }, [payload]);
    function onMessageChange(_a) {
        var value = _a.target.value;
        setMessage(value);
        updateMainPayload({ message: message });
    }
    function onConfirm() {
        return __awaiter(this, void 0, void 0, function () {
            var finalPayload, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        finalPayload = prepareFinalPayload();
                        if (!isFacilitatorBulkView) return [3 /*break*/, 2];
                        _a = websocketMessage;
                        return [4 /*yield*/, bulkTransactionRequest(finalPayload)];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        return [3 /*break*/, 4];
                    case 2:
                        _b = websocketMessage;
                        return [4 /*yield*/, transactionRequestNew(finalPayload)];
                    case 3:
                        _b.apply(void 0, [_c.sent()]);
                        _c.label = 4;
                    case 4:
                        if (isFacilitatorBulkView) {
                            redirect("/facilitator/participants");
                        }
                        else if (bankVariables) {
                            redirect("/banking/".concat(urlParams.accountId));
                        }
                        else {
                            redirect("/money/".concat(urlParams.type, "/").concat(urlParams.accountId));
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function prepareFinalPayload() {
        var updatedPayload = __assign(__assign({}, payload), { message: message });
        updatedPayload.to = isFacilitatorBulkView ? updatedPayload.to : updatedPayload.to[0];
        return updatedPayload;
    }
    function findEntityName(id) {
        var entity = playersAndCompanies.find(function (entity) { return entity.id === id; });
        return entity ? entity.name : '';
    }
    return (React.createElement("div", { className: "playerdashboard playerdashboard--with-players-sidebar" },
        React.createElement(Sidebar, { type: SIDEBAR_MODE_PLAYER }),
        React.createElement(Infobox, { title: setT('confirmation') },
            React.createElement("div", { className: "fields fields--table fields--medium-spacing" },
                React.createElement("div", { className: "fields__row" },
                    React.createElement("div", { className: "fields__col" },
                        React.createElement("div", { className: "fields__label" }, setT('payee'))),
                    React.createElement("div", { className: "fields__col" },
                        React.createElement("div", { className: "fields__value" }, isFacilitatorBulkView ? setT('facilitator') : findEntityName(payload.from)))),
                React.createElement("div", { className: "fields__row" },
                    React.createElement("div", { className: "fields__col" },
                        React.createElement("div", { className: "fields__label" }, setT('recipient'))),
                    React.createElement("div", { className: "fields__col" },
                        React.createElement("div", { className: "fields__value" }, payload.to.map(function (id, index) {
                            return index !== 0 ? ", ".concat(findEntityName(id)) : findEntityName(id);
                        })))),
                React.createElement("div", { className: "fields__row" },
                    React.createElement("div", { className: "fields__col" },
                        React.createElement("div", { className: "fields__label" }, setT('amount'))),
                    React.createElement("div", { className: "fields__col" },
                        React.createElement("div", { className: "fields__value" }, Numeral(payload.amount).format('0,0')))),
                React.createElement("div", { className: "fields__row" },
                    React.createElement("div", { className: "fields__col" },
                        React.createElement("div", { className: "fields__label" }, setT('remark'))),
                    React.createElement("div", { className: "fields__col" },
                        React.createElement("input", { className: "fields__input", placeholder: t('enter_your_remark_here'), onChange: onMessageChange, value: message })))),
            React.createElement("div", { className: "infobox__footer text-align-right justify-content-flex-end" },
                React.createElement("button", { className: "main-button main-button--lg main-button--secondary", onClick: function () { return setStep(NEW_TRANSACTION_INITIAL_VIEW); } }, setT('cancel')),
                React.createElement("button", { className: "main-button main-button--lg main-button--inline", onClick: function () { return onConfirm(); } }, setT('accept'))))));
}
