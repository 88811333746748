import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FacilitatorTemplateList from '../../../manager/facilitator/new-game/list/container';
import FacilitatorTemplateDetails from '../../../manager/facilitator/new-game/template-details/container';
import Moment from 'moment';
import { checkSearchQuery } from 'helpers/checkSearchQuery';
import { setT } from 'helpers/translationUtilities';
ViewFacilitatorNewGame.propTypes = {
    authToken: PropTypes.string.isRequired,
    getGameTemplates: PropTypes.func.isRequired,
    createGame: PropTypes.func.isRequired,
    redirectToFacilitatorGames: PropTypes.func.isRequired,
    redirectToGames: PropTypes.func.isRequired
};
export default function ViewFacilitatorNewGame(props) {
    var authToken = props.authToken, getGameTemplates = props.getGameTemplates, createGame = props.createGame, redirectToFacilitatorGames = props.redirectToFacilitatorGames, redirectToGames = props.redirectToGames;
    var _a = useState(1), position = _a[0], setPosition = _a[1];
    var _b = useState(null), selectedTemplateID = _b[0], setSelectedTemplateID = _b[1];
    var _c = useState(''), newGameTitle = _c[0], setNewGameTitle = _c[1];
    var _d = useState(Moment().format('X')), newGameDate = _d[0], setNewGameDate = _d[1];
    var didUserComeFromAdminPanel = checkSearchQuery('origin', 'facilitator');
    useEffect(function () {
        getGameTemplates(authToken);
    }, []);
    function onTemplateClick(id) {
        setSelectedTemplateID(id);
    }
    function onBack() {
        setPosition(1);
    }
    function onExit() {
        if (didUserComeFromAdminPanel) {
            redirectToFacilitatorGames();
            return;
        }
        redirectToGames();
    }
    function onNext() {
        if (selectedTemplateID) {
            setPosition(2);
        }
    }
    function onTitleChange(newTitle) {
        setNewGameTitle(newTitle);
    }
    function onDateTimeChange(newDate) {
        setNewGameDate(newDate);
    }
    function handleCreateGame() {
        if (!selectedTemplateID || !newGameTitle || !newGameDate || !authToken) {
            console.error('Error creating game', selectedTemplateID, newGameTitle, newGameDate, authToken);
            return false; // TODO: Throw error
        }
        createGame(selectedTemplateID, newGameTitle, newGameDate, authToken);
    }
    return (React.createElement("div", null,
        React.createElement("div", { className: "grid-row" },
            React.createElement("div", { className: "grid-sm-12" },
                React.createElement("div", { className: "general-box" },
                    React.createElement("div", { className: "topheader" },
                        React.createElement("div", { className: "topheader__primary" }, setT('add_game')),
                        React.createElement("div", { className: "infobox__subheading" }, position === 1
                            ? setT('select_a_template')
                            : setT('enter_new_template_details'))),
                    position === 1 ? (React.createElement("div", null,
                        React.createElement(FacilitatorTemplateList, { onTemplateClick: onTemplateClick, selectedTemplateID: selectedTemplateID }),
                        React.createElement("div", { className: "text-right" },
                            React.createElement("button", { className: "main-button main-button--generic main-button--secondary", onClick: function () { return onExit(); } }, setT('cancel')),
                            React.createElement("button", { className: "main-button main-button--generic main-button--left", onClick: function () { return onNext(); } }, setT('proceed'))))) : (React.createElement("div", { className: "grid-row" },
                        React.createElement("div", { className: "grid-xxl-8 grid-xl-9 grid-sm-12" },
                            React.createElement(FacilitatorTemplateDetails, { onTemplateClick: null, templateID: selectedTemplateID, onTitleChange: onTitleChange, onDateTimeChange: onDateTimeChange })),
                        React.createElement("div", { className: "grid-xxl-8 grid-xl-9 grid-sm-12 text-right" },
                            React.createElement("button", { className: "main-button main-button--generic main-button--secondary", onClick: onBack }, setT('cancel')),
                            React.createElement("button", { className: "main-button main-button--generic main-button--left", onClick: handleCreateGame }, setT('done'))))))))));
}
