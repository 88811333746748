import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Infobox from 'components/player/shared/infobox/component';
import Playersbar from '../../../player/dashboard/playersbar/container';
import Sidebar from 'components/shared/sidebar/container';
import PlayersSidebar from 'components/player/shared/players-sidebar/container';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
import { hasGameEnded, isGameLocked } from 'helpers/gameUtilities';
import { setT } from 'helpers/translationUtilities';
ViewDashboard.propTypes = {
    company: PropTypes.object,
    game: PropTypes.object,
    items: PropTypes.array,
    player: PropTypes.object,
    players: PropTypes.array,
    redirectToOnboardingPlayers: PropTypes.func.isRequired,
    stages: PropTypes.array,
    tasks: PropTypes.array
};
export default function ViewDashboard(_a) {
    var _b = _a.company, company = _b === void 0 ? {
        id: '',
        incomeBalance: 0,
        expenseBalance: 0,
        totalBalance: 0,
        transactions: [],
        description: '',
    } : _b, _c = _a.game, game = _c === void 0 ? {} : _c, _d = _a.player, player = _d === void 0 ? {} : _d, _e = _a.players, players = _e === void 0 ? [] : _e, redirectToOnboardingPlayers = _a.redirectToOnboardingPlayers, _f = _a.stages, stages = _f === void 0 ? [] : _f, _g = _a.tasks, tasks = _g === void 0 ? [] : _g;
    var managePlayersDisabled = isGameLocked(stages) || hasGameEnded(stages);
    var _h = getDashboardTaskCounts(tasks, player), playerTasks = _h.playerTasks, companyTasks = _h.companyTasks;
    var companyPlayers = players.filter(function (player) {
        return player.companyID === company.id;
    });
    return (React.createElement("div", { className: "playerdashboard playerdashboard--with-players-sidebar" },
        React.createElement(Sidebar, { type: SIDEBAR_MODE_PLAYER }),
        React.createElement(PlayersSidebar, null),
        React.createElement("div", { className: "topheader" },
            React.createElement("div", { className: "topheader__primary" },
                setT('welcome_back'),
                ", ",
                player.name,
                "!"),
            React.createElement("div", { className: "topheader__secondary" },
                setT('game_code'),
                ": ",
                game.token)),
        React.createElement("div", { className: "dashboard" },
            React.createElement("div", { className: "f-grid flex-wrap flex-no-wrap-lg" },
                React.createElement("div", { className: "f-grid-col-12 f-grid-col-lg-8 infoboxes flex-wrap flex-no-wrap-sm" },
                    React.createElement("div", { className: "f-grid flex-wrap flex-no-wrap-sm" },
                        React.createElement("div", { className: "f-grid-col-12 f-grid-col-sm-6" },
                            React.createElement(Infobox, { title: setT('tasks_assigned'), icon: 'task_colored', className: 'infobox__wrapper--primary', columns: [
                                    {
                                        label: setT('personal'),
                                        value: playerTasks.length,
                                        className: 'infobox__column--lg'
                                    },
                                    {
                                        label: setT('business'),
                                        value: companyTasks.length,
                                        className: 'infobox__column--lg'
                                    }
                                ] })),
                        React.createElement("div", { className: "f-grid-col-12 f-grid-col-sm-6 mar-grid-space-top mar-top-0-sm" },
                            React.createElement(Infobox, { title: setT('account_balance'), icon: 'account_colored', className: 'infobox__wrapper--primary', columns: [
                                    {
                                        label: setT('personal'),
                                        value: "".concat(player.totalBalance),
                                        className: 'infobox__column--lg'
                                    },
                                    {
                                        label: setT('business'),
                                        value: "".concat(company.totalBalance),
                                        className: 'infobox__column--lg'
                                    }
                                ] }))),
                    React.createElement("div", { className: "custom-content-area mar-top-15", dangerouslySetInnerHTML: { __html: company.description } })),
                React.createElement("div", { className: "f-grid-col-12 f-grid-col-lg-4 mar-grid-space-top mar-top-0-lg" },
                    React.createElement("div", { className: "dashboard__sidebar" },
                        React.createElement(Infobox, { title: setT('employees'), className: 'infobox__wrapper--primary', icon: 'person', headerRender: function () { return (React.createElement("button", { className: clsx(['main-button main-button--lg', managePlayersDisabled && 'main-button--disabled']), onClick: function () {
                                    return onManageClick(managePlayersDisabled, redirectToOnboardingPlayers, company.id);
                                } }, setT('manage'))); } },
                            React.createElement(Playersbar, { players: companyPlayers }))))))));
}
var getDashboardTaskCounts = function (tasks, player) {
    return {
        playerTasks: tasks.filter(function (task) { return task.to === player.id; }),
        companyTasks: tasks.filter(function (task) { return task.to === player.company.id; })
    };
};
var onManageClick = function (managePlayersDisabled, redirectToOnboardingPlayers, id) {
    if (managePlayersDisabled) {
        return null;
    }
    redirectToOnboardingPlayers(id);
};
