import { connect } from 'react-redux';
import Component from './component';
import { WEBSOCKET_MESSAGE } from 'components/constants';
var mapStateToProps = function (state) {
    return {
        adminName: state.userAuthorization ? state.userAuthorization.userSession.name : null,
        game: state.game,
        player: state.player,
        playerThumbnail: state.player.thumbnail,
        companyThumbnail: state.player.company.thumbnail,
        stages: state.game.stages,
        showStageInfoBar: state.userInterface.showStageInfoBar,
        currentUserRank: state.userAuthorization.userSession.rank
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirect: function () { return dispatch({ type: 'ROUTE', to: '/token' }); },
        websocketMessage: function (msg) { return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg }); },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
