import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
AuthPermissions.propTypes = {
    allowedRanks: PropTypes.array.isRequired,
    currentUserRank: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
};
export default function AuthPermissions(_a) {
    var allowedRanks = _a.allowedRanks, currentUserRank = _a.currentUserRank, children = _a.children;
    return allowedRanks && allowedRanks.some(function (rank) { return rank === currentUserRank; }) ? (React.createElement(Fragment, null, children)) : null;
}
