var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PlayersbarSingle from './single/component';
var Playersbar = /** @class */ (function (_super) {
    __extends(Playersbar, _super);
    function Playersbar() {
        var _this = _super.call(this) || this;
        _this.state = {
            placeholder: ''
        };
        _this.onPlayerClick = _this.onPlayerClick.bind(_this);
        _this.onUserProfile = _this.onUserProfile.bind(_this);
        return _this;
    }
    Playersbar.prototype.shouldComponentUpdate = function () {
        return true;
    };
    Playersbar.prototype.onPlayerClick = function (token) {
        this.props.gameJoin(token);
    };
    Playersbar.prototype.onUserProfile = function () {
        this.props.redirect('/facilitator');
    };
    Playersbar.prototype.render = function () {
        var _this = this;
        var _a = this.props, players = _a.players, user = _a.user, showFacilitator = _a.showFacilitator;
        return (React.createElement("div", { className: "playerlisting" },
            players.map(function (player) { return (React.createElement(PlayersbarSingle, { player: player, onPlayerClick: function () { return _this.onPlayerClick(player.token); }, key: player.id, visibleCode: true })); }),
            showFacilitator && user.username ? (React.createElement(PlayersbarSingle, { player: {
                    avatar: '',
                    name: user.username
                }, onPlayerClick: this.onUserProfile })) : null));
    };
    Playersbar.propTypes = {
        players: PropTypes.array,
        user: PropTypes.object,
        redirect: PropTypes.func.isRequired,
        showFacilitator: PropTypes.bool,
        gameJoin: PropTypes.func.isRequired
    };
    Playersbar.defaultProps = {
        players: [],
        showFacilitator: false,
        user: {
            id: null,
            username: null
        }
    };
    return Playersbar;
}(Component));
export default Playersbar;
