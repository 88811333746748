import { PLAYER_RECEIVE_COMPANY_INFORMATION, GAME_RECEIVE_INFORMATION, GAME_UPDATE_STAGES, GAME_SET_CURRENT_STAGE_PAUSE, GENERAL_UPDATE_GAME_STATUS, PLAYER_RECEIVE_INFORMATION } from 'components/constants';
export var gameTypes = {
    playerInformation: {
        type: PLAYER_RECEIVE_INFORMATION,
        payload: function (payload) { return payload.playerInformation; }
    },
    companyInformation: {
        type: PLAYER_RECEIVE_COMPANY_INFORMATION,
        payload: function (payload) { return payload.companyInformation; }
    },
    gameInfo: {
        type: GAME_RECEIVE_INFORMATION,
        payload: function (payload) { return payload.gameInfo; }
    },
    gameStageChange: {
        type: GAME_SET_CURRENT_STAGE_PAUSE,
        payload: function (payload) { return payload; }
    },
    gameStages: {
        type: GAME_UPDATE_STAGES,
        payload: function (payload) { return payload.gameStages.items; }
    },
    gameStatusChange: {
        type: GENERAL_UPDATE_GAME_STATUS,
        payload: function (payload) { return payload; }
    }
};
