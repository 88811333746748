var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect } from 'react';
import GetTime, { formatTimes } from 'helpers/timeUtilities';
export var useAutoOpenedGameStage = function (currentlyActiveState, setOpenRowIds, ignoreBreak) {
    if (ignoreBreak === void 0) { ignoreBreak = true; }
    useEffect(function () {
        if (currentlyActiveState && (!ignoreBreak || ignoreBreak && !currentlyActiveState.discussionBreak)) {
            setOpenRowIds([currentlyActiveState.id]);
        }
    }, [currentlyActiveState && currentlyActiveState.id]);
};
// First stage that has a discussion break in it, gets its own group. Usually discussion breaks are merged to previous regular stage.
// In case of multiple discussionBreaks happening, all after first merged one, get their own group
export var mergeStageToPreviousStage = function (stage, prevStage) {
    return stage.discussionBreak && stage.ordinal !== 1 && prevStage && !prevStage.discussionBreak;
};
export var GetStagePauseAmount = function (pauseArray, moment) {
    var seconds = 0;
    if (pauseArray) {
        for (var i = pauseArray.length - 1; i >= 0; i--) {
            if (pauseArray[i].end !== 0 && pauseArray[i].end) {
                seconds += pauseArray[i].end - pauseArray[i].start;
            }
            else {
                seconds += moment().unix() - pauseArray[i].start;
            }
        }
    }
    return seconds;
};
export var getActiveStage = function (stages) {
    var activeStage = stages.find(function (stage) { return (stage.active); });
    if (!activeStage)
        activeStage = stages.findLast(function (stage) { return (stage.startedAt); });
    return activeStage;
};
export var sortStagesByOrdinal = function (stages) {
    return __spreadArray([], stages, true).sort(function (a, b) {
        if (b.ordinal > a.ordinal) {
            return -1;
        }
        return 1;
    });
};
export var hasStageEnded = function (stage) {
    return !!stage.endedAt;
};
export var isCurrentStageInOvertime = function (stage) {
    return stage.spentTime > stage.duration;
};
export var sortGameStagesByStartDate = function (stages, type) {
    if (type === void 0) { type = 'ASC'; }
    return stages.sort(function (a, b) {
        if (type === 'ASC') {
            if (a.startedAt > b.startedAt) {
                return 1;
            }
        }
        else {
            if (a.startedAt > b.startedAt) {
                return -1;
            }
        }
    });
};
export var getCurrentlyActiveState = function (stages) {
    return stages.find(function (stage) {
        return stage.active === true;
    });
};
export var GetStageRelevantTimes = function (stageObject, moment, addSeconds) {
    if (stageObject) {
        var pausedSeconds = 0;
        var spentTime = 0;
        var remainingTime = 0;
        var plannedTime = GetTime(0, stageObject.duration, false, 0, addSeconds);
        pausedSeconds = GetStagePauseAmount(stageObject.pauseHistory, moment);
        if (stageObject.startedAt !== 0 && stageObject.startedAt) {
            spentTime = GetTime(moment().unix(), stageObject.startedAt, false, -pausedSeconds, addSeconds);
            remainingTime = GetTime(stageObject.duration - (moment().unix() - stageObject.startedAt), 0, true, pausedSeconds, addSeconds);
            if (stageObject.endedAt !== 0 && stageObject.endedAt) {
                spentTime = GetTime(stageObject.endedAt, stageObject.startedAt, false, 0, addSeconds);
                remainingTime = GetTime(stageObject.duration - (stageObject.endedAt - stageObject.startedAt), 0, true, 0, addSeconds);
            }
        }
        return {
            formatted: {
                pausedSeconds: formatTimes(pausedSeconds, addSeconds),
                spentTime: formatTimes(spentTime, addSeconds),
                remainingTime: formatTimes(remainingTime, addSeconds),
                plannedTime: formatTimes(plannedTime, addSeconds)
            },
            raw: {
                pausedSeconds: pausedSeconds,
                spentTime: spentTime,
                remainingTime: remainingTime,
                plannedTime: plannedTime
            }
        };
    }
    return null;
};
