import moment from 'moment';
import React from 'react';
import { setT } from 'helpers/translationUtilities';
function getTableColumns() {
    return [
        { label: setT('title'), name: 'title' },
        { label: setT('owner'), name: 'owner', classes: 'd-none d-table-cell-md' },
        { label: setT('template'), name: 'template' },
        { label: setT('date_n_time'), name: 'date' }
    ];
}
export default function GamesTableConfig(_a) {
    var _b = _a.games, games = _b === void 0 ? [] : _b, selectGame = _a.selectGame;
    return {
        tableColumns: getTableColumns(),
        rows: games.map(function (game) {
            var _a, _b;
            var momentGameTime = moment.unix(game.datetime);
            return {
                utilityProps: {
                    id: game.token,
                    onClick: function () { return selectGame(game._id); },
                    className: 'cursor-pointer',
                },
                title: {
                    id: "".concat(game.token, "_").concat(game.name),
                    value: (React.createElement("div", null,
                        React.createElement("div", null,
                            React.createElement("b", null, game.name)),
                        React.createElement("div", { className: "color-third" }, game.token)))
                },
                owner: {
                    id: "".concat(game.token, "_").concat(game.owner.name),
                    className: 'd-none d-table-cell-md',
                    value: (React.createElement("div", null, game.owner.name || 'owner not defined'))
                },
                template: {
                    id: "".concat(game.token, "_").concat((_a = game === null || game === void 0 ? void 0 : game.parentID) === null || _a === void 0 ? void 0 : _a.name),
                    value: ((_b = game === null || game === void 0 ? void 0 : game.parentID) === null || _b === void 0 ? void 0 : _b.name) || 'template not defined'
                },
                date: {
                    id: "".concat(game.token, "_").concat(game.datetime),
                    value: (React.createElement("div", null,
                        React.createElement("div", null, momentGameTime.format('DD.MM.YYYY')),
                        React.createElement("div", null, momentGameTime.format('HH:mm'))))
                }
            };
        })
    };
}
