import { WEBSOCKET_MESSAGE } from 'components/constants';
import { connect } from 'react-redux';
import Component from './component';
var mapStateToProps = function (state) {
    return {
        companies: state.general.companies,
        players: state.general.players
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketMessage: function (msg) {
            return dispatch({
                type: WEBSOCKET_MESSAGE,
                payload: msg
            });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
