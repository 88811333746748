import { connect } from 'react-redux';
import Component from './component';
import { WEBSOCKET_MESSAGE } from 'components/constants';
import { websocketConnectAction } from '../../../../redux/actions/shared.actions';
var mapStateToProps = function (state) {
    return {
        isLoggedInAsUser: state.playerAuthorization.token || null,
        token: state.playerAuthorization.token
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketConnection: function (token) { return dispatch(websocketConnectAction(token)); },
        websocketMessage: function (msg) { return dispatch({
            type: WEBSOCKET_MESSAGE,
            payload: msg
        }); },
        redirectToDashboard: function () { return dispatch({ type: 'ROUTE', to: '/dashboard' }); },
        redirectToFacilitator: function () {
            return dispatch({ type: 'ROUTE', to: '/facilitator' });
        },
        redirectToToken: function () { return dispatch({ type: 'ROUTE', to: '/token' }); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
