var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from 'components/shared/sidebar/container';
import PropTypes from 'prop-types';
import { SIDEBAR_MODE_ADMIN } from 'components/constants';
import { setT } from 'helpers/translationUtilities';
import GenericModal from 'components/shared/Modal/component';
ViewTemplateListing.propTypes = {
    templates: PropTypes.array,
    getTemplateListing: PropTypes.func.isRequired,
    duplicateTemplate: PropTypes.func.isRequired,
    deleteTemplate: PropTypes.func.isRequired,
    authToken: PropTypes.string.isRequired
};
export default function ViewTemplateListing(_a) {
    var _b = _a.templates, templates = _b === void 0 ? [] : _b, getTemplateListing = _a.getTemplateListing, duplicateTemplate = _a.duplicateTemplate, deleteTemplate = _a.deleteTemplate, authToken = _a.authToken;
    var _c = useState(false), showConfirmationModal = _c[0], setConfirmationModalVisibility = _c[1];
    var _d = useState(null), confirmationCallback = _d[0], setConfirmationCallback = _d[1];
    useEffect(function () {
        getTemplateListing(authToken);
    }, [authToken]);
    var onTemplateDuplicateClick = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, duplicateTemplate(id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, getTemplateListing(authToken)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /** TODO: Add delete when necessary */
    // eslint-disable-next-line no-unused-vars
    var onTemplateDeleteClick = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, deleteTemplate(id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, getTemplateListing(authToken)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    var displayConfirmationModal = function (callback) {
        if (typeof callback === "function") {
            setConfirmationCallback(function () { return callback; });
        }
        setConfirmationModalVisibility(true);
    };
    var handleConfirmationClick = function (event) {
        event.preventDefault();
        if (typeof confirmationCallback === "function") {
            confirmationCallback();
        }
        setConfirmationModalVisibility(false);
    };
    return (React.createElement("div", null,
        React.createElement(Sidebar, { type: SIDEBAR_MODE_ADMIN }),
        React.createElement(GenericModal, { expanded: showConfirmationModal, onOutSideClick: function () { return setConfirmationModalVisibility(false); } },
            React.createElement("div", { className: "d-flex flex-direction-column flex-gap-20" },
                React.createElement("h2", { className: "text-align-center" }, "Are you sure?"),
                React.createElement("div", { className: "d-flex flex-align-items-center flex-justify-content-center flex-gap-20" },
                    React.createElement("button", { onClick: handleConfirmationClick }, setT('Confirm')),
                    React.createElement("button", { onClick: function () { return setConfirmationModalVisibility(false); } }, setT('Cancel'))))),
        React.createElement("div", { className: "wrapper wrapper--alternate" },
            React.createElement("div", { className: "container-fluid" },
                React.createElement("div", { className: "container mar-top-100" },
                    React.createElement("ul", null, templates.map(function (x) { return (React.createElement("li", { key: x._id },
                        x.name,
                        " - ",
                        React.createElement(Link, { to: "/admin/templates/".concat(x._id) }, setT('change')),
                        " / ",
                        React.createElement("span", { onClick: function () { return displayConfirmationModal(function () { return onTemplateDuplicateClick(x._id); }); } }, "Duplicate"))); })))))));
}
