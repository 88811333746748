var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { objectHasProperty } from 'helpers/utilities';
CollapsedTable.propTypes = {
    groups: PropTypes.array,
    tableWrapperClasses: PropTypes.string
};
export default function CollapsedTable(_a) {
    var _b = _a.tableWrapperClasses, tableWrapperClasses = _b === void 0 ? '' : _b, _c = _a.groups, groups = _c === void 0 ? [] : _c;
    return (React.createElement("div", { className: clsx(['collapse-table', tableWrapperClasses]) },
        React.createElement("table", null, groups.map(function (group) { return (React.createElement(Fragment, { key: group.id },
            React.createElement("thead", __assign({ className: "".concat(clsx('collapse-table__group-title', group.addedClasses)) }, group.properties),
                React.createElement("tr", null, group.groupHeaderCells.map(function (groupHeader) { return (React.createElement("th", __assign({ key: groupHeader.id }, groupHeader.properties), getGroupHeaderContent(groupHeader))); }))),
            group.isRowOpen ? getGroupMainContent(group) : null)); }))));
}
function getGroupMainContent(group) {
    return (React.createElement("tbody", { className: "".concat(clsx('collapse-table__inner-area', group.innerAreaClasses)) },
        React.createElement("tr", { className: "collapse-table__inner-head" }, group.tableColumns.map(function (tableColumn) { return (React.createElement("td", { key: "".concat(group.id, "_").concat(tableColumn.name) },
            React.createElement("div", { className: clsx(['collapse-table__cell-data', tableColumn.classNames]) },
                tableColumn.label,
                tableColumn.renderComponent ? tableColumn.renderComponent : null))); })),
        group.rows.map(function (row) { return getTableRow(row, group); })));
}
function getTableRow(row, group) {
    var customRowProps = getRowCustomProperties(row.customProperties);
    return (React.createElement("tr", __assign({}, customRowProps, { key: row.key, className: clsx([
            'collapse-table__row',
            customRowProps && customRowProps.className
        ]) }), getTableRowCells(row, group.tableColumns)));
}
function getRowCustomProperties(props) {
    if (!props) {
        return;
    }
    var cellProperties = props.cellProperties, filteredProps = __rest(props, ["cellProperties"]);
    return filteredProps;
}
function getTableRowCells(row, tableColumns) {
    return tableColumns.map(function (column) {
        var cellProperties = getSingleCellProperties(row, [column.name]);
        var cellKey = "".concat(row.key, "_").concat(column.name);
        if (row[column.name] && objectHasProperty(row[column.name], 'component')) {
            return (React.createElement("td", __assign({}, cellProperties, { key: cellKey }),
                React.createElement("div", { className: "collapse-table__cell-data" }, row[column.name].component)));
        }
        var isHtmlContent = ["description"].includes(column.name);
        return (React.createElement("td", __assign({}, cellProperties, { key: cellKey }), isHtmlContent ?
            (React.createElement("div", { className: "collapse-table__cell-data", dangerouslySetInnerHTML: { __html: row[column.name] } })) :
            (React.createElement("div", { className: "collapse-table__cell-data" }, row[column.name]))));
    });
}
function getSingleCellProperties(row, columnName) {
    return __assign({}, (row.customProperties &&
        row.customProperties.cellProperties &&
        row.customProperties.cellProperties[columnName]));
}
function getGroupHeaderContent(item) {
    if (objectHasProperty(item, 'component')) {
        return item.component;
    }
    return item.title;
}
