var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import NumericSelectionInput from 'components/shared/transaction/new/process/numeric-selection/item/component';
NumericSelection.propTypes = {
    onChange: PropTypes.func.isRequired,
    presetValue: PropTypes.number
};
export default function NumericSelection(_a) {
    var onChange = _a.onChange, _b = _a.presetValue, presetValue = _b === void 0 ? 0 : _b;
    var _c = useState([
        '0',
        '0',
        '0',
        '0',
        '0'
    ]), numericValueArray = _c[0], setNumericValueArray = _c[1];
    var _d = useState(null), focusedInputIndex = _d[0], setFocusedInputIndex = _d[1];
    var INPUT_AMOUNT = 5;
    useEffect(function () {
        var newValues = fillNumericValueArray(presetValue.toString().split(''));
        setNumericValueArray(newValues);
    }, [presetValue]);
    function fillNumericValueArray(splitValues) {
        var newArray = __spreadArray([], splitValues, true);
        if (splitValues.length < INPUT_AMOUNT) {
            newArray.unshift('0');
            return fillNumericValueArray(newArray);
        }
        return splitValues;
    }
    function getSelectionItems() {
        var numericsHTMLArray = [];
        var _loop_1 = function (i) {
            numericsHTMLArray.push(React.createElement(NumericSelectionInput, { key: i, onClick: function () { return setFocusedInputIndex(i); }, wrapperClasses: clsx(i === 1 && 'increased-spacing'), onItemChange: onInputChange, inputIndex: i, presetInputValue: parseInt(numericValueArray[i]), focused: i === focusedInputIndex }));
        };
        for (var i = 0; i < INPUT_AMOUNT; i++) {
            _loop_1(i);
        }
        return numericsHTMLArray;
    }
    function onInputChange(numberIndex, value, focusOnNextInput) {
        if (numberIndex !== INPUT_AMOUNT && focusOnNextInput) {
            setFocusedInputIndex(focusedInputIndex + 1);
        }
        var newNumericValueArray = __spreadArray([], numericValueArray, true);
        newNumericValueArray[numberIndex] = value;
        setNumericValueArray(newNumericValueArray);
        onChange(+newNumericValueArray.join(''));
    }
    return (React.createElement("div", { className: "numeric-selection" },
        React.createElement("div", { className: "numeric-selection__list" }, getSelectionItems())));
}
