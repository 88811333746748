var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { TASK_REQUEST_DONE, TASK_REQUEST_SKIP } from 'components/player/task/constants';
import { setT } from 'helpers/translationUtilities';
import { reformatTinyMCEiframe } from 'helpers/utilities';
var TaskModalContent = /** @class */ (function (_super) {
    __extends(TaskModalContent, _super);
    function TaskModalContent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TaskModalContent.prototype.componentDidMount = function () {
        reformatTinyMCEiframe('.modal-content__inner iframe');
    };
    TaskModalContent.prototype.componentDidUpdate = function () {
        reformatTinyMCEiframe('.modal-content__inner iframe');
    };
    TaskModalContent.prototype.setModalFooter = function () {
        var _a = this.props, fields = _a.fields, onUpdate = _a.onUpdate, action = _a.action, isPlayerTask = _a.isPlayerTask, closeModal = _a.closeModal, _b = _a.redirectToTasks, redirectToTasks = _b === void 0 ? function () { } : _b;
        var type = isPlayerTask ? 1 : 2;
        var footerClasses = "modal-content__footer modal-content__footer--task text-align-center text-align-right-md";
        if (fields.status === 0 || fields.status === 2) {
            if (action === 'confirm') {
                return (React.createElement("div", { className: footerClasses },
                    React.createElement("button", { className: clsx('main-button main-button--lg', !fields.skippable && 'main-button--disabled'), onClick: fields.skippable
                            ? function () { return onUpdate(fields.id, type, TASK_REQUEST_SKIP); }
                            : null }, setT('skip')),
                    React.createElement("button", { className: "main-button main-button--inline main-button--lg", onClick: function () { closeModal(); redirectToTasks(); } }, setT('accept'))));
            }
            else if (action === 'finalize') {
                return (React.createElement("div", { className: footerClasses },
                    React.createElement("button", { className: "main-button main-button--inline main-button--lg main-button--secondary", onClick: function () { return closeModal(); } }, setT('close')),
                    React.createElement("button", { className: "main-button main-button--inline main-button--lg", onClick: function () { return onUpdate(fields.id, type, TASK_REQUEST_DONE); } }, setT('done'))));
            }
        }
        return (React.createElement("div", { className: footerClasses },
            React.createElement("button", { className: "main-button main-button--inline main-button--lg main-button--secondary", onClick: function () { return closeModal(); } }, setT('close'))));
    };
    TaskModalContent.prototype.render = function () {
        var _a = this.props, fields = _a.fields, icon = _a.icon;
        if (!fields) {
            return false;
        }
        return (React.createElement(Fragment, null,
            React.createElement("div", { className: "modal__content modal__content--fixed-footer" },
                React.createElement("div", { className: "modal__heading" },
                    React.createElement("span", { className: "underlined-subtitle" }, setT('task'))),
                React.createElement("div", { className: "modal__content-wrap" },
                    React.createElement("div", { className: "modal__header" }, icon ? React.createElement("div", { className: "icon icon--img icon--".concat(icon) }) : null),
                    React.createElement("div", { className: "modal-content__inner text-align-left", dangerouslySetInnerHTML: { __html: fields.description } }))),
            this.setModalFooter()));
    };
    TaskModalContent.propTypes = {
        fields: PropTypes.object,
        onUpdate: PropTypes.func,
        isPlayerTask: PropTypes.bool.isRequired,
        action: PropTypes.string.isRequired,
        icon: PropTypes.string,
        closeModal: PropTypes.func,
        redirectToTasks: PropTypes.func
    };
    TaskModalContent.defaultProps = {
        fields: null,
        onUpdate: null,
        icon: '',
        closeModal: function () { }
    };
    return TaskModalContent;
}(Component));
export default TaskModalContent;
