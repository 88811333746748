import { PLAYER_RECEIVE_TRANSACTION, PLAYER_RECEIVE_TRANSACTION_LIST } from 'components/constants';
export var transactionTypes = {
    transactionListing: {
        type: PLAYER_RECEIVE_TRANSACTION_LIST,
        payload: function (payload) { return payload.transactionListing; }
    },
    transaction: {
        type: PLAYER_RECEIVE_TRANSACTION,
        payload: function (payload) { return payload.transaction; }
    }
};
