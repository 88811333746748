import LanguageService from 'services/LanguageService/LanguageService';
export function parseTranslation(translation, language) {
    var single = translation.find(function (x) {
        return x.language === language;
    });
    if (single) {
        return single.value;
    }
    return '';
}
export function setT(key) {
    return LanguageService.t(key);
}
