import { TASK_REQUIRE_COMPANY, TASK_REQUIRE_NEW, TASK_REQUIRE_PLAYER } from 'components/player/task/constants';
export var onNewTaskClick = function (game) {
    if (game.taskSettings.playerCards && game.taskSettings.companyCards) {
        return TASK_REQUIRE_NEW;
    }
    if (game.taskSettings.playerCards) {
        return TASK_REQUIRE_PLAYER;
    }
    return TASK_REQUIRE_COMPANY;
};
