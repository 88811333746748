import { connect } from 'react-redux';
import Component from './component';
import { adminCreateCompanyAction, adminRemoveCompanyAction, adminUpdateCompanyAction } from '../../../../../../../redux/actions/admin/admin.company.actions';
var mapStateToProps = function (state) {
    return {
        templateId: state.admin.singleTemplate._id
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        removeCompany: function (id, templateId) { return dispatch(adminRemoveCompanyAction(id, templateId)); },
        updateCompany: function (id, payload) { return dispatch(adminUpdateCompanyAction(id, payload)); },
        createCompany: function (payload) { return dispatch(adminCreateCompanyAction(payload)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
