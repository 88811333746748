var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { connect } from 'react-redux';
import Component from './component';
var mapStateToProps = function (state) {
    return {
        player: {
            id: state.player.id,
            name: state.player.name,
            incomeBalance: state.player.incomeBalance,
            expenseBalance: state.player.expenseBalance,
            totalBalance: state.player.totalBalance,
            company: state.player.company
        },
        company: {
            incomeBalance: state.player.company.incomeBalance,
            expenseBalance: state.player.company.expenseBalance,
            totalBalance: state.player.company.totalBalance,
            transactions: state.player.company.transactions
        },
        playersAndCompanies: __spreadArray(__spreadArray([], state.general.players, true), state.general.companies, true),
        transactions: state.player.transactions,
        players: state.general.players,
        companies: state.general.companies,
        stages: state.game.stages
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirectToPayment: function (type, accountId) {
            return dispatch({ type: 'ROUTE', to: "/money/".concat(type, "/").concat(accountId, "/pay") });
        },
        redirectToMoney: function (type, accountId) {
            return dispatch({ type: 'ROUTE', to: "/money/".concat(type, "/").concat(accountId) });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
