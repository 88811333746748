import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/shared/avatar/component';
import clsx from 'clsx';
PaymentAvatar.propTypes = {
    thumbnail: PropTypes.string,
    name: PropTypes.string,
    avatarPosition: PropTypes.string,
    payeeString: PropTypes.string,
    addedClasses: PropTypes.string
};
PaymentAvatar.defaultProps = {
    thumbnail: '',
    name: '',
    avatarPosition: '',
    payeeString: '',
    addedClasses: ''
};
export default function PaymentAvatar(props) {
    return (React.createElement("div", { className: clsx(['payee', 'text-align-center', props.addedClasses]) },
        React.createElement("div", { className: "payee__icon" },
            React.createElement("div", { className: "payee-icon__inner" },
                React.createElement(Avatar, { src: props.thumbnail, position: props.avatarPosition, addedClasses: 'green-borders' }))),
        React.createElement("div", { className: "payee__profile" },
            React.createElement("div", { className: "payee__name" }, props.name),
            React.createElement("div", { className: "payee__role" }, props.payeeString))));
}
