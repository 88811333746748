import { Root } from 'protobufjs';
export function socketEncode(type, payload) {
    return new Promise(function (resolve, reject) {
        var root = Root.fromJSON(require('assets/protocol/to-server.json'));
        var message = root.lookupType(type);
        var error = message.verify(payload);
        if (error) {
            reject(error);
        }
        var buffer = message.encode(payload).finish();
        resolve(buffer);
    });
}
export function socketDecode(type, buffer) {
    return new Promise(function (resolve) {
        var root = Root.fromJSON(require('assets/protocol/from-server.json'));
        var message = root.lookupType(type);
        var decoded = message.decode(buffer);
        resolve(decoded);
    });
}
