var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/shared/header/container';
import FacilitatorSwitchView from 'components/manager/facilitator/switch-view/container';
import { UserRouter } from 'components/routers/UserRouter';
import { history } from '../../../../store';
import { USER_RANK_ADMIN, USER_RANK_FACILITATOR } from 'components/permissions/types';
var ViewWrapper = /** @class */ (function (_super) {
    __extends(ViewWrapper, _super);
    function ViewWrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ViewWrapper.prototype.shouldComponentUpdate = function () {
        return true;
    };
    ViewWrapper.prototype.render = function () {
        var rank = this.props.rank;
        var isManagerRank = [USER_RANK_ADMIN, USER_RANK_FACILITATOR].includes(rank);
        return (React.createElement("div", { className: "wrapper" },
            React.createElement("div", { className: "container-fluid" },
                React.createElement(Header, null),
                React.createElement(UserRouter, { history: history, rank: rank }),
                isManagerRank ? React.createElement(FacilitatorSwitchView, null) : null)));
    };
    ViewWrapper.propTypes = {
        token: PropTypes.string,
        rank: PropTypes.string.isRequired,
        websocketConnection: PropTypes.func.isRequired
    };
    ViewWrapper.defaultProps = {
        token: null,
    };
    return ViewWrapper;
}(Component));
export default ViewWrapper;
