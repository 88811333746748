var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import NewTransactionInitialized from './process/container';
import TransactionFinalize from './finalize/container';
import PlayersList from 'components/shared/transaction/new/playersList/container';
import { NEW_TRANSACTION_CONFIRMATION_VIEW, NEW_TRANSACTION_INITIAL_VIEW, NEW_TRANSACTION_RECIPIENT_SELECTION_VIEW, NEW_TRANSACTION_TYPE_SELECTION_VIEW, NEW_TRANSACTION_WALLET_FACILITATOR, TRANSACTION_TYPE_DEPOSIT, TRANSACTION_TYPE_REGULAR_TRANSACTION, TRANSACTION_TYPE_WITHDRAW } from 'components/player/money/transactions/types';
import { getWalletKind, isBankViewingOtherPlayers } from 'components/shared/transaction/new/utilities';
import TransactionType from 'components/shared/transaction/new/type/component';
NewTransaction.propTypes = {
    match: PropTypes.object,
    isBank: PropTypes.bool.isRequired,
    bank: PropTypes.object.isRequired,
    type: PropTypes.string,
    accountId: PropTypes.string,
    isFacilitatorBulkView: PropTypes.bool
};
export default function NewTransaction(_a) {
    var _b = _a.type, type = _b === void 0 ? 0 : _b, _c = _a.accountId, accountId = _c === void 0 ? '' : _c, isBank = _a.isBank, bank = _a.bank, _d = _a.isFacilitatorBulkView, isFacilitatorBulkView = _d === void 0 ? false : _d;
    var bankVariables;
    var walletType = isFacilitatorBulkView ? NEW_TRANSACTION_WALLET_FACILITATOR : getWalletKind(type); // Company or player, comes from routes. In Bank or facilitator, viewing others, this doesnt exsist.
    var isBankViewingPlayer = isBankViewingOtherPlayers(walletType, isBank);
    if (isBankViewingPlayer && !isFacilitatorBulkView) {
        bankVariables = __assign(__assign({}, bank), { currentlyViewedPlayer: accountId });
    }
    var _e = useState(isBankViewingPlayer && !isFacilitatorBulkView
        ? NEW_TRANSACTION_TYPE_SELECTION_VIEW
        : NEW_TRANSACTION_INITIAL_VIEW), step = _e[0], setStep = _e[1];
    var _f = useState({
        message: '',
        amount: 0,
        from: '',
        to: [],
        type: isFacilitatorBulkView ? TRANSACTION_TYPE_DEPOSIT : TRANSACTION_TYPE_REGULAR_TRANSACTION,
        kind: walletType
    }), payload = _f[0], setPayload = _f[1];
    function updatePayload(updatedPayload, newStep) {
        setPayload(__assign(__assign({}, payload), updatedPayload));
        if (newStep) {
            setStep(newStep);
        }
    }
    function onTransactionTypeSelect(type) {
        var updatedPayload = {
            type: type,
            message: setPayloadMessage(type),
            to: TRANSACTION_TYPE_REGULAR_TRANSACTION !== type
                ? [bankVariables.currentlyViewedPlayer]
                : [],
            from: bankVariables.currentlyViewedPlayer
        };
        updatePayload(updatedPayload, NEW_TRANSACTION_INITIAL_VIEW);
    }
    function getView() {
        switch (step) {
            default:
                break;
            case NEW_TRANSACTION_TYPE_SELECTION_VIEW:
                return (React.createElement(TransactionType, { onTypeSelect: function (type) { return onTransactionTypeSelect(type); } }));
            case NEW_TRANSACTION_INITIAL_VIEW:
                return (React.createElement(NewTransactionInitialized, { urlParams: { type: type, accountId: accountId }, bankVariables: bankVariables, kind: walletType, presetPayload: payload, changeStep: function (step) { return setStep(step); }, updateMainPayload: updatePayload, setStep: function (step) { return setStep(step); }, isFacilitatorBulkView: isFacilitatorBulkView }));
            case NEW_TRANSACTION_RECIPIENT_SELECTION_VIEW:
                return (React.createElement(PlayersList, { changeStep: function (step) { return setStep(step); }, updateMainPayload: updatePayload, currentAccountId: accountId, isFacilitatorBulkView: isFacilitatorBulkView, selectedPlayers: payload.to }));
            case NEW_TRANSACTION_CONFIRMATION_VIEW:
                return (React.createElement(TransactionFinalize, { type: payload.type, bankVariables: bankVariables, urlParams: { type: type, accountId: accountId }, payload: payload, updateMainPayload: updatePayload, setStep: function (step) { return setStep(step); }, isFacilitatorBulkView: isFacilitatorBulkView }));
        }
    }
    return (React.createElement(Fragment, null, getView()));
}
function setPayloadMessage(type) {
    switch (type) {
        default:
            return '';
        case TRANSACTION_TYPE_DEPOSIT:
            return 'Deposit';
        case TRANSACTION_TYPE_WITHDRAW:
            return 'Withdraw';
    }
}
