import { connect } from 'react-redux';
import Component from './component';
import { adminGetGameTasksAction } from '../../../../../../../redux/actions/admin/admin.actions';
var mapStateToProps = function (state) {
    return {
        template: state.admin.singleTemplate
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        getGameTasks: function (gameID) { return dispatch(adminGetGameTasksAction(gameID)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
