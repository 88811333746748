import SocketService from '../SocketService';
export var movePlayersToCompany = function (players, companyID) {
    return SocketService.createEncodedMessage({
        kind: 'companyJoin',
        companyJoin: {
            companyID: companyID,
            players: players,
            inGame: true
        }
    });
};
