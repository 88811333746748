import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { setT } from 'helpers/translationUtilities';
FilterButtons.propTypes = {
    setTableView: PropTypes.func.isRequired,
    tableView: PropTypes.number
};
export default function FilterButtons(_a) {
    var setTableView = _a.setTableView, _b = _a.tableView, tableView = _b === void 0 ? 0 : _b;
    function setActiveClass(step) {
        return tableView === step && 'filterbuttons__single--active';
    }
    return (React.createElement("div", { className: "filterbuttons" },
        React.createElement("div", { onClick: function () { return setTableView(0); }, className: clsx('filterbuttons__single', setActiveClass(0)) }, setT('all')),
        React.createElement("div", { onClick: function () { return setTableView(1); }, className: clsx('filterbuttons__single', setActiveClass(1)) }, setT('personal')),
        React.createElement("div", { onClick: function () { return setTableView(2); }, className: clsx('filterbuttons__single', setActiveClass(2)) }, setT('company'))));
}
