import { SIDEBAR_MODE_ADMIN, SIDEBAR_MODE_FACILITATOR, SIDEBAR_MODE_PLAYER } from 'components/constants';
export var getLinkVariations = function (_a) {
    var _b;
    var _c = _a.variables, playerId = _c.playerId, companyId = _c.companyId, t = _c.t, _d = _a.conditions, isBank = _d.isBank, tasksPresent = _d.tasksPresent;
    return _b = {},
        _b[SIDEBAR_MODE_PLAYER] = [
            {
                key: "player-dash",
                to: '/dashboard',
                icon: 'stacks',
                text: t('dashboard')
            },
            {
                key: "player-money",
                to: "/money/player/".concat(playerId),
                icon: 'money',
                text: t('my_money')
            },
            {
                key: "player-comoney",
                to: "/money/company/".concat(companyId),
                icon: 'company_money',
                text: t('company_money')
            },
            {
                key: "player-task",
                to: '/task',
                icon: 'list',
                text: t('task'),
                condition: tasksPresent
            },
            {
                key: "player-banking",
                to: '/banking',
                icon: 'building',
                text: t('banking'),
                condition: isBank
            }
        ],
        _b[SIDEBAR_MODE_ADMIN] = [
            {
                key: "admin-stacks",
                to: '/admin/templates',
                icon: 'stacks',
                text: t('templates')
            },
            {
                key: "admin-users",
                to: '/admin/users',
                icon: 'user',
                text: t('users')
            }
        ],
        _b[SIDEBAR_MODE_FACILITATOR] = [
            {
                key: 'fa-dash',
                to: '/facilitator',
                icon: 'stacks',
                text: t('dashboard')
            },
            {
                key: 'fa-part',
                to: '/facilitator/participants',
                icon: 'people',
                text: t('participants')
            },
            {
                key: 'fa-rep',
                to: '/reports',
                icon: 'stacks',
                text: t('reports')
            },
            {
                key: 'fa-games',
                to: '/facilitator/games',
                icon: 'controller',
                text: t('games')
            }
        ],
        _b;
};
