import { connect } from 'react-redux';
import Component from './component';
import { UI_TOGGLE_STAGE_INFO_BAR } from 'components/constants';
import { getActiveStage } from 'helpers/stageUtilities';
var mapStateToProps = function (state) {
    return {
        adminID: state.userAuthorization ? state.userAuthorization.userSession.id : null,
        token: state.playerAuthorization.token,
        game: state.game,
        player: state.player,
        playerThumbnail: state.player.thumbnail,
        companyThumbnail: state.player.company.thumbnail,
        ownToken: state.player.ownToken,
        stages: state.game.stages,
        activeStage: getActiveStage(state.game.stages)
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirect: function () { return dispatch({ type: 'ROUTE', to: '/token' }); },
        toggleStageBar: function () { return dispatch({ type: UI_TOGGLE_STAGE_INFO_BAR }); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
