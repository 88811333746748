import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
TableLabel.propTypes = {
    addedClasses: PropTypes.string,
    label: PropTypes.string
};
export default function TableLabel(_a) {
    var _b = _a.addedClasses, addedClasses = _b === void 0 ? '' : _b, _c = _a.label, label = _c === void 0 ? '' : _c;
    return (React.createElement("span", { className: clsx(['transaction__amount', addedClasses]) }, label));
}
