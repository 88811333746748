var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
var GenericModal = /** @class */ (function (_super) {
    __extends(GenericModal, _super);
    function GenericModal(props) {
        var _this = _super.call(this, props) || this;
        _this.Ref = React.createRef();
        _this.outsideClickHandler = _this.outsideClickHandler.bind(_this);
        return _this;
    }
    GenericModal.prototype.shouldComponentUpdate = function () {
        return true;
    };
    GenericModal.prototype.componentDidMount = function () {
        document.addEventListener('mousedown', this.outsideClickHandler);
    };
    GenericModal.prototype.componentWillUnmount = function () {
        document.removeEventListener('mousedown', this.outsideClickHandler);
    };
    GenericModal.prototype.outsideClickHandler = function (_a) {
        var target = _a.target;
        if (this.Ref && !this.Ref.contains(target)) {
            this.props.onOutSideClick();
        }
    };
    GenericModal.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: clsx(['lightbox', this.props.expanded && 'active']) },
            React.createElement("div", { className: "modal ".concat(this.props.modalClasses) },
                React.createElement("div", { className: "modal__inner", ref: function (node) { return (_this.Ref = node); } }, this.props.children))));
    };
    GenericModal.propTypes = {
        children: PropTypes.object,
        expanded: PropTypes.bool,
        onOutSideClick: PropTypes.func,
        modalClasses: PropTypes.string
    };
    GenericModal.defaultProps = {
        children: {},
        expanded: false,
        onOutSideClick: function () { },
        modalClasses: ''
    };
    return GenericModal;
}(Component));
export default GenericModal;
