import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
NumericSelectionInput.propTypes = {
    inputIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    onItemChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    focused: PropTypes.bool,
    wrapperClasses: PropTypes.any,
    presetInputValue: PropTypes.number
};
export default function NumericSelectionInput(_a) {
    var inputIndex = _a.inputIndex, onItemChange = _a.onItemChange, onClick = _a.onClick, focused = _a.focused, wrapperClasses = _a.wrapperClasses, _b = _a.presetInputValue, presetInputValue = _b === void 0 ? 0 : _b;
    var _c = useState(0), inputValue = _c[0], setInputValue = _c[1];
    var input = useRef(null);
    useEffect(function () {
        if (focused && input) {
            input.focus();
        }
    }, [focused]);
    useEffect(function () {
        setInputValue(presetInputValue !== 0 ? parseInt(presetInputValue) : 0);
    }, [presetInputValue]);
    function filterReceivedValue(lastKeyPressed, focusOnNextInput) {
        if (focusOnNextInput === void 0) { focusOnNextInput = false; }
        if (!RegExp('[0-9]').test(lastKeyPressed)) {
            return;
        }
        setInputValue(lastKeyPressed.toString());
        onItemChange(inputIndex, lastKeyPressed, focusOnNextInput);
    }
    return (React.createElement("div", { className: clsx('numeric-selection__item', wrapperClasses) },
        React.createElement("div", { className: "icon icon--increment", onClick: function () {
                return filterReceivedValue(+inputValue + 1 > 9 ? 0 : +inputValue + 1);
            } }),
        React.createElement("div", { onKeyUp: function (e) { return filterReceivedValue(e.key, true); } },
            React.createElement("input", { onClick: onClick, className: "numeric-selection-item__value", type: "number", value: inputValue, onChange: function () { }, ref: function (node) {
                    input = node;
                } })),
        React.createElement("div", { className: "icon icon--decrement", onClick: function () {
                return filterReceivedValue(inputValue === 0 ? 9 : +inputValue - 1);
            } })));
}
