import { connect } from 'react-redux';
import Component from './component';
import { websocketConnectAction } from '../../../../redux/actions/shared.actions';
var mapStateToProps = function (state) {
    return {
        token: state.playerAuthorization.token,
        rank: state.userAuthorization.userSession.rank,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketConnection: function (token) { return dispatch(websocketConnectAction(token)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
