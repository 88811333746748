import React, { useEffect } from 'react';
import Sidebar from 'components/shared/sidebar/container';
import PropTypes from 'prop-types';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
ViewHome.propTypes = {
    websocketConnection: PropTypes.func.isRequired,
    redirectToDashboard: PropTypes.func.isRequired,
    redirectToFacilitator: PropTypes.func.isRequired,
    redirectToToken: PropTypes.func.isRequired,
    isLoggedInAsUser: PropTypes.string.isRequired,
    token: PropTypes.string
};
export default function ViewHome(_a) {
    var redirectToDashboard = _a.redirectToDashboard, redirectToFacilitator = _a.redirectToFacilitator, redirectToToken = _a.redirectToToken, isLoggedInAsUser = _a.isLoggedInAsUser, _b = _a.token, token = _b === void 0 ? '' : _b;
    useEffect(function () {
        if (token && !isLoggedInAsUser) {
            redirectToDashboard();
            return;
        }
        if (isLoggedInAsUser) {
            redirectToFacilitator();
            return;
        }
        redirectToToken();
    }, [token, isLoggedInAsUser]);
    return (React.createElement("div", null,
        React.createElement(Sidebar, { type: SIDEBAR_MODE_PLAYER }),
        "Nothing here, sorry :("));
}
