var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { WEBSOCKET_MESSAGE, ALLOW_GAME_INIT } from 'components/constants';
import { connect } from 'react-redux';
import Component from './component';
var mapStateToProps = function (state) {
    return {
        players: state.general.players,
        companies: state.general.companies,
        transactions: state.player.bank.transactions,
        bank: state.player.bank,
        items: __spreadArray(__spreadArray([], state.general.players, true), state.general.companies, true)
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketMessage: function (msg) {
            return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg });
        },
        redirectionGameJoin: function (gameToken) { return dispatch({ type: 'ROUTE', to: '/join/' + gameToken }); },
        allowGameJoin: function (payload) { return dispatch({ type: ALLOW_GAME_INIT, payload: payload }); },
        redirect: function (route) {
            return dispatch({
                type: 'ROUTE',
                to: "".concat(route)
            });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
