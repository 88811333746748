import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
Checkbox.propTypes = {
    selected: PropTypes.bool
};
export default function Checkbox(_a) {
    var _b = _a.selected, selected = _b === void 0 ? false : _b;
    return (React.createElement("div", { className: "checkbox" },
        React.createElement("div", { className: clsx(['checkbox__block', selected && 'checkbox__block--checked']) })));
}
