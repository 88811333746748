var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'components/shared/sidebar/container';
import { SIDEBAR_MODE_FACILITATOR } from 'components/constants';
import CollapsedTable from 'components/shared/tables/collapsedTable/component';
import StageTableConfig from 'components/configs/collapseTable/stageTable/StageTableConfig';
import GameStageControls from 'components/shared/gameStageControls/GameStageControls';
import { activeStageUpdateRequest } from '../../../../socketConnection/socketMessageRequests/stageRequests';
import { getActiveStage, useAutoOpenedGameStage } from 'helpers/stageUtilities';
ViewFacilitatorDashboard.propTypes = {
    stages: PropTypes.array,
    websocketMessage: PropTypes.func.isRequired,
    currentUserRank: PropTypes.string,
    currentlyActiveState: PropTypes.object
};
export default function ViewFacilitatorDashboard(_a) {
    var _b = _a.stages, stages = _b === void 0 ? [] : _b, websocketMessage = _a.websocketMessage, _c = _a.currentUserRank, currentUserRank = _c === void 0 ? '' : _c, _d = _a.currentlyActiveState, currentlyActiveState = _d === void 0 ? {} : _d;
    var activeStage = getActiveStage(stages);
    var _e = useState([activeStage && activeStage.id]), openRows = _e[0], setOpenRows = _e[1];
    useAutoOpenedGameStage(currentlyActiveState, setOpenRows, false);
    function onGroupClick(id) {
        var rows = __spreadArray([], openRows, true);
        var index = openRows.findIndex(function (row) { return row === id; });
        if (index !== -1) {
            rows.splice(index, 1);
            setOpenRows(rows);
            return;
        }
        rows.push(id);
        setOpenRows(rows);
    }
    return (React.createElement(Fragment, null,
        React.createElement(Sidebar, { type: SIDEBAR_MODE_FACILITATOR }),
        React.createElement(GameStageControls, { stages: stages, activeStage: activeStage, manipulateGameStage: function (action) { return manipulateGameStage(action, websocketMessage); }, currentUserRank: currentUserRank }),
        React.createElement(CollapsedTable, { groups: StageTableConfig({ stages: stages, onGroupClick: onGroupClick, openRows: openRows, activeStage: activeStage }) })));
}
function manipulateGameStage(action, websocketMessage) {
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = websocketMessage;
                    return [4 /*yield*/, activeStageUpdateRequest(action)];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [2 /*return*/];
            }
        });
    });
}
