import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Avatar from 'components/shared/avatar/component';
import { getPlayersTokenFromCompany } from 'helpers/tokenUtilities';
import { CSSTransition } from 'react-transition-group';
import { Trans } from 'react-i18next';
var eyeIcon;
FacilitatorSwitchView.propTypes = {
    companies: PropTypes.array,
    players: PropTypes.array.isRequired,
    joinGameWithToken: PropTypes.func.isRequired,
    redirectToFacilitator: PropTypes.func.isRequired
};
export default function FacilitatorSwitchView(_a) {
    var _b = _a.companies, companies = _b === void 0 ? [] : _b, players = _a.players, joinGameWithToken = _a.joinGameWithToken, redirectToFacilitator = _a.redirectToFacilitator;
    var _c = useState(false), menuOpen = _c[0], setMenuOpen = _c[1];
    var elementRef = useRef(null);
    function onItemClick(id) {
        setMenuOpen(false);
        if (id) {
            joinGameWithToken(getPlayersTokenFromCompany(id, players));
            return;
        }
        redirectToFacilitator();
    }
    function outsideClickHandler(_a) {
        var target = _a.target;
        if (elementRef.current && !elementRef.current.contains(target)) {
            setMenuOpen(false);
        }
    }
    useEffect(function () {
        document.addEventListener('mousedown', outsideClickHandler);
        eyeIcon = require('assets/img/icons/icon_eye.svg?fill=#ffffff');
        return function () {
            document.removeEventListener('mousedown', outsideClickHandler);
        };
    }, []);
    return (React.createElement("div", { className: "switch", ref: elementRef },
        React.createElement("div", { className: clsx(['switch__icon', menuOpen && 'switch__icon--active']), onClick: function () { return setMenuOpen(!menuOpen); } },
            React.createElement("div", { className: "icon", style: { 'backgroundImage': "url(".concat(eyeIcon, ")") } })),
        React.createElement(CSSTransition, { in: menuOpen, timeout: 500, classNames: "anim-fade", unmountOnExit: true },
            React.createElement("div", { className: clsx(['switch__modal']) },
                React.createElement("ul", { className: "switch__list" },
                    companies.map(function (company) { return (React.createElement("li", { className: "switch__item", key: company.id, onClick: function () { return onItemClick(company.id); } },
                        React.createElement(Avatar, { src: company.thumbnail }),
                        React.createElement("div", { className: "flex-grow-1" }, company.name))); }),
                    React.createElement("li", { className: "switch__item switch__item--border", onClick: function () { return onItemClick(); } },
                        React.createElement(Avatar, null),
                        React.createElement("div", { className: "flex-grow-1" },
                            React.createElement(Trans, { i18nKey: "facilitator" }))))))));
}
