var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { GAME_RECEIVE_INFORMATION, GAME_UPDATE_STAGES, GAME_SET_CURRENT_STAGE_PAUSE, GAME_RECEIVE_SINGLE_GAME_FROM_API, GAME_RECEIVE_STAGES_FROM_API, GAME_PURGE_ALL_DATA } from 'components/constants';
import { parseTranslation } from 'helpers/translationUtilities';
var initialState = {
    id: '',
    name: '',
    stage: [],
    stages: [],
    startAt: null,
    settings: {
        companyToCompany: false,
        companyToPlayer: false,
        playerToCompany: false,
        playerToPlayer: false
    },
    token: ''
};
export default (function (state, _a) {
    if (state === void 0) { state = initialState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        default:
            return state;
        case GAME_UPDATE_STAGES: {
            return __assign(__assign({}, state), { stages: setFirstStateStartedAtToGameStartedAt(payload, state) });
        }
        case GAME_SET_CURRENT_STAGE_PAUSE: {
            return __assign(__assign({}, state), { stages: getModifiedGameStages(payload.gameStageChange, state) });
        }
        case GAME_RECEIVE_STAGES_FROM_API: {
            var stages = __spreadArray([], payload, true);
            for (var i in stages) {
                stages[i].stageName = parseTranslation(stages[i].stageName, 'et_EE');
            }
            return __assign(__assign({}, state), { stages: stages });
        }
        case GAME_RECEIVE_INFORMATION: {
            return __assign(__assign({}, state), { stages: [], id: payload.id || payload._id, name: payload.name, startAt: payload.startAt, settings: payload.settings, token: payload.token, taskSettings: payload.taskSettings });
        }
        case GAME_PURGE_ALL_DATA: {
            return __assign({}, initialState);
        }
        case GAME_RECEIVE_SINGLE_GAME_FROM_API: {
            var stages = payload.stages.map(function (stage) {
                return __assign(__assign({}, stage), { title: parseTranslation(stage.title, 'et_EE') });
            });
            return __assign(__assign({}, state), { stages: stages, id: payload._id, name: payload.name, settings: {
                    companyToCompany: payload.transactionSettings.companyToCompany,
                    companyToPlayer: payload.transactionSettings.companyToPlayer,
                    playerToCompany: payload.transactionSettings.playerToCompany,
                    playerToPlayer: payload.transactionSettings.playerToPlayer
                }, token: payload.token });
        }
    }
});
var setFirstStateStartedAtToGameStartedAt = function (stages, game) {
    var modifiedStages = __spreadArray([], stages, true);
    if (modifiedStages[0] && !modifiedStages[0].startedAt) {
        modifiedStages[0].startedAt = game.startAt;
    }
    return modifiedStages;
};
var getModifiedGameStages = function (gameStageChange, game) {
    var stageId = gameStageChange.stageId, StageStatus = gameStageChange.StageStatus;
    var stages = __spreadArray([], game.stages, true);
    var index = stages.findIndex(function (x) { return x.id === stageId; });
    if (index !== -1) {
        if (StageStatus === 'STARTED') {
            stages[index].pausedAt = false;
        }
        else if (StageStatus === 'PAUSED') {
            stages[index].pausedAt = true;
        }
    }
    return setFirstStateStartedAtToGameStartedAt(stages, game);
};
