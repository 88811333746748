import SocketService from '../SocketService';
var playerRequestAddPlayer = function (name, companyID) {
    return SocketService.createEncodedMessage({
        kind: 'newPlayer',
        newPlayer: {
            name: name,
            companyID: companyID
        }
    });
};
export { playerRequestAddPlayer };
