import { USER_RANK_ADMIN, USER_RANK_FACILITATOR } from 'components/permissions/types';
export var findPlayerById = function (id, playersArray) {
    return playersArray.find(function (player) { return player.id === id; });
};
export var getBankPlayerBalanceFromId = function (id, balances) {
    if (balances === void 0) { balances = []; }
    var foundBalance = balances.find(function (balance) { return id === balance.id; });
    if (foundBalance) {
        return foundBalance.balance;
    }
    return 0;
};
export var isPlayerAdminOrFacilitator = function (rank) {
    return rank === USER_RANK_ADMIN || rank === USER_RANK_FACILITATOR;
};
