import { connect } from 'react-redux';
import Component from './component';
import { adminCreateNewUserAction } from '../../../../redux/actions/admin/admin.actions';
var mapStateToProps = function (state) {
    return {
        players: state.general.players
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        createNewUser: function (payload) { return dispatch(adminCreateNewUserAction(payload)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
