import { NEW_TRANSACTION_WALLET_KIND_BANK, NEW_TRANSACTION_WALLET_KIND_COMPANY, NEW_TRANSACTION_WALLET_KIND_PLAYER, TRANSACTION_KIND_PLAYER } from 'components/player/money/transactions/types';
export var isBankViewingOtherPlayers = function (walletType, isBank) {
    return walletType === NEW_TRANSACTION_WALLET_KIND_BANK && isBank;
};
export var getWalletKind = function (valueFromParams) {
    if (valueFromParams) {
        return valueFromParams === TRANSACTION_KIND_PLAYER
            ? NEW_TRANSACTION_WALLET_KIND_PLAYER
            : NEW_TRANSACTION_WALLET_KIND_COMPANY;
    }
    return NEW_TRANSACTION_WALLET_KIND_BANK;
};
