export var setScoresToCompanies = function (companies, stages, scores) {
    return companies.map(function (company) {
        var companyScores = scores.filter(function (score) { return score.referenceId === company.id; });
        company.scores = stages.map(function (stage) {
            var relevantStageScores = companyScores.find(function (score) { return score.stageId === stage.id; });
            return {
                stageId: stage.id,
                stageName: stage.stageName,
                income: relevantStageScores === null || relevantStageScores === void 0 ? void 0 : relevantStageScores.income,
                expense: relevantStageScores === null || relevantStageScores === void 0 ? void 0 : relevantStageScores.expenses,
                balance: relevantStageScores === null || relevantStageScores === void 0 ? void 0 : relevantStageScores.endBalance,
                startingBalance: company.startingBalance
            };
        });
        return company;
    });
};
export var getAllCompanyTotals = function (company) {
    var scores = company.scores;
    var getTotalValue = function (key) {
        return scores.reduce(function (accumulator, score) {
            return accumulator + score[key];
        }, 0);
    };
    return {
        income: getTotalValue('income'),
        expense: getTotalValue('expense'),
        balance: getTotalValue('balance')
    };
};
export var getCompanyScoresInASingleStage = function (scores, stageId) {
    var _a = scores.find(function (score) { return score.stageId === stageId; }), income = _a.income, expense = _a.expense, balance = _a.balance;
    return {
        income: income,
        expense: expense,
        balance: balance
    };
};
