import { TABLE_FILTER_ALL, TABLE_FILTER_COMPANY, TABLE_FILTER_PERSONAL } from 'components/player/task/constants';
export var getTasksCounts = function (tasks, tableFilterSetting, presetPlayer) {
    return tasks.reduce(function (countObject, task) {
        if (isTableTaskPersonal(tableFilterSetting, task, presetPlayer) ||
            isTableTaskCompany(tableFilterSetting, task, presetPlayer) ||
            tableFilterSetting === TABLE_FILTER_ALL) {
            if (task.status === 3) {
                countObject.tasksCompleted++;
            }
            else if (task.status === 1) {
                countObject.tasksSkipped++;
            }
            else if (task.status === 2) {
                countObject.tasksInProgress++;
            }
            countObject.tasksAssigned++;
        }
        countObject.totalTasks++;
        return countObject;
    }, {
        tasksInProgress: 0,
        tasksAssigned: 0,
        tasksCompleted: 0,
        tasksSkipped: 0,
        totalTasks: 0
    });
};
var isTableTaskPersonal = function (tableFilterSetting, task, presetPlayer) {
    return (tableFilterSetting === TABLE_FILTER_PERSONAL && task.to === presetPlayer.id);
};
var isTableTaskCompany = function (tableFilterSetting, task, presetPlayer) {
    return (tableFilterSetting === TABLE_FILTER_COMPANY && task.to !== presetPlayer.id);
};
