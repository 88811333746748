import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'components/shared/sidebar/container';
import { SIDEBAR_MODE_FACILITATOR } from 'components/constants';
import NewTransaction from 'components/shared/transaction/new/container';
ViewBulkTransaction.propTypes = {
    match: PropTypes.object.isRequired
};
export default function ViewBulkTransaction(_a) {
    var match = _a.match;
    var facilitatorActionType = match.params.facilitatorActionType;
    return (React.createElement(Fragment, null,
        React.createElement(Sidebar, { type: SIDEBAR_MODE_FACILITATOR }),
        React.createElement(NewTransaction, { isFacilitatorBulkView: facilitatorActionType && facilitatorActionType === "mass-transaction" })));
}
