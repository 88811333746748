import { connect } from 'react-redux';
import Component from './component';
import { adminUpdateTemplateAction } from '../../../../../../redux/actions/admin/admin.template.actions';
var mapStateToProps = function (state) {
    return {
        template: state.admin.singleTemplate
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        updateTemplate: function (id, payload) { return dispatch(adminUpdateTemplateAction(id, payload)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
