import React from 'react';
import PropTypes from 'prop-types';
import { USER_RANK_ADMIN, USER_RANK_FACILITATOR } from 'components/permissions/types';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { LANG_OPTION_EN, LANG_OPTION_ET, LANG_OPTION_RU } from 'services/LanguageService/languageService.types';
import clsx from 'clsx';
UserNavigationMenu.propTypes = {
    currentLang: PropTypes.string.isRequired,
    setLanguage: PropTypes.func.isRequired,
    userRank: PropTypes.string.isRequired,
    logoutRequest: PropTypes.func.isRequired,
    onItemClick: PropTypes.func
};
export default function UserNavigationMenu(_a) {
    var currentLang = _a.currentLang, setLanguage = _a.setLanguage, userRank = _a.userRank, logoutRequest = _a.logoutRequest, onItemClick = _a.onItemClick;
    var i18n = useTranslation().i18n;
    return (React.createElement("ul", { className: "list-styles-clear" },
        userRank === USER_RANK_ADMIN ? (React.createElement("li", null,
            React.createElement(Link, { onClick: function () { return onItemClick(); }, to: "/admin" },
                React.createElement(Trans, { i18nKey: "admin" })))) : null,
        userRank === USER_RANK_ADMIN || userRank === USER_RANK_FACILITATOR ? (React.createElement("li", null,
            React.createElement(Link, { onClick: function () { return onItemClick(); }, to: "/facilitator/games" },
                React.createElement(Trans, { i18nKey: "games" })))) : null,
        React.createElement("li", null,
            React.createElement("div", { className: "lang-switch" },
                React.createElement("button", { className: clsx(['lang-switch__item', currentLang === LANG_OPTION_ET && 'lang-switch__item--active']), onClick: function () { return setLanguage(LANG_OPTION_ET, i18n); } }, "et"),
                React.createElement("button", { className: clsx(['lang-switch__item', currentLang === LANG_OPTION_EN && 'lang-switch__item--active']), onClick: function () { return setLanguage(LANG_OPTION_EN, i18n); } }, "en"),
                React.createElement("button", { className: clsx(['lang-switch__item', currentLang === LANG_OPTION_RU && 'lang-switch__item--active']), onClick: function () { return setLanguage(LANG_OPTION_RU, i18n); } }, "ru"))),
        React.createElement("li", null,
            React.createElement("button", { className: "main-button main-button--lg main-button--hollow", onClick: function () { return logoutRequest(); } },
                React.createElement(Trans, { i18nKey: "logout" })))));
}
