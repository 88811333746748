import React from 'react';
import PropTypes from 'prop-types';
import { TRANSACTION_TYPE_DEPOSIT, TRANSACTION_TYPE_REGULAR_TRANSACTION, TRANSACTION_TYPE_WITHDRAW } from 'components/player/money/transactions/types';
import { setT } from 'helpers/translationUtilities';
var TransactionType = function (props) {
    return (React.createElement("div", { className: "infoboxes" },
        React.createElement("div", { className: "infobox__wrapper" },
            React.createElement("div", { className: "infobox__header" },
                React.createElement("div", { className: "infobox__heading" }, setT('choose_transaction_type'))),
            React.createElement("div", { className: "selectionboxes" },
                React.createElement("div", { className: "selectionboxes__item selectionboxes__item--by-two", onClick: function () { return props.onTypeSelect(TRANSACTION_TYPE_WITHDRAW); } },
                    React.createElement("div", { className: "selectionboxes__inner" },
                        React.createElement("div", { className: "selectionboxes__icon selectionboxes__icon--withdraw" }),
                        React.createElement("div", { className: "selectionboxes__title" }, setT('cash_withdraw')))),
                React.createElement("div", { className: "selectionboxes__item selectionboxes__item--by-two", onClick: function () { return props.onTypeSelect(TRANSACTION_TYPE_DEPOSIT); } },
                    React.createElement("div", { className: "selectionboxes__inner" },
                        React.createElement("div", { className: "selectionboxes__icon selectionboxes__icon--deposit" }),
                        React.createElement("div", { className: "selectionboxes__title" }, setT('deposit')))),
                React.createElement("div", { className: "clear" }),
                React.createElement("div", { className: "selectionboxes__item selectionboxes__item--horizontal", onClick: function () {
                        return props.onTypeSelect(TRANSACTION_TYPE_REGULAR_TRANSACTION);
                    } },
                    React.createElement("div", { className: "selectionboxes__inner" },
                        React.createElement("div", { className: "selectionboxes__icon selectionboxes__icon--transaction" }),
                        React.createElement("div", { className: "selectionboxes__title" }, setT('make_payment_internet_bank'))))))));
};
TransactionType.propTypes = {
    onTypeSelect: PropTypes.func
};
TransactionType.defaultProps = {
    onTypeSelect: function () { }
};
export default TransactionType;
