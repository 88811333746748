import { connect } from 'react-redux';
import Component from './component';
import { gameJoinAction } from '../../../../redux/actions/game.actions';
var mapStateToProps = function (state) {
    return {
        players: state.general.players,
        player: state.player,
        isMobilePlayerSelectionOpen: state.userInterface.showMobilePlayerSelection
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        gameJoin: function (token) { return dispatch(gameJoinAction(token)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
