export var NEW_TRANSACTION_INITIAL_VIEW = 1;
export var NEW_TRANSACTION_RECIPIENT_SELECTION_VIEW = 2;
export var NEW_TRANSACTION_CONFIRMATION_VIEW = 3;
export var NEW_TRANSACTION_TYPE_SELECTION_VIEW = 4;
export var NEW_TRANSACTION_WALLET_KIND_PLAYER = 1;
export var NEW_TRANSACTION_WALLET_KIND_COMPANY = 2;
export var NEW_TRANSACTION_WALLET_KIND_BANK = 3;
export var NEW_TRANSACTION_WALLET_FACILITATOR = 4;
export var RECEIVED_TRANSACTION_TYPE_DEPOSIT = 3;
export var RECEIVED_TRANSACTION_TYPE_WITHDRAW = 4;
export var RECEIVED_TRANSACTION_TYPE_TRANSACTION = 5;
export var TRANSACTION_TYPE_REGULAR_TRANSACTION = 1;
export var TRANSACTION_TYPE_WITHDRAW = 2;
export var TRANSACTION_TYPE_DEPOSIT = 3;
export var TRANSACTION_KIND_COMPANY = 'company';
export var TRANSACTION_KIND_PLAYER = 'player';
export var TRANSACTION_KIND_BANK = 'bank';
