import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
Modal.propTypes = {
    children: PropTypes.any,
    expanded: PropTypes.bool,
    onClose: PropTypes.func,
    modalClasses: PropTypes.string,
    modalInnerClasses: PropTypes.string,
    lightboxClasses: PropTypes.string,
    lightboxUnclosable: PropTypes.bool,
    mobileFullScreen: PropTypes.bool,
    innerModalNonFixedWidth: PropTypes.bool,
    fullScreen: PropTypes.bool,
    externalElements: PropTypes.any
};
export default function Modal(_a) {
    var _b = _a.children, children = _b === void 0 ? null : _b, _c = _a.expanded, expanded = _c === void 0 ? false : _c, _d = _a.onClose, onClose = _d === void 0 ? null : _d, _e = _a.modalClasses, modalClasses = _e === void 0 ? '' : _e, _f = _a.modalInnerClasses, modalInnerClasses = _f === void 0 ? '' : _f, _g = _a.lightboxClasses, lightboxClasses = _g === void 0 ? '' : _g, _h = _a.lightboxUnclosable, lightboxUnclosable = _h === void 0 ? false : _h, _j = _a.mobileFullScreen, mobileFullScreen = _j === void 0 ? false : _j, _k = _a.innerModalNonFixedWidth, innerModalNonFixedWidth = _k === void 0 ? false : _k, _l = _a.fullScreen, fullScreen = _l === void 0 ? false : _l, _m = _a.externalElements, externalElements = _m === void 0 ? '' : _m;
    var _o = useState(null), stateExpanded = _o[0], setStateExpanded = _o[1];
    var elementRef = useRef(null);
    useEffect(function () {
        document.addEventListener('mousedown', outsideClickHandler);
        return function () {
            document.removeEventListener('mousedown', outsideClickHandler);
        };
    }, []);
    useEffect(function () {
        setStateExpanded(expanded);
    }, [expanded]);
    function outsideClickHandler(_a) {
        var target = _a.target;
        if (lightboxUnclosable) {
            return;
        }
        if (elementRef.current && !elementRef.current.contains(target)) {
            setStateExpanded(false);
            if (typeof onClose === 'function') {
                onClose();
            }
        }
    }
    if (!stateExpanded) {
        return null;
    }
    return (React.createElement("div", { className: clsx(['lightbox', lightboxClasses, stateExpanded && 'active', fullScreen && 'lightbox--full-screen']) },
        React.createElement("div", { className: clsx(['modal', mobileFullScreen && 'modal--mob-full', modalClasses]) },
            React.createElement("div", { className: clsx(["modal__inner", innerModalNonFixedWidth && "modal__inner--nonfixed-width", modalInnerClasses]), ref: elementRef }, children)),
        externalElements));
}
