import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import NavLink from './link/component';
import { SIDEBAR_MODE_PLAYER } from 'components/constants';
import { getLinkVariations } from 'components/shared/sidebar/utils';
import { objectHasProperty } from 'helpers/utilities';
import { useTranslation } from 'react-i18next';
Sidebar.propTypes = {
    isBank: PropTypes.bool,
    type: PropTypes.string,
    playerId: PropTypes.string,
    companyId: PropTypes.string,
    taskSettings: PropTypes.object
};
export default function Sidebar(_a) {
    var _b = _a.isBank, isBank = _b === void 0 ? false : _b, _c = _a.type, type = _c === void 0 ? SIDEBAR_MODE_PLAYER : _c, _d = _a.playerId, playerId = _d === void 0 ? '' : _d, _e = _a.companyId, companyId = _e === void 0 ? '' : _e, _f = _a.taskSettings, taskSettings = _f === void 0 ? {} : _f;
    var t = useTranslation().t;
    var windowPath = window.location.pathname;
    var _g = useState(windowPath), activePath = _g[0], setActivePath = _g[1];
    function renderLinks(t) {
        var sidebarLinksVariation = getLinkVariations({ variables: {
                playerId: playerId,
                companyId: companyId,
                t: t
            },
            conditions: {
                isBank: isBank,
                tasksPresent: taskSettings.playerCards || taskSettings.companyCards
            } })[type];
        if (sidebarLinksVariation) {
            return (React.createElement(Fragment, null, sidebarLinksVariation.map(function (singleLink) {
                return (objectHasProperty(singleLink, 'condition') &&
                    singleLink.condition) ||
                    !objectHasProperty(singleLink, 'condition') ? (React.createElement(NavLink, { key: singleLink.key, onLinkClick: function () { return setActivePath(activePath); }, to: singleLink.to, icon: singleLink.icon }, singleLink.text)) : null;
            })));
        }
    }
    return React.createElement("div", { className: "sidebar" }, renderLinks(t));
}
