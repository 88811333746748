import { connect } from 'react-redux';
import Component from './component';
import { WEBSOCKET_MESSAGE } from 'components/constants';
var mapStateToProps = function (state) {
    return {
        player: state.player,
        gameTemplates: state.general.gameTemplates
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        websocketMessage: function (msg) {
            return dispatch({ type: WEBSOCKET_MESSAGE, payload: msg });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
