import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
MobCollapseTable.propTypes = {
    options: PropTypes.object,
    config: PropTypes.object,
    onExtensionClick: PropTypes.func.isRequired
};
export default function MobCollapseTable(_a) {
    var config = _a.config, onExtensionClick = _a.onExtensionClick;
    var rows = config.rows, keyValues = config.keyValues, options = config.options;
    return (React.createElement("div", { className: "mob-collapse-table" }, rows.map(function (row) { return (React.createElement(Fragment, { key: row.key },
        React.createElement("div", { className: clsx([
                'mob-collapse-table__item',
                row.childrenVisible && 'mob-collapse-table__item--active'
            ]), onClick: function () { return onExtensionClick(row.key); } }, row.component),
        getChildComponent(row, keyValues, options))); })));
}
function getChildComponent(data, keyValues, options) {
    if (options === void 0) { options = {
        childrenTransparent: false
    }; }
    if (!data.childrenVisible)
        return null;
    if (data.childComponent)
        return data.childComponent;
    if (!data.childRows || !data.childRows.length)
        return;
    return (React.createElement("table", { className: clsx(['mob-collapse-table__children', options.childrenTransparent && 'mob-collapse-table__children--transparent']) },
        React.createElement("thead", null,
            React.createElement("tr", null, keyValues.map(function (value) { return React.createElement("th", { key: value.name }, value.label); }))),
        React.createElement("tbody", null, data.childRows.map(function (childRow) { return (React.createElement("tr", { key: childRow.key, onClick: childRow.onRowClick }, keyValues.map(function (value) { return (React.createElement("td", { key: "".concat(childRow.key, "_").concat(value.name) }, childRow[value.name].component || childRow[value.name])); }))); }))));
}
