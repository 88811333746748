import { connect } from 'react-redux';
import Component from './component';
var mapStateToProps = function (state) {
    return {
        company: {
            id: state.player.company.id,
            name: state.player.company.name,
            totalBalance: state.player.company.totalBalance,
            transactions: state.player.company.transactions,
            description: state.player.company.description
        },
        game: state.game,
        players: state.general.players,
        player: {
            id: state.player.id,
            name: state.player.name,
            totalBalance: state.player.totalBalance,
            company: state.player.company
        },
        stages: state.game.stages,
        tasks: state.player.tasks.items
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        redirectToOnboardingPlayers: function (id) {
            return dispatch({
                type: 'ROUTE',
                to: "/onboarding/players/".concat(id)
            });
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Component);
