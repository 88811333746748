import { getActiveStage, getCurrentlyActiveState, sortStagesByOrdinal } from 'helpers/stageUtilities';
export var isGameLocked = function (stages) {
    var activeStage = getActiveStage(stages);
    if (!activeStage) {
        return false;
    }
    return !!activeStage.pausedAt;
};
export var hasGameStarted = function (stages) {
    if (!stages[0]) {
        return false;
    }
    return stages.some(function (stage) { return stage.active; });
};
export var hasGameEnded = function (stages) {
    var last = sortStagesByOrdinal(stages)[stages.length - 1];
    return last && !!last.endedAt;
};
export var isGameActive = function (stages) {
    var status = false;
    var currentlyActiveStage = getCurrentlyActiveState(stages);
    if (currentlyActiveStage) {
        status = !!currentlyActiveStage.pausedAt;
    }
    return status;
};
export var isGameInShutDownState = function (stages) {
    return isGameActive(stages) || !hasGameStarted(stages) || hasGameEnded(stages);
};
